import React, { useEffect } from 'react'
import { Box, Grid, Hidden, Paper, makeStyles } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'
import { withRouter } from 'react-router'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import HeadBar from '../components/container/diagnosis/HeadBar'
import { useTransition, animated, useSpring } from 'react-spring'
import BottomBar from '../components/container/diagnosis/BottomBar'
import Text from '../components/container/diagnosis/util/Text'
//import ChanelDeveloper from '../components/ChanellDeveloper'
import { useCookies } from 'react-cookie'

const Ready = (props: { history: any }) => {
  //  ChanelDeveloper.shutdown()
  const { history } = props
  const diCardPCHeight = '72vh'
  const diCardSPHeight = window.innerHeight * 0.72 + 'px' //sp sfari chrome では100vh≠window.innerHeight
  const headPCHeight = '20vh'
  const headSPHeight = window.innerHeight * 0.2 + 'px'
  const bottomBarPCHeight = '8vh'
  const bottomBarSPHeight = window.innerHeight * 0.08 + 'px'
  localStorage.removeItem('initOBJs')
  const queryString = require('querystring')
  const parsed = queryString.parse(history.location.search.replace('?', ''))
  const lpParsed = parsed
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])
  if (lpParsed?.lp === 'true') {
    setCookie('lpCookie', parsed, { path: '/' })
  }

  const onClick = () => {
    history.push({
      pathname: '/diagnosis'
    })
  }
  const animationFade = useSpring({
    delay: 1000,
    config: { duration: 1000 },
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  const useStyles = makeStyles({
    baseBox: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })

  const classes = useStyles()

  const PC = () => {
    //   ChanelDeveloper.shutdown()

    return (
      <>
        <Box width="100%" height={headPCHeight} display="flex" justifyContent="center" alignItems="flex-end">
          <Box width="450px">
            <Box>
              <HeadBar sectionNumber={0} />
            </Box>
          </Box>
        </Box>
        <Box width="100%" height={diCardPCHeight} display="flex" justifyContent="center" alignItems="center">
          <Box width="450px" m={'auto'}>
            <Text fw="bold" fs={18} lh={'40px'} ls={'0.1em'} style={{ marginTop: '16px', textAlign: 'center' }}>
              3つのセクションに分けて診断を実施し、
              <br />
              あなた専用のサプリメントをお作りいたします。
              <br />
              <br />
              準備はできていますか？
            </Text>
            <Box
              mt={8}
              className={classes.baseBox}
              margin="auto"
              onClick={onClick}
              width={'176px'}
              height={'52px'}
              borderRadius={'50px'}
              display="flex"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              textAlign="center"
              style={{
                backgroundColor: '#041E42',
                fontWeight: 'bold',
                fontSize: '13px',
                lineHeight: '140%',
                letterSpacing: '0.1em',
                padding: 0,
                color: '#fff'
              }}
            >
              <Text fw={'boald'} fs={14} lh="140%" ls={'0.1em'} style={{ color: 'white' }}>
                診断を始める
              </Text>
            </Box>
          </Box>
        </Box>
        <Box width="100%" height={bottomBarPCHeight} bottom="0px" position="fixed">
          <BottomBar onClick={() => {}} leftButtonDisable={true} rightButtonDisable={true} />
        </Box>
      </>
    )
  }
  const SP = () => {
    // ChanelDeveloper.shutdown()
    return (
      <>
        <Box width="100%" height={headSPHeight} display="flex" justifyContent="center" alignItems="flex-end">
          <Box width="250px">
            <Box>
              <HeadBar sectionNumber={0} />
            </Box>
          </Box>
        </Box>
        <Box width="100%" height={diCardSPHeight} display="flex" justifyContent="center" alignItems="center">
          <Box width="250px" m={'auto'}>
            <Text fw="bold" fs={14} lh={'30px'} ls={'0.1em'} style={{ marginTop: '16px', textAlign: 'center' }}>
              3つのセクションに分けて
              <br />
              診断を実施し、 あなた専用の
              <br />
              サプリメントをお作りいたします。
              <br />
              <br />
              準備はできていますか？
            </Text>
            <Box
              mt={8}
              className={classes.baseBox}
              margin="auto"
              onClick={onClick}
              width={'176px'}
              height={'52px'}
              borderRadius={'50px'}
              display="flex"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              textAlign="center"
              style={{
                backgroundColor: '#041E42',
                fontWeight: 'bold',
                fontSize: '13px',
                lineHeight: '140%',
                letterSpacing: '0.1em',
                padding: 0,
                color: '#fff'
              }}
            >
              <Text fw={'boald'} fs={14} lh="140%" ls={'0.1em'} style={{ color: 'white' }}>
                診断を始める
              </Text>
            </Box>
          </Box>
        </Box>
        <Box width="100%" height={bottomBarSPHeight} bottom="0px" position="fixed">
          <BottomBar onClick={() => {}} leftButtonDisable={true} rightButtonDisable={true} />
        </Box>
      </>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <animated.div style={animationFade}>
          <SP />
        </animated.div>
      </Hidden>
      <Hidden mdDown>
        <animated.div style={animationFade}>
          <PC />
        </animated.div>
      </Hidden>
    </>
  )
}

export default withRouter(Ready)
