import React, { useState, useEffect } from 'react'
import Styled from 'styled-components'
import Header from '../components/Header'
import autoImgSizeStyle from '../common/styleFunctions'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { url } from 'inspector'
import Grid from '@material-ui/core/Grid'
import { textAlign } from '@material-ui/system'
import Container from '@material-ui/core/Container'
import { testProblemChecker } from '../common/problemCheckers'
import { ConversionButton } from '../components/Header'
import { ConversionMessage } from '../components/Header'
//import ChanelDeveloper from '../components/ChanellDeveloper'

const maxWidth: string = '1280px'
const vitamine = 'VITAMINE'
const mineral = 'MINERAL'
const fishoil = 'FISH OIL'
const energy = 'ENERGY'
const relax = 'RELAX'
const focus = 'FOCUS'
const memory = 'MEMORY'
const vitality = 'VITALITY'
const stylish = 'STYLISH'

const Arrow = Styled.img.attrs({
  src: 'arrow_header.png'
})`
  display: flex;
  flex-basis: 10%;
  padding-left: 3px;
`

const Supplements = () => {
  // ChanelDeveloper.boot({
  //   pluginKey: '8324e2a5-9a85-4d44-a57a-2118a321f35b' //please fill with your plugin key
  // })

  // testProblemChecker();
  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          fontFamily: 'Cera Pro'
        }}
      >
        <Header />
        <Title />
        <SupplementList />
        <IhackExplaining />
        <IhackProcess />
        <IhackBanner />
        <IhackFooter />
      </Container>
    </>
  )
}

const Title = () => {
  return (
    <>
      <Container maxWidth={'md'} style={{ textAlign: 'center' }}>
        <Box margin="0 auto">
          <h2
            style={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '32px',
              lineHeight: '108px',
              letterSpacing: '0.1em',
              color: '#041E42',
              textAlign: 'center'
            }}
          >
            SUPPLEMENTS
          </h2>
        </Box>
      </Container>
    </>
  )
}

const SupplementList = () => {
  return (
    <>
      <Box
        style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          backgroundImage: `url(./Vector8.png)`
        }}
      >
        <SupplementCardsTilted1 />
        <SupplementCardsTilted2 />
        <SupplementCardsTilted3 />
      </Box>
    </>
  )
}

//詳しくみる→
const SupplementCardDetail = () => {
  return (
    <>
      <Box
        style={{
          position: 'absolute',
          left: '165px',
          top: '324px',
          display: 'flex',
          width: '94px',
          height: '24px',
          fontFamily: 'YuGothic',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '12px',
          lineHeight: '23px',
          alignItems: 'cente',
          letterSpacing: '0.03em',
          color: '#041E42'
        }}
      >
        <p
          style={{
            width: '77px',
            height: '24px'
          }}
        >
          詳しくみる→
        </p>
      </Box>
    </>
  )
}

//ビタミンのカード
const SupplementCardVitamin = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameVitamin />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameVitamin = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {vitamine}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//ミネラルのカード
const SupplementCardMineral = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '40px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameMineral />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameMineral = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {mineral}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardMineral2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameMineral />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//フィッシュオイルのカード
const SupplementCardFishOil = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '80px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameFishOil />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameFishOil = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {fishoil}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardFishOil2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameFishOil />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//エネルギーのカード
const SupplementCardEnergy = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameEnergy />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameEnergy = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {energy}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}

//リラックスのカード
const SupplementCardRelax = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '40px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameRelax />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameRelax = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {relax}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardRelax2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameRelax />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//フォーカスのカード
const SupplementCardFocus = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '80px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameFocus />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameFocus = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {focus}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardFocus2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameFocus />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//メモリーのカード
const SupplementCardMemory = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameMemory />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameMemory = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {memory}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}

//バイタリティーのカード
const SupplementCardVitality = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '40px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameVitality />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameVitality = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {vitality}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardVitality2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameVitality />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//スタイリッシュのカード
const SupplementCardStylish = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          marginTop: '80px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameStylish />
        <SupplementCardDetail />
      </Box>
    </>
  )
}
const SupplementNameStylish = () => {
  return (
    <>
      <Box margin="0 auto" style={{ width: '216px' }}>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'left'
          }}
        >
          {stylish}
        </p>
        <ul
          style={{
            paddingLeft: '19px',
            fontFamily: 'YuGothic',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px'
          }}
        >
          <li>ビタミンA</li>
          <li>ナイアシン</li>
          <li>ルテイン</li>
          <li>BioPerine®</li>
        </ul>
      </Box>
    </>
  )
}
const SupplementCardStylish2 = () => {
  return (
    <>
      <Box
        style={{
          position: 'relative',
          width: '264px',
          height: '373px',
          borderRadius: '8px',
          boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.05)',
          cursor: 'pointer'
        }}
      >
        <Box
          style={{
            backgroundImage: `url(./bin.png)`,
            width: '264px',
            height: '163px'
          }}
        ></Box>
        <SupplementNameStylish />
        <SupplementCardDetail />
      </Box>
    </>
  )
}

//一覧横１列目
export const SupplementCards1 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <SupplementCardVitamin />

        <SupplementCardMineral2 />

        <SupplementCardFishOil2 />
      </Box>
    </>
  )
}

//一覧横２列目
export const SupplementCards2 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <SupplementCardEnergy />
        <SupplementCardRelax2 />
        <SupplementCardFocus2 />
      </Box>
    </>
  )
}
//一覧横３列目
export const SupplementCards3 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <SupplementCardMemory />
        <SupplementCardVitality2 />
        <SupplementCardStylish2 />
      </Box>
    </>
  )
}

//一覧横１列目斜めに並べたレイアウト
export const SupplementCardsTilted1 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <Link href="#">
          <SupplementCardVitamin />
        </Link>
        <Link href="#">
          <SupplementCardMineral />
        </Link>
        <Link href="#">
          <SupplementCardFishOil />
        </Link>
      </Box>
    </>
  )
}

//一覧横２列目斜めに並べたレイアウト
export const SupplementCardsTilted2 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <Link href="#">
          <SupplementCardEnergy />
        </Link>
        <Link href="#">
          <SupplementCardRelax />
        </Link>
        <Link>
          <SupplementCardFocus />
        </Link>
      </Box>
    </>
  )
}
//一覧横３列目斜めに並べたレイアウト
export const SupplementCardsTilted3 = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '840px',
          height: '453px',
          margin: '0 auto'
        }}
      >
        <SupplementCardMemory />
        <SupplementCardVitality />
        <SupplementCardStylish />
      </Box>
    </>
  )
}

//透明性のある情報を〜〜〜
const IhackExplaining = () => {
  return (
    <Box
      style={{
        width: '100%',
        height: '671px',
        padding: '130px 0',
        backgroundImage: `url(./Rectangle414.png)`
      }}
    >
      <Box
        style={{
          width: '670px',
          margin: '0 auto'
        }}
      >
        <h2
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '32px',
            lineHeight: '108px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'center'
          }}
        >
          透明性のある情報を
        </h2>
        <p
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontSize: '14px',
            letterSpacing: '0.1em',
            color: '#041E42',
            lineHeight: '32px',
            textAlign: 'center'
          }}
        >
          我々は、製造に関わる全ての過程とその原価を開示しています。
          <br />
          お客様に安心してご購入していただくべく、国内最高品のプロダクトをご提供していきます。
        </p>
        <img
          src={'icons.png'}
          style={{
            marginTop: '65px'
          }}
        />
      </Box>
    </Box>
  )
}

//iHackができるまでの過程
const IhackProcess = () => {
  return (
    <Box
      style={{
        width: '80%',
        height: '671px',
        margin: '0 auto',
        padding: '130px 0',
        backgroundImage: `url(./shadow.png)`
      }}
    >
      <Box
        style={{
          width: '670px',
          margin: '0 auto'
        }}
      >
        <h2
          style={{
            fontFamily: 'Cera Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '32px',
            lineHeight: '108px',
            letterSpacing: '0.1em',
            color: '#041E42',
            textAlign: 'center'
          }}
        >
          iHackができるまでの過程
        </h2>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}
      >
        <Box
          style={{
            width: '400px',
            backgroundImage: `url(slider/1.png)`,
            backgroundSize: 'contain'
          }}
        >
          {/* <img
            src="slider/1.png"
            alt=""
            style={{
              width: "80%",
              height: "auto"
            }} */}
          />
        </Box>

        <Box>
          <img
            src="slider/2.png"
            alt=""
            style={{
              width: '80%',
              height: 'auto'
            }}
          />
        </Box>

        <Box>
          <img
            src="slider/3.png"
            alt=""
            style={{
              width: '80%',
              height: 'auto'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const IhackBanner = () => {
  return (
    <>
      <Box width="100%">
        <Box
          style={{
            position: 'relative'
          }}
        >
          <Box>
            <img src="ihack_banner 1.png" alt="" style={{ width: '100%' }} />
          </Box>
          <Box
            style={{
              position: 'absolute',
              top: '25%',
              right: '15%',
              height: '160px'
            }}
          >
            <p
              style={{
                marginBottom: '60px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '32px',
                lineHeight: '40px',
                letterSpacing: '0.1em',
                color: '#041E42'
              }}
            >
              PERSONALIZED BOX
            </p>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.16em',
                color: '#041E42'
              }}
            >
              パフォーマンスを引き出す定期コース。
              <br />
              まずはあなただけのオリジナル処方を
              <br />
              ご覧ください。
            </p>
            <ConversionButton style={{ marginTop: '36px' }}>
              <ConversionMessage>まずは無料診断する</ConversionMessage>
              <Arrow></Arrow>
            </ConversionButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const IhackFooter = () => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '200px',
          backgroundImage: `url(./footer_bg.png)`
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '1080px',
            margin: '0 auto'
          }}
        >
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '101px',
              height: '102px',
              margin: '46px auto 52px',
              padding: '0',
              listStyle: 'none',
              letterSpacing: '0.145em'
            }}
          >
            <li
              style={{
                width: '100%',
                height: '33.333%',
                fontSize: '18px'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                STORY
              </Link>
            </li>
            <li
              style={{
                width: '100%',
                height: '33.333%',
                fontSize: '18px',
                color: '#FFF'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                PRODUCT
              </Link>
            </li>
            <li
              style={{
                width: '100%',
                height: '33.333%',
                fontSize: '18px'
              }}
            >
              <img
                src="./login_footer.png"
                style={{
                  width: '16px',
                  height: 'auto',
                  marginRight: '5px'
                }}
              />
              <Link
                href=""
                style={{
                  color: '#fff',
                  lineHeight: '3px'
                }}
              >
                LOGIN
              </Link>
            </li>
          </ul>
          <Box
            style={{
              width: '536px',
              margin: '76px auto'
            }}
          >
            <Box
              style={{
                width: '116px',
                margin: '0 auto'
              }}
            >
              <img
                src="logo_footer.png"
                style={{
                  width: '120px',
                  height: 'auto'
                }}
              />
            </Box>
          </Box>
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '161px',
              height: '120px',
              margin: '40px auto',
              padding: '0',
              textAlign: 'right',
              listStyle: 'none'
            }}
          >
            <li
              style={{
                width: '100%',
                height: '25%',
                fontSize: '14px'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                プライバシーポリシー
              </Link>
            </li>
            <li
              style={{
                width: '100%',
                height: '25%',
                fontSize: '14px',
                color: '#FFF'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                特定商取引に基づく表記
              </Link>
            </li>
            <li
              style={{
                width: '100%',
                height: '25%',
                fontSize: '14px',
                color: '#FFF'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                利用規約
              </Link>
            </li>
            <li
              style={{
                width: '100%',
                height: '25%',
                fontSize: '14px',
                color: '#FFF'
              }}
            >
              <Link
                href=""
                style={{
                  color: '#FFF'
                }}
              >
                運営会社
              </Link>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  )
}

export default Supplements
