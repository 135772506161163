import React from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { Question } from '../../../types/Score'
import TextFiled from './AnsFiledTypes/TextFiled'
import PullDown from './AnsFiledTypes/PullDown'
import FiledButton from './AnsFiledTypes/FiledButton'
import RadioButton from './AnsFiledTypes/RadioButton'
import FQRadioButton from './AnsFiledTypes/FQRadio'
import CheckBox from './AnsFiledTypes/CheckBox'
import Allergie from './AnsFiledTypes/Allergie'
import MostWorrie from './AnsFiledTypes/MostWorrie'
import { Answer, MostWories, WoriesObj, Allergie as Allergies } from '../../../types/Score/index'
import { makeStyles } from '@material-ui/core/styles'
import Email from './AnsFiledTypes/EmailFiled'

type Props = {
  question: Question
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (isReverse: boolean) => void
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  setWories: React.Dispatch<React.SetStateAction<MostWories[]>>
  worries: MostWories[]
  mostWories: MostWories
  setMostWories: React.Dispatch<React.SetStateAction<MostWories>>
  allergie: Allergies[]
  setAllergie: React.Dispatch<React.SetStateAction<Allergies[]>>
  moveResult: (emai?: string) => void
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
}

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },

  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: '16px',
    color: '#041E42'
  },
  context: {
    marginBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    color: '#041E42'
  },
  icon: {
    textAlign: 'center'
  },
  radioForm: {
    marginBottom: 12,
    textAlign: 'center',
    display: 'inline-block',
    width: '150px'
  },
  radioIcon: {
    background: '#fff',
    width: 52,
    height: 52,
    border: '2px solid #AFAFAF',
    borderRadius: '50%'
  },
  radioCheckedIcon: {
    background: '#041E42',
    width: 42,
    height: 42,
    border: '8px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 0 0 2px #041E42'
  },
  radioIconSP: {
    background: '#fff',
    width: 32,
    height: 32,
    border: '2px solid #AFAFAF',
    borderRadius: '50%'
  },
  radioCheckedIconSP: {
    background: '#041E42',
    width: 24,
    height: 24,
    border: '6px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 0 0 2px #041E42'
  },
  selectForm: {
    margin: 0,
    minWidth: 400,
    maxWidth: 400,
    width: '100%',
    borderRadius: 4,
    border: '2px solid #041E42',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#041E42'
    },
    textAlign: 'center'
  },
  selectFormSP: {
    margin: 0,
    minWidth: 280,
    maxWidth: 400,
    width: '100%',
    borderRadius: 4,
    border: '1px solid #041E42',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#041E42'
    },
    textAlign: 'center'
  }
})

const AnsCard = (props: Props) => {
  const {
    question,
    answer,
    onMoveAnimation,
    name,
    setName,
    mostWories,
    setMostWories,
    setAnswer,
    worries,
    setWories,
    allergie,
    setAllergie,
    moveResult,
    email,
    setEmail
  } = props

  const SwitchAnswer = () => {
    switch (question.type) {
      case 'PullDown':
        return (
          <PullDown
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
          />
        )
      case 'RadioButton':
        return (
          <RadioButton
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
          />
        )
      case 'CheckBoxs':
        return (
          <CheckBox
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            worries={worries}
            setWories={setWories}
          />
        )
      case 'TextInput':
        return (
          <TextFiled
            question={question}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            diagnosisName={name}
            setDiagnosName={setName}
          />
        )
      case 'FiledButton':
        return (
          <FiledButton
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
          />
        )
      case 'TextFiled':
        return (
          <TextFiled
            question={question}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            diagnosisName={name}
            setDiagnosName={setName}
          />
        )
      case 'WorriedSelect':
        return (
          <MostWorrie
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            worries={worries}
            mostWorries={mostWories}
            setMostWorries={setMostWories}
          />
        )
      case 'Allergie':
        return (
          <Allergie
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            allergie={allergie}
            setAllergie={setAllergie}
            moveResult={moveResult}
          />
        )
      case 'FinalQuestionRadio':
        return (
          <FQRadioButton
            question={question}
            useStyles={useStyles}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            moveResult={moveResult}
          />
        )
      case 'Email':
        return (
          <Email
            question={question}
            answer={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            diagnosisName={email}
            setDiagnosName={setEmail}
            moveResult={moveResult}
          />
        )
      default:
        return <p style={{ margin: 0, padding: 0 }}>どこにも属さず</p>
    }
  }

  const SP = () => {
    return (
      <>
        <Box width="100%" justifyContent="center" alignItems="space-between">
          <Box>
            <SwitchAnswer />
          </Box>
        </Box>
      </>
    )
  }
  const PC = () => {
    return (
      <>
        <Box width="100%" display="flex" justifyContent="center" alignItems="space-between">
          <Box>
            <SwitchAnswer />
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <SP />
      </Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </>
  )
}

export default AnsCard
