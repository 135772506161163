import React from 'react'

const PhysicalConditionIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.0902 31.6289C51.6503 29.8761 51.4411 29.656 47.4729 21.7197C46.7529 20.2794 45.3052 19.3848 43.6951 19.3848H39.9859C38.6719 16.8148 37.6809 15.493 39.4954 13.2249C40.2311 12.3054 39.5752 10.9375 38.396 10.9375H23.3786C22.9509 10.9375 22.5464 11.1319 22.2792 11.4659C19.7342 14.6471 20.7371 17.3279 21.7887 19.3847H18.0795C16.4694 19.3847 15.0217 20.2794 14.3017 21.7196C10.3298 29.6632 10.1241 29.8771 9.68446 31.6286C9.65158 31.7319 9.29968 33.0734 9.29968 34.7146V46.1346C9.29968 48.4635 11.1944 50.3583 13.5233 50.3583H48.2512C50.5801 50.3583 52.4748 48.4635 52.4748 46.1346V34.7146C52.4748 33.1966 52.1617 31.8538 52.0902 31.6289ZM24.0939 13.7532H35.9994C35.1457 16.2709 36.275 18.3949 37.153 20.0459C38.2363 22.0829 38.7875 23.3116 37.6807 25.0163H25.7751C26.6254 22.5095 25.5117 20.3973 24.6215 18.7236C23.5382 16.6865 22.9871 15.458 24.0939 13.7532ZM16.8202 22.9788C17.0602 22.4988 17.5427 22.2006 18.0795 22.2006H23.1122C23.393 23.1514 23.3231 23.9992 22.6628 25.0163H19.1549C18.3774 25.0163 17.747 25.6466 17.747 26.4242C17.747 27.2017 18.3773 27.8321 19.1549 27.8321C19.5823 27.8321 41.6613 27.8321 42.6197 27.8321C43.3972 27.8321 44.0276 27.2018 44.0276 26.4242C44.0276 25.6467 43.3973 25.0163 42.6197 25.0163H40.7922C41.1323 24.0136 41.1581 23.0733 41.0102 22.2006H43.6952C44.2319 22.2006 44.7145 22.4988 44.9545 22.9788C48.9454 30.9601 48.6548 30.371 48.7799 30.6479H12.9948C13.1198 30.3715 12.8346 30.9499 16.8202 22.9788ZM49.659 46.1346C49.659 46.9109 49.0274 47.5425 48.2511 47.5425H13.5233C12.747 47.5425 12.1154 46.9109 12.1154 46.1346C12.1154 33.6023 12.0852 34.3309 12.197 33.4636H49.5774C49.6308 33.8786 49.6589 34.2966 49.6589 34.7146L49.659 46.1346Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default PhysicalConditionIcon
