import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button, makeStyles, Drawer, Hidden, Card, Container, Grid } from '@material-ui/core'

import '../../../CeraPro/stylesheet.css'
import autoImgSizeStyle from '../../../common/styleFunctions'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const H2CarePro = Styled.h2`
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 35px;
letter-spacing: 0.1em;
color: #041E42;
margin:0;
padding:0;
`

const ButtonYuGothic = Styled(Button)`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  /* or 196% */
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #041E42;
`

const SpH2CarePro = Styled.h2`
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 35px;
letter-spacing: 0.1em;
color: #041E42;
margin:0;
padding:0;
`

const SpLiYuGothic = Styled.h3`
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 10px;
line-height: 20px;
letter-spacing: 0.1em;
color: #041E42;
textAlign: 'justify'
`
const PSpYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 23px;
display: flex;
align-items: center;
letter-spacing: 0.03em;
textAlign: 'justify'
color: #041E42;
`

const H3YuGothic = Styled.h3`
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.12em;
color: #041E42;
textAlign: 'justify'
`

type Supplement = {
  title: string
  ingredient: string[]
  discription: string
  imgURL: string
}
const supplements: Supplement[] = [
  {
    title: 'Vitamin',
    ingredient: ['ビタミンA', 'ナイアシン', 'ルテイン', 'BioPerine'],
    imgURL: 'Vitamin.jpg',
    discription:
      '体内ではいくつものビタミン・ミネラルがお互いに作用しています。栄養素の偏りが問題視される現代だからこそ不足しがちなビタミンを贅沢に配合しました。'
  },
  {
    title: 'Mineral',
    ingredient: ['亜鉛', 'クロム', '高麗人参エキス', 'EC-12(乳酸菌)'],
    imgURL: 'Mineral.jpg',
    discription:
      'ビタミンとともに、健康のベースメントになるミネラル。日々忙しく、バランスのよい食事を取れていない方のために、複数種類のミネラルを贅沢に配合しました。'
  },
  {
    title: 'Omega3',
    ingredient: ['クリルオイル', 'DHA/EPA（フィッシュオイル）', 'アザラシ精製油'],
    imgURL: 'Omega3.jpg',
    discription:
      '男女ともに広く支持されるフィッシュオイル。最近では、アスリートの間でも愛飲されています。日々、頭と体を使うビジネスリーダーのために。'
  },
  {
    title: 'Recovery',
    ingredient: ['OleaVita®', 'イミダゾールジペプチド', 'コエンザイムQ10', 'HMBカルシウム '],
    imgURL: 'Recovery.jpg',
    discription:
      '頑張れば頑張るほど、消耗していくあなたの体。忙しい日々のなかで、体のケアをするのは難しいことです。Recoveryは、抗疲労や疲労回復に関する成分を贅沢に配合しました。'
  },
  {
    title: 'Chill',
    ingredient: ['ロディオラ・ロゼア', 'GABA', 'L-トリプトファン ', 'マグネシウム'],
    imgURL: 'Chill.jpg',
    discription:
      '忙しい日々の中で、十分に休む時間を確保するのは難しいこと。Chillには、リラックスやストレス、睡眠に関わる４種類の成分を贅沢に配合しています。'
  },
  {
    title: 'Zone',
    ingredient: ['カフェイン', 'L-テアニン', 'バコパエキス', 'L-フェニルアラニン'],
    imgURL: 'Zone.jpg',
    discription:
      '集中を持続させることが難しいと言われている現代。Zoneは、カフェインをはじめ、集中に関わる成分を贅沢に配合しています。日々高いパフォーマンスを求めるビジネスアスリートのために'
  },
  {
    title: 'Memory',
    ingredient: ['ホスファチジルセリン', 'イチョウ葉エキス', 'レモンバーム', 'ビタミンD'],
    imgURL: 'Memory.jpg',
    discription:
      '現代人が1日に触れる情報量は、平安時代の一生分、江戸時代の1年分とも言われています。Memoryは記憶に関わる成分を贅沢に配合しています。'
  },
  {
    title: 'Vitality',
    ingredient: ['マカ', '黒マカ', 'ガラナ', '雪蓮花エキス'],
    imgURL: 'Vitality.jpg',
    discription:
      'どんなに強い人でも、老いには敵わない。ひとりでも多くの人が、何歳になっても自信に満ちた自分でいて欲しい。Vitalityには、滋養強壮に関わる成分を贅沢に配合しました。'
  },
  {
    title: 'Style',
    ingredient: ['a-リボ酸', 'コエンザイムQ10', 'L-カルニチン', 'L-オルニチン'],
    imgURL: 'Style.jpg',
    discription:
      '無理なダイエットは、辛く苦しいもの。ひとりで追い込まず、たまには誰かの力を借りてもいいのではないでしょうか。Styleは、ダイエット系の成分を贅沢に配合しています。'
  }
]

const useStyles = makeStyles({
  baseBox: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '8px 8px 8px 8px rgba(100,100,100,.1)'
    }
  }
})

const DragCard = (props: {
  ingredient: 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Chill' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  DetailDrowOpen: (
    name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  ) => void
}) => {
  const { ingredient, DetailDrowOpen } = props
  const pickSp = supplements.filter((c) => c.title === ingredient)[0]

  const worries: (
    | 'agingcare'
    | 'eyecare'
    | 'immunity'
    | 'fatigue'
    | 'sleep'
    | 'stress'
    | 'memory'
    | 'depression'
    | 'fitness'
    | 'focus'
    | 'vitality'
    | 'diet'
  )[] = []

  switch (ingredient) {
    case 'Vitamin':
      worries.push('agingcare', 'eyecare', 'immunity')
      break
    case 'Mineral':
      worries.push('fatigue', 'sleep', 'immunity')
      break
    case 'Omega3':
      worries.push('stress', 'memory', 'depression')
      break
    case 'Recovery':
      worries.push('fatigue', 'fitness', 'immunity')
      break
    case 'Chill':
      worries.push('sleep', 'stress', 'depression')
      break
    case 'Zone':
      worries.push('focus', 'stress')
      break
    case 'Memory':
      worries.push('memory', 'depression')
      break
    case 'Vitality':
      worries.push('vitality', 'fatigue', 'immunity')
      break
    case 'Style':
      worries.push('diet', 'fitness', 'vitality')
      break
  }

  const classes = useStyles()
  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          className={classes.baseBox}
          width="280px"
          height="390px"
          boxShadow={1}
          borderRadius={10}
          bgcolor="#fff"
          onClick={() => DetailDrowOpen(ingredient)}
        >
          <Box
            width="100%"
            height="166px"
            m={0}
            p={0}
            overflow="hidden"
            style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
          >
            <img
              src={'common/PC_' + ingredient + '.png'}
              style={{
                width: '101%',
                marginLeft: '-1%',
                marginTop: '-1%',
                textAlign: 'center',
                objectFit: 'cover'
              }}
            />
          </Box>

          <Box mx={3}>
            <Box mt={4} mb={1}>
              <H2CarePro>{pickSp?.title}</H2CarePro>
            </Box>

            <Box height="100px" overflow="hidden">
              <H3YuGothic>{pickSp.discription.substr(0, 40)}...</H3YuGothic>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                {worries.map((value: string) => {
                  return <img src={`result_${value}_logo.png`} width="35px" style={{ marginRight: '2px' }} />
                })}
              </Box>

              <Button
                onClick={() => {
                  DetailDrowOpen(ingredient)
                }}
                style={{ fontFamily: 'YuGothic', color: '#041E42' }}
              >
                詳しく見る→
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box
          width="225px"
          height="316px"
          boxShadow={4}
          borderRadius={10}
          bgcolor="#fff"
          onClick={() => DetailDrowOpen(ingredient)}
        >
          <Box
            width="100%"
            height="138px"
            overflow="hidden"
            style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
          >
            <img
              src={'common/PC_' + ingredient + '.png'}
              style={{
                objectFit: 'cover',
                width: '101%',
                marginLeft: '-1%',
                marginTop: '-1%',
                textAlign: 'center'
              }}
            />
          </Box>

          <Box mx={2}>
            <Box mt={2}>
              <SpH2CarePro>{pickSp?.title}</SpH2CarePro>
            </Box>

            <Box height="85px" overflow="hidden">
              <SpLiYuGothic>{pickSp.discription.substr(0, 48)}...</SpLiYuGothic>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                {worries.map((value: string) => {
                  return <img src={`result_${value}_logo.png`} width="35px" />
                })}
              </Box>

              <PSpYuGothic>詳しく見る→</PSpYuGothic>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DragCard
