import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button, Drawer, Hidden, Card, Container, Grid } from '@material-ui/core'
import '../../../CeraPro/stylesheet.css'

import autoImgSizeStyle from '../../../common/styleFunctions'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const H1YuGothic = Styled.h1`
  margin-top:0;
  padding-top:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.1em;
  color: #041E42;
`

const PYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: #041E42;
`

const H1SpYuGothic = Styled.h1`
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #041E42;
`

const PSpYuGothic = Styled.p`
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: #041E42;
`

const texts: {
  id: string
  title: string
  statementText: string
  recommendText: string
}[] = [
  {
    id: 'relax',
    title: '必要な場面で、必要以上のパフォーマンスを。',
    statementText:
      '高いパフォーマンスを発揮するためには、日頃からの綿密なケアが欠かせません。\n\n忙しい日々の中でも、休む時間の質を限りなく高められるように。\nそしてワーキングメモリを開放し、%name%さんが目の前の仕事に没頭できるように。\n\nそんなエールを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、\n%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 *Vitamin・Mineral・Omega3* 。\n\n休息を促す*Chill*。ワーキングメモリを開放する*Memory*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、\nサプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'diet',
    title: 'もっと遠くへ、もっと上へ。',
    statementText:
      '常に最高の自分でいるために、もっと自分の身体を絞りたい。\nそんな想いはあれど、生活習慣を変えることは容易ではありません。\n\nさらに、無理なダイエットはむしろ身体を壊す恐れがあり、長続きはしません。\nちゃんと自分の身体を労りつつも、%name%さんがスタイリッシュな身体を目指せるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、\n%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 *Vitamin・Mineral・Omega3* 。\n\nコンディションを整える*Recovery*。スタイリッシュな体をつくる*Style*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、\nサプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'memory',
    title: 'いつまでも、自分のために、自分らしく。',
    statementText:
      'どんなに強い人間でも、老いには敵いません。\n\nいつまでも、若々しい身体を保つためには、日頃からのケアが必要不可欠。\n%name%さんが何歳になっても自信に満ちた自分の身体でいられるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、\n%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 *Vitamin・Mineral・Omega3* 。\n\n仕事に没頭するための*Zone*。持久力を保持する*Vitality*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、\nサプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'care',
    title: '勝敗は、戦う前に決まっている。',
    statementText:
      'ハードワークで疲労が溜まりがちな日々、しっかりとケアはできていますか？\n\n「何事も準備8割」と言われるように、パフォーマンスを発揮するためにも、心と身体のケアが不可欠です。\n%name%さんが毎日高いパフォーマンスを発揮するための、コンディショニングケアができるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、\n%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 *Vitamin・Mineral・Omega3* 。\n\n休息を促す*Chill*。コンディションを整える*Recovery*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、\nサプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'focus',
    title: '100%じゃない。120％のパフォーマンス。',
    statementText:
      '「普通の成果」では満足しないあなたのために。\n\nいつだって、圧倒的なパフォーマンスは、圧倒的な熱量から生まれるものです。\n%name%さんが自分の世界に没入し、そして120％のパフォーマンスを発揮できるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、\n%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 *Vitamin・Mineral・Omega3* 。\n\nワーキングメモリを開放する*Memory*。仕事に没頭するための*Zone*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、\nサプリメントを通じて、日々の挑戦を支えていきます 。'
  }
]
const spTexts: {
  id: string
  title: string
  statementText: string
  recommendText: string
}[] = [
  {
    id: 'relax',
    title: '必要な場面で、\n必要以上の\nパフォーマンスを。',
    statementText:
      '高いパフォーマンスを発揮するためには、日頃からの綿密なケアが欠かせません。\n忙しい日々の中でも、休む時間の質を限りなく高められるように。そしてワーキングメモリを開放し、%name%さんが目の前の仕事に没頭できるように。\nそんなエールを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、 \n*Vitamin・Mineral・Omega3*。\n\n休息を促す*Chill*。\n\nワーキングメモリを開放する*Memory*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、サプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'diet',
    title: 'もっと遠くへ、\nもっと上へ。',
    statementText:
      '常に最高の自分でいるために、もっと自分の身体を絞りたい。そんな想いはあれど、生活習慣を変えることは容易ではありません。\nさらに、無理なダイエットはむしろ身体を壊す恐れがあり、長続きはしません。ちゃんと自分の身体を労りつつも、%name%さんがスタイリッシュな身体を目指せるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、\n*Vitamin・Mineral・Omega3*。\n\nコンディションを整える*Recovery*。\n\nスタイリッシュな体をつくる*Style*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、サプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'memory',
    title: 'いつまでも、\n自分のために、\n自分らしく。',
    statementText:
      'どんなに強い人間でも、老いには敵いません。\nいつまでも、若々しい身体を保つためには、日頃からのケアが必要不可欠。%name%さんが何歳になっても自信に満ちた自分の身体でいられるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、\n*Vitamin・Mineral・Omega3*。\n\n仕事に没頭するための*Zone*。\n\n持久力を保持する*Vitality*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、サプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'care',
    title: '勝敗は、戦う前に\n決まっている。',
    statementText:
      'ハードワークで疲労が溜まりがちな日々、しっかりとケアはできていますか？\n「何事も準備8割」と言われるように、パフォーマンスを発揮するためにも、心と身体のケアが不可欠です。%name%さんが毎日高いパフォーマンスを発揮するための、コンディショニングケアができるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、\n*Vitamin・Mineral・Omega3*。\n\n休息を促す*Chill*。\n\nコンディションを整える*Recovery*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、サプリメントを通じて、日々の挑戦を支えていきます 。'
  },
  {
    id: 'focus',
    title: '100%じゃない。\n120%のパフォーマンス',
    statementText:
      '「普通の成果」では満足しないあなたのために。いつだって、圧倒的なパフォーマンスは、圧倒的な熱量から生まれるものです。\n%name%さんが自分の世界に没入し、そして120％のパフォーマンスを発揮できるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と医師・サプリメント専門家の知見をもとに、%name%さんに必要な*5種類のサプリメント*を選定いたしました。\n\n不足しがちなベース栄養素、\n*Vitamin・Mineral・Omega3*。\n\nワーキングメモリを開放する*Memory*。\n\n仕事に没頭するための*Zone*。\n\n生活習慣は、*日々の継続*によって徐々に変わっていくもの。\n\n私たちは、%name%さんの多忙な日常に寄り添い、サプリメントを通じて、日々の挑戦を支えていきます 。'
  }
]

type Props = {
  name: string
  spName: string
}

const UnderLine = (props: { text: string }) => {
  const { text } = props
  return (
    <PYuGothic>
      {text.split('*').map((cx, i) => {
        return i % 2 === 1 ? <span style={{ borderBottom: 'solid 2px #00D4A1' }}> {cx} </span> : cx
      })}
    </PYuGothic>
  )
}

const RecommendSection = (props: Props) => {
  const { name, spName } = props
  //const text = texts.filter((c) => c.id === spName)[0].recommendText
  const text =
    window.innerWidth > 600
      ? texts.filter((c) => c.id === spName)[0].recommendText
      : spTexts.filter((c) => c.id === spName)[0].recommendText
  const applyedNameText = text.replace(/%name%/g, name)
  return (
    <>
      <Hidden mdDown>
        <Box width="100%" display="flex" justifyContent="center" mt={22}>
          <Box display="flex" width="1000px">
            <Box width="45%" display="flex" alignItems="center">
              <H1YuGothic>
                {name}さんが、
                <br />
                日々挑戦するための
                <br />
                サポートを。
              </H1YuGothic>
            </Box>
            <Box width="55%" style={{ wordBreak: 'normal' }}>
              <PYuGothic style={{ whiteSpace: 'pre-wrap', wordBreak: 'normal' }}>
                <UnderLine text={applyedNameText} />
              </PYuGothic>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box width="90vw" my={10} mx="auto">
          <H1SpYuGothic>{name}さんが、日々挑戦するためのサポートを。</H1SpYuGothic>

          <Box mt={8}>
            <PSpYuGothic style={{ whiteSpace: 'pre-wrap' }}>
              <UnderLine text={applyedNameText} />
            </PSpYuGothic>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box width="100%" display="flex" justifyContent="center" mt={22}>
          <Box mx={4}>
            <Box width="100%">
              <H1SpYuGothic>
                {name}さんが、
                <br />
                日々挑戦するための
                <br />
                サポートを。
              </H1SpYuGothic>
            </Box>
            <Box width="100%" mt={8}>
              <PSpYuGothic style={{ whiteSpace: 'pre-wrap' }}>
                <UnderLine text={applyedNameText} />
              </PSpYuGothic>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default RecommendSection
