import React, { useState, useEffect } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBox from '@material-ui/core/Checkbox'
import Styled from 'styled-components'
import AgingIcon from '../../icon/Leaf'
import ConcentrationIcon from '../../icon/ConcentrationIcon'
import DepressionIcon from '../../icon/DepressionIcon'
import DietIcon from '../../icon/DietIcon'
import EyeIcon from '../../icon/EyeIcon'
import FatigueIcon from '../../icon/FatigueIcon'
import MemoryIcon from '../../icon/MemoryIcon'
import MotionIcon from '../../icon/MotionIcon'
import PhysicalConditionIcon from '../../icon/PhysicalConditionIcon'
import SleepIcon from '../../icon/SleepIcon'
import StressIcon from '../../icon/StressIcon'
import VitalityIcon from '../../icon/Fire'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

type IconCheckBoxProps = {
  question: any
  choices: string[]
  setChoices: (event: any) => void
  setIsChoice: (event: any) => void
}

type Icons = JSX.Element[]

const icons: Icons = [
  <SleepIcon />,
  <StressIcon />,
  <VitalityIcon />,
  <FatigueIcon />,
  <MotionIcon />,
  <MemoryIcon />,
  <ConcentrationIcon />,
  <PhysicalConditionIcon />,
  <EyeIcon />,
  <DepressionIcon />,
  <DietIcon />,
  <AgingIcon />
]

const H1CeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
`

const PYuGothic = Styled.p`
word-wrap: break-all;
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 26px;
letter-spacing: 0.1em;
color: #041E42;
`

const H1SpCeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
`

const PSpYuGothic = Styled.p`
word-wrap: break-all;
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 11px;
line-height: 24px;
letter-spacing: 0.05em;
color: #041E42;
`

type Woriy =
  | '睡眠'
  | 'ストレス'
  | '活力'
  | '疲労'
  | '運動不足'
  | '記憶力'
  | '集中力'
  | '体調不良'
  | 'アイケア'
  | 'うつっぽさ'
  | 'ダイエット'
  | 'エイジングケア'

// const worries: Woriy[] = ["体調不良", "アイケア", "うつっぽさ"];
const worrieIcon = (worrie: Woriy) => {
  switch (worrie) {
    case '睡眠':
      return <SleepIcon />
    case 'ストレス':
      return <StressIcon />
    case '活力':
      return <VitalityIcon />
    case '疲労':
      return <FatigueIcon />
    case '運動不足':
      return <MotionIcon />
    case '記憶力':
      return <MemoryIcon />
    case '集中力':
      return <ConcentrationIcon />
    case '体調不良':
      return <PhysicalConditionIcon />
    case 'アイケア':
      return <EyeIcon />
    case 'うつっぽさ':
      return <DepressionIcon />
    case 'ダイエット':
      return <DietIcon />
    case 'エイジングケア':
      return <AgingIcon />
  }
}

const getWorrieName = (worrie: Woriy): string => {
  switch (worrie) {
    case '睡眠':
      return 'SLEEP'
    case 'ストレス':
      return 'STRESS'
    case '活力':
      return 'VITALITY'
    case '疲労':
      return 'FATIGUE'
    case '運動不足':
      return 'FITNESS'
    case '記憶力':
      return 'MEMORY'
    case '集中力':
      return 'FOCUS'
    case '体調不良':
      return 'IMMUNITY'
    case 'アイケア':
      return 'EYECARE'
    case 'うつっぽさ':
      return 'DEPRESSION'
    case 'ダイエット':
      return 'DIET'
    case 'エイジングケア':
      return 'AGING'
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  })
)

type Props = {
  name: string
  text: string
  worries: Woriy[]
}

const TotalFb = (props: Props) => {
  const UnderLine = (props: { text: string; isSp: boolean }) => {
    const { text, isSp } = props
    return isSp ? (
      <PSpYuGothic>
        {text.split('*').map((cx, i) => {
          return i % 2 === 1 ? <span style={{ borderBottom: 'solid 2px #00D4A1' }}> {cx} </span> : cx
        })}
      </PSpYuGothic>
    ) : (
      <PYuGothic>
        {text.split('*').map((cx, i) => {
          return i % 2 === 1 ? <span style={{ borderBottom: 'solid 2px #00D4A1' }}> {cx} </span> : cx
        })}
      </PYuGothic>
    )
  }

  const { name, text, worries } = props
  const applyedNameText = text.replace(/%name%/g, name)

  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box width="100%" display="flex" justifyContent="center">
          <Box width="840px" textAlign="center" mt={4}>
            <H1CeraPro>YOUR RESULTS</H1CeraPro>
            {/* <Box width="840px" display="flex" justifyContent="center" mt={4}>
              <Box width="200px" display="flex" justifyContent="space-between">
                {worries?.map(c => {
                  return (
                    <img
                      src={"worriesIcon/" + getWorrieName(c) + ".svg"}
                      style={{ objectFit: "contain" }}
                      width="40px"
                      height="40px"
                    />
                  );
                })}
              </Box>
            </Box> */}
            <PYuGothic
              style={{
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                marginTop: '32px'
              }}
            >
              <UnderLine text={applyedNameText} isSp={false} />
            </PYuGothic>
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box width="100%" display="flex" justifyContent="center">
          <Box mx={4} mt={7}>
            <Box width="100%">
              <H1SpCeraPro>YOUR RESULTS</H1SpCeraPro>
              {/* <Box display="flex" justifyContent="center" mt={4}>
                <Box width="150px" display="flex" justifyContent="space-between">
                  {worries?.map((c) => {
                    return (
                      <img
                        src={'worriesIcon/' + getWorrieName(c) + '.svg'}
                        style={{ objectFit: 'contain' }}
                        width="32px"
                        height="32px"
                      />
                    )
                  })}
                </Box>
              </Box> */}
              <Box>
                <Box width="100%">
                  <PSpYuGothic
                    style={{
                      whiteSpace: 'pre-wrap',
                      textAlign: 'left',
                      marginTop: '32px'
                    }}
                  >
                    <UnderLine text={applyedNameText} isSp={true} />
                  </PSpYuGothic>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TotalFb
