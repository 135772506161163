import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button, Link, Drawer, Hidden, IconButton } from '@material-ui/core'
import XsBlackLogo from '../components/icon/iHackLogo_Black_Mini'
import LgBlackLogo from '../components/icon/IHackLogo_Black'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import castspTypeToNumber from '../function/castspTypeToNumber'

const HeaderWrapperPC = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100px;
  width: 100vw;
`

const HeaderItemPC = Styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  flex-grow: 1;
  width: 33%;
`

const TopLogoPC = Styled.h1`
  color: #041E42;
  margin: 0;
  padding: 0;
  font-family: Cera Pro;
  line-height: 52px;
`

const BlueLink = Styled.a`
  color: #041E42;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-decoration: none;
`

const LoginIcon = Styled.img.attrs({
  src: 'login_header.png'
})`

  height:24px;
  margin-right: 8px;
`

export const ConversionButton = Styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width:220px;
  height: 6vh;
  background-color: #00D4A1;
  padding: 5px 20px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
`

export const ConversionMessage = Styled.h2`
  display: flex;
  justify-content: center;
  flex-basis: 90%;
  font-family: YuGothic;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #041E42;
  padding: 0;
  margin: 0;
`

const ArrowHeader = Styled.img.attrs({
  src: 'arrow_new.png'
})`
  width: 24px;
  height: 8px;
  paddingLeft: 2vw;
`

const HeaderWrapperSP = Styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 80px;
  width: 100vw;
`

const HeaderItemSPLeft = Styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  width: 50%;
`

const HeaderItemSPRight = Styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
  width: 50%;
`

const TopLogoSP = Styled.h2`
  color: #041E42;
  margin: 0;
  padding: 0;
  font-family: Cera Pro;
  font-size: 30px;
  line-height: 42px;
  color: #041E42;
`

const MenuIconUl = Styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 55vw;
  height: 100%;
  list-style: none;
  text-align: right;
  margin-right: 10vw;
`

const MenuIconLi = Styled.li`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.145em;
  width: 100%;
  margin: 5px 0;
`

const LoginIconSP = Styled.img.attrs({
  src: 'login_header.png'
})`
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  margin-right: 5px;
`
interface PPCYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PPCYuGothic = Styled.p<PPCYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<PPCYuGothic>

const FreeDiagnosticTest = () => {
  const [isDiagnosed, setDiagnosed] = useState<boolean>(localStorage.getItem('answerObjects') ? true : false)
  return (
    <>
      <Hidden lgUp>
        <Box
          width="100%"
          height="40px"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          textAlign="center"
        >
          <Button
            href="ready"
            style={{
              width: '250px',
              height: '40px',
              display: 'flex',
              alignSelf: 'center',
              backgroundColor: '#00D4A1',
              padding: 0,
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 0 100px 0'
            }}
          >
            <h2
              style={{
                textAlign: 'center',
                fontFamily: 'YuGothic',
                fontWeight: 'bold',
                lineHeight: '40px',
                fontSize: '12px',
                letterSpacing: '0.1em',
                color: '#041E42',
                padding: '0',
                margin: '0 0 0 0'
              }}
            >
              {isDiagnosed ? (
                <Box width="100%" display="flex" justifyContent="center">
                  <a href="result" style={{ textDecoration: 'none' }}>
                    <PPCYuGothic fs="10px">過去の診断結果はこちら</PPCYuGothic>
                  </a>
                </Box>
              ) : (
                <Box width="100%" display="flex" justifyContent="center">
                  <a href="ready" style={{ textDecoration: 'none' }}>
                    <PPCYuGothic fs="12px">まずは無料診断する</PPCYuGothic>
                  </a>
                </Box>
              )}
            </h2>
            <Box ml={2}>
              <ArrowHeader />
            </Box>
          </Button>
        </Box>
        <h3
          style={{
            textAlign: 'center',
            fontFamily: 'YuGothic',
            fontWeight: 'bold',
            lineHeight: '40px',
            fontSize: '12px',
            letterSpacing: '0.1em',
            color: '#041E42',
            padding: '0',
            margin: '-30px 0px 30px 0px'
          }}
        >
          {isDiagnosed ? (
            <Box width="100%" display="flex" justifyContent="center">
              <a href="ready" style={{ textDecoration: 'none' }}>
                <PPCYuGothic fs="10px">再診断する</PPCYuGothic>
              </a>
            </Box>
          ) : (
            ''
          )}
        </h3>
      </Hidden>
      <Hidden mdDown>
        <Box
          width="100%"
          maxWidth="100%"
          height="10vh"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            textAlign: 'center'
          }}
        >
          <Button
            href="ready"
            style={{
              width: '53vw',
              display: 'flex',
              alignSelf: 'center',
              backgroundColor: '#00D4A1',
              padding: '5px 22px',
              borderRadius: '4px',
              textAlign: 'center'
            }}
          >
            <h2
              style={{
                textAlign: 'center',
                fontFamily: 'YuGothic',
                fontWeight: 'bold',
                lineHeight: '40px',
                fontSize: '12px',
                letterSpacing: '0.1em',
                color: '#041E42',
                padding: '0',
                margin: '0'
              }}
            >
              {isDiagnosed ? (
                <Box width="100%" display="flex" justifyContent="center">
                  <a href="result" style={{ textDecoration: 'none' }}>
                    <PPCYuGothic fs="10px">過去の診断結果はこちら</PPCYuGothic>
                  </a>
                </Box>
              ) : (
                <Box width="100%" display="flex" justifyContent="center">
                  <a href="ready" style={{ textDecoration: 'none' }}>
                    <PPCYuGothic fs="10px">まずは無料診断する</PPCYuGothic>
                  </a>
                </Box>
              )}
            </h2>
            <Box ml={3}>
              <ArrowHeader />
            </Box>
          </Button>
        </Box>
      </Hidden>
    </>
  )
}

const Header = (props: {
  isResult?: boolean
  isLP?: boolean
  spType?: 'relax' | 'diet' | 'memory' | 'care' | 'focus'
}) => {
  const { isResult = false } = props
  const { isLP = false } = props
  const { spType = 'care' } = props
  const useStyle = makeStyles({
    baseBox: {
      '&:hover': {
        cursor: 'pointer'
      },
      '&:active': {
        background: ''
      }
    }
  })
  const classes = useStyle()
  const [toggleStatus, setToggleStatus] = useState<boolean>(false)

  if (!isResult) {
    return (
      <>
        <Box position="fixed" width="100%" zIndex="1000">
          <Hidden mdDown>
            <Box display="flex" flexWrap="nowrap" alignItems="center" height="100px" width="100vw">
              <HeaderItemPC>
                <Box>
                  <BlueLink href="story">STORY</BlueLink>
                </Box>
                <Box>
                  <BlueLink href="supplementList">SUPPLEMENTS</BlueLink>
                </Box>
              </HeaderItemPC>

              <HeaderItemPC>
                <Box>
                  <a href="top">
                    <LgBlackLogo />
                  </a>
                </Box>
              </HeaderItemPC>

              <HeaderItemPC>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.baseBox}
                  onClick={() => {}}
                >
                  <LoginIcon></LoginIcon>
                  <BlueLink href="https://ec.ihack.energy/shop/customers/sign_in">
                    {/* <a
                      style={{ textDecorationLine: 'none', color: '#041E42' }}
                      href="https://ec.ihack.energy/shop/customers/sign_in"
                    > */}
                    LOGIN
                    {/* </a> */}
                  </BlueLink>
                </Box>
                <Box>
                  <Box style={{ width: '230px' }}>
                    <FreeDiagnosticTest />
                  </Box>
                </Box>
              </HeaderItemPC>
            </Box>
          </Hidden>

          <Hidden lgUp>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.baseBox}
              width="85%"
              height={'80px'}
              margin={'auto'}
            >
              <Box height={'40px'}>
                <IconButton href="/top" style={{ margin: 0, padding: 0 }}>
                  <LgBlackLogo />
                </IconButton>
              </Box>
              <Box>
                <img src="menu_icon.png" onClick={() => setToggleStatus(true)} />
              </Box>
            </Box>

            <Drawer
              anchor="right"
              open={toggleStatus}
              onClose={() => setToggleStatus(false)}
              style={{
                minHeight: '100vh'
              }}
            >
              <MenuIconUl>
                <MenuIconLi
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '20vh'
                  }}
                >
                  <img
                    onClick={() => setToggleStatus(false)}
                    src={'close_icon.png'}
                    style={{
                      width: '20px',
                      height: '20px',
                      objectFit: 'cover'
                    }}
                  />
                </MenuIconLi>
                <MenuIconLi style={{ marginBottom: '10px' }}>
                  <IconButton href="top">
                    <LgBlackLogo />
                  </IconButton>
                </MenuIconLi>
                <MenuIconLi>
                  <Link href="story" style={{ color: '#041E42' }}>
                    STORY
                  </Link>
                </MenuIconLi>
                <MenuIconLi>
                  <Link href="supplementList" style={{ color: '#041E42' }}>
                    SUPPLEMENTS
                  </Link>
                </MenuIconLi>
                <MenuIconLi
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                  className={classes.baseBox}
                >
                  <LoginIconSP />
                  <Link href="https://ec.ihack.energy/shop/customers/sign_in" style={{ color: '#041E42' }}>
                    LOGIN
                  </Link>
                </MenuIconLi>
                <MenuIconLi style={{ marginTop: '15vh' }}>
                  <Box width="100%">
                    <FreeDiagnosticTest />
                  </Box>
                </MenuIconLi>
              </MenuIconUl>
            </Drawer>
          </Hidden>
        </Box>
      </>
    )
  } else {
    return (
      <>
        <Box position="fixed" width="100%" zIndex="1000">
          <Hidden mdDown>
            <Box display="flex" flexWrap="nowrap" alignItems="center" height="100px" width="100vw">
              <HeaderItemPC>
                <Box p={2}>
                  <BlueLink href="story">STORY</BlueLink>
                </Box>
                <Box p={2}>
                  <BlueLink href="supplementList">SUPPLEMENTS</BlueLink>
                </Box>
              </HeaderItemPC>

              <HeaderItemPC>
                <a href="top">
                  <LgBlackLogo />
                </a>
              </HeaderItemPC>
              <HeaderItemPC>
                <Box
                  p={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className={classes.baseBox}
                >
                  <LoginIcon />
                  <BlueLink href="https://ec.ihack.energy/shop/customers/sign_in">LOGIN</BlueLink>
                </Box>
              </HeaderItemPC>
              <Box style={{ position: 'fixed', right: '0', top: '0', height: '70px' }}>
                <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
                  <img src="top_cta.svg" style={{ width: '100%' }} />
                </a>
              </Box>
            </Box>
          </Hidden>

          <Hidden lgUp>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.baseBox}
              width="85%"
              height={'60px'}
              margin={'auto'}
            >
              <Box height={'40px'}>
                <IconButton href="/top" style={{ margin: 0, padding: 0 }}>
                  <LgBlackLogo />
                </IconButton>
              </Box>
              <Box mr="80px">
                <img src="menu_icon.png" onClick={() => setToggleStatus(true)} />
              </Box>
              <Box
                style={{
                  width: '100px',
                  position: 'fixed',
                  right: '0',
                  top: '0',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
                  <img src="top_cta.svg" style={{ width: '100%', maxWidth: '100px', textAlign: 'right' }} />
                </a>
              </Box>
            </Box>

            <Drawer
              anchor="right"
              open={toggleStatus}
              onClose={() => setToggleStatus(false)}
              style={{
                minHeight: '100vh'
              }}
            >
              <MenuIconUl>
                <MenuIconLi
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '20vh'
                  }}
                >
                  <img
                    onClick={() => setToggleStatus(false)}
                    src={'close_icon.png'}
                    style={{
                      width: '20px',
                      height: '20px',
                      objectFit: 'cover'
                    }}
                  />
                </MenuIconLi>
                <MenuIconLi style={{ marginBottom: '10px' }}>
                  <IconButton href="top">
                    <LgBlackLogo />
                  </IconButton>
                </MenuIconLi>
                <MenuIconLi>
                  <Link href="story" style={{ color: '#041E42' }}>
                    STORY
                  </Link>
                </MenuIconLi>
                <MenuIconLi>
                  <Link href="supplementList" style={{ color: '#041E42' }}>
                    SUPPLEMENTS
                  </Link>
                </MenuIconLi>
                <MenuIconLi
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                  className={classes.baseBox}
                >
                  <LoginIconSP />
                  <Link href="https://ec.ihack.energy/shop/customers/sign_in" style={{ color: '#041E42' }}>
                    LOGIN
                  </Link>
                </MenuIconLi>
              </MenuIconUl>
            </Drawer>
          </Hidden>
        </Box>
      </>
    )
  }
}

export default Header
