import React, { useState, useEffect } from 'react'
import { testProblemChecker } from '../common/problemCheckers'
import { Other, Answers } from '../types/Score'

const maxWidth: string = '1280px'

const ResultCheck = () => {
  const others: Other = {
    name: 'Yu',
    worries: ['睡眠', 'ストレス', '集中力', '運動不足'],
    mostWorrie: 'ストレス',
    allergies: ['鶏肉'],
    weight: 80,
    height: 170
  }

  // "睡眠",
  //   "ストレス",
  //   "活力",
  //   "疲労",
  //   "運動不足",
  //   "記憶力",
  //   "集中力",
  //   "体調不良",
  //   "アイケア",
  //   "うつっぽさ",
  //   "ダイエット",
  //   "エイジングケア"

  const answers: Answers = {
    createDate: new Date(2020, 3, 31),
    Answer: [
      // {
      //   id: 1,
      //   questionSortId: 1,
      //   choiceId: 1
      // },
      {
        id: 2,
        questionSortId: 2,
        choiceId: 1
      },
      {
        id: 3,
        questionSortId: 3,
        choiceId: 11
      },
      {
        id: 4,
        questionSortId: 4,
        choiceId: 11
      },
      {
        id: 5,
        questionSortId: 5,
        choiceId: 11
      },
      {
        id: 6,
        questionSortId: 6,
        choiceId: 7
      },
      // {
      //   id: 7,
      //   questionSortId: 7,
      //   choiceId: 1
      // },
      // {
      //   id: 8,
      //   questionSortId: 8,
      //   choiceId: 1
      // },
      {
        id: 9,
        questionSortId: 9,
        choiceId: 3
      },
      // {
      //   id: 10,
      //   questionSortId: 10,
      //   choiceId: 1
      // },
      {
        id: 11,
        questionSortId: 11,
        choiceId: 1
      },
      {
        id: 12,
        questionSortId: 12,
        choiceId: 1
      },
      {
        id: 13,
        questionSortId: 13,
        choiceId: 1
      },
      {
        id: 14,
        questionSortId: 14,
        choiceId: 1
      },
      {
        id: 15,
        questionSortId: 15,
        choiceId: 1
      },
      {
        id: 16,
        questionSortId: 16,
        choiceId: 1
      },
      {
        id: 17,
        questionSortId: 17,
        choiceId: 1
      },
      {
        id: 18,
        questionSortId: 18,
        choiceId: 1
      },
      {
        id: 19,
        questionSortId: 19,
        choiceId: 1
      },
      {
        id: 20,
        questionSortId: 20,
        choiceId: 1
      },
      {
        id: 21,
        questionSortId: 21,
        choiceId: 1
      },
      {
        id: 22,
        questionSortId: 22,
        choiceId: 1
      },
      {
        id: 23,
        questionSortId: 23,
        choiceId: 1
      },
      {
        id: 24,
        questionSortId: 24,
        choiceId: 1
      },
      {
        id: 25,
        questionSortId: 25,
        choiceId: 1
      },
      {
        id: 26,
        questionSortId: 26,
        choiceId: 1
      },
      {
        id: 27,
        questionSortId: 27,
        choiceId: 1
      },
      {
        id: 28,
        questionSortId: 28,
        choiceId: 1
      },
      {
        id: 29,
        questionSortId: 29,
        choiceId: 1
      },
      {
        id: 30,
        questionSortId: 30,
        choiceId: 4
      },
      {
        id: 31,
        questionSortId: 31,
        choiceId: 4
      },
      {
        id: 32,
        questionSortId: 32,
        choiceId: 5
      },
      {
        id: 33,
        questionSortId: 33,
        choiceId: 4
      },
      {
        id: 34,
        questionSortId: 34,
        choiceId: 4
      },
      {
        id: 35,
        questionSortId: 35,
        choiceId: 5
      },
      {
        id: 36,
        questionSortId: 36,
        choiceId: 1
      },
      {
        id: 37,
        questionSortId: 37,
        choiceId: 1
      },
      {
        id: 38,
        questionSortId: 38,
        choiceId: 5
      },
      {
        id: 39,
        questionSortId: 39,
        choiceId: 4
      },
      {
        id: 40,
        questionSortId: 40,
        choiceId: 4
      },
      {
        id: 41,
        questionSortId: 41,
        choiceId: 4
      }
      // {
      //   id: 42,
      //   questionSortId: 42,
      //   choiceId: 3
      // },
      // {
      //   id: 43,
      //   questionSortId: 43,
      //   choiceId: 4
      // },
      // {
      //   id: 44,
      //   questionSortId: 44,
      //   choiceId: 3
      // },
      // {
      //   id: 45,
      //   questionSortId: 45,
      //   choiceId: 4
      // },
      // {
      //   id: 46,
      //   questionSortId: 46,
      //   choiceId: 3
      // },
      // {
      //   id: 47,
      //   questionSortId: 47,
      //   choiceId: 2
      // },
      // {
      //   id: 48,
      //   questionSortId: 48,
      //   choiceId: 6
      // },
      // {
      //   id: 49,
      //   questionSortId: 49,
      //   choiceId: 5
      // }
    ]
  }

  testProblemChecker(answers, others)
  return <h1>ResultCheck view</h1>
}

export default ResultCheck
