import React from 'react'
import Hidden from '@material-ui/core/Hidden'

const LeftButton = (props: { color?: string }) => (
  <>
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.243 10.0822V13.0005L0.341098 7.35588V6.49191L13.243 0.828125V3.76561L5.5633 6.93349L13.243 10.0822Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default LeftButton
