const BASE = {
  colorInfo: '#4285f4',
  colorInfoDarken1: '#3076eb',
  colorInfoDarken2: '#2468d8',
  colorInfoLigten1: '#76a7fa',
  colorInfoLigten2: '#fafcff',
  colorCaution: '#db4437',
  colorCautionDarken1: '#c53727',
  colorCautionDarken2: '#b0281a',
  colorCautionLigten1: '#e57368',
  colorCautionLigten2: '#fff7f6',
  colorHighlight: '#d4b400',
  colorHighlightDarken1: '#dca300',
  colorHighlightDarken2: '#a57b00',
  colorHighlightLigten1: '#fbcb43',
  colorHighlightLigten2: '#fffcf4',
  colorAction: '#0f9d58',
  colorActionDarken1: '#0e9453',
  colorActionDarken2: '#047e43',
  colorActionLigten1: '#33b679',
  colorActionLigten2: '#f8fffc',
  gridCol: 16,
  gridMaxWidth: 100,
  gridColGutters: 2
}

export type ITheme = {
  base: {
    size: number
    maxWidth: string
    viewport: number
    fontFamily: string
    fontStyle: string
    fontSize: number
    lineHeight: number
    color: {
      bg: string
      text: string
      link: string
    }
  }
  font: {
    sizeH1: number
    sizeH2: number
    sizeH3: number
    sizeH4: number
    sizeH5: number
    sizeH6: number
    sizeDefault: number
    color: {
      default: string
      dark: string
      light: string
    }
  }
  height: {
    large: number
    middle: number
    medium: number
    small: number
    tiny: number
  }
  layer: {
    foreFront: number
    front: number
    middle: number
    back: number
    foreBack: number
  }
  color: {
    default: string
    white: string
    gray: {
      darken1: string
      darken2: string
      darken3: string
      lighten1: string
      lighten2: string
      lighten3: string
      alpha1: string
    }
    info: {
      default: string
      darken1: string
      darken2: string
      lighten1: string
      lighten2: string
    }
    caution: {
      default: string
      darken1: string
      darken2: string
      lighten1: string
      lighten2: string
    }
    highlight: {
      default: string
      darken1: string
      darken2: string
      lighten1: string
      lighten2: string
    }
    action: {
      default: string
      darken1: string
      darken2: string
      lighten1: string
      lighten2: string
    }
  }
  button: {
    base: string
    border: string
    hover: string
    info: {
      base: string
      border: string
      hover: string
    }
    caution: {
      base: string
      border: string
      hover: string
    }
    highlight: {
      base: string
      border: string
      hover: string
    }
    action: {
      base: string
      border: string
      hover: string
    }
    dark: {
      base: string
      border: string
      hover: string
    }
    darkOpen: {
      base: string
      border: string
      hover: string
    }
  }
}

export const valiables: ITheme = {
  base: {
    size: 8,
    maxWidth: '100%',
    viewport: 768,
    fontFamily: '"Cera Pro,YuGothic"',
    fontStyle: 'nomal',
    fontSize: 62.5,
    lineHeight: 1.6,
    color: {
      bg: '#fafafa',
      text: '#041E42',
      link: BASE.colorInfo
    }
  },
  font: {
    sizeH1: 20,
    sizeH2: 16,
    sizeH3: 14,
    sizeH4: 12,
    sizeH5: 10,
    sizeH6: 10,
    sizeDefault: 12,
    color: {
      default: 'rgb(102, 102, 102)',
      dark: '#333',
      light: '#999'
    }
  },
  height: {
    large: 96,
    middle: 56,
    medium: 56,
    small: 44,
    tiny: 32
  },
  layer: {
    foreFront: 900,
    front: 700,
    middle: 500,
    back: 300,
    foreBack: 100
  },
  color: {
    default: '#041E42',
    white: '#fff',
    gray: {
      darken1: '#c6c6c6',
      darken2: '#8e8e8e',
      darken3: '#666',
      lighten1: '#e5e5e5',
      lighten2: '#f1f1f1',
      lighten3: '#fbfbfb',
      alpha1: 'rgba(102, 102, 102, 0.1)'
    },
    info: {
      default: BASE.colorInfo,
      darken1: BASE.colorInfoDarken1,
      darken2: BASE.colorInfoDarken2,
      lighten1: BASE.colorInfoLigten1,
      lighten2: BASE.colorInfoLigten2
    },
    caution: {
      default: BASE.colorCaution,
      darken1: BASE.colorCautionDarken1,
      darken2: BASE.colorCautionDarken2,
      lighten1: BASE.colorCautionLigten1,
      lighten2: BASE.colorCautionLigten2
    },
    highlight: {
      default: BASE.colorHighlight,
      darken1: BASE.colorHighlightDarken1,
      darken2: BASE.colorHighlightDarken2,
      lighten1: BASE.colorHighlightLigten1,
      lighten2: BASE.colorHighlightLigten2
    },
    action: {
      default: BASE.colorAction,
      darken1: BASE.colorActionDarken1,
      darken2: BASE.colorActionDarken2,
      lighten1: BASE.colorActionLigten1,
      lighten2: BASE.colorActionLigten2
    }
  },
  button: {
    base: '#fcfcfc',
    border: '#c6c6c6',
    hover: '#eee',
    info: {
      base: BASE.colorInfo,
      border: BASE.colorInfoDarken2,
      hover: BASE.colorInfoDarken1
    },
    caution: {
      base: BASE.colorCaution,
      border: BASE.colorCautionDarken2,
      hover: BASE.colorCautionDarken1
    },
    highlight: {
      base: BASE.colorHighlight,
      border: BASE.colorHighlightDarken2,
      hover: BASE.colorHighlightDarken1
    },
    action: {
      base: BASE.colorAction,
      border: BASE.colorActionDarken2,
      hover: BASE.colorActionDarken1
    },
    dark: {
      base: '#414d5b',
      border: '#313a45',
      hover: '#313a45'
    },
    darkOpen: {
      base: '#f4f6f8',
      border: '#414d5b',
      hover: '#313a45'
    }
  }
}
