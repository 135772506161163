import React from 'react'
import { Box, Link, Hidden, Grid, Button, Divider } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'
//import ChanelDeveloper from '../components/ChanellDeveloper'

const H1PcYuGothic = Styled.h1`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin: 0;
`

const H2PcYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 54px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin: 0;
`

const PPcYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 54px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const SpanPcCeraPro = Styled.span`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const H1SpYuGothic = Styled.h1`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 45px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin: 0;
`

const H2SpYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 60px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface PSpYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PSpYuGothic = Styled.p<PSpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

const SpanSpCeraPro = Styled.span`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const Low = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  )
}

const Main = () => {
  // ChanelDeveloper.boot({
  //   pluginKey: '8324e2a5-9a85-4d44-a57a-2118a321f35b' //please fill with your plugin key
  // })
  return (
    <>
      <Hidden mdDown>
        <Box width="86vw" mx="auto" mb={20} pt={20}>
          <H1PcYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>News</H1PcYuGothic>

          <Box mb={13} width="65vw" mx="auto" textAlign="left">
            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2021.10.29</PPcYuGothic>
              <PPcYuGothic>2021年10月を持ちまして販売を終了させていただくこととなりました</PPcYuGothic>
            </Box>

            <Divider />

            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2020.09.18</PPcYuGothic>
              <PPcYuGothic>
                <Link href="https://prtimes.jp/main/html/rd/p/000000109.000013830.html">
                  D2C支援プログラム「Original Lab by FABRIC TOKYO」に採択されました
                </Link>
              </PPcYuGothic>
            </Box>

            <Divider />

            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2020.08.31</PPcYuGothic>
              <PPcYuGothic>
                <Link href="https://prtimes.jp/main/html/rd/p/000000006.000055248.html">
                  Forbes JAPAN Rising Star Communityに採択されました
                </Link>
              </PPcYuGothic>
            </Box>

            <Divider />

            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2020.07.29</PPcYuGothic>
              <PPcYuGothic>
                <SpanPcCeraPro>iHack</SpanPcCeraPro>本リリース開始
              </PPcYuGothic>
            </Box>

            <Divider />

            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2020.06.01</PPcYuGothic>
              <Link href="https://www.makuake.com/project/ihack/?from=keywordsearch&keyword=ihack&disp_order=1">
                <PPcYuGothic>クラウドファンディング開始約21時間で目標達成</PPcYuGothic>
              </Link>
            </Box>

            <Divider />

            <Box py={2} display="flex">
              <PPcYuGothic style={{ marginRight: '40px' }}>2020.05.31</PPcYuGothic>
              <Link href="https://www.makuake.com/project/ihack/?from=keywordsearch&keyword=ihack&disp_order=1">
                <PPcYuGothic>クラウドファンディングにて先行予約販売開始</PPcYuGothic>
              </Link>
            </Box>

            <Divider />
          </Box>

          <Button
            href="top"
            style={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '16px',
              letterSpacing: '0.1em',
              color: '#041E42',
              margin: '0px 0px 0px 130px'
            }}
          >
            Back To Top <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
          </Button>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box width="84vw" mx="auto" mb={10} pt={20}>
          <H1SpYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>News</H1SpYuGothic>

          <Box mb={10}>
            <Box py={2}>
              <PSpYuGothic mb="16px">2020.09.18</PSpYuGothic>
              <Link href="https://prtimes.jp/main/html/rd/p/000000109.000013830.html">
                <PSpYuGothic>D2C支援プログラム「Original Lab by FABRIC TOKYO」に採択されました</PSpYuGothic>
              </Link>
            </Box>

            <Divider />

            <Box py={2}>
              <PSpYuGothic mb="16px">2020.08.31</PSpYuGothic>
              <Link href="https://prtimes.jp/main/html/rd/p/000000006.000055248.html">
                <PSpYuGothic>Forbes JAPAN Rising Star Communityに採択されました</PSpYuGothic>
              </Link>
            </Box>

            <Divider />
            <Box py={2}>
              <PSpYuGothic mb="16px">2020.07.29</PSpYuGothic>
              <PSpYuGothic>
                <SpanSpCeraPro>iHack</SpanSpCeraPro>本リリース開始
              </PSpYuGothic>
            </Box>

            <Divider />

            <Box py={2}>
              <PSpYuGothic mb="16px">2020.06.01</PSpYuGothic>
              <Link href="https://www.makuake.com/project/ihack/?from=keywordsearch&keyword=ihack&disp_order=1">
                <PSpYuGothic>クラウドファンディング開始約21時間で目標達成</PSpYuGothic>
              </Link>
            </Box>

            <Divider />

            <Box py={2}>
              <PSpYuGothic mb="16px">2020.05.31</PSpYuGothic>
              <Link href="https://www.makuake.com/project/ihack/?from=keywordsearch&keyword=ihack&disp_order=1">
                <PSpYuGothic>クラウドファンディングにて先行予約販売開始</PSpYuGothic>
              </Link>
            </Box>

            <Divider />
          </Box>

          <Button
            href="top"
            style={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '32px',
              letterSpacing: '0.1em',
              color: '#041E42',
              margin: 0
            }}
          >
            Back To Top <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
          </Button>
        </Box>
      </Hidden>
    </>
  )
}

export default Low
