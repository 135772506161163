import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { Answer, MostWories, WoriesObj, Question, Allergie } from '../../../../types/Score'
import MoveButton from '../../diagnosis/MoveButton'
import MilkIcon from '../../../icon/MilkIcon'
import NoneIcon from '../../../icon/NoneIcon'
import GelatinIcon from '../../../icon/GelatinIcon'
import ChickenIcon from '../../../icon/ChickenIcon'
import Soy from '../../../icon/Soy'
import Wheat from '../../../icon/Wheat'
import PhosphatidylserineIcon from '../../../icon/PhosphatidylserineIcon'
import { makeStyles } from '@material-ui/core/styles'

import Text from '../util/Text'

type Icons = { icon: JSX.Element; name: Allergie }[]

const icons: Icons = [
  { icon: <NoneIcon />, name: 'なし' },
  { icon: <MilkIcon />, name: '乳' },
  { icon: <GelatinIcon />, name: 'ゼラチン' },
  { icon: <ChickenIcon />, name: '鶏肉' },
  { icon: <Wheat />, name: '小麦' },
  { icon: <Soy />, name: '大豆' }
]

const hilightIcons: Icons = [
  { icon: <NoneIcon color={'#00D4A1'} />, name: 'なし' },
  { icon: <MilkIcon color={'#00D4A1'} />, name: '乳' },
  { icon: <GelatinIcon color={'#00D4A1'} />, name: 'ゼラチン' },
  { icon: <ChickenIcon color={'#00D4A1'} />, name: '鶏肉' },
  { icon: <Wheat color={'#00D4A1'} />, name: '小麦' },
  { icon: <Soy color={'#00D4A1'} />, name: '大豆' }
]

type Props = {
  question: Question
  useStyles: any
  answer: Answer[]
  allergie: Allergie[]
  setAllergie: React.Dispatch<React.SetStateAction<Allergie[]>>
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
  moveResult: (emai?: string) => void
}

const CheckBox = (props: Props) => {
  const { question, useStyles, answer, setAnswer, onMoveAnimation, setAllergie, moveResult } = props
  const [selectValues, setSelectValues] = useState<Allergie[]>([])

  const useStyle = makeStyles({
    baseBox: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })

  const onChange = (worieTitle: Allergie) => {
    if (selectValues.filter((c) => c === worieTitle).length > 0) {
      setSelectValues((_) => selectValues.filter((c) => c !== worieTitle))
    } else if (selectValues.filter((c) => c === 'なし').length > 0) {
      setSelectValues((_) => ['なし'])
    } else {
      if (worieTitle !== 'なし') {
        setSelectValues((_) => [...selectValues, worieTitle])
      } else {
        setSelectValues((_) => ['なし'])
      }
    }
  }
  const nextQuestion = () => {
    setAllergie((_) => [...selectValues])
  }
  const classes = useStyle()
  return (
    <>
      <Hidden lgUp>
        <Box
          width="300px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          m={'auto'}
          mt={-7}
        >
          {icons.map((c, index) => (
            <Box width="100px" height="78px" mt={4} onClick={() => onChange(c.name)}>
              <Box width="100px" height="50px" m={'auto'}>
                {selectValues.filter((i) => i === c.name).length > 0 ? hilightIcons[index].icon : c.icon}
              </Box>
              <Box
                mt={1}
                width="100px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                style={{ whiteSpace: 'nowrap' }}
              >
                <Text
                  fw={'bold'}
                  fs={12}
                  lh={'140%'}
                  ls={'0.1em'}
                  style={{ color: selectValues.filter((i) => i === c.name).length > 0 ? '#00D4A1' : '#041E42' }}
                >
                  {c.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
        <Box width="100%" height="100px">
          <MoveButton
            disable={selectValues.length <= 0}
            answers={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            question={question}
            anyFunction={nextQuestion}
            isFixed={true}
            moveResult={moveResult}
            isFinish={true}
          />
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box
          width="390px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          m={'auto'}
          mt={-4}
        >
          {icons.map((c, index) => (
            <Box width="130px" height="96px" mt={6} onClick={() => onChange(c.name)} className={classes.baseBox}>
              <Box width="130px" height="50px" m={'auto'}>
                {selectValues.filter((i) => i === c.name).length > 0 ? hilightIcons[index].icon : c.icon}
              </Box>
              <Box
                mt={3}
                width="130px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                style={{ whiteSpace: 'nowrap' }}
              >
                <Text
                  fw={'bold'}
                  fs={14}
                  lh={'140%'}
                  ls={'0.1em'}
                  style={{ color: selectValues.filter((i) => i === c.name).length > 0 ? '#00D4A1' : '#041E42' }}
                >
                  {c.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
        <MoveButton
          disable={selectValues.length <= 0}
          answers={answer}
          setAnswer={setAnswer}
          onMoveAnimation={onMoveAnimation}
          question={question}
          anyFunction={nextQuestion}
          moveResult={moveResult}
          isFinish={true}
        />
      </Hidden>
    </>
  )
}

export default CheckBox
