import React from 'react'
import Hidden from '@material-ui/core/Hidden'

const RightButton = (props: { color?: string }) => (
  <>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2.91782L0 -0.000465262L12.9019 5.64412V6.50809L0 12.1719L0 9.23439L7.67971 6.06651L0 2.91782Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default RightButton
