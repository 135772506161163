import { createGlobalStyle } from '../styledCore/index'
import { utilityStyles } from './utility'

const baseStyles = createGlobalStyle`
    body {
    background: white;
    color: ${({ theme }) => theme.base.color.text};
    font-family: ${({ theme }) => theme.base.fontFamily};
    }
    ${({ theme }) => utilityStyles(theme)}
`

export default baseStyles
