import firebase from "firebase";
import { firebaseConfig } from "./config";

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : "";

export const db = firebase.firestore();

export default firebase;
//  firebase.firestore.Firestore;
