import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Text from './util/Text'

type HeadBarProps = {
  sectionNumber: number
}

const HeadBar = (props: HeadBarProps) => {
  const { sectionNumber } = props
  const windowSize = window.innerWidth
  const radius = windowSize >= 1100 ? '40px' : '32px'
  const numberFontSize = windowSize >= 1100 ? 20 : 16
  return (
    <>
      <Box
        width="100%"
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Box
          ml={windowSize >= 1100 ? '0px' : '3px'}
          height={radius}
          width={radius}
          borderRadius="50%"
          border={sectionNumber > 0 ? '2px solid #041E42' : '2px solid #AFAFAF'}
          style={{ backgroundColor: '#fff' }}
          justifyContent="center"
          alignItems="center"
          display="flex"
          textAlign="center"
        >
          <Text fs={numberFontSize} fw={'bold'} ff="Cera Pro" color={sectionNumber > 0 ? '#041E42' : 'gray'}>
            1
          </Text>
        </Box>
        <Box
          width="50%"
          left={windowSize >= 1100 ? '0px' : '3px'}
          height={2}
          position="absolute"
          zIndex={-1}
          style={{ backgroundColor: sectionNumber > 1 ? '#041E42' : '#AFAFAF' }}
        />
        <Box
          height={radius}
          width={radius}
          borderRadius="50%"
          border={sectionNumber > 1 ? '2px solid #041E42' : '2px solid #AFAFAF'}
          style={{ backgroundColor: '#fff' }}
          justifyContent="center"
          alignItems="center"
          display="flex"
          textAlign="center"
        >
          <Text fs={numberFontSize} fw={'bold'} ff="Cera Pro" color={sectionNumber > 1 ? '#041E42' : 'gray'}>
            2
          </Text>
        </Box>
        <Box
          width="50%"
          height={2}
          position="absolute"
          right={windowSize >= 1100 ? 0 : 3}
          zIndex={-1}
          style={{ backgroundColor: sectionNumber > 2 ? '#041E42' : '#AFAFAF' }}
        />
        <Box
          mr={windowSize >= 1100 ? '0px' : '3px'}
          height={radius}
          width={radius}
          borderRadius="50%"
          border={sectionNumber > 2 ? '2px solid #041E42' : '2px solid #AFAFAF'}
          style={{ backgroundColor: '#fff' }}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          display="flex"
        >
          <Text fs={numberFontSize} fw={'bold'} ff="Cera Pro" color={sectionNumber > 2 ? '#041E42' : 'gray'}>
            3
          </Text>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" marginTop="10px" mb={1}>
        <Text fs={10} fw={'bold'} color={sectionNumber > 0 ? 'default' : 'gray'}>
          基本情報
        </Text>
        <Text fs={10} fw={'bold'} color={sectionNumber > 1 ? 'default' : 'gray'}>
          悩み
        </Text>
        <Text fs={10} fw={'bold'} color={sectionNumber > 2 ? 'default' : 'gray'}>
          生活習慣
        </Text>
      </Box>
    </>
  )
}

export default HeadBar
