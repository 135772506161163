import { css } from '../styledCore/index'
export const utilityStyles = (theme: any) => css`
  .u-fz-8 {
    font-size: 8px !important;
  }
  .u-fz-10 {
    font-size: 10px !important;
  }
  .u-fz-12 {
    font-size: 12px !important;
  }
  .u-fz-14 {
    font-size: 14px !important;
  }
  .u-fz-16 {
    font-size: 16px !important;
  }
  .u-fz-18 {
    font-size: 18px !important;
  }
  .u-fw-n {
    font-weight: normal !important;
  }
  .u-fw-b {
    font-weight: bold !important;
  }
`
