import React, { useState, useCallback, useEffect } from 'react'
import { Box, Hidden, Fade, Button } from '@material-ui/core/'
import HeadBar from '../components/container/diagnosis/HeadBar'
import BottomBar from '../components/container/diagnosis/BottomBar'
import { useTransition, animated, useSpring } from 'react-spring'
import { questions } from '../common/question'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router'
// import { lastQuestionId, worriesId, figuaId, sexualQuestionId1, sexualQuestionId2 } from '../common/keyQuestion'
import { testProblemChecker } from '../common/problemCheckers'
import { db } from '../firebase/index'
import { now } from '../firebase/common'
import { useInput } from '../function'
import {
  deleteSkipQuestion as deleteSkipQuestionFunc,
  createSkipquestions,
  getSectionLength,
  getIdForDisplay
} from '../components/container/diagnosis/util/diagnosisUtil'
import firebase from '../firebase/index'
// import QuestionCard from '../components/container/diagnosis/QuestionCard'
/////
// import FormControl from '@material-ui/core/FormControl'
// import NextButton from '../components/container/diagnosis/question/common/NextButton'
import '../style.css'
import Loading from '../components/container/diagnosis/loading'
import SectionAnimation from '../components/container/diagnosis/SectionAnimation'
import DiagnosisCard from '../components/container/diagnosis/diagnosisCard'
import * as d3 from 'd3-ease'
//import ChanelDeveloper from '../components/ChanellDeveloper'
////

//types
import { Answer, Answers, Results, Other, WoriesObj, MostWories, Allergie, Quetsions } from '../types/Score/index'
import { reverse } from 'dns'

const Diagnosis = (props: { history?: any }) => {
//  ChanelDeveloper.shutdown()
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])
  const questionLen = questions.length

  useEffect(() => {
    fetch(
      'https://www.google-analytics.com/collect?v=1&tid=UA-167311277-1&cid=' +
        cookies._hjid +
        '&t=pageview&dp=%2Fdiagnosis'
    )
  }, [])

  const { history } = props
  const initOBJ: any = JSON.parse(localStorage.getItem('initOBJs') || '{}')
  const initIsRevers = initOBJ?.initIsRevers ? initOBJ.initIsRevers : false
  const initAnswer = initOBJ?.initAnswer ? initOBJ.initAnswer : []
  const initPushBottomButtonCounter = initOBJ?.initPushBottomButtonCounter ? initOBJ.initPushBottomButtonCounter : 0
  const initSectionNumber = initOBJ?.initSectionNumber ? initOBJ.initSectionNumber : 0
  const initName = initOBJ?.initName ? initOBJ.initName : ''
  const initIsChoice = initOBJ?.initIsChoice ? initOBJ.initIsChoice : false
  const initIsLoading = initOBJ?.initIsLoading ? initOBJ.initIsLoading : false
  const initAnimationIndex = initOBJ?.initAnimationIndex ? initOBJ.initAnimationIndex : 0
  const initWorried = initOBJ?.initWorried ? initOBJ.initWorried : []
  const initMostWorryied = initOBJ?.initMostWorryied ? initOBJ.initMostWorryied : '未選択'
  const initAllergies = initOBJ?.initAllergies ? initOBJ.initAllergies : []
  const initDispQuestionId = initOBJ?.initDispQuestionId ? initOBJ.initDispQuestionId : 0
  const initStartAnimation = initOBJ?.initStartAnimation ? initOBJ.initStartAnimation : true
  const initEndSectionAnimations = initOBJ?.initEndSectionAnimations ? initOBJ.initEndSectionAnimations : []
  const initEmail = initOBJ?.initEmail ? initOBJ.initEmail : ''

  const [isRevers, setRevers] = useState<boolean>(initIsRevers)
  const [answer, setAnswer] = React.useState<Answer[]>(initAnswer)
  const [pushBottomButtonCounter, setPushBottomBarCounter] = useState<number>(initPushBottomButtonCounter)
  const [sectionNumber, setSectionNumber] = useState<number>(initSectionNumber)
  const [name, setName] = React.useState<string>(initName)
  const [email, setEmail] = React.useState<string>(initEmail)
  const [isChoice, setIsChoice] = React.useState<boolean>(initIsChoice)
  const [isLoading, setLoading] = React.useState<boolean>(initIsLoading)
  const [animationIndex, setAnimationIndex] = useState<number>(initAnimationIndex)
  const [worried, setWorried] = useState<MostWories[]>(initWorried)
  const [mostWorryied, setMostWorryied] = useState<MostWories>(initMostWorryied)
  const [allergies, setAllergies] = React.useState<Allergie[]>(initAllergies)
  const [dispQuestionId, setDispQuestionId] = React.useState<number>(initDispQuestionId)
  const [startAnimation, setStartAnimation] = React.useState<boolean>(initStartAnimation)
  const [endSectionAnimations, setEndAnimation] = React.useState<number[]>(initEndSectionAnimations)
  const startTime = now

  const initOBJs: any = {
    initIsRevers: isRevers,
    initAnswer: answer,
    initPushBottomButtonCounter: pushBottomButtonCounter,
    initSectionNumber: sectionNumber,
    initName: name,
    initIsChoice: isChoice,
    initIsLoading: isLoading,
    initAnimationIndex: animationIndex,
    initWorried: worried,
    initMostWorryied: mostWorryied,
    initAllergies: allergies,
    initDispQuestionId: dispQuestionId,
    initStartAnimation: startAnimation,
    initEndSectionAnimations: endSectionAnimations,
    initEmail: email
  }

  localStorage.setItem('initOBJs', JSON.stringify(initOBJs))

  const ansOBJ: Answers = {
    createDate: new Date(),
    Answer: answer
  }

  const diCardPCHeight = '72vh'
  const diCardSPHeight = window.innerHeight * 0.72 + 'px' //sp sfari chrome では100vh≠window.innerHeight
  const headPCHeight = '20vh'
  const headSPHeight = window.innerHeight * 0.2 + 'px'
  const bottomBarPCHeight = '8vh'
  const bottomBarSPHeight = window.innerHeight * 0.08 + 'px'
  const skipIds = createSkipquestions(
    answer,
    mostWorryied !== '未選択' ? mostWorryied : worried?.[0] ? worried?.[0] : '未選択',
    worried
  )
  const deletedSkipQuestion: Quetsions = deleteSkipQuestionFunc(skipIds)
  const sectionLength = getSectionLength(sectionNumber, deletedSkipQuestion)
  const idForDisplay = getIdForDisplay(deletedSkipQuestion, answer, sectionNumber, pushBottomButtonCounter)
  const setNextMoveAnimation = useCallback(
    () => setAnimationIndex((c: number) => (c < deletedSkipQuestion.length - 1 ? c + 1 : c)),
    []
  )
  const setPreviosMoveAnimation = useCallback(() => setAnimationIndex((c: number) => (c <= 0 ? 0 : c - 1)), [])

  //TODO getWaitとgetHeightを作る
  const others: Other = {
    name: name,
    email: email,
    worries: worried,
    mostWorrie: mostWorryied !== '未選択' ? mostWorryied : worried?.[0] ? worried[0] : '未選択',
    allergies: allergies,
    weight: 0,
    height: 0
  }

  console.log({ email, others })
  const moveNextQuestion = (itemKey = 0, isButtomBar = false) => {
    //setPushBottomBarCounter(pushBottomButtonCounter < 0 ? pushBottomButtonCounter + 1 : pushBottomButtonCounter)
  }
  const movePreviousQuestion = () => {}

  const onMoveAnimation = (isRevers: boolean, isBottomBar = false) => {
    setRevers(isRevers)
    isRevers ? movePreviousQuestion() : moveNextQuestion()
    isRevers ? setPreviosMoveAnimation() : setNextMoveAnimation()
    if (isBottomBar || pushBottomButtonCounter < 0) {
      setPushBottomBarCounter((c) => c + (isRevers ? -1 : 1))
    }
  }

  const moveResult = (emailAdress = '') => {
    const result: Results = testProblemChecker(ansOBJ, others)
    localStorage.setItem('answerObjects', JSON.stringify({ ansOBJ: ansOBJ, others: others }))
    db.collection('iHackFrom20200708')
      .add({
        ans: answer,
        email: emailAdress,
        others: others,
        result: result,
        startTime: startTime,
        timeStamp: firebase.firestore.Timestamp.fromDate(new Date())
      })
      .then(function (docRef: any) {
        console.log('Document written with ID: ', docRef.id)
      })
      .catch(function (error: any) {
        console.error('Error adding document: ', error)
      })

    setLoading(true)
  }

  const onMostWorriedChange = (e: any) => {
    setMostWorryied((_) => e.target.value as MostWories)
    moveNextQuestion()
  }

  const questionInitializ = (sectionNumber: number, key: number) => {
    setSectionNumber((_) => sectionNumber)
    if (sectionNumber !== getPrevSectionId(key - 1) && !endSectionAnimations.includes(sectionNumber)) {
      setStartAnimation(true)
      endSectionAnimations.push(sectionNumber)
    } else {
      //setStartAnimation(false)
    }
  }

  const getPrevSectionId = (prevKey: number) => {
    return deletedSkipQuestion[Number(prevKey >= 0 ? prevKey : 0)].sectionNumber
  }

  const transitions = useTransition(animationIndex, (p) => p, {
    unique: true,
    config: { duration: 1200, easing: d3.easePoly.exponent(2) },
    initial: {
      transform: 'translate3d(100%,0%,0)'
    },
    from: {
      opacity: 0,
      pointerEvents: 'none',
      transform: isRevers ? 'translate3d(-50%,0%,0)' : 'translate3d(50%,0%,0)'
    },

    enter: {
      opacity: startAnimation ? 0 : 1,
      pointerEvents: 'auto',
      transform: 'translate3d(0%,0%,0)'
    },

    update: {
      opacity: startAnimation ? 0 : 1,
      transform: 'translate3d(0%,0,0)'
    },

    leave: {
      opacity: 0,
      transform: isRevers ? 'translate3d(50%,0,0)' : 'translate3d(-50%,0,0)'
    }
  })

  const animationFade = useSpring({
    config: { duration: 1000 },
    from: startAnimation ? { opacity: 0 } : { opacity: 1 },
    to: { opacity: 0 },
    reverse: true
  })
  
  const SP = () => {
    return (
      <>
        <Box>
          <Box
            width="100%"
            height={headSPHeight}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            position="fixed"
          >
            <animated.div style={animationFade}>
              <Box width="250px">
                <HeadBar sectionNumber={sectionNumber} />
              </Box>
            </animated.div>
          </Box>
          <Box width="100%" height={diCardSPHeight} position="fixed" marginTop={headSPHeight} zIndex={1}>
            {transitions.map(({ item, props, key }) => {
              const question = deletedSkipQuestion[Number(key)]
              questionInitializ(question.sectionNumber, Number(key))
              return (
                <Box
                  key={key}
                  width="100%"
                  textAlign="center"
                  height={diCardSPHeight}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                >
                  <DiagnosisCard
                    deleteSkipQuestions={deletedSkipQuestion}
                    styleProps={props}
                    item={item}
                    height={diCardSPHeight}
                    question={question}
                    sectionLength={sectionLength}
                    idForDisplay={idForDisplay}
                    answer={answer}
                    setAnswer={setAnswer}
                    onMoveAnimation={onMoveAnimation}
                    name={name}
                    setName={setName}
                    worries={worried}
                    setWorries={setWorried}
                    mostWories={mostWorryied}
                    setMostWories={setMostWorryied}
                    allergie={allergies}
                    setAllergie={setAllergies}
                    moveResult={moveResult}
                    skipIds={skipIds}
                    dispQuestionId={dispQuestionId}
                    setDispQuestionId={setDispQuestionId}
                    sectionId={sectionNumber}
                    email={email}
                    setEmail={setEmail}
                  />
                </Box>
              )
            })}
          </Box>
          <Box width="100%" height={bottomBarSPHeight} bottom="0px" position="fixed" zIndex={0}>
            <BottomBar
              onClick={onMoveAnimation}
              leftButtonDisable={animationIndex === 0}
              rightButtonDisable={pushBottomButtonCounter >= 0}
            />
          </Box>
        </Box>
      </>
    )
  }
  const PC = () => {
    return (
      <>
        <Box width="100%" height={headPCHeight} display="flex" justifyContent="center" alignItems="flex-end">
          <Box width="450px">
            <animated.div style={animationFade}>
              <Box>
                <HeadBar sectionNumber={sectionNumber} />
              </Box>
            </animated.div>
          </Box>
        </Box>
        <Box width="100%" height={diCardPCHeight}>
          {transitions.map(({ item, props, key }) => {
            const question = deletedSkipQuestion[Number(key)]

            questionInitializ(question.sectionNumber, Number(key))
            return (
              <Box
                key={key}
                width="100%"
                textAlign="center"
                height={diCardPCHeight}
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
              >
                <DiagnosisCard
                  deleteSkipQuestions={deletedSkipQuestion}
                  styleProps={props}
                  item={item}
                  height={diCardSPHeight}
                  question={question}
                  sectionLength={sectionLength}
                  idForDisplay={idForDisplay}
                  answer={answer}
                  setAnswer={setAnswer}
                  onMoveAnimation={onMoveAnimation}
                  name={name}
                  setName={setName}
                  worries={worried}
                  setWorries={setWorried}
                  mostWories={mostWorryied}
                  setMostWories={setMostWorryied}
                  allergie={allergies}
                  setAllergie={setAllergies}
                  moveResult={moveResult}
                  skipIds={skipIds}
                  dispQuestionId={dispQuestionId}
                  setDispQuestionId={setDispQuestionId}
                  sectionId={sectionNumber}
                  email={email}
                  setEmail={setEmail}
                />
              </Box>
            )
          })}
        </Box>
        <Box width="100%" height={bottomBarPCHeight} bottom="0px" position="fixed">
          <BottomBar
            onClick={onMoveAnimation}
            leftButtonDisable={animationIndex === 0}
            rightButtonDisable={pushBottomButtonCounter >= 0}
          />
        </Box>
      </>
    )
  }

  return (
    <Box width="100%">
      <Loading isHidden={!isLoading} start={isLoading} others={others} ans={ansOBJ} history={history} />
      <SectionAnimation
        isHidden={!startAnimation}
        sectionNumber={sectionNumber}
        start={startAnimation}
        setStartAnimation={setStartAnimation}
        isSP={window.screen.width < 1100}
        //window.screen.width < 1100
      />

      <Hidden lgUp>
        <SP />
      </Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </Box>
  )
}

export default withRouter(Diagnosis)
