import React from 'react'
import Hidden from '@material-ui/core/Hidden'

import Text from '../../util/Text'

import { nl2br } from '../../../../../function'

type QuestionTextProps = {
  questionString: string
  spQuestionString: string
}
const QuestionText: React.FC<QuestionTextProps> = ({ questionString, spQuestionString }) => (
  <>
    <Hidden mdDown>
      <Text ff="YuGothic" fw="bold" fs={16} lh="27px" ls="0.1em" style={{ margin: '16px 16px 0px' }}>
        {nl2br(questionString)}
      </Text>
    </Hidden>
    <Hidden lgUp>
      <Text ff="YuGothic" fw="bold" fs={16} lh="27px" ls="0.1em" style={{ margin: '16px 16px 0px' }}>
        {nl2br(spQuestionString)}
      </Text>
    </Hidden>
  </>
)

export default QuestionText
