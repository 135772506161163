import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import { Button } from '@material-ui/core'

const LogoBlack = (props: { color?: string }) => (
  <>
    <Hidden lgUp>
      <svg width="61" height="50" viewBox="0 0 61 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40.9217 28.4601C39.0397 28.9118 39.2655 30.3421 37.6038 30.3421C36.5259 30.3421 35.6521 29.4995 35.6521 28.4601C35.6521 27.4207 36.4049 26.5781 37.6038 26.5781C39.2655 26.5781 39.0397 28.0084 40.9217 28.4601Z"
          fill="#041E42"
        />
        <path
          d="M40.5962 28.4609C43.231 27.8286 43.0052 25.8261 45.2413 25.8261C46.7692 25.8261 47.9736 27.0058 47.9736 28.4609C47.9736 29.9161 46.7692 31.0957 45.2413 31.0957C43.0052 31.0957 43.231 29.0933 40.5962 28.4609Z"
          fill="#041E42"
        />
        <path
          d="M49.9982 28.461C47.3635 29.0933 47.5893 31.0957 45.3532 31.0957C43.8253 31.0957 42.6208 29.9161 42.6208 28.461C42.6208 27.0058 43.8253 25.8262 45.3532 25.8262C47.5893 25.8262 47.3635 27.8286 49.9982 28.461Z"
          fill="#041E42"
        />
        <path
          d="M49.2871 28.4607C52.7499 28.3101 53.7237 24.9218 56.7356 24.9218C58.7935 24.9218 60.4159 26.5059 60.4159 28.4599C60.4159 30.414 58.7935 31.998 56.7356 31.998C53.7237 31.998 52.7499 28.6112 49.2871 28.4607Z"
          fill="#041E42"
        />
        <path d="M0 21.8718L0.022445 12.6332H3.04196V21.8718H0Z" fill="#041E42" />
        <path
          d="M6.93013 21.8718V7H9.97209V12.6988H16.5517V7H19.5937V21.8718H16.5517V15.4478H9.97209V21.8718H6.93013Z"
          fill="#041E42"
        />
        <path
          d="M32.3891 15.202V21.8267H29.595V20.7451C28.7237 21.6464 27.571 21.9916 26.174 21.9916C25.0022 21.9916 24.0819 21.7891 23.3759 21.1732C22.6849 20.5573 22.3394 19.7537 22.3394 18.7622C22.3394 17.7557 22.7149 16.9671 23.466 16.3962C24.2322 15.8104 25.2612 15.5175 26.5531 15.5175H29.3472V15.0217C29.3472 14.3908 29.1669 13.9101 28.8064 13.5796C28.4609 13.2341 27.9501 13.0614 27.2741 13.0614C26.7183 13.0614 26.2151 13.1815 25.7644 13.4219C25.3138 13.6622 24.833 14.0528 24.3223 14.5936L22.745 12.7234C24.0369 11.1911 25.6593 10.425 27.6121 10.425C29.0843 10.425 30.2485 10.8456 31.1047 11.6868C31.961 12.5131 32.3891 13.6848 32.3891 15.202ZM29.3472 17.6581V17.5229H26.9587C25.9221 17.5229 25.4039 17.8984 25.4039 18.6495C25.4039 19.0251 25.5466 19.3255 25.832 19.5509C26.1324 19.7612 26.523 19.8663 27.0037 19.8663C27.6797 19.8663 28.2355 19.6635 28.6712 19.2579C29.1218 18.8373 29.3472 18.304 29.3472 17.6581Z"
          fill="#041E42"
        />
        <path
          d="M35.3702 16.028C35.3702 14.4056 35.9486 13.0311 37.1052 11.9044C38.2619 10.7778 39.674 10.2145 41.3415 10.2145C43.5347 10.2145 45.1495 11.1308 46.186 12.9635L44.0679 14.6084C43.3168 13.5268 42.423 12.986 41.3865 12.986C40.5303 12.986 39.8242 13.279 39.2684 13.8648C38.7276 14.4357 38.4572 15.1567 38.4572 16.028C38.4572 16.9143 38.7276 17.6504 39.2684 18.2362C39.8242 18.8071 40.5303 19.0925 41.3865 19.0925C42.423 19.0925 43.3168 18.5592 44.0679 17.4926L46.186 19.115C45.6903 19.9863 45.0218 20.6698 44.1806 21.1655C43.3544 21.6462 42.408 21.864 41.3415 21.864C39.7041 21.8941 38.292 21.3533 37.1052 20.1966C35.9185 19.0249 35.3402 17.6353 35.3702 16.028Z"
          fill="#041E42"
        />
        <path
          d="M60.4164 21.8718H56.9464L52.4623 15.6752V21.8718H49.4203V7H52.4623V15.4499L56.4732 10.6954H60.0785L55.8873 15.6301L60.4164 21.8718Z"
          fill="#041E42"
        />
        <circle cx="1.53594" cy="8.52545" r="1.51568" fill="#041E42" />
      </svg>
    </Hidden>
    <Hidden mdDown>
      <svg
        width="78"
        height="32"
        viewBox="0 0 78 32"
        fill={props.color ? props.color : '#041E42'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.3815 27.4715C49.9725 28.0497 50.2615 29.8805 48.1345 29.8805C46.7547 29.8805 45.6362 28.802 45.6362 27.4715C45.6362 26.1411 46.5998 25.0625 48.1345 25.0625C50.2615 25.0625 49.9725 26.8934 52.3815 27.4715Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M51.9651 27.4711C55.3377 26.6617 55.0486 24.0985 57.9109 24.0985C59.8667 24.0985 61.4084 25.6085 61.4084 27.4711C61.4084 29.3338 59.8667 30.8438 57.9109 30.8437C55.0486 30.8437 55.3377 28.2806 51.9651 27.4711Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M63.9997 27.4713C60.6271 28.2807 60.9162 30.8439 58.0539 30.8439C56.0982 30.8439 54.5564 29.3339 54.5564 27.4713C54.5564 25.6086 56.0982 24.0986 58.0539 24.0986C60.9162 24.0986 60.6271 26.6618 63.9997 27.4713Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M63.0899 27.472C67.5225 27.2793 68.7689 22.9421 72.6242 22.9421C75.2585 22.9421 77.3352 24.9698 77.3352 27.4711C77.3352 29.9723 75.2585 32 72.6242 32C68.7689 32 67.5225 27.6647 63.0899 27.472Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path d="M0 19.0365L0.0287305 7.21076H3.89382V19.0365H0Z" fill={props.color ? props.color : '#041E42'} />
        <path
          d="M8.87084 19.0365V8.81082e-07H12.7647V7.29471H21.1868V8.81082e-07H25.0807V19.0365H21.1868V10.8136H12.7647V19.0365H8.87084Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M41.4593 10.4989V18.9788H37.8828V17.5943C36.7675 18.748 35.292 19.1898 33.5037 19.1898C32.0038 19.1898 30.8258 18.9307 29.9221 18.1423C29.0375 17.3539 28.5953 16.3252 28.5953 15.0561C28.5953 13.7678 29.076 12.7583 30.0374 12.0276C31.0181 11.2776 32.3353 10.9027 33.989 10.9027H37.5655V10.2681C37.5655 9.46052 37.3348 8.84521 36.8733 8.42217C36.431 7.97991 35.7772 7.75878 34.9119 7.75878C34.2005 7.75878 33.5563 7.91261 32.9794 8.22027C32.4026 8.52793 31.7873 9.02788 31.1335 9.72011L29.1145 7.32613C30.7681 5.3648 32.8448 4.38413 35.3446 4.38413C37.229 4.38413 38.7192 4.92254 39.8153 5.99935C40.9113 7.05693 41.4593 8.55677 41.4593 10.4989ZM37.5655 13.6428V13.4697H34.5081C33.1813 13.4697 32.518 13.9504 32.518 14.9119C32.518 15.3926 32.7006 15.7772 33.066 16.0656C33.4505 16.3348 33.9505 16.4694 34.5658 16.4694C35.4311 16.4694 36.1426 16.2098 36.7002 15.6906C37.2771 15.1522 37.5655 14.4696 37.5655 13.6428Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M45.2752 11.5562C45.2752 9.47947 46.0155 7.72004 47.4962 6.27788C48.9768 4.83573 50.7843 4.11465 52.9187 4.11465C55.7261 4.11465 57.7931 5.2876 59.1199 7.63351L56.4087 9.73906C55.4472 8.35459 54.3031 7.66235 52.9763 7.66235C51.8803 7.66235 50.9766 8.03731 50.2651 8.78723C49.5729 9.51793 49.2267 10.4409 49.2267 11.5562C49.2267 12.6907 49.5729 13.6329 50.2651 14.3828C50.9766 15.1135 51.8803 15.4788 52.9763 15.4788C54.3031 15.4788 55.4472 14.7962 56.4087 13.431L59.1199 15.5077C58.4854 16.6229 57.6297 17.4979 56.5529 18.1324C55.4953 18.7477 54.2839 19.0266 52.9187 19.0266C50.8227 19.065 49.0152 18.3728 47.4962 16.8922C45.9771 15.3923 45.2368 13.6137 45.2752 11.5562Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M77.3354 19.0365H72.8936L67.1538 11.1046V19.0365H63.2599V0H67.1538V10.8162L72.2878 4.73026H76.9027L71.5379 11.0469L77.3354 19.0365Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <circle cx="1.9665" cy="1.95283" r="1.94013" fill={props.color ? props.color : '#041E42'} />
      </svg>
    </Hidden>
  </>
)

export default LogoBlack
