import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'

import { enableScroll } from '../common/scroll'

type TextFieldProps = {
  questionSortId: string
  onNameChange: (event: React.ChangeEvent<any>) => void
  name: string
  onKeyDown: (event: any, isSp: boolean) => void
}

const NameTextField: React.FC<TextFieldProps> = ({ questionSortId, onNameChange, onKeyDown, name }) => {
  enableScroll()
  return (
    <>
      <Hidden mdDown>
        <TextField
          id={questionSortId}
          label=""
          value={name}
          onChange={(event) => {
            onNameChange(event)
          }}
          variant="outlined"
          style={{ width: '400px' }}
          onKeyDown={(event) => {
            onKeyDown(event, false)
          }}
        />
      </Hidden>
      <Hidden lgUp>
        <TextField
          id={String(questionSortId)}
          label=""
          value={name}
          onChange={(event) => {
            onNameChange(event)
          }}
          onKeyDown={(event) => {
            onKeyDown(event, false)
          }}
          variant="outlined"
          style={{ width: '275px' }}
        />
      </Hidden>
    </>
  )
}

export default NameTextField
