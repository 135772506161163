import React from 'react'
import CheckBox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'

import { enableScroll } from './question/common/scroll'

import AgingIcon from '../../icon/Leaf'
import ConcentrationIcon from '../../icon/ConcentrationIcon'
import DepressionIcon from '../../icon/DepressionIcon'
import DietIcon from '../../icon/DietIcon'
import EyeIcon from '../../icon/EyeIcon'
import FatigueIcon from '../../icon/FatigueIcon'
import MemoryIcon from '../../icon/MemoryIcon'
import MotionIcon from '../../icon/MotionIcon'
import PhysicalConditionIcon from '../../icon/PhysicalConditionIcon'
import SleepIcon from '../../icon/SleepIcon'
import StressIcon from '../../icon/StressIcon'
import VitalityIcon from '../../icon/Fire'

type Props = {
  worried: {
    睡眠: boolean
    ストレス: boolean
    活力: boolean
    疲労: boolean
    運動不足: boolean
    記憶力: boolean
    集中力: boolean
    体調不良: boolean
    目の疲れ: boolean
    うつっぽさ: boolean
    ダイエット: boolean
    身体の衰え: boolean
  }
  setWorried: (event: any) => void
}

type Icons = JSX.Element[]

type chunkedArray = any[][]

const icons: Icons = [
  <SleepIcon />,
  <StressIcon />,
  <VitalityIcon />,
  <FatigueIcon />,
  <MotionIcon />,
  <MemoryIcon />,
  <ConcentrationIcon />,
  <PhysicalConditionIcon />,
  <EyeIcon />,
  <DepressionIcon />,
  <DietIcon />,
  <AgingIcon />
]
const checkedIcons: Icons = [
  <SleepIcon color={'#00D4A1'} />,
  <StressIcon color={'#00D4A1'} />,
  <VitalityIcon color={'#00D4A1'} />,
  <FatigueIcon color={'#00D4A1'} />,
  <MotionIcon color={'#00D4A1'} />,
  <MemoryIcon color={'#00D4A1'} />,
  <ConcentrationIcon color={'#00D4A1'} />,
  <PhysicalConditionIcon color={'#00D4A1'} />,
  <EyeIcon color={'#00D4A1'} />,
  <DepressionIcon color={'#00D4A1'} />,
  <DietIcon color={'#00D4A1'} />,
  <AgingIcon color={'#00D4A1'} />
]

const arrayChunk = ([...array], size = 1) => {
  return array.reduce((acc, _, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]), [])
}
const titles: string[] = [
  '睡眠',
  'ストレス',
  '活力',
  '疲労',
  '運動不足',
  '記憶力',
  '集中力',
  '体調不良',
  '目の疲労',
  'うつっぽさ',
  'ダイエット',
  '身体の衰え'
]
const WorriedCheckBox: React.FC<Props> = ({ worried, setWorried }) => {
  enableScroll()
  const onWorriedChange = (event: any) => {
    setWorried({ ...worried, [event.target.name]: event.target.checked })
  }
  const chunkedTitle: chunkedArray = arrayChunk(titles, 6)
  const chunkedTitleSP: chunkedArray = arrayChunk(titles, 3)
  const chunkedIcon: chunkedArray = arrayChunk(icons, 6)
  const chunkedIconSP: chunkedArray = arrayChunk(icons, 3)
  const chunkedCheckedIcon: chunkedArray = arrayChunk(checkedIcons, 6)
  const chunkedCheckedIconSP: chunkedArray = arrayChunk(checkedIcons, 3)
  const chunkedFormName: chunkedArray = arrayChunk(Object.keys(worried), 6)
  const chunkedFormNameSP: chunkedArray = arrayChunk(Object.keys(worried), 3)
  const chunkedFormValue: chunkedArray = arrayChunk(Object.values(worried), 6)
  const chunkedFormValueSP: chunkedArray = arrayChunk(Object.values(worried), 3)
  return (
    <>
      <Hidden mdDown>
        <BlockBox>
          {[1, 2].map((_, i) => (
            <FlexBox>
              <FormRows
                names={chunkedFormName[i]}
                labels={chunkedTitle[i]}
                values={chunkedFormValue[i]}
                icons={chunkedIcon[i]}
                checkedIcons={chunkedCheckedIcon[i]}
                handleChange={onWorriedChange}
              />
            </FlexBox>
          ))}
        </BlockBox>
      </Hidden>
      <Hidden lgUp>
        <Box mb={5}>
          <BlockBox>
            {[1, 2, 3, 4].map((_, i) => (
              <FlexBox>
                <FormRows
                  names={chunkedFormNameSP[i]}
                  labels={chunkedTitleSP[i]}
                  values={chunkedFormValueSP[i]}
                  icons={chunkedIconSP[i]}
                  checkedIcons={chunkedCheckedIconSP[i]}
                  handleChange={onWorriedChange}
                  sp
                />
              </FlexBox>
            ))}
          </BlockBox>
        </Box>
      </Hidden>
    </>
  )
}

type FormRowProps = {
  names: string[]
  values: boolean[]
  labels: string[]
  icons: JSX.Element[]
  checkedIcons: JSX.Element[]
  sp?: boolean
  handleChange: (event: any) => void
}

const FormRows: React.FC<FormRowProps> = ({ names, values, labels, icons, checkedIcons, sp, handleChange }) => (
  <>
    {names.map((name: string, i: number) => (
      <CheckBoxBlock sp={sp ? true : false}>
        <CheckBox
          checked={values[i]}
          onChange={handleChange}
          name={name}
          icon={icons[i]}
          checkedIcon={checkedIcons[i]}
        />
        <br />
        <CheckBoxText sp={sp ? true : false}>{labels[i]}</CheckBoxText>
      </CheckBoxBlock>
    ))}
  </>
)

const BlockBox = (props: { children: React.ReactNode }) => (
  <Box textAlign="center" alignItems="center" justifyContent="center" display={'inline-block'}>
    {props.children}
  </Box>
)

const FlexBox = (props: { children: React.ReactNode }) => (
  <Box textAlign="center" alignItems="center" justifyContent="center" display={'flex'}>
    {props.children}
  </Box>
)
const CheckBoxBlock = (props: { children: React.ReactNode; sp: boolean }) => (
  <Box display="inline-block" alignItems="center" ml={props.sp ? 2 : 2} mr={props.sp ? 2 : 2} mt={2}>
    {props.children}
  </Box>
)

const CheckBoxText = (props: { children: React.ReactChild; sp: boolean }) => (
  <Box
    component="p"
    style={{
      fontFamily: 'YuGothic',
      fontWeight: 'bold',
      fontSize: !props.sp ? '14px' : '10px',
      lineHeight: '140%',
      margin: '0'
    }}
  >
    {props.children}
  </Box>
)

export default WorriedCheckBox
