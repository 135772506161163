import React from 'react'

const SleepIcon = (props: { color?: string }) => (
  <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.7281 38.2466C50.4469 37.9604 50.0165 37.8718 49.6423 38.0233C38.9435 42.3784 26.68 37.3805 22.2509 26.8599C20.0019 21.5175 20.1376 15.4919 22.625 10.2528C22.8581 9.76819 22.6475 9.18956 22.1547 8.96036C21.9017 8.84267 21.6098 8.83624 21.3517 8.9424C9.62219 13.6803 4.01947 26.8713 8.83768 38.4053C13.6559 49.9393 27.0705 55.4486 38.8001 50.7107C44.1497 48.5498 48.473 44.4833 50.9059 39.3239C51.077 38.9631 51.0066 38.5359 50.7281 38.2466ZM30.0876 50.4769C18.4919 50.475 9.09334 41.2299 9.09532 29.8275C9.09655 22.3403 13.22 15.4406 19.871 11.7961C19.0834 14.0949 18.6832 16.5048 18.6865 18.9305C18.6871 31.405 28.9717 41.5172 41.6576 41.5166C43.7837 41.5165 45.8995 41.2262 47.9444 40.6538C44.1163 46.7405 37.3654 50.4541 30.0876 50.4769Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M35.0429 22.3961C34.8282 22.2275 34.5569 22.1444 34.2829 22.1632H30.137L34.0361 17.8826L34.4013 17.4846L34.5889 17.2516C34.654 17.1616 34.7071 17.0637 34.7468 16.9604C34.7916 16.8492 34.8149 16.7306 34.8159 16.611C34.8434 16.3279 34.7008 16.0549 34.4507 15.9121C34.1263 15.7658 33.7705 15.6991 33.4142 15.718H28.5675C28.3089 15.7006 28.0527 15.7766 27.8469 15.9315C27.6769 16.0739 27.5822 16.2847 27.5902 16.5042C27.5902 16.8342 27.7022 17.0348 27.9259 17.106C28.2467 17.1957 28.5797 17.235 28.913 17.2225H32.0717C31.9401 17.4037 31.7691 17.6172 31.5584 17.8631L30.749 18.7561L29.6533 19.9209L28.3799 21.309C27.9391 21.7943 27.6495 22.1179 27.5113 22.2796C27.1979 22.6539 27.2369 23.2039 27.6001 23.5318C27.8605 23.7247 28.1832 23.8178 28.5083 23.7939H34.273C34.5488 23.8151 34.8229 23.7356 35.0429 23.5706C35.2106 23.4266 35.3046 23.2168 35.2996 22.9979C35.3081 22.7699 35.2142 22.5498 35.0429 22.3961Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M43.7788 27.3566C43.6061 27.2207 43.3873 27.1548 43.1668 27.1721H39.8501L42.9595 23.7554L43.2557 23.4351L43.4432 23.2119C43.4961 23.14 43.5392 23.0618 43.5715 22.9789C43.6089 22.8895 43.629 22.7941 43.6308 22.6974C43.6556 22.4709 43.5436 22.2509 43.3445 22.1344C43.085 22.0172 42.8005 21.9638 42.5153 21.9791H38.6064C38.4004 21.9678 38.1971 22.0298 38.0338 22.1538C37.8974 22.2667 37.821 22.4348 37.8266 22.61C37.8266 22.8689 37.9154 23.0307 38.0931 23.0954C38.35 23.1654 38.6165 23.1949 38.8828 23.1827H41.4098C41.311 23.3283 41.1729 23.4933 40.9952 23.6972L40.3437 24.4155L39.4948 25.3861L38.5077 26.4927C38.1523 26.8809 37.9187 27.1463 37.8068 27.2886C37.5742 27.582 37.6041 28.0006 37.8759 28.2593C38.0831 28.4108 38.3391 28.4833 38.5965 28.4631H43.1767C43.3956 28.4803 43.6132 28.4182 43.7887 28.2884C43.9192 28.172 43.9913 28.0053 43.9861 27.8322C43.9907 27.6515 43.915 27.4779 43.7788 27.3566Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M54.1336 21.8997C53.9189 21.731 53.6476 21.6479 53.3735 21.6667H49.1981L53.0971 17.3861L53.4624 16.9882L53.6993 16.7067C53.7644 16.6166 53.8175 16.5187 53.8572 16.4155C53.902 16.3042 53.9253 16.1857 53.9263 16.066C53.9538 15.783 53.8112 15.51 53.5611 15.3672C53.2367 15.2208 52.881 15.1542 52.5246 15.173H47.6285C47.3699 15.1557 47.1138 15.2316 46.908 15.3866C46.7379 15.5289 46.6433 15.7398 46.6513 15.9593C46.6513 16.2893 46.7632 16.4899 46.9869 16.5611C47.3077 16.6507 47.6408 16.6901 47.974 16.6776H51.1328C51.0011 16.8587 50.8301 17.0722 50.6195 17.3182L49.8002 18.2306L48.7341 19.3954L47.4607 20.7834C47.0199 21.2688 46.7303 21.5924 46.5921 21.7541C46.2787 22.1284 46.3177 22.6784 46.6809 23.0062C46.9413 23.1992 47.2641 23.2922 47.5891 23.2683H53.3439C53.6225 23.3028 53.9042 23.2335 54.1336 23.0742C54.3013 22.9302 54.3953 22.7204 54.3903 22.5015C54.3988 22.2735 54.3049 22.0534 54.1336 21.8997Z"
      fill={props.color ? props.color : '#041E42'}
    />
  </svg>
)

export default SleepIcon
