import React from 'react'

const ChickenIcon = (props: { color?: string }) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.12201 9.08375C6.89783 8.93508 6.61263 8.90938 6.36359 9.01624L0.739449 11.4266C0.443803 11.5528 0.252575 11.8436 0.252575 12.165C0.252575 12.4863 0.443803 12.7772 0.739449 12.9033L6.36359 15.3137C6.46481 15.3571 6.57252 15.3788 6.68014 15.3788C6.83521 15.3788 6.98944 15.3337 7.12201 15.2462C7.34779 15.0975 7.48356 14.8453 7.48356 14.5753V9.75461C7.48356 9.48466 7.34779 9.23242 7.12201 9.08375ZM5.87672 13.3566L3.09595 12.1651L5.87672 10.9728V13.3566Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M19.5354 0.113281H15.5181C12.4167 0.113281 9.89392 2.63616 9.89392 5.73742C9.89392 6.18089 10.2539 6.54085 10.6973 6.54085H14.7146C17.816 6.54085 20.3388 4.01797 20.3388 0.916703C20.3389 0.473235 19.9789 0.113281 19.5354 0.113281ZM14.7146 4.934H11.582C11.9548 3.10298 13.5786 1.72022 15.5182 1.72022H18.6508C18.278 3.55125 16.655 4.934 14.7146 4.934Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M12.3043 8.95152C10.9754 8.95152 9.89392 10.033 9.89392 11.3619C9.89392 12.6908 10.9754 13.7722 12.3043 13.7722C13.6332 13.7722 14.7146 12.6908 14.7146 11.3619C14.7146 10.033 13.6333 8.95152 12.3043 8.95152ZM12.3043 12.1654C11.8616 12.1654 11.5009 11.8047 11.5009 11.362C11.5009 10.9193 11.8616 10.5586 12.3043 10.5586C12.747 10.5586 13.1077 10.9193 13.1077 11.362C13.1077 11.8047 12.747 12.1654 12.3043 12.1654Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path d="M22.7492 41.0894H21.1423V47.517H22.7492V41.0894Z" fill={props.color ? props.color : '#041E42'} />
    <path d="M30.7837 46.7136H17.9285V48.3206H30.7837V46.7136Z" fill={props.color ? props.color : '#041E42'} />
    <path d="M27.57 41.0894H25.963V47.517H27.57V41.0894Z" fill={props.color ? props.color : '#041E42'} />
    <path d="M24.3561 43.4997H21.9457V45.1066H24.3561V43.4997Z" fill={props.color ? props.color : '#041E42'} />
    <path d="M29.1768 43.4997H26.7664V45.1066H29.1768V43.4997Z" fill={props.color ? props.color : '#041E42'} />
    <path
      d="M7.0835 13.8804C6.83606 13.7366 6.52996 13.7358 6.28083 13.8788L2.06835 16.29C0.965983 16.9263 0.270179 18.1123 0.252572 19.3962V19.7673C0.244475 20.7331 0.612432 21.6433 1.28978 22.3327C1.96713 23.0213 2.87177 23.4054 3.84392 23.4134H3.86802C5.86222 23.4134 7.48356 21.792 7.48356 19.7978L7.48356 14.5753C7.48356 14.2894 7.33094 14.0242 7.0835 13.8804ZM5.87672 19.7978C5.87672 20.9058 4.97603 21.8064 3.86811 21.8064V22.6098L3.85126 21.8064C3.31458 21.8016 2.81236 21.5886 2.43555 21.2063C2.0595 20.8238 1.85461 20.3176 1.85951 19.7746V19.4074C1.86911 18.6979 2.25722 18.0366 2.86941 17.6831L5.87672 15.9613V19.7978Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M48.4597 5.73746V4.93404H41.2286C41.2278 4.93404 41.2278 4.93404 41.2262 4.93404C35.5506 4.93404 30.3048 8.24583 27.8655 13.3703L24.356 20.7412L18.7488 8.97214C17.7139 6.6952 15.5237 5.14927 13.0137 4.93564C11.218 4.82152 9.49546 5.41526 8.15046 6.60199C6.80546 7.78947 6.00279 9.42936 5.89037 11.2178C5.88076 11.3616 5.87671 11.5062 5.87671 11.6508V23.8159C5.88236 33.778 13.9908 41.888 23.9544 41.8928C33.6377 41.8928 41.4978 33.2621 42.0064 25.782C45.6292 25.3931 48.4598 22.3174 48.4598 18.5927V17.7893H45.769C47.4089 16.4627 48.4598 14.4341 48.4598 12.1651V11.3617H45.769C47.4088 10.0351 48.4597 8.0064 48.4597 5.73746ZM46.7958 12.9685C46.4045 15.6906 44.0576 17.7893 41.2286 17.7893V19.3962H46.7958C46.4045 22.1183 44.0576 24.2169 41.2286 24.2169H40.4252V25.0203C40.4252 31.8923 32.8245 40.2859 23.9544 40.2859C14.877 40.2811 7.48761 32.8925 7.48365 23.8152V11.6508C7.48365 11.5408 7.48685 11.4315 7.4941 11.3198C7.57931 9.9571 8.18991 8.7102 9.21346 7.80707C10.1567 6.9755 11.345 6.52798 12.5775 6.52798C12.6828 6.52798 12.7888 6.53118 12.8957 6.53758C14.8087 6.70066 16.4911 7.88823 17.2921 9.65015L24.3569 24.4779L29.3166 14.0611C31.4916 9.49263 36.1661 6.54079 41.2262 6.54079C41.2271 6.54079 41.2278 6.54079 41.2287 6.54079H46.7959C46.4046 9.26289 44.0577 11.3615 41.2287 11.3615V12.9684L46.7958 12.9685Z"
      fill={props.color ? props.color : '#041E42'}
    />
    <path
      d="M26.7664 24.2169V25.8238H36.2087C35.4678 28.1666 33.2632 29.8379 30.7419 29.8411L30.7267 31.448C30.7323 31.448 30.7371 31.448 30.7435 31.448H32.9948C32.254 33.7933 30.0453 35.4653 27.5208 35.4653C27.5184 35.4653 27.516 35.4653 27.5136 35.4653H24.356C18.5968 35.4653 13.9111 30.7796 13.9111 25.0205V24.217H12.3042V25.0205C12.3042 31.6658 17.7106 37.0723 24.356 37.0723H27.5127C27.5159 37.0723 27.5184 37.0723 27.5216 37.0723C31.158 37.0723 34.2842 34.3582 34.7928 30.7571L34.8772 30.157C36.5292 29.0217 37.7102 27.2276 38.0066 25.133L38.136 24.217H26.7664V24.2169Z"
      fill={props.color ? props.color : '#041E42'}
    />
  </svg>
)

export default ChickenIcon
