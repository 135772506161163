import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'
import './char.css'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplements/Vitamin/SP_TOP_BG.png'
const pcTopBgURL: string = '../supplements/Vitamin/PC_PRODUCT_BG.png'
const SpTopBgURL: string = '../supplements/Vitamin/SP_PRODUCT_BG.png'
const title = 'VITAMIN'
const sub_title = 'Luck is a matter of preparation meeting opportunity. Boys, be ambitious.'
const text =
  '体内ではいくつものビタミン・ミネラルがお互いに作用しており、ひとつでも欠ければ正常に機能することが難しくなります。栄養素の偏りが問題視される現代だからこそ、不足しがちなビタミンを贅沢に配合しました。日常の健康のベースに、Vitamin'
const containtext =
  'Vitaminは、主要なビタミン１１種類をベースとして、ルテイン、BioPerine®といったサポート成分を贅沢に配合した、次世代型マルチビタミンです。日々の健康にお役立てください。'

const product_name = 'VITAMIN'
const product_amount =
  'ビタミンA：652μg、チアミン：2.5mg、リボフラビン：3.3mg、ピリドキシン：3.4mg、シアノコバラミン：10μg、ナイアシン：28mg、パントテン酸：16mg、葉酸：235μg、ビタミンC：169mg、ビタミンD：6.6μg、ピペリン：5.0mg、ルテイン：0.6mg'
const product_materials =
  'ビタミンA、ビタミンB1、ビタミンB2、ビタミンB6、ビタミンB12、ナイアシン、パントテン酸Ca、葉酸、ビタミンC、ビタミンD、黒胡椒抽出物（バイオペリン）、マリーゴールド色素、還元麦芽糖水飴、デキストリン、セルロース、ステアリン酸Ca、微粒二酸化ケイ素、シェラック'
const product_allergy = 'なし'
const product_agent_type = '../tablet.png'
const productBgSp = '../supplements/Vitamin/SP_SPM.png'
const productBgPc = '../supplements/Vitamin/PC_SPM.png'

const evidences: EvidenceProps = [
  {
    title: 'バイオペリン',
    sub_title: '-  吸収をサポート',
    text:
      'バイオペリンは、様々な栄養成分の吸収効率を向上させるエビデンスを有しているピペリンを95％以上含有するよう規格された素材です。論文によると「健康な成人男性を対象とした臨床研究において、両群に15mgのβカロテンを投与しつつ、実験群には5mgのピペリン、対照群にはプラセボを投与した結果、ピペリンを摂取した群において血中のβカロテン濃度が有意に上昇したことが報告された。」と示されています。',
    thesis:
      'Piperine, an alkaloid derived from black pepper increases serum response of beta-carotene during 14-days of oral beta-carotene supplementation',
    magazine: 'The Journal of Nutritional Biochemistry',
    Impact_Factor: '4.518',
    Impact_Factor_Year: '2016',
    publish: '2000',
    link: 'https://www.sciencedirect.com/science/article/pii/S027153179900007X',
    total_quote: '102',
    exists: 'あり'
  },
  {
    title: 'ビタミンC・E',
    sub_title: '-  抗酸化力をサポート',
    text:
      'ビタミンC（アスコルビン酸）は、皮膚や髪、爪などの構成要素であるコラーゲンの生成に必須となるビタミンです。ビタミンEは細胞膜に多く存在しているビタミンで、非常に強い抗酸化能を有しており、細胞の老化を抑えると考えられています。論文によると「線維筋痛女性患者32名を対象として、①運動を行ったグループ②ビタミンC・Eを摂取したグループ③運動及びビタミンC・Eを摂取したグループの３群で実施した臨床試験において、ビタミンC・Eを摂取したグループは運動疲労による酸化ストレスの上昇が抑制された。このことから、ビタミンC・Eを摂取することで抗疲労の効果が考えられる。」と示されています。',
    thesis:
      'Vitamins C and E Treatment Combined With Exercise Modulates Oxidative Stress Markers in Blood of Patients With Fibromyalgia: A Controlled Clinical Pilot Study',
    magazine: 'Stress: the International Journal on Biology of Stress',
    Impact_Factor: '2.168',
    Impact_Factor_Year: '2018',
    publish: '2010',
    link: 'https://pubmed.ncbi.nlm.nih.gov/20666654',
    total_quote: '34',
    exists: 'あり'
  },
  {
    title: 'ルテイン',
    sub_title: '-  眼精疲労をサポート',
    text:
      'ルテインは、ビルベリー等に含まれているカロテノイド色素の一種で、強力な抗酸化作用を有しています。不足すると、黄斑変性や白内障などの眼の老化が起こりやすくなると考えられています。論文によると「22人の健康な被験者を対象とした臨床試験において、対象者を①ルテイン10mg②ゼアキサンチン10mgの２群に分けた３ヶ月間の調査において、ルテインは-4.0以下の弱度近視患者の中心窩内の黄斑色素濃度を有意に向上させた。このことから、ルテインは加齢黄斑変性症のリスクを低減することが示唆されている。」と示されています。',
    thesis:
      'Macular pigment density changes in Japanese individuals supplemented with lutein or zeaxanthin: quantification via resonance Raman spectrophotometry and autofluorescence imaging',
    magazine: 'Japanese Journal of Ophthalmology',
    Impact_Factor: '1.653',
    Impact_Factor_Year: '2018',
    publish: '2012',
    link: 'https://pubmed.ncbi.nlm.nih.gov/22699751/',
    total_quote: '14',
    exists: 'なし'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeVitamin = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="../supplements/Vitamin/PC_TOP_BG.png" width="320px" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeVitamin
