import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplements/Omega3/SP_TOP_BG.png'
const pcTopBgURL: string = '../supplements/Omega3/PC_PRODUCT_BG.png'
const SpTopBgURL: string = '../supplements/Omega3/SP_PRODUCT_BG.png'
const title = 'OMEGA 3'
const sub_title = 'Happiness depends upon ourselves.'
const text =
  '男女ともに広く支持されるフィッシュオイル。最近では、アスリートの間でも愛飲されています。日々、頭と体を使うビジネスアスリートのために、Omega 3'
const containtext =
  'OMEGA 3は、フィッシュオイルをベースに、オキアミ由来でアスタキサンチンを含むクリルオイルや、ハープシールオイル等のオメガ３脂肪酸を豊富に配合しました。日々の健康にお役立てください。'

const product_name = 'OMEGA 3'
const product_amount = 'DHA：0.7mg、EPA：1.3mg、ｎ-3系脂肪酸：2.6mg、DHA：124mg、EPA：14mg'
const product_materials = 'クリルオイル、DHA含有精製魚油、ハープシールオイル、ゼラチン、グリセリン'
const product_allergy = 'ゼラチン'
const product_agent_type = '../soft.png'
const productBgSp = '../supplements/Omega3/SP_SPM.png'
const productBgPc = '../supplements/Omega3/PC_SPM.png'

const evidences: EvidenceProps = [
  {
    title: 'オメガ３脂肪酸 ',
    sub_title: '-  生活習慣病予防に',
    text:
      'オメガ3脂肪酸とは、美容や健康に良いという研究報告が多く、世界中で注目されている成分です。しかし、オメガ3脂肪酸が含まれている食材が青魚やエゴマ油などとかなり限られており、常に不足しがちな栄養素とも言われています。論文によると「オメガ３系脂肪酸について2018年3月4日までに公開されている研究論文19報（合計対象数2,240人）を対象としたメタアナリシスにおいて、オメガ3系脂肪酸の経口摂取は不安症状を軽減することと、身体疾患や精神疾患等を抱えている場合にはよりその効果が顕著であることが示された。」と示されています。',
    thesis: 'Association of Use of Omega-3 Polyunsaturated Fatty Acids With Changes in Severity of Anxiety Symptoms',
    magazine: 'JAMA Network Open',
    Impact_Factor: '44.41',
    Impact_Factor_Year: '2016',
    publish: '2018',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6324500/',
    total_quote: '30',
    exists: 'なし（レビュー論文のため）'
  },
  {
    title: 'DHA',
    sub_title: '-  生活習慣病予防をサポート',
    text:
      'DHAは、青魚の脂に多く含まれる栄養素で、記憶力や健康維持と深く関与していると言われています。人間が生きていく上で欠かせない必須脂肪酸の一種であり、EPA同様に体内では生成することができないため、食事から摂取する必要があります。論文によると「2011年5月12日までに公開されている研究論文から諸条件（論文参照）で抽出された11報を対象としたメタアナリシスにおいて、藻由来DHAの経口摂取は、高脂血症や糖尿病の原因となる中性脂肪を減少させることが示さた。」と示されています。',
    thesis:
      'A meta-analysis shows that docosahexaenoic acid from algal oil reduces serum triglycerides and increases HDL-cholesterol and LDL-cholesterol in persons without coronary heart disease',
    magazine: 'The Journal of Nutrition',
    Impact_Factor: '4.145',
    Impact_Factor_Year: '2016',
    publish: '2012',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/22113870',
    total_quote: '117',
    exists: 'なし（レビュー論文のため）'
  },
  {
    title: 'EPA',
    sub_title: '-  血液のサラサラをサポート',
    text:
      'EPAは、青魚の脂に多く含まれる栄養素で、記憶力や健康維持と深く関与していると言われています。人間が生きていく上で欠かせない必須脂肪酸の一種であり、DHA同様に体内では生成することができないため、食事から摂取する必要があります。論文によると「日本において、魚の消費量が多いグループ（EPA換算で2.5g/day）と魚の消費量の少ないグループ（EPA換算で0.9g/day）の血小板凝集能を比較したところ、魚の消費量が多い市民の方が、血小板凝集能が低かったことがわかった。このことから、EPAには血液流動性の改善効果があることが示された。」と示されています。',
    thesis: 'Eicosapentaenoic Acid and Platelet Function in Japanese',
    magazine: 'The Lancet',
    Impact_Factor: '59.10',
    Impact_Factor_Year: '2018',
    publish: '1980',
    link: 'https://pubmed.ncbi.nlm.nih.gov/6107739/',
    total_quote: '471',
    exists: 'なし'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeVitamin = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="../supplements/Omega3/PC_TOP_BG.png" width="320px" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeVitamin
