export function diasableScroll() {
  document.addEventListener('touchmove', scrollControl, { passive: false })
}
export function enableScroll() {
  document.removeEventListener('touchmove', scrollControl)
}

function scrollControl(event: any) {
  event.preventDefault()
}
