import React from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { enableScroll } from '../common//scroll'

type PullDownSelectProp = {
  classes: any
  questionSortId: string
  options: any[]
  selectValue: number
  onSubmit: (event: any) => void
}

const PullDownSelect: React.FC<PullDownSelectProp> = ({ classes, questionSortId, options, selectValue, onSubmit }) => {
  if (options.length > 12) {
    enableScroll()
  }
  return (
    <Box>
      <FormControl variant="outlined">
        <InputLabel id={questionSortId} />
        <Hidden mdDown>
          <Select
            labelId={questionSortId}
            id={questionSortId}
            value={selectValue ? selectValue : 0}
            onChange={onSubmit}
            name={questionSortId}
            className={classes.selectForm}
          >
            {options.map((x: any) => (
              <MenuItem key={x} value={x?.value}>
                {x?.label}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <Hidden lgUp>
          <Select
            labelId={questionSortId}
            id={questionSortId}
            value={selectValue ? selectValue : 0}
            onChange={onSubmit}
            name={questionSortId}
            className={classes.selectFormSP}
          >
            {options.map((x: any) => (
              <MenuItem key={x} value={x?.value}>
                {x?.label}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
      </FormControl>
    </Box>
  )
}

export default PullDownSelect
