import React, { useState } from 'react'
import Styled from 'styled-components'
import Header from '../../../Header'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'

type Props = {
  iConAdress: string
  title: string
  sub_title: string
  text: string
  containtext: string
  bgimgURl: string
  agent_type: string
  thesis: string
  magazine: string
  total_quote: string
  causes: string[]
  name: string
  amount: string
  materials: string
  link: string
  Impact_Factor: string
  publish: string
  Impact_Factor_Year: string
  allergy: string
}

export const EvidenceOpenButtonSP = Styled(Button)`
  position: absolute;
  top:31px;
  right: 10px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  background-color: #00D4A1;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size:32px;
  &:hover{
    background-color: #00D4A1;
  }
`

const BlueLink = Styled.a`
  color: #041E42;
  font-family: YuGothic;
  font-weight: bold;
  font-size: 12px;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-decoration: none;
`

const Title = Styled.h2`
margin:0 auto 0;
padding:0 0 32px 0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;
/* identical to box height */

letter-spacing: 0.1em;

/* main */

color: #041E42;
`

const SecondTitle = Styled.h3`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 32px;
/* identical to box height */
letter-spacing: 0.12em;
color: #041E42;
`

const Text = Styled.p`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 32px;
letter-spacing: 0.12em;
color: #041E42;
`

export const IHackdetails = Styled.details`
  position:relative;
  padding: 20px;
  border-radius:8px;
  box-shadow:0px 12px 16px rgba(0,0,0,0.05);
  background-color:#FFF;
  list-style:none;
  margin:0 auto;
  transition:.5s;
`

export const IHacksummary = Styled.summary`
  display: block;
  font-size:24px;
  outline:none;
  list-style:none;
  vertical-align:middle;
  color: #041E42;
  cursor:pointer;
  &::-webkit-details-marker {
  display: none;
}
`

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizem: string = '28px'
const fontSizelg: string = '32px'
const fontSizexss: string = '60px'
const fontSizel: string = '50px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '24px'
const lineHeightmd = '40px'

export const SplTopSP = (props: {
  title: string
  sub_title: string
  text: string
  containtext: string
  bgimgURL: string
  spSPM: string
}) => {
  const { title, text, sub_title, containtext, bgimgURL, spSPM } = props
  return (
    <>
      <Box
        width={'100%'}
        display="flex"
        justifyContent="center"
        style={{
          backgroundSize: '100% auto',
          backgroundImage: `url(${bgimgURL})`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box width="88%" pt={'55%'}>
          <Box>
            <Box width="100%">
              <h2
                style={{
                  margin: '0px',
                  padding: '0px',
                  fontSize: `${fontSizel}`,
                  color: `${textColor}`,
                  letterSpacing: '0.1em'
                }}
              >
                {title}
              </h2>
              <Text
                style={{
                  fontSize: `${fontSizexs}`
                }}
              >
                {sub_title}
              </Text>
              <Box textAlign="center">
                <img width={'100%'} src={spSPM} />
              </Box>
              <p
                style={{
                  marginTop: '32px',
                  marginBottom: '55px',
                  color: `${textColor}`,
                  fontSize: `${fontSizexs}`,
                  fontWeight: 'bold',
                  lineHeight: '30px',
                  letterSpacing: `${letterSpacinglg}`,
                  textAlign: 'justify'
                }}
              >
                {text}
              </p>
            </Box>
          </Box>
          <Box width="100%" mt={7} bgcolor="#F2F4F6">
            <SecondTitle
              style={{
                fontSize: '24px',
                fontWeight: 'bold'
              }}
            >
              どんな成分か？
            </SecondTitle>
            <p
              style={{
                marginTop: '10px',
                marginBottom: '0',
                paddingBottom: '0px',
                color: `${textColor}`,
                fontSize: '14px',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacingsm}`,
                textAlign: 'justify'
              }}
            >
              {containtext}
            </p>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export const EvidenceListSP = () => {
  return (
    <>
      <Container
        style={{
          width: '100%',
          backgroundImage: `url(../Rectangle.png)`,
          backgroundRepeat: 'repeat-y',
          paddingTop: '56px'
        }}
      >
        <Box
          style={{
            width: '85%',
            margin: '0 auto'
          }}
        >
          <Title
            style={{
              fontSize: `${fontSizem}`,
              textAlign: 'center'
            }}
          >
            エビデンス一覧
          </Title>
        </Box>
      </Container>
    </>
  )
}

export const ProductDetailSP = (props: {
  name: string
  agent_type: string
  amount: string
  materials: string
  allergy: string
  bgimg?: string
}) => {
  const { name, agent_type, amount, materials, allergy, bgimg } = props
  return (
    <>
      <Box
        width="100%"
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${bgimg})`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box
          style={{
            width: 'inherit',
            color: 'white',
            margin: '0 auto',
            padding: '62px 0'
          }}
        >
          <Title
            style={{
              width: '100%',
              margin: '0 auto',
              color: '#fff',
              fontSize: `${fontSizem}`,
              textAlign: 'center'
            }}
          >
            商品仕様
          </Title>
          <table
            style={{
              height: '500px',
              borderCollapse: 'separate',
              fontSize: `${fontSizexs}`,
              fontWeight: 'bold',
              color: '#fff',
              padding: '0 20px'
            }}
          >
            <tr style={{ height: '110px' }}>
              <td
                style={{
                  fontSize: '16px'
                }}
              >
                <p style={{ margin: '0' }}>名称</p>
                <p style={{ fontSize: `${fontSizem}`, margin: '17px 0' }}>iHack - {name}</p>
                <hr />
              </td>
            </tr>
            <tr style={{ height: '110px' }}>
              <td
                style={{
                  fontSize: '16px'
                }}
              >
                <p style={{ margin: '0' }}>剤形</p>
                <Box
                  width="61px"
                  height="56px"
                  style={{
                    backgroundImage: `url(${agent_type})`,
                    backgroundRepeat: 'no-repeat'
                  }}
                ></Box>
                <hr />
              </td>
            </tr>
            <tr style={{ height: '110px' }}>
              <td
                style={{
                  fontSize: '16px'
                }}
              >
                <p style={{ margin: '0' }}>有効成分</p>
                <p style={{ fontSize: '14px' }}>{amount}</p>
                <hr />
              </td>
            </tr>
            <tr style={{ height: '110px' }}>
              <td
                style={{
                  fontSize: '16px'
                }}
              >
                <p style={{ margin: '0' }}>原材料名</p>
                <p style={{ fontSize: '14px' }}>{materials}</p>
                <hr />
              </td>
            </tr>
            <tr style={{ height: '110px' }}>
              <td
                style={{
                  fontSize: '16px'
                }}
              >
                <p style={{ margin: '0' }}>アレルギー</p>
                <p
                  style={{
                    margin: '17px 0',
                    fontSize: '14px'
                  }}
                >
                  {allergy}
                </p>
              </td>
            </tr>
          </table>

          <ul
            style={{
              width: '80%',
              fontWeight: 'bold',
              letterSpacing: `${letterSpacingsm}`,
              lineHeight: `${lineHeightsm}`,
              fontSize: `${fontSizexxs}`
            }}
          >

            <li>{name == 'VITAMIN' ? '１日２粒' : '１日１粒'}を目安にお召し上がりください。</li>
            <li>原材料をご確認の上、食物アレルギーのある方はお召し上がりにならないでください。</li>
            <li>
              使用する前には必ず医師、薬剤師などの専門家に相談しましょう。あなたが妊娠・授乳中の場合、使用を控えるようにしてください。
            </li>
            <li>お子様の手の届かないところで保管し、開封後はなるべくお早めにお召し上がりください。</li>
          </ul>
        </Box>
      </Box>
    </>
  )
}
