import React, { useEffect } from 'react'
import { Box, Hidden } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'

const H1PcYuGothic = Styled.h1`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const H2PcYuGothic = Styled.h2`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 54px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const PPcYuGothic = Styled.p`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin-top: 0;
    margin-bottom: 24px;
    `

const SpanPcCeraPro = Styled.span`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: '16px';
    line-height: '35px';
    letter-spacing: '0.1em';
    color: #041E42;
    margin: 0;`

const H1SpYuGothic = Styled.h1`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 45px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const H2SpYuGothic = Styled.h2`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin-top: 0;
    margin-bottom: 24px;`

const PSpYuGothic = Styled.p`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin-top: 0;
    margin-bottom: 24px;
    `

const SpanSpCeraPro = Styled.span`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: '14px';
    line-height: '18px';
    letter-spacing: '0.1em';
    color: #041E42;
    margin: 0;`

const Privacy = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  )
}

const Main = () => {
  return (
    <>
      <Hidden xsDown>
        <Box width="86vw" mx="auto" mb={10} pt={20}>
          <H1PcYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>【プライバシーポリシー】</H1PcYuGothic>
          <PPcYuGothic>
            株式会社<SpanPcCeraPro>iHack</SpanPcCeraPro>
            （以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
          </PPcYuGothic>
          <H2PcYuGothic>第1条 プライバシー情報</H2PcYuGothic>
          <PPcYuGothic>
            プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
            プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
          </PPcYuGothic>
          <H2PcYuGothic>第2条 プライバシー情報の収集方法</H2PcYuGothic>
          <PPcYuGothic>
            当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
            当社は、ユーザーについて、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。
          </PPcYuGothic>
          <H2PcYuGothic>第3条 個人情報を収集・利用する目的</H2PcYuGothic>
          <PPcYuGothic>
            当社が個人情報を収集・利用する目的は、以下のとおりです。
            <br />
            （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービスや購入された商品、およびそれらの代金などに関する情報を表示する目的
            <br />
            （2）ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的
            <br />
            （3）ユーザーの本人確認を行うために、氏名、生年月日、住所、電話番号、銀行口座番号、クレジットカード番号、運転免許証番号、配達証明付き郵便の到達結果などの情報を利用する目的
            <br />
            （4）ユーザーに代金を請求するために、購入された商品名や数量、利用されたサービスの種類や期間、回数、請求金額、氏名、住所、銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的
            <br />
            （5）ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的
            <br />
            （6）代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的
            <br />
            （7）ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的
            <br />
            （8）上記の利用目的に付随する目的
          </PPcYuGothic>
          <H2PcYuGothic>第4条 個人情報の第三者提供</H2PcYuGothic>
          <PPcYuGothic>
            当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
            <br />
            （1）法令に基づく場合
            <br />
            （2）人の生命、カラダまたは財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            <br />
            （5）予め次の事項を告知あるいは公表をしている場合
            <br />
            ⅰ. 利用目的に第三者への提供を含むこと
            <br />
            ⅱ. 第三者に提供されるデータの項目
            <br />
            ⅲ.第三者への提供の手段または方法
            <br />
            ⅳ.本人の求めに応じて個人情報の第三者への提供を停止すること
            前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。
            <br />
            （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
            <br />
            （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合
            <br />
            （3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき
            <br />
          </PPcYuGothic>
          <H2PcYuGothic>第5条 個人情報の開示</H2PcYuGothic>
          <PPcYuGothic>
            当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1,000円の手数料を申し受けます。
            （1）本人または第三者の生命、カラダ、財産その他の権利利益を害するおそれがある場合
            <br />
            （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
            <br />
            （3）その他法令に違反することとなる場合
            <br />
            前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
          </PPcYuGothic>
          <H2PcYuGothic>第6条 個人情報の訂正および削除</H2PcYuGothic>
          <PPcYuGothic>
            ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。
            当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。
          </PPcYuGothic>
          <H2PcYuGothic>第7条 個人情報の利用停止等</H2PcYuGothic>
          <PPcYuGothic>
            当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
          </PPcYuGothic>
          <H2PcYuGothic>第8条 プライバシーポリシーの変更</H2PcYuGothic>
          <PPcYuGothic>
            当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を本ウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
          </PPcYuGothic>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box width="84vw" mx="auto" mb={10} pt={20}>
          <H1SpYuGothic style={{ textAlign: 'center', marginBottom: '40px' }}>プライバシーポリシー</H1SpYuGothic>
          <PSpYuGothic>
            株式会社<SpanSpCeraPro>iHack</SpanSpCeraPro>
            （以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
          </PSpYuGothic>

          <H2SpYuGothic>第1条 プライバシー情報</H2SpYuGothic>
          <PSpYuGothic>
            プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
            プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
          </PSpYuGothic>

          <H2SpYuGothic>第2条 プライバシー情報の収集方法</H2SpYuGothic>
          <PSpYuGothic>
            当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
            当社は、ユーザーについて、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。
          </PSpYuGothic>

          <H2SpYuGothic>第3条 個人情報を収集・利用する目的</H2SpYuGothic>
          <PSpYuGothic>
            当社が個人情報を収集・利用する目的は、以下のとおりです。
            <br />
            （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービスや購入された商品、およびそれらの代金などに関する情報を表示する目的
            <br />
            （2）ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合やユーザーに商品を送付したり必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的
            <br />
            （3）ユーザーの本人確認を行うために、氏名、生年月日、住所、電話番号、銀行口座番号、クレジットカード番号、運転免許証番号、配達証明付き郵便の到達結果などの情報を利用する目的
            <br />
            （4）ユーザーに代金を請求するために、購入された商品名や数量、利用されたサービスの種類や期間、回数、請求金額、氏名、住所、銀行口座番号やクレジットカード番号などの支払に関する情報などを利用する目的
            <br />
            （5）ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的
            <br />
            （6）代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的
            <br />
            （7）ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報など当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的
            <br />
            （8）上記の利用目的に付随する目的
          </PSpYuGothic>

          <H2SpYuGothic>第4条 個人情報の第三者提供</H2SpYuGothic>
          <PSpYuGothic>
            当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
            <br />
            （1）法令に基づく場合
            <br />
            （2）人の生命、カラダまたは財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            <br />
            （5）予め次の事項を告知あるいは公表をしている場合
            <br />
            ⅰ. 利用目的に第三者への提供を含むこと
            <br />
            ⅱ. 第三者に提供されるデータの項目
            <br />
            ⅲ.第三者への提供の手段または方法
            <br />
            ⅳ.本人の求めに応じて個人情報の第三者への提供を停止すること
            前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。
            <br />
            （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
            <br />
            （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合
            <br />
            （3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき
            <br />
          </PSpYuGothic>

          <H2SpYuGothic>第5条 個人情報の開示</H2SpYuGothic>
          <PSpYuGothic>
            当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1,000円の手数料を申し受けます。
            （1）本人または第三者の生命、カラダ、財産その他の権利利益を害するおそれがある場合
            <br />
            （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
            <br />
            （3）その他法令に違反することとなる場合
            <br />
            前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
          </PSpYuGothic>

          <H2SpYuGothic>第6条 個人情報の訂正および削除</H2SpYuGothic>
          <PSpYuGothic>
            ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。
            当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。
          </PSpYuGothic>

          <H2SpYuGothic>第7条 個人情報の利用停止等</H2SpYuGothic>
          <PSpYuGothic>
            当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
          </PSpYuGothic>

          <H2SpYuGothic>第8条 プライバシーポリシーの変更</H2SpYuGothic>
          <PSpYuGothic>
            当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を本ウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
          </PSpYuGothic>
        </Box>
      </Hidden>
    </>
  )
}

export default Privacy
