import React from 'react'
import Box from '@material-ui/core/Box'
import RadioGroup from '@material-ui/core/RadioGroup'
import Hidden from '@material-ui/core/Hidden'
import Radio from '@material-ui/core/Radio'

import Text from '../../util/Text'
import { enableScroll } from '../common/scroll'

type FieldButtonRootProps = {
  classes: any
  questionSortId: string
  onSubmit: (event: any) => void
  selectValue: number
  options: any[]
}
const FieldButtonRoot: React.FC<FieldButtonRootProps> = ({
  classes,
  questionSortId,
  onSubmit,
  selectValue,
  options
}) => {
  enableScroll()
  return (
    <Box className={classes.context}>
      <RadioGroup
        name={questionSortId}
        onChange={onSubmit}
        value={selectValue ? selectValue : 0}
        style={{ display: 'flex' }}
      >
        {options.map((x: any) => (
          <>
            <Box marginLeft={4} marginRight={4} display="inline-block">
              <Radio
                value={x?.value}
                style={{}}
                icon={
                  <>
                    <Hidden mdDown>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        width={400}
                        height={54}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text ff="YuGothic" fw="bold" fs={16} lh={'29px'} ls="0.1em" style={{ margin: 0, padding: 0 }}>
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                    <Hidden lgUp>
                      <Box
                        borderRadius={'4px'}
                        border="1px solid #041E42"
                        width={230}
                        height={52}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text ff="YuGothic" fw="bold" fs={14} lh={'29px'} ls="0.1em" style={{ margin: 0, padding: 0 }}>
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                  </>
                }
                checkedIcon={
                  <>
                    <Hidden mdDown>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        bgcolor="#041E42"
                        width={400}
                        height={54}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text
                          ff="YuGothic"
                          fw="bold"
                          fs={16}
                          lh={'29px'}
                          ls="0.1em"
                          color="white"
                          style={{ margin: 0, padding: 0 }}
                        >
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                    <Hidden lgUp>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        bgcolor="#041E42"
                        width={230}
                        height={52}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text
                          ff="YuGothic"
                          fw="bold"
                          fs={14}
                          lh={'29px'}
                          ls="0.1em"
                          color="white"
                          style={{ margin: 0, padding: 0 }}
                        >
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                  </>
                }
              />
            </Box>
          </>
        ))}
      </RadioGroup>
    </Box>
  )
}

export default FieldButtonRoot
