import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplementDetail/Memory/SP_TOP_BG.png' // "../supplementDetail/Style/SP_SPM.png";
const pcTopBgURL: string = '../supplementDetail/Memory/PC_PRODUCT_BG.png' // "../supplementDetail/Style/PC_TOP_BG.png";
const SpTopBgURL: string = '../supplementDetail/Memory/SP_PRODUCT_BG.png' //"../supplementDetail/Style/SP_TOP_BG.png";
const title = 'MEMORY' // STORAGE
const sub_title = 'Everything is practice. Nothing is a waste of time if you use the experience wisely.'
const text =
  '現代人が1日に触れる情報量は、平安時代の一生分、江戸時代の1年分とも言われています。情報が溢れるこの時代に、必要な情報をしっかりと覚えておくために、Memoryは記憶に関わる成分を贅沢に配合しています。情報過多のこの時代に生き抜く力を、Memory。'
const containtext =
  'Memoryは、記憶サポートの２大成分であるイチョウ葉エキスとホスファチジルセリンをベースに、レモンバーム（メリッサ）のエキスを加えたサプリメントです。 ワーキングメモリの解放が必要な時に、ご利用ください。'

const product_name = 'MEMORY' // STORAGE
const product_amount =
  'フラボノール配糖体：20mg、テルペンラクトン類：5.6mg、ギンゴライドB：0.644mg、ホスファチジルセリン：20mg、ポリフェノール：0.8mg、ビタミンD：55μg'
const product_materials =
  'イチョウ葉エキス末、ニチユPS50、レモンバームエキス末、ビタミンD、加工デンプン、セルロース、ステアリン酸Ca、微粒二酸化ケイ素 HPMC、カラメル色素、水'
const product_allergy = '大豆'
const product_agent_type = '../hard.png'
const productBgSp = '../supplementDetail/Memory/SP_SPM.png' // "../supplementDetail/Style/SP_PRODUCT_BG.png";
const productBgPc = '../supplementDetail/Memory/PC_SPM.png' // "../supplementDetail/Style/PC_PRODUCT_BG.png";

const evidences: EvidenceProps = [
  {
    title: 'イチョウ葉エキス＆ホスファチジルセリン',
    sub_title: '-  記憶力を相乗サポート',
    text:
      'イチョウ葉エキスは、イチョウの葉から抽出されたエキスです。また、ホスファチジルセリンは、有効成分であるフラボノイド配糖体とテルペンラクトン、これらの成分は単体でも記憶力向上のエビデンスが多数存在しています。論文によると「28人の健康な若年男女を対象とした試験において、1. イチョウ葉エキス120mg 2. イチョウ葉エキス＆大豆由来ホスファチジルセリン120mg 3. イチョウ葉エキス＆ホスファチジルコリン120mg 4.プラセボ を投与した上で記憶に関するテストを実施した結果、イチョウ葉エキスとホスファチジルセリンの混合物を摂取したグループは記憶速度（記憶に要する時間）及び記憶量が有意に高いことが示された。一方で、イチョウ葉エキス単体でも両項目の改善が見られたものの、有意差は確認できなかった。」と示されています。',
    thesis: 'Acute cognitive effects of standardised Ginkgo biloba extract complexed with phosphatidylserine',
    magazine: 'Human Psychopharmacology',
    Impact_Factor: '2.265',
    Impact_Factor_Year: '2016',
    publish: '2007',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/17457961',
    total_quote: '78',
    exists: 'あり'
  },
  {
    title: 'レモンバーム',
    sub_title: '-  記憶力をサポート',
    text:
      'レモンバームは、ヨーロッパを原産とするシソ科の多年草です。「長寿のハーブ」として古代ギリシャで珍重されてきた成分で、リラックスや記憶力、活力などとの関連性が示されています。論文によると「20人の男女（18-29歳）を対象とした二重盲検ランダム化比較試験において、300-900mgのレモンバームエキスを単回投与したグループは、プラセボを投与されたグループと比較して記憶保持能力が顕著に改善された。 ただ一方でワーキングメモリについては改善が見られなかった。」と示されています。',
    thesis:
      'Modulation of mood and cognitive performance following acute administration of Melissa officinalis (lemon balm)',
    magazine: 'Journal of Alternative and Complementary Medicine',
    Impact_Factor: '1.395',
    Impact_Factor_Year: '2015',
    publish: '2002',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/12062586',
    total_quote: '211',
    exists: 'あり'
  },
  {
    title: 'ビタミンD',
    sub_title: '-  学習力をサポート',
    text:
      'ビタミンDは、ビタミンの一種であり、脂溶性ビタミンに分類されます。必須栄養素だが、日光浴によって生合成もされる唯一のビタミンです。別名、太陽のビタミンとも呼ばれています。論文によると「米ラトガース大学において、50-70歳の女性を対象とした二重盲検ランダム化比較試験を実施した。被験者を3群に分け、それぞれ(i)600IUビタミンD/day、(ii)2,000IUビタミンD/day、(iii)4,000IU/dayを投与した。その結果、1日2,000IUのビタミンDを取ったグループは、記憶力と学習力が有意に改善した。しかしながら、4,000IU群ではその効果は見られなかった。」と示されています。',
    thesis:
      'Three Doses of Vitamin D and Cognitive Outcomes in Older Women: A Double-Blind Randomized Controlled Trial',
    magazine: 'The Journals of Gerontology: Series A',
    Impact_Factor: '4.711',
    Impact_Factor_Year: '-',
    publish: '2020',
    link: 'https://pubmed.ncbi.nlm.nih.gov/30951148/',
    total_quote: '4',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeChill = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img width="320px" height="320px" src="supplementDetail/Memory/PC_TOP_BG.png" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeChill
