import React, { useState, useCallback } from 'react'
import { Box, Hidden, makeStyles, Button } from '@material-ui/core/'
import { Answer, Question } from '../../../types/Score'
import Text from '../../../components/container/diagnosis/util/Text'

type Props = {
  disable: boolean
  onMoveAnimation: (revers: boolean) => void
  answers: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  question: Question
  anyFunction?: () => void
  isFixed?: boolean
  isFinish?: boolean
  moveResult?: (emai?: string) => void
  isAddAns?: boolean
  email?: string
}

const MoveButton = (props: Props) => {
  const {
    disable,
    onMoveAnimation,
    answers,
    question,
    anyFunction = () => {},
    setAnswer,
    isFixed = false,
    isFinish = false,
    moveResult = () => {},
    isAddAns = true,
    email = 'test'
  } = props
  const useStyles = makeStyles({
    baseBox: {
      '&:hover': {
        cursor: disable ? 'not-allowed' : 'pointer'
      }
    }
  })
  const classes = useStyles()

  const setAnswerFunction = () => {
    const answer: Answer = { id: question.id, questionSortId: question.questionSortId, choiceId: 0 }
    if (answers.filter((c) => c.questionSortId === question.questionSortId).length <= 0) {
      if (isAddAns) {
        setAnswer((c) => [...c, answer])
      }
    }
  }
  const SP = () => {
    return (
      <Button>
        <Box
          zIndex={9999}
          margin="auto"
          mt={1}
          left={0}
          right={0}
          onClick={() => {
            if (!disable) {
              setAnswerFunction()
              anyFunction()
              if (isFinish) {
                moveResult(email)
              } else {
                onMoveAnimation(false)
              }
            }
          }}
          width={'176px'}
          height={'52px'}
          borderRadius={'50px'}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          style={{
            backgroundColor: disable ? 'rgba(4, 30, 66, 0.3)' : '#041E42',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '140%',
            letterSpacing: '0.1em',
            padding: 0,
            color: '#fff'
          }}
        >
          <Text fw={'boald'} fs={14} lh="140%" ls={'0.1em'} style={{ color: 'white' }}>
            {isFinish ? '診断結果へ' : '次へ'}
          </Text>
        </Box>
      </Button>
    )
  }
  const FixedButton = () => {
    return (
      <Box
        zIndex={9999}
        margin="auto"
        left={0}
        right={0}
        bottom={10}
        onClick={() => {
          if (!disable) {
            setAnswerFunction()
            anyFunction()
            if (isFinish) {
              moveResult(email)
            } else {
              onMoveAnimation(false)
            }
          }
        }}
        width={'176px'}
        height={'52px'}
        borderRadius={'50px'}
        display="flex"
        position={'fixed'}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        style={{
          backgroundColor: disable ? 'rgba(4, 30, 66, 0.3)' : '#041E42',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '140%',
          letterSpacing: '0.1em',
          padding: 0,
          color: '#fff'
        }}
      >
        <Text fw={'boald'} fs={14} lh="140%" ls={'0.1em'} style={{ color: 'white' }}>
          {isFinish ? '診断結果へ' : '次へ'}
        </Text>
      </Box>
    )
  }
  const PC = () => {
    return (
      <Box
        className={classes.baseBox}
        onClick={() => {
          if (!disable) {
            setAnswerFunction()
            anyFunction()
            if (isFinish) {
              moveResult(email)
            } else {
              onMoveAnimation(false)
            }
          }
        }}
        zIndex={10}
        left={'85%'}
        width={'100px'}
        bottom={'0%'}
        height={'100px'}
        borderRadius={'50px'}
        display="flex"
        position="absolute"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        style={{
          backgroundColor: disable ? 'rgba(4, 30, 66, 0.3)' : '#041E42',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '140%',
          letterSpacing: '0.1em',
          color: '#fff'
        }}
      >
        <Text fw={'boald'} fs={14} lh="140%" ls={'0.1em'} style={{ color: 'white' }}>
          {isFinish ? '診断結果へ' : '次へ'}
        </Text>
      </Box>
    )
  }

  return (
    <>
      <Hidden lgUp>{isFixed ? <FixedButton /> : <SP />}</Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </>
  )
}

export default MoveButton
