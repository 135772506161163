import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Link, Button, Drawer, Hidden } from '@material-ui/core'

const Footer_wrapper = Styled(Box)`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40vh;
    background-image: url(./footer_bg.png);
`

const Footer_content = Styled(Box)`
    display: flex;
    justify-content: space-around;
    width: 86vw;
`

const Footer_ul_left = Styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 33%;
    height: 22vh;
    margin-top: auto;
    padding-left: 0;
    list-style: none;
    letter-spacing: 0.145em;
    text-align: left;
`

const Footer_middle = Styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 33%;
`

const Footer_ul_right = Styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 33%;
    height: 22vh;
    margin-top: auto;
    padding-left: 0;
    list-style: none;
    letter-spacing: 0.145em;
    text-align: right;
`

const Footer_li_left = Styled.li`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    height: 33%;
`

const Footer_li_right = Styled.li`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    height: 25%;
`

const Footer_wrapper_SP = Styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 70vh;
    background-image: url(./footer_bg.png);
`

const Footer_top_SP = Styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 6vh;
`

const Footer_middle_SP = Styled(Box)`
    display: flex;
    justify-content: space-around;
    width: 84vw;
    height: 40%;
    margin-top: 8vh;
`

const Footer_ul_left_SP = Styled.ul`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 22vh;
    margin-top: auto;
    padding-left: 0;
    list-style: none;
    letter-spacing: 0.145em;
    text-align: left;
`

const Footer_ul_right_SP = Styled.ul`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 22vh;
    margin-top: auto;
    padding-left: 0;
    list-style: none;
    letter-spacing: 0.145em;
    text-align: right;
`

const Footer_li_left_SP = Styled.li`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    height: 33%;
`

const Footer_li_right_SP = Styled.li`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    width: 100%;
    height: 25%;
`

const Footer_bottom_SP = Styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 6vh;
`

const Footer = () => {
  return (
    <>
      <Hidden xsDown>
        <Footer_wrapper>
          <Footer_content>
            <Footer_ul_left>
              <Footer_li_left>
                <Link href="story" style={{ color: '#FFFFFF' }}>
                  STORY
                </Link>
              </Footer_li_left>

              <Footer_li_left>
                <Link href="supplementList" style={{ color: '#FFFFFF' }}>
                  SUPPLEMENTS
                </Link>
              </Footer_li_left>

              <Footer_li_left>
                <img
                  src="login_footer.png"
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    objectFit: 'cover'
                  }}
                />
                <Link
                  style={{ color: '#FFFFFF' }}
                  href={'https://ec.ihack.energy/shop/customers/sign_in'}
                  onClick={() => {}}
                >
                  LOGIN
                </Link>
              </Footer_li_left>
            </Footer_ul_left>

            <Footer_middle>
              <img
                src="logo_footer.png"
                style={{
                  width: '28%',
                  height: 'auto',
                  marginTop: '20%',
                  objectFit: 'cover'
                }}
              />
              <Box
                style={{
                  fontFamily: 'Cera Pro',
                  fontStyle: 'normal',
                  fontSize: '10px',
                  lineHeight: '23px',
                  textAlign: 'center',
                  color: '#FFFFFF',
                  marginBottom: '5%'
                }}
              >
                <p>© 2020 iHack Inc. All rights reserved.</p>
              </Box>
            </Footer_middle>

            <Footer_ul_right>
              <Footer_li_right>
                <Link href="faq" style={{ color: '#FFFFFF' }}>
                  よくある質問
                </Link>
              </Footer_li_right>

              <Footer_li_right>
                <Link href="company" style={{ color: '#FFFFFF' }}>
                  運営会社
                </Link>
              </Footer_li_right>

              <Footer_li_right>
                <Link href="privacy" style={{ color: '#FFFFFF' }}>
                  プライバシーポリシー
                </Link>
              </Footer_li_right>

              <Footer_li_right>
                <Link href="low" style={{ color: '#FFFFFF' }}>
                  特定商取引に基づく表記
                </Link>
              </Footer_li_right>
              <Footer_li_right>
                <Link href="https://ihack-energy.channel.io" style={{ color: '#FFFFFF' }}>
                  お問い合わせ
                </Link>
              </Footer_li_right>

              {/* <Footer_li_right>
                <Link href="" style={{ color: '#FFFFFF' }}>
                  利用規約
                </Link>
              </Footer_li_right> */}
            </Footer_ul_right>
          </Footer_content>
        </Footer_wrapper>
      </Hidden>

      <Hidden smUp>
        <Footer_wrapper_SP>
          <Footer_top_SP>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="logo_footer_sp.png"
                style={{
                  width: '30%',
                  height: '30%',
                  objectFit: 'cover'
                }}
              />
            </Box>
          </Footer_top_SP>

          <Footer_middle_SP>
            <Footer_ul_left_SP>
              <Footer_li_left_SP>
                <Link href="story" style={{ color: '#FFFFFF' }}>
                  STORY
                </Link>
              </Footer_li_left_SP>

              <Footer_li_left_SP>
                <Link href="supplementList" style={{ color: '#FFFFFF' }}>
                  SUPPLEMENTS
                </Link>
              </Footer_li_left_SP>

              <Footer_li_left_SP style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <img
                  src="login_footer.png"
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    objectFit: 'cover'
                  }}
                />
                <Link
                  style={{ color: '#FFFFFF' }}
                  href={'https://ec.ihack.energy/shop/customers/sign_in'}
                  onClick={() => {}}
                >
                  LOGIN
                </Link>
              </Footer_li_left_SP>
            </Footer_ul_left_SP>

            <Footer_ul_right_SP>
              <Footer_li_right_SP>
                <Link href="faq" style={{ color: '#FFFFFF' }}>
                  よくある質問
                </Link>
              </Footer_li_right_SP>

              <Footer_li_right_SP>
                <Link href="company" style={{ color: '#FFFFFF' }}>
                  運営会社
                </Link>
              </Footer_li_right_SP>

              <Footer_li_right_SP>
                <Link href="privacy" style={{ color: '#FFFFFF' }}>
                  プライバシーポリシー
                </Link>
              </Footer_li_right_SP>

              <Footer_li_right_SP>
                <Link href="low" style={{ color: '#FFFFFF' }}>
                  特定商取引に基づく表記
                </Link>
              </Footer_li_right_SP>
              <Footer_li_right_SP>
                <Link href="https://ihack-energy.channel.io" style={{ color: '#FFFFFF' }}>
                  お問い合わせ
                </Link>
              </Footer_li_right_SP>
            </Footer_ul_right_SP>
          </Footer_middle_SP>

          <Footer_bottom_SP>
            <Box
              mb={3}
              style={{
                fontFamily: 'Cera Pro',
                fontStyle: 'normal',
                fontSize: '10px',
                lineHeight: '23px',
                textAlign: 'center',
                color: '#FFFFFF'
              }}
            >
              <p>© 2020 iHack Inc. All rights reserved.</p>
            </Box>
          </Footer_bottom_SP>
        </Footer_wrapper_SP>
      </Hidden>
    </>
  )
}

export default Footer
