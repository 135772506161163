import React from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

type PullDownSelectProp = {
  classes: any
  questionSortId: string
  mostWorryied: {
    value: string
  }
  onMostWorriedChange: (event: any) => void
  choices: string[]
}

const WorriedSelect: React.FC<PullDownSelectProp> = ({
  classes,
  questionSortId,
  mostWorryied,
  onMostWorriedChange,
  choices
}) => (
  <>
    <Box className={classes.context}>
      <FormControl variant="outlined">
        <InputLabel id={questionSortId} />
        <Hidden mdDown>
          <Select
            labelId={questionSortId}
            id={questionSortId}
            value={mostWorryied.value}
            onChange={onMostWorriedChange}
            name={questionSortId}
            className={classes.selectForm}
          >
            {choices.map((x: any) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <Hidden lgUp>
          <Select
            labelId={questionSortId}
            id={questionSortId}
            value={mostWorryied.value}
            onChange={onMostWorriedChange}
            name={questionSortId}
            className={classes.selectFormSP}
          >
            {choices.map((x: any) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
      </FormControl>
    </Box>
  </>
)

export default WorriedSelect
