import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button, Drawer, Hidden, Card, Container, Grid } from '@material-ui/core'
import '../../../CeraPro/stylesheet.css'
import autoImgSizeStyle from '../../../common/styleFunctions'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const HeaderWrapper = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
`

const TopLogo = Styled.h1`
  color: #041E42;
  margin: 0;
  padding: 0;
  font-family: YuGothic;
  line-height: 52px;
`

const BlueLink = Styled.a`
  color: #041E42;
  font-family: YuGothic;
  font-weight: bold;
  font-size: 18px;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-decoration: none;
`

const ConversionButton = Styled.button`
  background-color: #00D4A1;
  padding: 5px 30px;
  border-radius: 4px;
`

const H1YuGothic = Styled.h1`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 60px;
/* identical to box height */
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
`

const PYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 32px;
/* or 229% */

text-align: center;
letter-spacing: 0.1em;

/* main */

color: #041E42;
`

const H1SpYuGothic = Styled.h1`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 39px;
letter-spacing: 0.08em;
color: #041E42;
`

const PSpYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
letter-spacing: 0.1em;
color: #041E42;

`

const texts: {
  id: string
  title: string
  statementText: string
  recommendText: string
}[] = [
  {
    id: 'relax',
    title: '必要な場面で、必要以上のパフォーマンスを。',
    statementText:
      '高いパフォーマンスを発揮するためには、日頃からの綿密なケアが欠かせません。\n\n忙しい日々の中でも、休む時間の質を限りなく高められるように。\nそしてワーキングメモリを開放し、%name%さんが目の前の仕事に没頭できるように。\n\nそんなエールを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、\nいま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、\n日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、大事な場面での集中力を向上させるために、*Chill・Memory*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、\n少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じて\nカラダの内側から日々の挑戦をサポートしていきたい*。\n躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'diet',
    title: 'もっと遠くへ、もっと上へ。',
    statementText:
      '常に最高の自分でいるために、もっと自分の身体を絞りたい。\nそんな想いはあれど、生活習慣を変えることは容易ではありません。\n\nさらに、無理なダイエットはむしろ身体を壊す恐れがあり、長続きはしません。\nちゃんと自分の身体を労りつつも、%name%さんがスタイリッシュな身体を目指せるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、\nいま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、\n日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、リカバリーとスタイリッシュをサポートするために、*Recovery、Style*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、\n少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じて\nカラダの内側から日々の挑戦をサポートしていきたい*。\n躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'memory',
    title: 'いつまでも、自分のために、自分らしく。',
    statementText:
      'どんなに強い人間でも、老いには敵いません。\n\nいつまでも、若々しい身体を保つためには、日頃からのケアが必要不可欠。\n%name%さんが何歳になっても自信に満ちた自分の身体でいられるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、\nいま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、\n日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、没頭する力と持久力をサポートするために、*Zone・Vitality*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、\n少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じて\nカラダの内側から日々の挑戦をサポートしていきたい*。\n躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'care',
    title: '勝敗は、戦う前に決まっている。',
    statementText:
      'ハードワークで疲労が溜まりがちな日々、しっかりとケアはできていますか？\n\n「何事も準備8割」と言われるように、パフォーマンスを発揮するためにも、心と身体のケアが不可欠です。\n%name%さんが毎日高いパフォーマンスを発揮するための、コンディショニングケアができるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、\nいま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、\n日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、休養とリカバリーをサポートするために、*Chill・Recovery*\n\n生活習慣を大きく変えるのは難しいことですが、*日々の継続によって、\n少しずつ生活の質を変えていくことは誰にでも可能*です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じて\nカラダの内側から日々の挑戦をサポートしていきたい*。\n躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'focus',
    title: '100%じゃない。120％のパフォーマンス。',
    statementText:
      '「普通の成果」では満足しないあなたのために。\n\nいつだって、圧倒的なパフォーマンスは、圧倒的な熱量から生まれるものです。\n%name%さんが自分の世界に没入し、そして120％のパフォーマンスを発揮できるように。\n\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、\nいま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、\n日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、没頭する力と記憶力をサポートするために、*Zone、Memory*\n\n生活習慣を大きく変えるのは難しいことですが、*日々の継続によって、\n少しずつ生活の質を変えていくことは誰にでも可能*です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じて\nカラダの内側から日々の挑戦をサポートしていきたい*。\n躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  }
]
const spTexts: {
  id: string
  title: string
  statementText: string
  recommendText: string
}[] = [
  {
    id: 'relax',
    title: '必要な場面で、\n必要以上の\nパフォーマンスを。',
    statementText:
      '高いパフォーマンスを発揮するためには、日頃からの綿密なケアが欠かせません。\n忙しい日々の中でも、休む時間の質を限りなく高められるように。そしてワーキングメモリを開放し、%name%さんが目の前の仕事に没頭できるように。\nそんなエールを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、いま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、大事な場面での集中力を向上させるために、*Chill・Memory*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じてカラダの内側から日々の挑戦をサポートしていきたい*。躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'diet',
    title: 'もっと遠くへ、\nもっと上へ。',
    statementText:
      '常に最高の自分でいるために、もっと自分の身体を絞りたい。そんな想いはあれど、生活習慣を変えることは容易ではありません。\nさらに、無理なダイエットはむしろ身体を壊す恐れがあり、長続きはしません。ちゃんと自分の身体を労りつつも、%name%さんがスタイリッシュな身体を目指せるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、いま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、リカバリーとスタイリッシュをサポートするために*Recovery、Style*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じてカラダの内側から日々の挑戦をサポートしていきたい*。躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'memory',
    title: 'いつまでも、\n自分のために、\n自分らしく。',
    statementText:
      'どんなに強い人間でも、老いには敵いません。\nいつまでも、若々しい身体を保つためには、日頃からのケアが必要不可欠。%name%さんが何歳になっても自信に満ちた自分の身体でいられるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、いま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、没頭する力と持久力をサポートするために、*Zone・Vitality*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じてカラダの内側から日々の挑戦をサポートしていきたい*。躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'care',
    title: '勝敗は、戦う前に\n決まっている。',
    statementText:
      'ハードワークで疲労が溜まりがちな日々、しっかりとケアはできていますか？\n「何事も準備8割」と言われるように、パフォーマンスを発揮するためにも、心と身体のケアが不可欠です。%name%さんが毎日高いパフォーマンスを発揮するための、コンディショニングケアができるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、いま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、休養とリカバリーをサポートするために、*Chill・Recovery*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じてカラダの内側から日々の挑戦をサポートしていきたい*。躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  },
  {
    id: 'focus',
    title: '100%じゃない。\n120%のパフォーマンス',
    statementText:
      '「普通の成果」では満足しないあなたのために。いつだって、圧倒的なパフォーマンスは、圧倒的な熱量から生まれるものです。\n%name%さんが自分の世界に没入し、そして120％のパフォーマンスを発揮できるように。\nそんな願いを込めて、こちらのサプリメントを選定させていただきました。',
    recommendText:
      '診断結果と、顧問医師・サプリメント専門家の知見を照らし合わせ、いま、%name%さんに必要なのはこちらの5種類のサプリメントです。\n\n健康維持に必要とされる栄養素でありながら、日常生活では不足しがちな*Vitamin、Mineral、Omega Three*。\n\nそして、没頭する力と記憶力をサポートするために、*Zone、Memory*\n\n生活習慣を大きく変えるのは難しいことですが、日々の継続によって、少しずつ生活の質を変えていくことは誰にでも可能です。\n\n*%name%さんの日常に寄り添い、サプリメントを通じてカラダの内側から日々の挑戦をサポートしていきたい*。躊躇することなく、何かに挑み続けてもらいたい。\n\n私たちは、ビジネスリーダーである、\n%name%さんの生活をとなりで支えていきます。'
  }
]

type Props = {
  name: string
  spName: string
}

const textTypes: {} = []

const StatementText = (props: Props) => {
  const { name, spName } = props
  const content =
    window.innerWidth > 600 ? texts.filter((c) => c.id === spName)[0] : spTexts.filter((c) => c.id === spName)[0]
  const applyedNameText = content.statementText.replace(/%name%/g, name)
  return (
    <>
      <Hidden mdDown>
        <Box width="100%" display="flex" justifyContent="center" mt={21}>
          <Box width="1052px" textAlign="cener">
            <H1YuGothic style={{ textAlign: 'center' }}>{content.title}</H1YuGothic>
            <PYuGothic
              style={{
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
                marginTop: '24px',
                lineHeight: '32px'
              }}
            >
              {applyedNameText}
            </PYuGothic>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box width="100%" display="flex" justifyContent="center" mt={21}>
          <Box width="1052px" textAlign="cener">
            <H1SpYuGothic style={{ textAlign: 'center' }}>{content.title}</H1SpYuGothic>
            <PSpYuGothic
              style={{
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
                marginTop: '24px',
                lineHeight: '32px'
              }}
            >
              {applyedNameText}
            </PSpYuGothic>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box width="100%" display="flex" justifyContent="center" mt={16}>
          <Box textAlign="cener" mx={4}>
            <Box width="100%">
              <H1SpYuGothic style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>{content.title}</H1SpYuGothic>
              <PSpYuGothic
                style={{
                  whiteSpace: 'pre-wrap',
                  textAlign: 'left',
                  marginTop: '24px',
                  lineHeight: '32px'
                }}
              >
                {applyedNameText}
              </PSpYuGothic>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default StatementText
