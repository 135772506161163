import React from 'react'

const MotionIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.6458 10.084C37.498 10.084 34.5765 11.8269 32.9651 14.5964H22.9013C21.2901 11.8269 18.3685 10.084 15.2207 10.084C10.2742 10.084 6.25 14.2725 6.25 19.4208C6.25 24.5691 10.2742 28.7576 15.2206 28.7576C18.3685 28.7576 21.29 27.0146 22.9013 24.2452H32.9651C34.5764 27.0146 37.498 28.7576 40.6458 28.7576C45.5922 28.7576 49.6164 24.5691 49.6164 19.4208C49.6164 14.2725 45.5922 10.084 40.6458 10.084ZM40.6457 25.7693C38.2916 25.7693 36.1233 24.3352 35.1216 22.1157L34.734 21.2568H21.1323L20.7447 22.1157C19.7431 24.3352 17.5747 25.7693 15.2206 25.7693C11.8573 25.7693 9.12109 22.9213 9.12109 19.4208C9.12109 15.9202 11.8573 13.0723 15.2206 13.0723C17.5747 13.0723 19.743 14.5063 20.7447 16.7258L21.1323 17.5848H34.734L35.1216 16.7258C36.1233 14.5064 38.2917 13.0723 40.6457 13.0723C44.009 13.0723 46.7452 15.9202 46.7452 19.4208C46.7452 22.9213 44.009 25.7693 40.6457 25.7693Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M46.2795 32.5195C43.1317 32.5195 40.2101 34.2626 38.5989 37.0319H28.5351C26.9238 34.2625 24.0022 32.5195 20.8544 32.5195C15.908 32.5195 11.8838 36.708 11.8838 41.8563C11.8838 47.0046 15.908 51.1931 20.8544 51.1931C24.0022 51.1931 26.9238 49.45 28.5351 46.6807H38.5989C40.2101 49.4501 43.1317 51.1931 46.2795 51.1931C51.2259 51.1931 55.2501 47.0046 55.2501 41.8563C55.2501 36.708 51.2259 32.5195 46.2795 32.5195ZM46.2795 48.2049C43.9254 48.2049 41.7571 46.7709 40.7554 44.5514L40.3678 43.6925H26.7661L26.3785 44.5514C25.3768 46.7708 23.2084 48.2049 20.8544 48.2049C17.4911 48.2049 14.7549 45.357 14.7549 41.8564C14.7549 38.3558 17.4911 35.5079 20.8544 35.5079C23.2085 35.5079 25.3768 36.942 26.3785 39.1615L26.7661 40.0204H40.3678L40.7554 39.1615C41.757 36.942 43.9254 35.5079 46.2795 35.5079C49.6428 35.5079 52.379 38.3558 52.379 41.8564C52.379 45.3569 49.6428 48.2049 46.2795 48.2049Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default MotionIcon
