import React from 'react'

const ConcentrationIcon = (props: { color?: string; checked?: boolean; sp?: boolean }) => (
  <>
    {!props.sp ? (
      <svg width="50" height="55" viewBox="0 0 40 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.558594" y="0.679688" width="55" height="55" fill="none" />
        <path
          d="M9.77155 47.4551H10.0346V50.6695H9.21189V49.3445L8.55657 50.1099H8.29348L7.64294 49.3493V50.6695H6.82019V47.4551H7.08328L8.42742 49.0958L9.77155 47.4551Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path d="M11.6213 50.6695V47.5125H12.4441V50.6695H11.6213Z" fill={props.color ? props.color : '#041E42'} />
        <path
          d="M14.0308 50.6695V47.5125H14.8536V49.9281H16.1594V50.6695H14.0308Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M20.5466 50.6695H19.5707L18.3701 49.0958V50.6695H17.5474V47.5125H18.3701V48.9427L19.4129 47.5125H20.3696L19.2837 49.0145L20.5466 50.6695Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M39.1327 17.6484C39.1218 17.5449 39.0891 17.4404 39.0391 17.3492L32.921 6.077V3.36999C32.921 1.75192 31.6046 0.435547 29.9866 0.435547H10.5171C8.89913 0.435547 7.58277 1.75192 7.58277 3.36999V6.06272L0.972148 17.3282C0.901065 17.4494 0.862061 17.5928 0.862061 17.7335V52.0657C0.862061 53.6837 2.17843 55.0001 3.7965 55.0001H36.2029C37.8209 55.0001 39.1373 53.6838 39.1373 52.0657V17.7335C39.1372 17.7047 39.1355 17.6763 39.1327 17.6484ZM37.5356 42.3126H26.2003V28.1443H37.5356V42.3126ZM33.4696 18.5342H37.5355V26.5426H26.2003V18.5342H33.4696C33.9118 18.5342 32.8451 18.1757 32.8451 17.7335C32.8451 17.2912 33.912 16.9325 33.4697 16.9325H26.798L32.093 7.90896L36.9906 16.9325H33.4697C33.0274 16.9325 32.6688 17.2911 32.6688 17.7333C32.6688 18.1757 33.0273 18.5342 33.4696 18.5342ZM2.46361 18.5342H24.5986V26.5426H2.46361V18.5342ZM8.84212 7.08123H13.1246C13.5668 7.08123 13.9255 6.72261 13.9255 6.28034C13.9255 5.83807 13.5668 5.47946 13.1246 5.47946H9.18432V3.36999C9.18432 2.63518 9.78218 2.03731 10.517 2.03731H29.9865C30.7213 2.03731 31.3192 2.63518 31.3192 3.36999V5.47957H16.328C15.8857 5.47957 15.5271 5.83807 15.5271 6.28045C15.5271 6.72283 15.8856 7.08133 16.328 7.08133H30.7216L24.9409 16.9327H3.06137L8.84212 7.08123ZM2.46361 52.0656V43.9143L20.6402 43.9144C21.0825 43.9144 19.8393 43.5559 19.8393 43.1135C19.8393 42.6711 21.0825 42.3126 20.6402 42.3126L2.46361 42.3125V28.1443H24.5986V42.3126H20.6402C20.1979 42.3126 19.8393 42.6712 19.8393 43.1135C19.8393 43.5558 20.1978 43.9144 20.6402 43.9144H24.5986V53.3984H3.79639C3.06158 53.3984 2.46361 52.8005 2.46361 52.0656ZM36.2028 53.3984H26.2003V43.9144H37.5356V52.0657C37.5356 52.8005 36.9376 53.3984 36.2028 53.3984Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M13.9965 29.8873C13.7177 29.6873 13.3423 29.6873 13.0635 29.8873C12.9014 30.0034 9.0946 32.7695 9.0946 36.0804C9.0946 38.639 11.0843 40.7206 13.53 40.7206C15.9757 40.7206 17.9654 38.6391 17.9654 36.0804C17.9654 32.7696 14.1586 30.0034 13.9965 29.8873ZM13.53 39.119C11.9676 39.119 10.6963 37.7559 10.6963 36.0805C10.6963 34.17 12.6252 32.3218 13.5337 31.5572C14.6117 32.4608 16.3637 34.3034 16.3637 36.0805C16.3637 37.7558 15.0925 39.119 13.53 39.119Z"
          fill={props.color ? props.color : '#041E42'}
        />
      </svg>
    ) : (
      <svg width="40" height="55" viewBox="0 0 40 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.558594" y="0.679688" width="50" height="50" fill="none" />
        <path
          d="M9.77155 47.4551H10.0346V50.6695H9.21189V49.3445L8.55657 50.1099H8.29348L7.64294 49.3493V50.6695H6.82019V47.4551H7.08328L8.42742 49.0958L9.77155 47.4551Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path d="M11.6213 50.6695V47.5125H12.4441V50.6695H11.6213Z" fill={props.color ? props.color : '#041E42'} />
        <path
          d="M14.0308 50.6695V47.5125H14.8536V49.9281H16.1594V50.6695H14.0308Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M20.5466 50.6695H19.5707L18.3701 49.0958V50.6695H17.5474V47.5125H18.3701V48.9427L19.4129 47.5125H20.3696L19.2837 49.0145L20.5466 50.6695Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M39.1327 17.6484C39.1218 17.5449 39.0891 17.4404 39.0391 17.3492L32.921 6.077V3.36999C32.921 1.75192 31.6046 0.435547 29.9866 0.435547H10.5171C8.89913 0.435547 7.58277 1.75192 7.58277 3.36999V6.06272L0.972148 17.3282C0.901065 17.4494 0.862061 17.5928 0.862061 17.7335V52.0657C0.862061 53.6837 2.17843 55.0001 3.7965 55.0001H36.2029C37.8209 55.0001 39.1373 53.6838 39.1373 52.0657V17.7335C39.1372 17.7047 39.1355 17.6763 39.1327 17.6484ZM37.5356 42.3126H26.2003V28.1443H37.5356V42.3126ZM33.4696 18.5342H37.5355V26.5426H26.2003V18.5342H33.4696C33.9118 18.5342 32.8451 18.1757 32.8451 17.7335C32.8451 17.2912 33.912 16.9325 33.4697 16.9325H26.798L32.093 7.90896L36.9906 16.9325H33.4697C33.0274 16.9325 32.6688 17.2911 32.6688 17.7333C32.6688 18.1757 33.0273 18.5342 33.4696 18.5342ZM2.46361 18.5342H24.5986V26.5426H2.46361V18.5342ZM8.84212 7.08123H13.1246C13.5668 7.08123 13.9255 6.72261 13.9255 6.28034C13.9255 5.83807 13.5668 5.47946 13.1246 5.47946H9.18432V3.36999C9.18432 2.63518 9.78218 2.03731 10.517 2.03731H29.9865C30.7213 2.03731 31.3192 2.63518 31.3192 3.36999V5.47957H16.328C15.8857 5.47957 15.5271 5.83807 15.5271 6.28045C15.5271 6.72283 15.8856 7.08133 16.328 7.08133H30.7216L24.9409 16.9327H3.06137L8.84212 7.08123ZM2.46361 52.0656V43.9143L20.6402 43.9144C21.0825 43.9144 19.8393 43.5559 19.8393 43.1135C19.8393 42.6711 21.0825 42.3126 20.6402 42.3126L2.46361 42.3125V28.1443H24.5986V42.3126H20.6402C20.1979 42.3126 19.8393 42.6712 19.8393 43.1135C19.8393 43.5558 20.1978 43.9144 20.6402 43.9144H24.5986V53.3984H3.79639C3.06158 53.3984 2.46361 52.8005 2.46361 52.0656ZM36.2028 53.3984H26.2003V43.9144H37.5356V52.0657C37.5356 52.8005 36.9376 53.3984 36.2028 53.3984Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M13.9965 29.8873C13.7177 29.6873 13.3423 29.6873 13.0635 29.8873C12.9014 30.0034 9.0946 32.7695 9.0946 36.0804C9.0946 38.639 11.0843 40.7206 13.53 40.7206C15.9757 40.7206 17.9654 38.6391 17.9654 36.0804C17.9654 32.7696 14.1586 30.0034 13.9965 29.8873ZM13.53 39.119C11.9676 39.119 10.6963 37.7559 10.6963 36.0805C10.6963 34.17 12.6252 32.3218 13.5337 31.5572C14.6117 32.4608 16.3637 34.3034 16.3637 36.0805C16.3637 37.7558 15.0925 39.119 13.53 39.119Z"
          fill={props.color ? props.color : '#041E42'}
        />
      </svg>
    )}
  </>
)

export default ConcentrationIcon
