import { Question } from '../types/Score/index'

export const questions: Question[] = [
         {
           id: 1,
           sectionNumber: 1,
           sectionInId: 1,
           questionSortId: 1,
           questionString: 'あなたのニックネームを教えてください',
           spQuestionString: 'あなたのニックネーム\nを教えてください',
           options: [
             {
               label: 'あなたのニックネームを\n教えてください',
               spLabel: 'あなたのニックネームを\n教えてください',
               value: 1
             }
           ],
           type: 'TextFiled',
           iConName: 'nickname.svg',
           skipOptions: [{ choiceIds: 0, skipQuestionSortIds: [0] }]
         },
         {
           id: 2,
           sectionNumber: 1,
           sectionInId: 2,
           questionSortId: 2,
           questionString: '性別を教えてください',
           spQuestionString: '性別を教えてください',
           options: [
             { label: '男性', spLabel: '男性', value: 1 },
             { label: '女性', spLabel: '女性', value: 2 },
             { label: 'その他', spLabel: 'その他', value: 3 }
           ],
           type: 'FiledButton',
           iConName: 'seibetsu.svg',
           skipOptions: [
             { choiceIds: 1, skipQuestionSortIds: [7] },
             { choiceIds: 2, skipQuestionSortIds: [6, 29, 30] },
             { choiceIds: 3, skipQuestionSortIds: [6, 7, 29, 30] }
           ]
         },
         {
           id: 3,
           sectionNumber: 1,
           sectionInId: 3,
           questionSortId: 3,
           questionString: '年齢を教えてください',
           spQuestionString: '年齢を教えてください',
           options: [
             { label: '20歳以下', spLabel: '20歳以下', value: 1 },
             { label: '21歳', spLabel: '21歳', value: 2 },
             { label: '22歳', spLabel: '22歳', value: 3 },
             { label: '23歳', spLabel: '23歳', value: 4 },
             { label: '24歳', spLabel: '24歳', value: 5 },
             { label: '25歳', spLabel: '25歳', value: 6 },
             { label: '26歳', spLabel: '26歳', value: 7 },
             { label: '27歳', spLabel: '27歳', value: 8 },
             { label: '28歳', spLabel: '28歳', value: 9 },
             { label: '29歳', spLabel: '29歳', value: 10 },
             { label: '30歳', spLabel: '30歳', value: 11 },
             { label: '31歳', spLabel: '31歳', value: 12 },
             { label: '32歳', spLabel: '32歳', value: 13 },
             { label: '33歳', spLabel: '33歳', value: 14 },
             { label: '34歳', spLabel: '34歳', value: 15 },
             { label: '35歳', spLabel: '35歳', value: 16 },
             { label: '36歳', spLabel: '36歳', value: 17 },
             { label: '37歳', spLabel: '37歳', value: 18 },
             { label: '38歳', spLabel: '38歳', value: 19 },
             { label: '39歳', spLabel: '39歳', value: 20 },
             { label: '40歳', spLabel: '40歳', value: 21 },
             { label: '41歳', spLabel: '41歳', value: 22 },
             { label: '42歳', spLabel: '42歳', value: 23 },
             { label: '43歳', spLabel: '43歳', value: 24 },
             { label: '44歳', spLabel: '44歳', value: 25 },
             { label: '45歳', spLabel: '45歳', value: 26 },
             { label: '46歳', spLabel: '46歳', value: 27 },
             { label: '47歳', spLabel: '47歳', value: 28 },
             { label: '48歳', spLabel: '48歳', value: 29 },
             { label: '49歳', spLabel: '49歳', value: 30 },
             { label: '50歳', spLabel: '50歳', value: 31 },
             { label: '51歳以上', spLabel: '51歳以上', value: 32 }
           ],
           type: 'PullDown',
           iConName: 'nenrei.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 4,
           sectionNumber: 1,
           sectionInId: 4,
           questionSortId: 4,
           questionString: '身長を教えてください',
           spQuestionString: '身長を教えてください',
           options: [
             { label: '149cm以下', spLabel: '149cm以下', value: 1 },
             { label: '150cm', spLabel: '150cm', value: 2 },
             { label: '151cm', spLabel: '151cm', value: 3 },
             { label: '152cm', spLabel: '152cm', value: 4 },
             { label: '153cm', spLabel: '153cm', value: 5 },
             { label: '154cm', spLabel: '154cm', value: 6 },
             { label: '155cm', spLabel: '155cm', value: 7 },
             { label: '156cm', spLabel: '156cm', value: 8 },
             { label: '157cm', spLabel: '157cm', value: 9 },
             { label: '158cm', spLabel: '158cm', value: 10 },
             { label: '159cm', spLabel: '159cm', value: 11 },
             { label: '160cm', spLabel: '160cm', value: 12 },
             { label: '161cm', spLabel: '161cm', value: 13 },
             { label: '162cm', spLabel: '162cm', value: 14 },
             { label: '163cm', spLabel: '163cm', value: 15 },
             { label: '164cm', spLabel: '164cm', value: 16 },
             { label: '165cm', spLabel: '165cm', value: 17 },
             { label: '166cm', spLabel: '166cm', value: 18 },
             { label: '167cm', spLabel: '167cm', value: 19 },
             { label: '168cm', spLabel: '168cm', value: 20 },
             { label: '169cm', spLabel: '169cm', value: 21 },
             { label: '170cm', spLabel: '170cm', value: 22 },
             { label: '171cm', spLabel: '171cm', value: 23 },
             { label: '172cm', spLabel: '172cm', value: 24 },
             { label: '173cm', spLabel: '173cm', value: 25 },
             { label: '174cm', spLabel: '174cm', value: 26 },
             { label: '175cm', spLabel: '175cm', value: 27 },
             { label: '176cm', spLabel: '176cm', value: 28 },
             { label: '177cm', spLabel: '177cm', value: 29 },
             { label: '178cm', spLabel: '178cm', value: 30 },
             { label: '179cm', spLabel: '179cm', value: 31 },
             { label: '180cm', spLabel: '180cm', value: 32 },
             { label: '181cm', spLabel: '181cm', value: 33 },
             { label: '182cm', spLabel: '182cm', value: 34 },
             { label: '183cm', spLabel: '183cm', value: 35 },
             { label: '184cm', spLabel: '184cm', value: 36 },
             { label: '185cm', spLabel: '185cm', value: 37 },
             { label: '186cm', spLabel: '186cm', value: 38 },
             { label: '187cm', spLabel: '187cm', value: 39 },
             { label: '188cm', spLabel: '188cm', value: 40 },
             { label: '189cm', spLabel: '189cm', value: 41 },
             { label: '190cm', spLabel: '190cm', value: 42 },
             { label: '191cm', spLabel: '191cm', value: 43 },
             { label: '192cm', spLabel: '192cm', value: 44 },
             { label: '193cm', spLabel: '193cm', value: 45 },
             { label: '194cm', spLabel: '194cm', value: 46 },
             { label: '195cm', spLabel: '195cm', value: 47 },
             { label: '196cm', spLabel: '196cm', value: 48 },
             { label: '197cm', spLabel: '197cm', value: 49 },
             { label: '198cm', spLabel: '198cm', value: 50 },
             { label: '199cm', spLabel: '199cm', value: 51 },
             { label: '200cm', spLabel: '200cm', value: 52 },
             { label: '201cm以上', spLabel: '201cm以上', value: 53 }
           ],
           type: 'PullDown',
           iConName: 'sintyo.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 5,
           sectionNumber: 1,
           sectionInId: 5,
           questionSortId: 5,
           questionString: '体重を教えてください',
           spQuestionString: '体重を教えてください',
           options: [
             { label: '39kg以下', spLabel: '39kg以下', value: 1 },
             { label: '40kg', spLabel: '40kg', value: 2 },
             { label: '41kg', spLabel: '41kg', value: 3 },
             { label: '42kg', spLabel: '42kg', value: 4 },
             { label: '43kg', spLabel: '43kg', value: 5 },
             { label: '44kg', spLabel: '44kg', value: 6 },
             { label: '45kg', spLabel: '45kg', value: 7 },
             { label: '46kg', spLabel: '46kg', value: 8 },
             { label: '47kg', spLabel: '47kg', value: 9 },
             { label: '48kg', spLabel: '48kg', value: 10 },
             { label: '49kg', spLabel: '49kg', value: 11 },
             { label: '50kg', spLabel: '50kg', value: 12 },
             { label: '51kg', spLabel: '51kg', value: 13 },
             { label: '52kg', spLabel: '52kg', value: 14 },
             { label: '53kg', spLabel: '53kg', value: 15 },
             { label: '54kg', spLabel: '54kg', value: 16 },
             { label: '55kg', spLabel: '55kg', value: 17 },
             { label: '56kg', spLabel: '56kg', value: 18 },
             { label: '57kg', spLabel: '57kg', value: 19 },
             { label: '58kg', spLabel: '58kg', value: 20 },
             { label: '59kg', spLabel: '59kg', value: 21 },
             { label: '60kg', spLabel: '60kg', value: 22 },
             { label: '61kg', spLabel: '61kg', value: 23 },
             { label: '62kg', spLabel: '62kg', value: 24 },
             { label: '63kg', spLabel: '63kg', value: 25 },
             { label: '64kg', spLabel: '64kg', value: 26 },
             { label: '65kg', spLabel: '65kg', value: 27 },
             { label: '66kg', spLabel: '66kg', value: 28 },
             { label: '67kg', spLabel: '67kg', value: 29 },
             { label: '68kg', spLabel: '68kg', value: 30 },
             { label: '69kg', spLabel: '69kg', value: 31 },
             { label: '70kg', spLabel: '70kg', value: 32 },
             { label: '71kg', spLabel: '71kg', value: 33 },
             { label: '72kg', spLabel: '72kg', value: 34 },
             { label: '73kg', spLabel: '73kg', value: 35 },
             { label: '74kg', spLabel: '74kg', value: 36 },
             { label: '75kg', spLabel: '75kg', value: 37 },
             { label: '76kg', spLabel: '76kg', value: 38 },
             { label: '77kg', spLabel: '77kg', value: 39 },
             { label: '78kg', spLabel: '78kg', value: 40 },
             { label: '79kg', spLabel: '79kg', value: 41 },
             { label: '80kg', spLabel: '80kg', value: 42 },
             { label: '81kg', spLabel: '81kg', value: 43 },
             { label: '82kg', spLabel: '82kg', value: 44 },
             { label: '83kg', spLabel: '83kg', value: 45 },
             { label: '84kg', spLabel: '84kg', value: 46 },
             { label: '85kg', spLabel: '85kg', value: 47 },
             { label: '86kg', spLabel: '86kg', value: 48 },
             { label: '87kg', spLabel: '87kg', value: 49 },
             { label: '88kg', spLabel: '88kg', value: 50 },
             { label: '89kg', spLabel: '89kg', value: 51 },
             { label: '90kg', spLabel: '90kg', value: 52 },
             { label: '91kg', spLabel: '91kg', value: 53 },
             { label: '92kg', spLabel: '92kg', value: 54 },
             { label: '93kg', spLabel: '93kg', value: 55 },
             { label: '94kg', spLabel: '94kg', value: 56 },
             { label: '95kg', spLabel: '95kg', value: 57 },
             { label: '96kg', spLabel: '96kg', value: 58 },
             { label: '97kg', spLabel: '97kg', value: 59 },
             { label: '98kg', spLabel: '98kg', value: 60 },
             { label: '99kg', spLabel: '99kg', value: 61 },
             { label: '100kg以上', spLabel: '100kg以上', value: 62 }
           ],
           type: 'PullDown',
           iConName: 'taizyu.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 6,
           sectionNumber: 1,
           sectionInId: 6,
           questionSortId: 6,
           questionString: '体脂肪率を教えてください',
           spQuestionString: '体脂肪率を教えてください',
           options: [
             { label: 'わからない', spLabel: 'わからない', value: 1 },
             { label: '6%以下', spLabel: '6%以下', value: 2 },
             { label: '7%〜10%', spLabel: '7%〜10%', value: 3 },
             { label: '10%〜13%', spLabel: '10%〜13%', value: 4 },
             { label: '14%〜16%', spLabel: '14%〜16%', value: 5 },
             { label: '17%〜19%', spLabel: '17%〜19%', value: 6 },
             { label: '20%〜22%', spLabel: '20%〜22%', value: 7 },
             { label: '23%〜25%', spLabel: '23%〜25%', value: 8 },
             { label: '26%〜28%', spLabel: '26%〜28%', value: 9 },
             { label: '29%〜31%', spLabel: '29%〜31%', value: 10 },
             { label: '32%〜34%', spLabel: '32%〜34%', value: 11 },
             { label: '35%以上', spLabel: '35%以上', value: 12 }
           ],
           type: 'PullDown',
           iConName: 'taishiboritsu.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 2, skipQuestionSortIds: [8] },
             { choiceIds: 3, skipQuestionSortIds: [8] },
             { choiceIds: 4, skipQuestionSortIds: [8] },
             { choiceIds: 5, skipQuestionSortIds: [8] },
             { choiceIds: 6, skipQuestionSortIds: [8] },
             { choiceIds: 7, skipQuestionSortIds: [8] },
             { choiceIds: 8, skipQuestionSortIds: [8] },
             { choiceIds: 9, skipQuestionSortIds: [8] },
             { choiceIds: 10, skipQuestionSortIds: [8] },
             { choiceIds: 11, skipQuestionSortIds: [8] },
             { choiceIds: 12, skipQuestionSortIds: [8] }
           ]
         },
         {
           id: 7,
           sectionNumber: 1,
           sectionInId: 7,
           questionSortId: 7,
           questionString: '体脂肪率を教えてください ',
           spQuestionString: '体脂肪率を教えてください ',
           options: [
             { label: 'わからない', spLabel: 'わからない', value: 1 },
             { label: '13%以下', spLabel: '13%以下', value: 2 },
             { label: '14%-16% ', spLabel: '14%-16% ', value: 3 },
             { label: '17%-19% ', spLabel: '17%-19% ', value: 4 },
             { label: '20%-22% ', spLabel: '20%-22% ', value: 5 },
             { label: '23%-25% ', spLabel: '23%-25% ', value: 6 },
             { label: '26%-28% ', spLabel: '26%-28% ', value: 7 },
             { label: '29%-31% ', spLabel: '29%-31% ', value: 8 },
             { label: '32%-34% ', spLabel: '32%-34% ', value: 9 },
             { label: '35%-39% ', spLabel: '35%-39% ', value: 10 },
             { label: '40%-44% ', spLabel: '40%-44% ', value: 11 },
             { label: '45%以上', spLabel: '45%以上', value: 12 }
           ],
           type: 'PullDown',
           iConName: 'taishiboritsu.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 2, skipQuestionSortIds: [8] },
             { choiceIds: 3, skipQuestionSortIds: [8] },
             { choiceIds: 4, skipQuestionSortIds: [8] },
             { choiceIds: 5, skipQuestionSortIds: [8] },
             { choiceIds: 6, skipQuestionSortIds: [8] },
             { choiceIds: 7, skipQuestionSortIds: [8] },
             { choiceIds: 8, skipQuestionSortIds: [8] },
             { choiceIds: 9, skipQuestionSortIds: [8] },
             { choiceIds: 10, skipQuestionSortIds: [8] },
             { choiceIds: 11, skipQuestionSortIds: [8] },
             { choiceIds: 12, skipQuestionSortIds: [8] }
           ]
         },
         {
           id: 8,
           sectionNumber: 1,
           sectionInId: 8,
           questionSortId: 8,
           questionString: '近い体型を教えてください',
           spQuestionString: '近い体型を教えてください',
           options: [
             { label: '痩せ型', spLabel: '痩せ型', value: 1 },
             { label: 'ふつう型', spLabel: 'ふつう型', value: 2 },
             { label: 'ぽっちゃり型', spLabel: 'ぽっちゃり型', value: 3 },
             { label: '細マッチョ型', spLabel: '細マッチョ型', value: 4 },
             { label: 'アスリートマッチョ型', spLabel: 'アスリートマッチョ型', value: 5 }
           ],
           type: 'PullDown',
           iConName: 'taikei.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 9,
           sectionNumber: 1,
           sectionInId: 9,
           questionSortId: 9,
           questionString: 'サプリメントの利用状況について教えてください',
           spQuestionString: 'サプリメントの利用状況について\n教えてください',
           options: [
             { label: '飲んだことがない', spLabel: '飲んでいない', value: 3 },
             { label: '過去飲んでいた', spLabel: '飲んでいた', value: 2 },
             { label: '現在も飲んでいる', spLabel: '飲んでいる', value: 1 }
           ],
           type: 'RadioButton',
           iConName: 'sapuri.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 10,
           sectionNumber: 2,
           sectionInId: 1,
           questionSortId: 10,
           questionString: 'お悩みに感じていることを全て教えてください',
           spQuestionString: 'お悩みに感じていることを\n全て教えてください',
           options: [
             { label: '睡眠・SLEEP', spLabel: '睡眠・SLEEP', value: 1 },
             { label: 'ストレス・STRESS', spLabel: 'ストレス・STRESS', value: 2 },
             { label: '活力・VITALITY', spLabel: '活力・VITALITY', value: 3 },
             { label: '疲労・FATIGUE/TIREDNESS', spLabel: '疲労・FATIGUE/TIREDNESS', value: 4 },
             { label: '運動不足・FITNESS', spLabel: '運動不足・FITNESS', value: 5 },
             { label: '記憶力・MEMORY', spLabel: '記憶力・MEMORY', value: 6 },
             { label: '集中力・FOCUS', spLabel: '集中力・FOCUS', value: 7 },
             { label: '体調不良・IMMUNITY', spLabel: '体調不良・IMMUNITY', value: 8 },
             { label: '目の疲労・EYECARE', spLabel: '目の疲労・EYECARE', value: 9 },
             { label: 'うつっぽさ・DEPRESSION', spLabel: 'うつっぽさ・DEPRESSION', value: 10 },
             { label: 'ダイエット・DIET', spLabel: 'ダイエット・DIET', value: 11 },
             { label: 'エイジングケア・AGING', spLabel: 'エイジングケア・AGING', value: 12 }
           ],
           type: 'CheckBoxs',
           iConName: 'onayami.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 11,
           sectionNumber: 2,
           sectionInId: 2,
           questionSortId: 11,
           questionString: '最も優先度の高いお悩みを教えてください',
           spQuestionString: '最も優先度の高いお悩みを\n教えてください',
           options: [{ label: '一時的に表示', spLabel: '一時的に表示', value: 1 }],
           type: 'WorriedSelect',
           iConName: 'onayami.svg',
           skipOptions: [{ choiceIds: 0, skipQuestionSortIds: [0] }]
         },
         {
           id: 12,
           sectionNumber: 2,
           sectionInId: 3,
           questionSortId: 12,
           questionString: '人よりも風邪を引きやすいと感じる',
           spQuestionString: '人よりも風邪を\n引きやすい方だと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'kaze.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 13,
           sectionNumber: 2,
           sectionInId: 4,
           questionSortId: 13,
           questionString: '風邪を引いた時、昔よりも治りが遅くなったと感じる',
           spQuestionString: '風邪を引いた時、昔よりも\n治りが遅くなったと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'kazenonaori.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 14,
           sectionNumber: 2,
           sectionInId: 5,
           questionSortId: 14,
           questionString: '周囲の人間にあたってしまうことがある',
           spQuestionString: '周囲の人間に\nあたってしまうことがある',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'atatteshimau.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 15,
           sectionNumber: 2,
           sectionInId: 6,
           questionSortId: 15,
           questionString: '常に漠然とした不安がある',
           spQuestionString: '常に漠然とした不安を\n抱えていると感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'huan.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 16,
           sectionNumber: 2,
           sectionInId: 7,
           questionSortId: 16,
           questionString: '疲れがとれにくいと感じる',
           spQuestionString: '疲れがとれにくい方\nだと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'tsukare.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 17,
           sectionNumber: 2,
           sectionInId: 8,
           questionSortId: 17,
           questionString: '常に体がだるいと感じる',
           spQuestionString: '常に身体への\nだるさを感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'karadagadarui.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 18,
           sectionNumber: 2,
           sectionInId: 9,
           questionSortId: 18,
           questionString: '常に仕事でプレッシャーを感じている',
           spQuestionString: '常に仕事でプレッシャーを\n感じている',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'pressure.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 19,
           sectionNumber: 2,
           sectionInId: 10,
           questionSortId: 19,
           questionString: '何事にもやる気が起きないと感じる',
           spQuestionString: '何事にもやる気が\n起きないと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'yarukigaokinai.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 20,
           sectionNumber: 2,
           sectionInId: 11,
           questionSortId: 20,
           questionString: '集中力が落ちてきたと感じることが多い',
           spQuestionString: '集中力が落ちてきたと\n感じることが多い',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'syutyuryoku.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 21,
           sectionNumber: 2,
           sectionInId: 12,
           questionSortId: 21,
           questionString: 'ベッドに入ってからなかなか寝付けない',
           spQuestionString: 'ベッドに入ってから\nなかなか寝付けないと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'netsukenai.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 22,
           sectionNumber: 2,
           sectionInId: 13,
           questionSortId: 22,
           questionString: '夜中に何度も目が覚めてしまう',
           spQuestionString: '夜中に何度も\n目が覚めてしまうと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'yonakanimezameru.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 23,
           sectionNumber: 2,
           sectionInId: 14,
           questionSortId: 23,
           questionString: '寝起きが悪く熟睡感がないと感じる',
           spQuestionString: '寝起きが悪く\n熟睡感がないと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'zyukusuikan.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 24,
           sectionNumber: 2,
           sectionInId: 15,
           questionSortId: 24,
           questionString: '人に会うのが億劫だと感じる',
           spQuestionString: '人に会うことが\n億劫だと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'okkuu.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 25,
           sectionNumber: 2,
           sectionInId: 16,
           questionSortId: 25,
           questionString: '小さなことでイライラしやすい',
           spQuestionString: '小さなことで\nイライラしやすいと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'iraira.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 26,
           sectionNumber: 2,
           sectionInId: 17,
           questionSortId: 26,
           questionString: '昔よりも1つの作業に打ち込むのが困難だと感じる',
           spQuestionString: '昔よりも1つの作業に打ち込むのが\n困難だと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'sagyoukonnan.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 27,
           sectionNumber: 2,
           sectionInId: 18,
           questionSortId: 27,
           questionString: '記憶力の衰えを感じる',
           spQuestionString: '昔よりも\n記憶力の衰えを感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'kiokuryoku.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 28,
           sectionNumber: 2,
           sectionInId: 19,
           questionSortId: 28,
           questionString: '物事を記憶するのが苦手だと感じる',
           spQuestionString: '物事を記憶するのが\n苦手だと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'kiokuganigate.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 29,
           sectionNumber: 2,
           sectionInId: 20,
           questionSortId: 29,
           questionString: '性交渉に自信がなくなってきた',
           spQuestionString: '性交渉に自信が\nなくなってきたと感じる',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'zishin.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 30,
           sectionNumber: 2,
           sectionInId: 21,
           questionSortId: 30,
           questionString: '性交渉が思うようにいかないことが多い',
           spQuestionString: '性交渉が思うように\nいかないことが多い',
           options: [
             { label: 'とてもそう思う', spLabel: 'とても\nそう思う', value: 1 },
             { label: '', spLabel: '', value: 2 },
             { label: '', spLabel: '', value: 3 },
             { label: '', spLabel: '', value: 4 },
             { label: '全くそう思わない', spLabel: '全くそう\n思わない', value: 5 }
           ],
           type: 'RadioButton',
           iConName: 'tsuzukanai.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 31,
           sectionNumber: 3,
           sectionInId: 1,
           questionSortId: 31,
           questionString: '平日の就寝時間を教えてください',
           spQuestionString: '平日の就寝時間を教えてください',
           options: [
             { label: '22時以前', spLabel: '22時以前', value: 1 },
             { label: '22時台', spLabel: '22時台', value: 2 },
             { label: '23時台', spLabel: '23時台', value: 3 },
             { label: '24時台', spLabel: '24時台', value: 4 },
             { label: '25時台', spLabel: '25時台', value: 5 },
             { label: '26時台', spLabel: '26時台', value: 6 },
             { label: '27時以降', spLabel: '27時以降', value: 7 }
           ],
           type: 'PullDown',
           iConName: 'syushin.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 32,
           sectionNumber: 3,
           sectionInId: 2,
           questionSortId: 32,
           questionString: '平均睡眠時間を教えてください',
           spQuestionString: '平均睡眠時間を教えてください',
           options: [
             { label: '3時間以下', spLabel: '3時間以下', value: 1 },
             { label: '3~4時間', spLabel: '3~4時間', value: 2 },
             { label: '4~5時間', spLabel: '4~5時間', value: 3 },
             { label: '5~6時間', spLabel: '5~6時間', value: 4 },
             { label: '6~7時間', spLabel: '6~7時間', value: 5 },
             { label: '7~8時間', spLabel: '7~8時間', value: 6 },
             { label: '8~9時間', spLabel: '8~9時間', value: 7 },
             { label: '9時間以上', spLabel: '9時間以上', value: 8 }
           ],
           type: 'PullDown',
           iConName: 'heikinsuimin.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 33,
           sectionNumber: 3,
           sectionInId: 3,
           questionSortId: 33,
           questionString: '1日のうちPCやスマホを見る時間を教えてください',
           spQuestionString: '1日のうちPCやスマホを\n見る時間を教えてください',
           options: [
             { label: '2時間以下', spLabel: '2時間以下', value: 1 },
             { label: '2~4時間', spLabel: '2~4時間', value: 2 },
             { label: '4~6時間', spLabel: '4~6時間', value: 3 },
             { label: '6~8時間', spLabel: '6~8時間', value: 4 },
             { label: '8~10時間', spLabel: '8~10時間', value: 5 },
             { label: '10~12時間', spLabel: '10~12時間', value: 6 },
             { label: '12~14時間', spLabel: '12~14時間', value: 7 },
             { label: '14時間以上', spLabel: '14時間以上', value: 8 }
           ],
           type: 'PullDown',
           iConName: 'gamen.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 34,
           sectionNumber: 3,
           sectionInId: 4,
           questionSortId: 34,
           questionString: '1週間のうち運動する頻度を教えてください',
           spQuestionString: '1週間のうち運動する頻度を\n教えてください',
           options: [
             { label: '運動はしない', spLabel: 'しない', value: 4 },
             { label: '1日~2日', spLabel: '1日~2日', value: 2 },
             { label: '2日~3日', spLabel: '2日~3日', value: 3 },
             { label: '4日以上', spLabel: '4日以上', value: 1 }
           ],
           type: 'RadioButton',
           iConName: 'undo.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 35,
           sectionNumber: 3,
           sectionInId: 5,
           questionSortId: 35,
           questionString: '1週間のうちお酒を飲む頻度を教えてください',
           spQuestionString: '1週間のうちお酒を飲む頻度を\n教えてください',
           options: [
             { label: 'ほぼ毎日', spLabel: 'ほぼ毎日', value: 1 },
             { label: '3~4日', spLabel: '3~4日', value: 2 },
             { label: '1~2日', spLabel: '1~2日', value: 3 },
             { label: '飲まない', spLabel: '飲まない', value: 4 }
           ],
           type: 'RadioButton',
           iConName: 'osake.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 36,
           sectionNumber: 3,
           sectionInId: 6,
           questionSortId: 36,
           questionString: 'たばこを吸いますか',
           spQuestionString: 'たばこを吸いますか',
           options: [
             { label: 'はい', spLabel: 'はい', value: 1 },
             { label: 'いいえ', spLabel: 'いいえ', value: 2 }
           ],
           type: 'FiledButton',
           iConName: 'tabako.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 2, skipQuestionSortIds: [37, 38] }
           ]
         },
         {
           id: 37,
           sectionNumber: 3,
           sectionInId: 6,
           questionSortId: 37,
           questionString: 'たばこのタイプを教えてください',
           spQuestionString: 'たばこのタイプを教えてください',
           options: [
             { label: '紙巻たばこ', spLabel: '紙巻たばこ', value: 1 },
             { label: '電子たばこ', spLabel: '電子たばこ', value: 2 }
           ],
           type: 'FiledButton',
           iConName: 'tabakonosyurui.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 38,
           sectionNumber: 3,
           sectionInId: 7,
           questionSortId: 38,
           questionString: '1日の喫煙頻度を教えてください',
           spQuestionString: '1日の喫煙頻度を\n教えてください',
           options: [
             { label: '20本以上', spLabel: '20本以上', value: 4 },
             { label: '11~20本', spLabel: '11~20本', value: 3 },
             { label: '4~10本', spLabel: '4~10本', value: 2 },
             { label: '1~3本', spLabel: '1~3本', value: 1 }
           ],
           type: 'RadioButton',
           iConName: 'kitsuenhindo.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 39,
           sectionNumber: 3,
           sectionInId: 8,
           questionSortId: 39,
           questionString: '1週間のうち野菜を食べる頻度を教えてください',
           spQuestionString: '1週間のうち野菜を食べる頻度を\n教えてください',
           options: [
             { label: 'ほぼ食べない', spLabel: 'ほぼ食べない', value: 4 },
             { label: '1~2日', spLabel: '1~2日', value: 3 },
             { label: '3~4日', spLabel: '3~4日', value: 2 },
             { label: 'ほぼ毎日', spLabel: 'ほぼ毎日', value: 1 }
           ],
           type: 'RadioButton',
           iConName: 'yasai.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 40,
           sectionNumber: 3,
           sectionInId: 9,
           questionSortId: 40,
           questionString: '1週間のうちお肉を食べる頻度を教えてください',
           spQuestionString: '1週間のうちお肉を食べる頻度を\n教えてください',
           options: [
             { label: 'ほぼ食べない', spLabel: 'ほぼ食べない', value: 4 },
             { label: '1~2日', spLabel: '1~2日', value: 3 },
             { label: '3~4日', spLabel: '3~4日', value: 2 },
             { label: 'ほぼ毎日', spLabel: 'ほぼ毎日', value: 1 }
           ],
           type: 'RadioButton',
           iConName: 'niku.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         },
         {
           id: 41,
           sectionNumber: 3,
           sectionInId: 10,
           questionSortId: 41,
           questionString: '1週間のうち魚を食べる頻度を教えてください',
           spQuestionString: '1週間のうち魚を食べる頻度を\n教えてください',
           options: [
             { label: 'ほぼ食べない', spLabel: 'ほぼ食べない', value: 4 },
             { label: '1~2日', spLabel: '1~2日', value: 3 },
             { label: '3~4日', spLabel: '3~4日', value: 2 },
             { label: 'ほぼ毎日', spLabel: 'ほぼ毎日', value: 1 }
           ],
           type: 'FinalQuestionRadio',
           iConName: 'sakana.svg',
           skipOptions: [
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] },
             { choiceIds: 0, skipQuestionSortIds: [0] }
           ]
         }
       ]