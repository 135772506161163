import React from 'react'
import styled from '../../../../styledCore'

type TextProps = {
  ff?: 'YuGothic' | 'Cera Pro' | string
  fs?: number
  fw?: 'bold' | string
  lh?: string
  ls?: string
  style?: React.CSSProperties
  color?: 'white' | 'gray' | 'default' | string
}

const Text: React.FC<TextProps> = (props) => (
  <ScText {...props} style={props.style}>
    {props.children}
  </ScText>
)

const ScText = styled.p<TextProps>`
  padding: 0;
  margin: 0px;
  font-family: ${(props) => (props.ff ? props.ff : 'YuGothic')};
  font-style: normal;
  font-weight: ${(props) => (props.fw ? props.fw : 'normal')};
  font-size: ${(props) => (props.fs ? `${props.fs}px` : '16px')};
  line-height: ${(props) => (props.lh ? props.lh : '0px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : 'normal')};
  color: ${(props) =>
    props.color === 'white'
      ? '#fff'
      : props.color === 'gray'
      ? '#AFAFAF'
      : props.color === 'green'
      ? '#00D4A1'
      : '#041e42'};
` as React.FunctionComponent<TextProps>

export default Text
