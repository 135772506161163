import threeProblemAxisJson from './threeProblemAxis.json'
import scoreObjectJson from './scoreObjectJson.json'
import {
  ThreeProblemAxis,
  Answers,
  Answer,
  Other,
  Cause,
  Part,
  PartDetail,
  CauseAnswer,
  Scores,
  WorriesScore,
  SummaryList,
  Results,
  AllScore,
  Introduction,
  HelthScoreArray
} from '../types/Score'

type Ans = {
  id: number
  questionSortId: number
  choiceId: number
}

type Body = {
  body: {
    first: string
    second: string
  }
  mental: {
    first: string
    second: string
  }
  brain: {
    first: string
    second: string
  }
}

export const testProblemChecker = (answers: Answers, other: Other): Results => {
  const tpa: ThreeProblemAxis = threeProblemAxisJson
  const introduction: {
    problem: string
    introduction: string
  }[] = createIntroduction(tpa, answers, other)
  const problemName: string[] = introduction.map((value: any) => {
    return value.problem
  })
  const scores: Scores = scoreObjectJson
  const allScore: AllScore = analyzeAnswer(answers, scores, other)
  const userSupplementType: 'relax' | 'diet' | 'memory' | 'care' | 'focus' = supplementType(allScore, other)

  const reultObj: Results = {
    introduction: adjustIntroduction(introduction, userSupplementType, other),
    cause: createCause(tpa, answers, problemName),
    analyzeAnswer: allScore,
    radarChartScore: createRadarChartScore(allScore),
    summary: createSummary(answers, other, scores) || '',
    supplementType: userSupplementType
  }

  return reultObj
}

const adjustIntroduction = (
  introduction: Introduction,
  supplementType: Results['supplementType'],
  other: Other
): Introduction => {
  const changeIntroduction: Introduction = []

  const body: Introduction[0] = {
    problem: introduction[0].problem,
    introduction: introduction[0].introduction
  }
  const mental: Introduction[0] = {
    problem: introduction[1].problem,
    introduction: introduction[1].introduction
  }

  const brain: Introduction[0] = {
    problem: introduction[2].problem,
    introduction: introduction[2].introduction
  }

  switch (supplementType) {
    case 'relax':
      if (mental.problem === ('無気力感' || '倦怠感') && brain.problem === ('集中力の低下' || '注意散漫')) {
        const data_relax = threeProblemAxisJson
          .filter((e) => e.axisName === '脳機能的な課題')?.[0]
          .parts.filter((e) => e.partsName === '睡眠負債')?.[0]
        const temp_relax = {
          problem: data_relax.partsName,
          introduction: data_relax.feedBack
        }
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(temp_relax)
      } else {
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(brain)
      }
      break
    case 'diet':
      const data_diet = threeProblemAxisJson
        .filter((e) => e.axisName === '身体の課題')?.[0]
        .parts.filter((e) => e.partsName === '過労')[0]
      const temp_diet = {
        problem: data_diet.partsName,
        introduction: data_diet.feedBack.replace(/〇〇/g, other.name)
      }
      changeIntroduction.push(temp_diet)
      changeIntroduction.push(mental)
      changeIntroduction.push(brain)
      break
    case 'memory':
      if (body.problem !== ('滋養強壮力の衰え' || 'スタミナの衰え') && brain.problem !== ('記憶力の低下' || '暗記力')) {
        const data_memory = threeProblemAxisJson
          .filter((e) => e.axisName === '身体の課題')?.[0]
          .parts.filter((e) => e.partsName === '滋養強壮力の衰え')[0]
        const temp_memory = {
          problem: data_memory.partsName,
          introduction: data_memory.feedBack.replace(/〇〇/g, other.name)
        }
        changeIntroduction.push(temp_memory)
        changeIntroduction.push(mental)
        changeIntroduction.push(brain)
      } else {
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(brain)
      }
      break
    case 'care':
      if (
        body.problem !== ('過労' || '疲労の蓄積') &&
        mental.problem === ('無気力感' || '倦怠感') &&
        brain.problem !== ('睡眠負債' || '睡眠の質の低下')
      ) {
        const data_care = threeProblemAxisJson
          .filter((e) => e.axisName === '脳機能的な課題')?.[0]
          .parts.filter((e) => e.partsName === '睡眠負債')[0]
        const temp_care = {
          problem: data_care.partsName,
          introduction: data_care.feedBack
        }
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(temp_care)
      } else {
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(brain)
      }
      break
    case 'focus':
      if (brain.problem === ('睡眠負債' || '睡眠の質の低下')) {
        const data_focus = threeProblemAxisJson
          .filter((e) => e.axisName === '脳機能的な課題')?.[0]
          .parts.filter((e) => e.partsName === '集中力の低下')[0]
        const temp_focus = {
          problem: data_focus.partsName,
          introduction: data_focus.feedBack
        }
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(temp_focus)
      } else {
        changeIntroduction.push(body)
        changeIntroduction.push(mental)
        changeIntroduction.push(brain)
      }
      break
  }

  return changeIntroduction
}

const createIntroduction = (threeProblemAxis: ThreeProblemAxis, Answers: Answers, Other: Other) => {
  const result = [
    {
      problem: '',
      introduction: ''
    },
    {
      problem: '',
      introduction: ''
    },
    {
      problem: '',
      introduction: ''
    }
  ]

  threeProblemAxis.forEach((axis: PartDetail, i: number) => {
    const axisQuestionSortIds: number[][] = []
    const subCategorys: string[] = []
    axis.parts.forEach((value) => {
      const questionSortIds: number[] = value.relationQuestionSortIds
      const partsName: string = value.partsName
      subCategorys.push(partsName)
      axisQuestionSortIds.push(questionSortIds)
    })
    let maxAverage = 0
    let maxAveragePriority = 999
    let maxAverageProblem = ''
    let maxAverageFeedBack = ''
    axisQuestionSortIds.forEach((relationQuestionSortIds: number[], j: number) => {
      let sum: number = 0
      relationQuestionSortIds.forEach((relationQuestionSortId) => {
        //tod: Answers?.Answer?.find を Answers.Answer　に変更した　戻す
        const matchIdAnswer: Ans = Answers.Answer.filter(
          (element) => element.questionSortId === relationQuestionSortId
        )[0] || {
          id: 0,
          questionSortId: 0,
          choiceId: 999
        }
        const score = matchIdAnswer.choiceId
        sum += score
      })
      const liteExConditionsUnder = axis.parts[j].liteExConditionsUnder
      // 「全くそう思わない」の「choiceId」が1のため、5から引く処理を行なっている
      const average = 5 - sum / relationQuestionSortIds.length
      const priority = axis.parts[j].priority
      const problem = axis.parts[j].partsName
      const liteProblem = axis.parts[j].litePartsNmae
      const feedBack = axis.parts[j].feedBack
      const liteFeedBack = axis.parts[j].liteExpression
      if (average > maxAverage) {
        maxAverage = average
        maxAveragePriority = priority
        maxAverageProblem = average > liteExConditionsUnder ? problem : liteProblem
        maxAverageFeedBack = average > liteExConditionsUnder ? feedBack : liteFeedBack
      } else if (average === maxAverage && priority < maxAveragePriority) {
        maxAverage = average
        maxAveragePriority = priority
        maxAverageProblem = average > liteExConditionsUnder ? problem : liteProblem
        maxAverageFeedBack = average > liteExConditionsUnder ? feedBack : liteFeedBack
      }
    })
    result[i].problem = maxAverageProblem
    result[i].introduction = maxAverageFeedBack.replace(/〇〇/g, Other.name)
  })

  return result
}

const createCause = (threeProblemAxis: ThreeProblemAxis, Answers: Answers, problemName: string[]) => {
  const score = [
    {
      axisName: '身体の健康',
      questionIds: [0, 0],
      scores: [-1, -1],
      priorities: [999, 999],
      causes: ['', '']
    },
    {
      axisName: '精神の健康',
      questionIds: [0, 0],
      scores: [-1, -1],
      priorities: [999, 999],
      causes: ['', '']
    },
    {
      axisName: '脳の健康',
      questionIds: [0, 0],
      scores: [-1, -1],
      priorities: [999, 999],
      causes: ['', '']
    }
  ]

  const relationQuestionSortIds = []
  for (let index = 0; index < problemName.length; index++) {
    const category: PartDetail = threeProblemAxis[index]
    const subCategory: Part = category.parts.find(
      (value) => value.partsName === problemName[index] || value.litePartsNmae === problemName[index]
    ) || {
      partsName: '',
      litePartsNmae: '',
      feedBack: '',
      liteExpression: '',
      liteExConditionsUnder: 0,
      relationQuestionSortIds: [],
      priority: 0,
      causes: [
        {
          questionId: 0,
          questionSortId: 0,
          casePriority: 999,
          answers: [{ cause: '', score: -1, ansId: 0 }]
        }
      ]
    }
    const questionSortIds = subCategory.causes.map((value) => value.questionSortId)
    relationQuestionSortIds.push(questionSortIds)
  }

  let alreadyUseQuestionSortIds: number[] = []

  relationQuestionSortIds.forEach((value, index) => {
    value.forEach((questionSortId) => {
      const category = threeProblemAxis[index]
      const subCategory: Part = category.parts.find(
        (element) => element.partsName === problemName[index] || element.litePartsNmae === problemName[index]
      ) || {
        partsName: '',
        litePartsNmae: '',
        feedBack: '',
        liteExpression: '',
        liteExConditionsUnder: 0,
        relationQuestionSortIds: [],
        priority: 0,
        causes: [
          {
            questionId: 0,
            questionSortId: 0,
            casePriority: 999,
            answers: [
              {
                cause: '',
                score: -1,
                ansId: 0
              }
            ]
          }
        ]
      }
      const cause: Cause = subCategory.causes.find((element) => element.questionSortId === questionSortId) || {
        questionId: 0,
        questionSortId: 0,
        casePriority: 999,
        answers: [
          {
            cause: '',
            score: -1,
            ansId: 0
          }
        ]
      }
      const answer: Answer = Answers.Answer.find((element) => element.questionSortId === questionSortId) || {
        id: 0,
        questionSortId: 0,
        choiceId: 0
      }
      const causeAnswer: CauseAnswer = cause.answers.find(
        (element: CauseAnswer) => element.ansId === answer.choiceId
      ) || {
        cause: '',
        score: -1,
        ansId: 0
      }

      if (alreadyUseQuestionSortIds.includes(questionSortId)) {
        return
      } else if (causeAnswer.score > score[index].scores[0]) {
        score[index].scores.pop()
        score[index].scores.unshift(causeAnswer.score)

        score[index].priorities.pop()
        score[index].priorities.unshift(cause.casePriority)

        score[index].questionIds.pop()
        score[index].questionIds.unshift(questionSortId)

        score[index].causes.pop()
        score[index].causes.unshift(causeAnswer.cause)
      } else if (causeAnswer.score === score[index].scores[0] && cause.casePriority < score[index].priorities[0]) {
        score[index].scores.pop()
        score[index].scores.unshift(causeAnswer.score)

        score[index].priorities.pop()
        score[index].priorities.unshift(cause.casePriority)

        score[index].questionIds.pop()
        score[index].questionIds.unshift(questionSortId)

        score[index].causes.pop()
        score[index].causes.unshift(causeAnswer.cause)
      } else if (causeAnswer.score > score[index].scores[1]) {
        score[index].scores.pop()
        score[index].scores.push(causeAnswer.score)

        score[index].priorities.pop()
        score[index].priorities.push(cause.casePriority)

        score[index].questionIds.pop()
        score[index].questionIds.push(questionSortId)

        score[index].causes.pop()
        score[index].causes.push(causeAnswer.cause)
      } else if (causeAnswer.score === score[index].scores[1] && cause.casePriority < score[index].priorities[1]) {
        score[index].scores.pop()
        score[index].scores.push(causeAnswer.score)

        score[index].priorities.pop()
        score[index].priorities.push(cause.casePriority)

        score[index].questionIds.pop()
        score[index].questionIds.push(questionSortId)

        score[index].causes.pop()
        score[index].causes.push(causeAnswer.cause)
      }
    })
    alreadyUseQuestionSortIds.push(...score[index].questionIds)
  })

  const result: Body = {
    body: {
      first: score[0].causes[0],
      second: score[0].causes[1]
    },
    mental: {
      first: score[1].causes[0],
      second: score[1].causes[1]
    },
    brain: {
      first: score[2].causes[0],
      second: score[2].causes[1]
    }
  }

  return result
}
//120	146	105	171	86
const maxScore = {
  supplement: {
    relax: 120,
    diet: 146,
    memory: 105,
    care: 171,
    focus: 86
  },
  health: {
    nutrition: 50,
    lifestyle: 110,
    power: 115,
    stress: 137,
    brain: 127,
    sleep: 98
  }
}

const analyzeAnswer = (answers: Answers, scores: Scores, other: Other) => {
  const score = {
    supplement: {
      relax: 0,
      diet: 0,
      memory: 0,
      care: 0,
      focus: 0
    },
    health: {
      nutrition: 0,
      lifestyle: 0,
      power: 0,
      stress: 0,
      brain: 0,
      sleep: 0
    }
  }

  answers.Answer.forEach((value: Answer) => {
    const questionSortId = value.questionSortId
    const choiceId = value.choiceId
    const machQuestionSortId = scores.find((element: Scores[number]) => element.questionSortId === questionSortId) || {
      id: 0,
      questionString: '',
      questionSortId: 0,
      summaryCategory: '',
      score: [
        {
          choiceId: 0,
          supplement: {
            relax: 0,
            diet: 0,
            memory: 0,
            care: 0,
            focus: 0
          },
          health: {
            nutrition: 0,
            lifestyle: 0,
            power: 0,
            stress: 0,
            brain: 0,
            sleep: 0
          }
        }
      ]
    }

    const machChoiceId = machQuestionSortId.score.find((element: Scores[number]['score'][number], i: number) => {
      return element.choiceId === choiceId
    }) || {
      choiceId: 0,
      supplement: {
        relax: 0,
        diet: 0,
        memory: 0,
        care: 0,
        focus: 0
      },
      health: {
        nutrition: 0,
        lifestyle: 0,
        power: 0,
        stress: 0,
        brain: 0,
        sleep: 0
      }
    }
    score.supplement.relax += machChoiceId.supplement.relax
    score.supplement.diet += machChoiceId.supplement.diet
    score.supplement.memory += machChoiceId.supplement.memory
    score.supplement.care += machChoiceId.supplement.care
    score.supplement.focus += machChoiceId.supplement.focus
    score.health.nutrition += machChoiceId.health.nutrition
    score.health.lifestyle += machChoiceId.health.lifestyle
    score.health.power += machChoiceId.health.power
    score.health.stress += machChoiceId.health.stress
    score.health.brain += machChoiceId.health.brain
    score.health.sleep += machChoiceId.health.sleep
  })

  const worriesScore = calculateWorriesScore(other)
  worriesScore.forEach((value) => {
    score.supplement.relax += value.supplement.relax
    score.supplement.diet += value.supplement.diet
    score.supplement.memory += value.supplement.memory
    score.supplement.care += value.supplement.care
    score.supplement.focus += value.supplement.focus
  })

  score.supplement.relax = score.supplement.relax / maxScore.supplement.relax
  score.supplement.diet = score.supplement.diet / maxScore.supplement.diet
  score.supplement.memory = score.supplement.memory / maxScore.supplement.memory
  score.supplement.care = score.supplement.care / maxScore.supplement.care
  score.supplement.focus = score.supplement.focus / maxScore.supplement.focus

  score.health.nutrition = 1 - score.health.nutrition / maxScore.health.nutrition
  score.health.lifestyle = 1 - score.health.lifestyle / maxScore.health.lifestyle
  score.health.power = 1 - score.health.power / maxScore.health.power
  score.health.stress = 1 - score.health.stress / maxScore.health.stress
  score.health.brain = 1 - score.health.brain / maxScore.health.brain
  score.health.sleep = 1 - score.health.sleep / maxScore.health.sleep
  return score
}

const createRadarChartScore = (allScore: AllScore) => {
  const radarChartScore = {
    nutrition: 0,
    lifestyle: 0,
    power: 0,
    stress: 0,
    brain: 0,
    sleep: 0
  }

  const healthScore = allScore.health

  const healthScoreValues = Object.values(healthScore)
  const healthScoreKeys = Object.keys(healthScore) as (keyof AllScore['health'])[]

  const healthScoreArray = healthScoreValues.map((value: number, index: number) => {
    const element = { name: healthScoreKeys[index], score: value }
    return element
  })

  const sortHealthScoreArray = healthScoreArray
    .slice()
    .sort((a: HelthScoreArray[number], b: HelthScoreArray[number]) => {
      return b.score - a.score
    })

  const slantedScore = [0, 0, 0, 0, -0.08, -0.15]

  sortHealthScoreArray.forEach((element: HelthScoreArray[number], index: number) => {
    const key: keyof AllScore['health'] = element.name
    const score = element.score + slantedScore[index]
    if (score < 0) {
      radarChartScore[key] = 0
    } else {
      radarChartScore[key] = score
    }
  })

  return radarChartScore
}

const supplementType = (allScore: AllScore, other: Other): Results['supplementType'] => {
  const supplementPriority = {
    relax: 1,
    diet: 5,
    memory: 4,
    care: 3,
    focus: 2
  }

  const supplementScore = allScore.supplement

  const supplementScoreArray = Object.values(supplementScore)
  const supplementTypeArray = Object.keys(supplementScore) as (keyof AllScore['supplement'])[]
  const maxSupplementScore = Math.max(...supplementScoreArray)
  const maxScoreIndex = supplementScoreArray.indexOf(maxSupplementScore)
  let result: Results['supplementType'] = supplementTypeArray[maxScoreIndex]
  let minPriority = 999

  supplementTypeArray.forEach((value: Results['supplementType'], index: number) => {
    if (supplementScoreArray[index] === maxSupplementScore && supplementPriority[value] < minPriority) {
      minPriority = supplementPriority[value]
      result = value
    }
  })

  return result
}

const createSummary = (answers: Answers, other: Other, scores: Scores) => {
  const summary = {
    sleep: {
      questionSortId: 32,
      score: [0, 0, 0, 1, 6, 7, 8, 3],
      text: [
        '',
        '',
        '',
        '平均睡眠時間が5〜6時間である',
        '平均睡眠時間が6〜7時間である',
        '平均睡眠時間が7〜8時間である',
        '平均睡眠時間が8〜9時間である',
        '平均睡眠時間が9時間以上である'
      ]
    },
    active: {
      questionSortId: 34,
      score: [5, 2, 4, 0],
      text: ['週に4日以上運動をしている', '週に1~2日運動をしている', '週に2~3日運動をしている', '']
    },
    food: {
      questionSortIds: [39, 40, 41],
      score: [4, 3, 1, 0],
      text: '*比較的バランスの良い食生活である*'
    }
  }

  let summaryList: SummaryList = []

  const sleepAnswer = answers.Answer.find(
    (element: Answer) => element.questionSortId === summary.sleep.questionSortId
  ) || {
    id: 0,
    questionSortId: 0,
    choiceId: 0
  }
  const sleepScore = summary.sleep.score[sleepAnswer.choiceId - 1]
  if (sleepScore !== 0) {
    const sleepText = summary.sleep.text[sleepAnswer.choiceId - 1]
    summaryList.push({ score: sleepScore, text: sleepText })
  }

  const activeAnswer = answers.Answer.find(
    (element: Answer) => element.questionSortId === summary.active.questionSortId
  ) || {
    id: 0,
    questionSortId: 0,
    choiceId: 0
  }
  const activeScore = summary.active.score[activeAnswer.choiceId - 1]
  if (activeScore !== 0) {
    const activeText = summary.active.text[activeAnswer.choiceId - 1]
    summaryList.push({ score: activeScore, text: activeText })
  }

  let sortSummaryList = summaryList.sort((m: SummaryList[number], n: SummaryList[number]) => {
    return n.score - m.score
  })

  let foodScore = 0
  summary.food.questionSortIds.forEach((value: number) => {
    const sleepAnswer = answers.Answer.find((element: Answer) => element.questionSortId == value) || {
      id: 0,
      questionSortId: 0,
      choiceId: 0
    }
    foodScore += summary.food.score[sleepAnswer.choiceId - 1]
  })
  if (foodScore >= 7) {
    const foodText = summary.food.text
    sortSummaryList.push({ score: foodScore, text: foodText })
  }

  const allScore = analyzeAnswer(answers, scores, other)
  const sumHealthScore =
    allScore.health.nutrition +
    allScore.health.lifestyle +
    allScore.health.power +
    allScore.health.stress +
    allScore.health.brain +
    allScore.health.sleep

  const goodPoints = sortSummaryList.length
  switch (true) {
    case goodPoints === 3 && sumHealthScore >= 4.66:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣*と言えるでしょう。\n\n特に、' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'こと、' +
        sortSummaryList[2].text +
        'ところなどが評価できるポイントです。'
      )
    case goodPoints === 3 && sumHealthScore >= 3.5:
      return (
        other.name +
        'さんは、同年代の方と比較して、*標準的な生活習慣*と言えるでしょう。\n\n' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'こと、' +
        sortSummaryList[2].text +
        'ところなどが評価できるポイントです。'
      )
    case goodPoints === 3 && sumHealthScore >= 0:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣とは言えないでしょう*。\n\nしかし、' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'こと、' +
        sortSummaryList[2].text +
        'ところなどが評価できるポイントです。'
      )
    case goodPoints === 2 && sumHealthScore >= 4.66:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣*と言えるでしょう。\n\n特に、' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 2 && sumHealthScore >= 3.5:
      return (
        other.name +
        'さんは、同年代の方と比較して、*標準的な生活習慣*と言えるでしょう。\n\n' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 2 && sumHealthScore >= 0:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣とは言えないでしょう*。\n\nしかし、' +
        sortSummaryList[0].text +
        '点や、' +
        sortSummaryList[1].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 1 && sumHealthScore >= 4.66:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣*と言えるでしょう。\n\n特に、' +
        sortSummaryList[0].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 1 && sumHealthScore >= 3.5:
      return (
        other.name +
        'さんは、同年代の方と比較して、*標準的な生活習慣*と言えるでしょう。\n\n' +
        sortSummaryList[0].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 1 && sumHealthScore >= 0:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣とは言えないでしょう*。\n\nしかし、' +
        sortSummaryList[0].text +
        'ことなどが評価できるポイントです。'
      )
    case goodPoints === 0 && sumHealthScore >= 4.66:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣*と言えるでしょう。\n\n今の*良い生活習慣*をベースに、引き続き日々のコンディションをケアしていきましょう。続いて、*身体・精神・脳機能*ごとの改善余地がある点についてです。'
      )
    case goodPoints === 0 && sumHealthScore >= 3.5:
      return (
        other.name +
        'さんは、同年代の方と比較して、*標準的な生活習慣*と言えるでしょう。\n\n食事・睡眠・運動のどれかひとつだけでも意識することで、日々のコンディションケアに繋がることでしょう。続いて、*身体・精神・脳機能*ごとの改善余地がある点についてです。'
      )
    case goodPoints === 0 && sumHealthScore >= 0:
      return (
        other.name +
        'さんは、同年代の方と比較して、*良い生活習慣とは言えないでしょう*。\n\n食事・睡眠・運動のどれかひとつだけでも意識することで、日々のコンディションケアに繋がることでしょう。それでは、*身体・精神・脳機能*ごとの改善余地がある点についてです。'
      )
  }
}

const calculateWorriesScore = (other: Other) => {
  const worries = other.worries
  const mostWorry = other.mostWorrie

  const worriesScores = [
    {
      worries: '睡眠',
      supplement: {
        relax: 5,
        diet: 2,
        memory: 2,
        care: 5,
        focus: 5
      }
    },
    {
      worries: 'ストレス',
      supplement: {
        relax: 3,
        diet: 2,
        memory: 2,
        care: 3,
        focus: 3
      }
    },
    {
      worries: '活力',
      supplement: {
        relax: 0,
        diet: 3,
        memory: 5,
        care: 1,
        focus: 0
      }
    },
    {
      worries: '疲労',
      supplement: {
        relax: 3,
        diet: 5,
        memory: 2,
        care: 5,
        focus: 3
      }
    },
    {
      worries: '運動不足',
      supplement: {
        relax: 0,
        diet: 3,
        memory: 2,
        care: 2,
        focus: 0
      }
    },
    {
      worries: '記憶力',
      supplement: {
        relax: 2,
        diet: 2,
        memory: 5,
        care: 0,
        focus: 5
      }
    },
    {
      worries: '集中力',
      supplement: {
        relax: 5,
        diet: 2,
        memory: 2,
        care: 0,
        focus: 5
      }
    },
    {
      worries: '体調不良',
      supplement: {
        relax: 0,
        diet: 2,
        memory: 2,
        care: 2,
        focus: 0
      }
    },
    {
      worries: 'アイケア',
      supplement: {
        relax: 2,
        diet: 1,
        memory: 2,
        care: 0,
        focus: 2
      }
    },
    {
      worries: 'うつっぽさ',
      supplement: {
        relax: 2,
        diet: 3,
        memory: 1,
        care: 3,
        focus: 3
      }
    },
    {
      worries: 'ダイエット',
      supplement: {
        relax: 0,
        diet: 5,
        memory: 2,
        care: 0,
        focus: 0
      }
    },
    {
      worries: 'エイジングケア',
      supplement: {
        relax: 2,
        diet: 2,
        memory: 3,
        care: 0,
        focus: 1
      }
    }
  ]

  let result: WorriesScore = []
  worries.forEach((value) => {
    const score = worriesScores.find((element) => element.worries === value) || {
      worries: '',
      supplement: {
        relax: 0,
        diet: 0,
        memory: 0,
        care: 0,
        focus: 0
      }
    }

    result.push(score)
  })

  result.forEach((value: any) => {
    if (value.worries === mostWorry) {
      value.supplement.relax *= 3
      value.supplement.diet *= 3
      value.supplement.memory *= 3
      value.supplement.care *= 3
      value.supplement.focus *= 3
    }
  })

  return result
}

// const rejectSupplementType = (other: Other, supplementScore: AllScore['supplement']) => {
//   const allergies = other.allergies

//   switch (true) {
//     case allergies.includes('鶏肉'):
//       delete supplementScore.diet
//       delete supplementScore.care
//       break

//     case allergies.includes('大豆'):
//       delete supplementScore.memory
//       delete supplementScore.focus
//       break

//     case allergies.includes('乳'):
//       delete supplementScore.diet
//       delete supplementScore.care
//       break
//   }

//   return supplementScore
// }
