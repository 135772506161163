import { createMuiTheme } from '@material-ui/core'

// デフォルトのテーマの設定
// https://material-ui.com/customization/default-theme/
// 設定できる変数
// https://material-ui.com/customization/theming/#theme-configuration-variables
const theme = createMuiTheme({
  palette: {
    common: {
      black: '#041E42'
    },
    primary: {
      main: '#041E42'
    },
    secondary: {
      main: '#041E42'
    },
    // これで MuiPaper-rootのcolorがoverrideいけた。
    text: {
      primary: '#041E42'
    }
  },
  overrides: {
    MuiSvgIcon: {
      colorSecondary: {
        color: '#fff'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1099,
      lg: 1100,
      xl: 1920
    }
  }
})

export default theme
