import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import autoImgSizeStyle from '../../../common/styleFunctions'
import '../../../CeraPro/stylesheet.css'
import { MapsLocalParking } from 'material-ui/svg-icons'
import FBCard from '../result/FBCard'

const HeaderWrapper = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
`

const H1CeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 60px;
letter-spacing: 0.1em;
`

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.1em;
`

const H3YuGothic = Styled.h3`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;
letter-spacing: 0.1em;
color: #041E42;
text-decoration-line: underline;
text-decoration-color:rgba(0, 212, 161, 0.38);
text-decoration-thickness: 3px;
`

const H4YuGothic = Styled.h4`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
/* identical to box height */
color: #041E42;
letter-spacing: 0.1em;
`

const PYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 25px;
letter-spacing: 0.05em;
color: #041E42;
`

type Props = {
  fbs: {
    id: string
    genre: string
    title: string
    text: string
    causes: string[]
  }[]
}

const ResultCard = (props: Props) => {
  const { fbs } = props
  const isSp = window.innerWidth < 1100
  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box width="100%" display="flex" justifyContent="center" mt={10}>
          <Box width="1052px" display="flex" justifyContent="space-between">
            {fbs.map((c, index) => {
              return <FBCard id={c.id} genre={c.genre} title={c.title} text={c.text} causes={c.causes} />
            })}
          </Box>
        </Box>
      </Box>

      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box width="100%" display="flex" justifyContent="center" mt={5}>
          <Box>
            {fbs.map((c, index) => {
              return (
                <Box mt={5}>
                  <FBCard id={c.id} genre={c.genre} title={c.title} text={c.text} causes={c.causes} />
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ResultCard
