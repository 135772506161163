import React, { useState } from 'react'
import { Box, Drawer, Hidden, Grid, Card, CardActionArea, CardMedia, CardContent, Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MergeChill from '../components/container/supplementDetails/merge/MergeChill'
import MergeZone from '../components/container/supplementDetails/merge/MergeZone'
import MergeStorage from '../components/container/supplementDetails/merge/MergeStorage'
import MergeVitality from '../components/container/supplementDetails/merge/MergeVitality'
import MergeStyle from '../components/container/supplementDetails/merge/MergeStyle'
import MergeMineral from '../components/container/supplementDetails/merge/MergeMineral'
import MergeOmega3 from '../components/container/supplementDetails/merge/MergeOmega3'
import MergeVitamin from '../components/container/supplementDetails/merge/MergeVitamin'
import MergeRecovery from '../components/container/supplementDetails/merge/MergeRecovery'
import Styled from 'styled-components'

interface H1PcCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1PcCeraPro = Styled.h1<H1PcCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface H2PcCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2PcCeraPro = Styled.h2<H2PcCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '32px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface H3PcCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H3PcCeraPro = Styled.h3<H3PcCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface PPcYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PPcYuGothic = Styled.p<PPcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '14px')};
  line-height: ${(props) => (props.lh ? props.lh : '24px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface H1SpCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1SpCeraPro = Styled.h1<H1SpCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface H2SpCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2SpCeraPro = Styled.h2<H2SpCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '32px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface H3SpCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H3SpCeraPro = Styled.h3<H3SpCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface PSpYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PSpYuGothic = Styled.p<PSpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '14px')};
  line-height: ${(props) => (props.lh ? props.lh : '24px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

const SupplementList: React.FC = () => {
  return (
    <>
      <Header />
      <Hidden mdDown>
        <Box mb={13} pt={20} textAlign="center">
          <H1PcCeraPro>Supplements</H1PcCeraPro>
        </Box>
        <SupplementSection />
        <ConversionSection />
      </Hidden>

      <Hidden lgUp>
        <Box pt={20} mb={13} textAlign="center">
          <H1PcCeraPro>Supplements</H1PcCeraPro>
        </Box>
        <SupplementSection />
        <ConversionSection />
      </Hidden>
      <Footer />
    </>
  )
}

const selectSupplement = (
  supplementName: 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Chill' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
) => {
  switch (supplementName) {
    case 'Style':
      return <MergeStyle />
    case 'Vitality':
      return <MergeVitality />
    case 'Memory':
      return <MergeStorage />
    case 'Zone':
      return <MergeZone />
    case 'Chill':
      return <MergeChill />
    case 'Mineral':
      return <MergeMineral />
    case 'Omega3':
      return <MergeOmega3 />
    case 'Vitamin':
      return <MergeVitamin />
    case 'Recovery':
      return <MergeRecovery />
  }
}

type Supplements = {
  name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  contents: string
}

const SupplementCard = (props: { supplement: Supplements }) => {
  const [isDrowOpen, setDrowOpen] = useState(false)
  const [openSupplementName, setOpenSupplementName] = useState<
    'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  >('Chill')
  const drowOpen = (
    name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  ) => {
    setOpenSupplementName(name)
    setDrowOpen(true)
  }

  return (
    <>
      <Hidden mdDown>
        <Drawer anchor="left" open={isDrowOpen} onClose={() => setDrowOpen(false)}>
          <Box width="960px">
            <Box
              width="960px"
              height="64px"
              position="fixed"
              zIndex={999}
              bgcolor="#fff"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={'logo.svg'} width="96px" />

              <IconButton onClick={() => setDrowOpen(false)} style={{ position: 'absolute', right: '16px' }}>
                <img src={'close.svg'} width={'18px'} />
              </IconButton>
            </Box>

            {selectSupplement(openSupplementName)}
          </Box>
        </Drawer>
      </Hidden>

      <Hidden lgUp>
        <Drawer anchor="bottom" open={isDrowOpen} onClose={() => setDrowOpen(false)}>
          <Box width="100%" height="90vh">
            <Box
              position="fixed"
              zIndex={999}
              width="100%"
              height="46px"
              bgcolor="#fff"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={'logo.svg'} width="67px" />

              <IconButton onClick={() => setDrowOpen(false)} style={{ position: 'absolute', right: '16px' }}>
                <img src={'close.svg'} width={'18px'} />
              </IconButton>
            </Box>

            {selectSupplement(openSupplementName)}
          </Box>
        </Drawer>
      </Hidden>

      <Card style={{ width: '280px' }}>
        <CardActionArea onClick={() => drowOpen(props.supplement.name)}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={`common/PC_${props.supplement.name}.png`}
            title="Contemplative Reptile"
          />
          <CardContent style={{ height: '140px' }}>
            <H2PcCeraPro mb="16px">{props.supplement.name}</H2PcCeraPro>
            <PPcYuGothic>{props.supplement.contents.substr(0, 45)}...</PPcYuGothic>
          </CardContent>
        </CardActionArea>
        <Box p={1} display="flex" justifyContent="flex-end">
          <Button size="small" onClick={() => drowOpen(props.supplement.name)}>
            <PPcYuGothic>詳しく見る</PPcYuGothic>
            <img src={'arrow_new.png'} style={{ width: '25px', height: '8px', paddingLeft: '1vw' }} />
          </Button>
        </Box>
      </Card>
    </>
  )
}

const SupplementSection = () => {
  const supplements1: Supplements[] = [
    {
      name: 'Vitamin',
      contents:
        '体内ではいくつものビタミン・ミネラルがお互いに作用しています。栄養素の偏りが問題視される現代だからこそ不足しがちなビタミンを贅沢に配合しました。'
    },
    {
      name: 'Mineral',
      contents:
        'ビタミンとともに、健康のベースメントになるミネラル。日々忙しく、バランスのよい食事を取れていない方のために、複数種類のミネラルを贅沢に配合しました。'
    },
    {
      name: 'Omega3',
      contents:
        '男女ともに広く支持されるフィッシュオイル。最近では、アスリートの間でも愛飲されています。日々、頭と体を使うビジネスリーダーのために。'
    }
  ]

  const supplements2: Supplements[] = [
    {
      name: 'Recovery',
      contents:
        '頑張れば頑張るほど、消耗していくあなたの体。忙しい日々のなかで、体のケアをするのは難しいことです。Recoveryは、抗疲労や疲労回復に関する成分を贅沢に配合しました。'
    },
    {
      name: 'Chill',
      contents:
        '忙しい日々の中で、十分に休む時間を確保するのは難しいこと。Chillには、リラックスやストレス、睡眠に関わる４種類の成分を贅沢に配合しています。'
    },
    {
      name: 'Zone',
      contents:
        '集中を持続させることが難しいと言われている現代。Zoneは、カフェインをはじめ、集中に関わる成分を贅沢に配合しています。日々高いパフォーマンスを求めるビジネスアスリートのために'
    }
  ]

  const supplements3: Supplements[] = [
    {
      name: 'Memory',
      contents:
        '現代人が1日に触れる情報量は、平安時代の一生分、江戸時代の1年分とも言われています。Memoryは記憶に関わる成分を贅沢に配合しています。'
    },
    {
      name: 'Vitality',
      contents:
        'どんなに強い人でも、老いには敵わない。ひとりでも多くの人が、何歳になっても自信に満ちた自分でいて欲しい。Vitalityには、滋養強壮に関わる成分を贅沢に配合しました。'
    },
    {
      name: 'Style',
      contents:
        '無理なダイエットは、辛く苦しいもの。ひとりで追い込まず、たまには誰かの力を借りてもいいのではないでしょうか。Styleは、ダイエット系の成分を贅沢に配合しています。'
    }
  ]

  const supplements: Supplements[] = [
    {
      name: 'Vitamin',
      contents:
        '体内ではいくつものビタミン・ミネラルがお互いに作用しています。栄養素の偏りが問題視される現代だからこそ不足しがちなビタミンを贅沢に配合しました。'
    },
    {
      name: 'Mineral',
      contents:
        'ビタミンとともに、健康のベースメントになるミネラル。日々忙しく、バランスのよい食事を取れていない方のために、複数種類のミネラルを贅沢に配合しました。'
    },
    {
      name: 'Omega3',
      contents:
        '男女ともに広く支持されるフィッシュオイル。最近では、アスリートの間でも愛飲されています。日々、頭と体を使うビジネスリーダーのために。'
    },
    {
      name: 'Recovery',
      contents:
        '頑張れば頑張るほど、消耗していくあなたの体。忙しい日々のなかで、体のケアをするのは難しいことです。Recoveryは、抗疲労や疲労回復に関する成分を贅沢に配合しました。'
    },
    {
      name: 'Chill',
      contents:
        '忙しい日々の中で、十分に休む時間を確保するのは難しいこと。Chillには、リラックスやストレス、睡眠に関わる４種類の成分を贅沢に配合しています。'
    },
    {
      name: 'Zone',
      contents:
        '集中を持続させることが難しいと言われている現代。Zoneは、カフェインをはじめ、集中に関わる成分を贅沢に配合しています。日々高いパフォーマンスを求めるビジネスアスリートのために'
    },
    {
      name: 'Memory',
      contents:
        '現代人が1日に触れる情報量は、平安時代の一生分、江戸時代の1年分とも言われています。Memoryは記憶に関わる成分を贅沢に配合しています。'
    },
    {
      name: 'Vitality',
      contents:
        'どんなに強い人でも、老いには敵わない。ひとりでも多くの人が、何歳になっても自信に満ちた自分でいて欲しい。Vitalityには、滋養強壮に関わる成分を贅沢に配合しました。'
    },
    {
      name: 'Style',
      contents:
        '無理なダイエットは、辛く苦しいもの。ひとりで追い込まず、たまには誰かの力を借りてもいいのではないでしょうか。Styleは、ダイエット系の成分を贅沢に配合しています。'
    }
  ]

  return (
    <>
      <Hidden mdDown>
        <Box
          width="100vw"
          height="1230px"
          style={{
            background: `url(./supplements_bg.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width="880px" mx="auto">
            <Grid container justify="space-between">
              {supplements1.map((value: Supplements, index: number) => {
                const mt = index * 24
                return (
                  <Grid item style={{ marginTop: `${mt}px` }}>
                    <SupplementCard supplement={value} />
                  </Grid>
                )
              })}
            </Grid>

            <Grid container justify="space-between">
              {supplements2.map((value: Supplements, index: number) => {
                const mt = index * 24
                return (
                  <Grid item style={{ marginTop: `${mt}px` }}>
                    <SupplementCard supplement={value} />
                  </Grid>
                )
              })}
            </Grid>

            <Grid container justify="space-between">
              {supplements3.map((value: Supplements, index: number) => {
                const mt = index * 24
                return (
                  <Grid item style={{ marginTop: `${mt}px` }}>
                    <SupplementCard supplement={value} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box
          width="100vw"
          height="2100px"
          style={{
            background: `url(./supplements_bg.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width="700px" mx="auto">
            <Grid container justify="center" spacing={4}>
              {supplements.map((value: Supplements) => {
                return (
                  <Grid item>
                    <SupplementCard supplement={value} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          width="100vw"
          height="3600px"
          style={{
            background: `url(./supplements_bg.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Grid container justify="center">
            {supplements.map((value: Supplements) => {
              return (
                <Grid item style={{ marginBottom: '32px' }}>
                  <SupplementCard supplement={value} />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Hidden>
    </>
  )
}

const ConversionSection = () => {
  return (
    <>
      <Hidden mdDown>
        <Box
          position="relative"
          component="section"
          width="100vw"
          height="110vh"
          style={{
            background: `url(./container/result/productImg.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width="380px" ml="auto" position="absolute" top={'35%'} left={'65%'}>
            <H2PcCeraPro fs="32px" lh="40px">
              PERSONALIZED BOX
            </H2PcCeraPro>
            <PPcYuGothic fs="12px" lh="24px" mb="16px">
              1ヶ月毎の定期コース
            </PPcYuGothic>

            <PPcYuGothic lh="25px" mb="16px">
              1日分の専用サプリメントが、小分けでまとまっているから手間いらず。1ヶ月分（30個）をまとめて毎月お届けします。
            </PPcYuGothic>

            <Button
              href="ready"
              style={{
                width: '251px',
                height: '45px',
                backgroundColor: '#00D4A1'
              }}
            >
              <PPcYuGothic>まずは無料診断する</PPcYuGothic>
              <img src={'arrow_new.png'} style={{ width: '25px', height: '8px', paddingLeft: '1vw' }} />
            </Button>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box bgcolor="#F3F3F3">
          <Box width="84vw" mx="auto">
            <Box mb={4} textAlign="center">
              <H2SpCeraPro>PERSONALIZED BOX</H2SpCeraPro>
              <PSpYuGothic fs="12px">1ヶ月毎の定期コース</PSpYuGothic>
            </Box>

            <PSpYuGothic mb="48px">
              1日分の専用サプリメントが、小分けでまとまっているから手間いらず。1ヶ月分（30個）をまとめて毎月お届けします。
            </PSpYuGothic>

            <Button
              href="ready"
              size="large"
              fullWidth
              style={{
                position: 'relative',
                padding: '16px 0',
                backgroundColor: '#00D4A1'
              }}
            >
              <PSpYuGothic>まずは無料診断する</PSpYuGothic>
              <img
                src={'arrow_new.png'}
                style={{ position: 'absolute', right: '16px', width: '24px', height: '8px' }}
              />
            </Button>
          </Box>
          <img src={'top_5_sp.png'} style={{ width: '100%' }} />
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box bgcolor="#F3F3F3">
          <Box width="84vw" mx="auto">
            <Box mb={4} textAlign="center">
              <H2SpCeraPro>PERSONALIZED BOX</H2SpCeraPro>
              <PSpYuGothic fs="12px">1ヶ月毎の定期コース</PSpYuGothic>
            </Box>

            <PSpYuGothic mb="48px">
              1日分の専用サプリメントが、小分けでまとまっているから手間いらず。1ヶ月分（30個）をまとめて毎月お届けします。
            </PSpYuGothic>

            <Button
              href="ready"
              size="large"
              fullWidth
              style={{
                padding: '14px 0',
                backgroundColor: '#00D4A1'
              }}
            >
              <PSpYuGothic>まずは無料診断する</PSpYuGothic>
              <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
            </Button>
          </Box>
          <img src={'top_5_sp.png'} style={{ width: '100%' }} />
        </Box>
      </Hidden>
    </>
  )
}

export default SupplementList
