import React from 'react'
import Box from '@material-ui/core/Box'
import RadioGroup from '@material-ui/core/RadioGroup'
import Hidden from '@material-ui/core/Hidden'
import Radio from '@material-ui/core/Radio'

import RadioButtonIcon from '../common/RadioButtonIcon'

import ThreeOption from './radio/ThreeOption'
import FourOption from './radio/FourOption'
import FiveOption from './radio/FiveOption'

import { nl2br } from '../../../../../function'

type RadioButtonRootProps = {
  classes: any
  questionSortId: string
  onSubmit: (event: any) => void
  selectValue: number
  options: any[]
}
const RadioButtonRoot: React.FC<RadioButtonRootProps> = ({
  classes,
  questionSortId,
  onSubmit,
  selectValue,
  options
}) => {
  const SwitchOption = (optionLen: number) => {
    switch (optionLen) {
      case 3:
        return <ThreeOption options={options} />
      case 4:
        return <FourOption options={options} />
      case 5:
        return <FiveOption options={options} />
      default:
        return <DefaultOption options={options} />
    }
  }
  return (
    <RadioGroup name={questionSortId} onChange={onSubmit} value={selectValue ? selectValue : 0}>
      <Box display="flex" justifyContent="center" alignContent="center" textAlign="center">
        {SwitchOption(options.length)}
      </Box>
    </RadioGroup>
  )
}

const DefaultOption: React.FC<any> = ({ options }) => (
  <>
    {options.map((x: any) => (
      <>
        <Hidden mdDown>
          <Box display="flex" justifyContent="center">
            <Box>
              <Radio
                key={x}
                value={x?.value}
                icon={<RadioButtonIcon size="pc" color="defalut" />}
                checkedIcon={<RadioButtonIcon size="pc" color="defalut" checked={true} />}
              />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                {nl2br(x?.label)}
              </p>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center">
            <Box>
              <Radio
                key={x}
                value={x?.value}
                icon={<RadioButtonIcon size="sp" color="defalut" />}
                checkedIcon={<RadioButtonIcon size="sp" color="defalut" checked={true} />}
              />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                {nl2br(x?.label)}
              </p>
            </Box>
          </Box>
        </Hidden>
      </>
    ))}
  </>
)
export default RadioButtonRoot
