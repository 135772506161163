import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = 'supplementDetail/Mineral/SP_TOP_BG.png'
const pcTopBgURL: string = '../supplements/Mineral/PC_PRODUCT_BG.png'
const SpTopBgURL: string = '../supplements/Mineral/SP_PRODUCT_BG.png'
const title = 'MINERAL'
const sub_title = 'Change before you have to.'
const text =
  'ビタミンとともに、健康のベースメントになるミネラル。日々忙しく、バランスのよい食事を取れていない方のために、複数種類のミネラルを贅沢に配合しました。あなたの健康の土台に、Mineral'
const containtext =
  'Mineralは、亜鉛やマンガン、銅などの必須ミネラルをベースに、乳酸菌のEC-12、ダイジェザイム、MCTパウダー、朝鮮人参エキス等の成分を配合した次世代型マルチミネラルです。日々の健康にお役立てください。'

const product_name = 'MINERAL'
const product_amount =
  '亜鉛：12mg、マンガン：0.5mg、銅：0.2mg、ヨウ素：25μg、セレン：7.5μg、クロム：5.0μg、モリブデン：4.1μg、総サポニン：10mg、中鎖脂肪酸：3.2mg、乳酸菌：250億個'
const product_materials =
  '酵母（亜鉛含有）、酵母（マンガン含有）、酵母（銅含有）、酵母（ヨウ素含有）、酵母（セレン含有）、酵母（クロム含有）、酵母（モリブデン含有）、高麗人参根 サポニン粉末、MCTパウダー、穀物発酵エキス末（ダイジェザイム）、乳酸菌EC-12AF（殺菌末）、植物油脂（ビタミンE含有）、デキストリン、加工デンプン、セルロース、ステアリン酸Ca、微粒二酸化ケイ素HPMC、カラメル色素、水'
const product_allergy = '小麦'
const product_agent_type = '../hard.png'
const productBgSp = '../supplements/Mineral/SP_SPM.png'
const productBgPc = '../supplements/Mineral/PC_SPM.png'

const evidences: EvidenceProps = [
  {
    title: '亜鉛 ',
    sub_title: '-  必須ミネラル',
    text:
      '亜鉛は、必須ミネラルの一種です。味覚を正常に保つのに働くほか、皮膚や粘膜の健康維持を助ける栄養素として知られています。論文によると「30人の健康な女性を対象とした臨床試験において、グループを①マルチビタミン②マルチビタミン＋7mgの亜鉛の２群に分けて10週間所定のサプリメントを投与した。検査にはProfile of Mood Statesを用いた。その結果、マルチビタミンに加えて亜鉛を摂取していたグループは、マルチビタミンだけを摂取していたグループと比較して、６項目のうち２項目（怒り - 敵意スコア及び抑うつ - 落ち込みスコア）が有意に改善した。」と示されています。',
    thesis: 'Effect of zinc supplementation on mood states in young women: a pilot study',
    magazine: 'European Journal of Clinical Nutrition',
    Impact_Factor: '3.057',
    Impact_Factor_Year: '2016',
    publish: '2010',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/20087376',
    total_quote: '83',
    exists: 'あり'
  },
  {
    title: '朝鮮人参エキス',
    sub_title: '-  情報処理能力をサポート',
    text:
      '朝鮮人参は、中国や韓国などの極東が原産で、現代でも高いものなら実際に1本数十万円という高値で取引される貴重品です。ストレスで弱ったからだのベースを上げる働きなど、滋養強壮作用が期待される成分です。論文によると「26人の健康な男女を対象とした臨床試験において、実験群に朝鮮人参エキス200mgまたは400mg、対照群にプラセボを投与した上で、10分間の計算及び情報処理テストを行った結果、朝鮮人参エキスを摂取したグループはプラセボ群と比較して認知テストのスコアが有意に改善した。」と示されています。',
    thesis:
      "Effects of Panax Ginseng, Consumed With and Without Glucose, on Blood Glucose Levels and Cognitive Performance During Sustained 'Mentally Demanding' Tasks",
    magazine: 'Journal of Psychopharmacology',
    Impact_Factor: '4.738',
    Impact_Factor_Year: '2018',
    publish: '2006',
    link: 'https://pubmed.ncbi.nlm.nih.gov/16401645',
    total_quote: '124',
    exists: 'あり'
  },
  {
    title: 'ダイジェザイム',
    sub_title: '-  消化吸収をサポート',
    text:
      'ダイジェザイム®は、小麦ふすまを原料とした穀物発酵エキスです。アミラーゼを始めとする５種類の酵素が含まれており、消化吸収をサポートすることが示されています。論文によると「40人の機能性ディスペプシア（腹部症状が慢性的に続いている状態）を対象とした臨床試験において、対象者を①ダイジェザイム50mg②プラセボの２群に分けた２ヶ月間の調査を実施した。その結果、複数の評価スケールにおいて、15日、30日、そして60日次点での機能性ディスペプシアの症状が大きく改善された。」と示されています。',
    thesis:
      'Evaluation of the Safety and Efficacy of a Multienzyme Complex in Patients with Functional Dyspepsia: A Randomized, Double-Blind, Placebo-Controlled Study',
    magazine: 'Journal of Medicinal Food',
    Impact_Factor: '1.955',
    Impact_Factor_Year: '2016',
    publish: '2018',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6249666/',
    total_quote: '5',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeVitamin = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`,
                fontFamily: 'YuGothic'
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="supplementDetail/Mineral/PC_TOP_BG.png" width="320px" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`,
              fontFamily: 'YuGothic'
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeVitamin
