import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioButtonIcon from '../../common/RadioButtonIcon'
import { nl2br } from '../../../../../../function/index'
import Text from '../../../util/Text'

type Props = {
  options: {
    label: string
    value: number
    spLabel: string
  }[]
}

const FiveOption = (props: Props) => {
  const { options } = props

  const firstColor: 'defalut' | 'highlight' | 'gray' = options.length <= 3 ? 'defalut' : 'highlight'

  const secondColor: 'defalut' | 'highlight' | 'gray' = options.length <= 3 ? 'defalut' : 'highlight'

  const therdColor: 'defalut' | 'highlight' | 'gray' =
    options.length === 3 ? 'defalut' : options.length === 4 ? 'defalut' : 'gray'

  const foursColor: 'defalut' | 'highlight' | 'gray' = 'defalut'

  const fifthColor: 'defalut' | 'highlight' | 'gray' = 'defalut'

  const FiveRadioButtonIcon = [
    <RadioButtonIcon size="pc" color={firstColor} />,
    <RadioButtonIcon size="pc" color={secondColor} />,
    <RadioButtonIcon size="pc" color={therdColor} />,
    <RadioButtonIcon size="pc" color={foursColor} />,
    <RadioButtonIcon size="pc" color={fifthColor} />
  ]
  const FiveCheckedRadioButtonIcon = [
    <RadioButtonIcon size="pc" color={firstColor} checked={true} />,
    <RadioButtonIcon size="pc" color={secondColor} checked={true} />,
    <RadioButtonIcon size="pc" color={therdColor} checked={true} />,
    <RadioButtonIcon size="pc" color={foursColor} checked={true} />,
    <RadioButtonIcon size="pc" color={fifthColor} checked={true} />
  ]
  const FiveRadioButtonIconSP = [
    <RadioButtonIcon size="sp" color={firstColor} />,
    <RadioButtonIcon size="sp" color={secondColor} />,
    <RadioButtonIcon size="sp" color={therdColor} />,
    <RadioButtonIcon size="sp" color={foursColor} />,
    <RadioButtonIcon size="sp" color="defalut" />
  ]

  const FiveCheckedRadioButtonIconSP = [
    <RadioButtonIcon size="sp" color={firstColor} checked={true} />,
    <RadioButtonIcon size="sp" color={secondColor} checked={true} />,
    <RadioButtonIcon size="sp" color={therdColor} checked={true} />,
    <RadioButtonIcon size="sp" color={foursColor} checked={true} />,
    <RadioButtonIcon size="sp" color={fifthColor} checked={true} />
  ]

  return (
    <>
      <Box width="100%" display="flex" justifyContent="space-around">
        {options.map((x, i: number) => (
          <>
            <Hidden mdDown>
              <Box display="flex" justifyContent="center">
                <Box>
                  <Radio
                    key={x.label}
                    value={x?.value}
                    icon={FiveRadioButtonIcon[i]}
                    checkedIcon={FiveCheckedRadioButtonIcon[i]}
                  />
                  <Box width="62px" textAlign="center" display="flex" justifyContent="center" mt={3}>
                    <Text fw={'bold'} fs={14} lh={'140%'} ls={'0.1em'} style={{ whiteSpace: 'nowrap' }}>
                      {x?.label}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Hidden>
            <Hidden lgUp>
              <Box display="flex" justifyContent="center">
                <Box textAlign="center">
                  <Radio
                    key={x.label}
                    value={x?.value}
                    icon={FiveRadioButtonIconSP[i]}
                    checkedIcon={FiveCheckedRadioButtonIconSP[i]}
                    size={'small'}
                  />
                  <Box
                    width="50px"
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    mt={2}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <Text fw={'bold'} fs={12} lh={'140%'} ls={'0.1em'}>
                      {nl2br(x?.spLabel)}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Hidden>
          </>
        ))}
      </Box>
    </>
  )
}
export default FiveOption
