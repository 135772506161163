import React from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { useTransition } from 'react-spring'
import { withRouter } from 'react-router'

import { questions } from '../common/question'
import { lastQuestionId, worriesId, figuaId, sexualQuestionId1, sexualQuestionId2 } from '../common/keyQuestion'
import { testProblemChecker } from '../common/problemCheckers'
import { db } from '../firebase/index'
import { now } from '../firebase/common'
import { sleep, flatten, useInput } from '../function'
import { Answers, Results, Other } from '../types/Score'

import { diasableScroll } from '../components/container/diagnosis/question/common/scroll'

import HeadBar from '../components/container/diagnosis/HeadBar'
import QuestionCard from '../components/container/diagnosis/QuestionCard'
import BottomBar from '../components/container/diagnosis/BottomBar'
import NextButton from '../components/container/diagnosis/question/common/NextButton'
import '../style.css'
import Loading from '../components/container/diagnosis/loading'

const Diagnosis = (props: { history: any }) => {
  diasableScroll()
  const { history } = props
  const [index, set] = React.useState(0)
  const [reverse, setReverse] = React.useState(false)
  const [onBottomButtonCounter, setOnBottomButtonCounter] = React.useState(0)
  const questionLen = questions.length

  const onClick = async () => {
    await sleep(80)
    setReverse(false)
    set((state) => {
      return state >= questionLen - 1 ? state : (state + 1) % questionLen
    })
    setOnBottomButtonCounter(onBottomButtonCounter < 0 ? onBottomButtonCounter + 1 : onBottomButtonCounter)
  }
  const onClickBack = React.useCallback(() => {
    set((state) => (state === 0 ? 0 : (state - 1) % questionLen))
    setReverse(true)
  }, [])
  const buttomBarClick = (revers: boolean) => {
    revers ? onClickBack() : onClick()
    setOnBottomButtonCounter(onBottomButtonCounter + (revers ? -1 : 1))
  }
  const transitions = useTransition(index, (p) => p, {
    unique: false,
    initial: {
      transform: reverse ? 'translate3d(-50%,0%,0)' : 'translate3d(50%,0%,0)'
    },
    from: {
      opacity: 0,
      transform: reverse ? 'translate3d(-30%,0%,0)' : 'translate3d(30%,0%,0)'
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0%,0)'
    },
    update: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)'
    },
    leave: {
      opacity: 0,
      transform: reverse ? 'translate3d(30%,0,0)' : 'translate3d(-30%,0,0)'
    }
  })
  const [ans, setAnswer] = React.useState([] as any)

  const onSubmit = (event: any) => {
    const value = parseInt(event.target.value)
    const questionSortId = parseInt(event.target.name)
    setAnswer((prev: any) => prev.filter((x: any) => x.questionSortId !== questionSortId))
    const skipIds = questions?.[questionSortId - 1]?.skipOptions
      ?.filter((x: any) => x.choiceIds === value)
      ?.map((y: any) => y.skipQuestionSortIds)
      ?.flat()
    setAnswer((prev: any) => [
      ...prev,
      {
        choiceId: value,
        questionSortId: questionSortId,
        skipQuestionSortIds: skipIds
      }
    ])
    onClick()
  }
  const mostWorryied = useInput('')
  const onMostWorriedChange = (e: any) => {
    mostWorryied.onChange(e.target.value)
    onClick()
  }
  const [worried, setWorried] = React.useState<{
    睡眠: boolean
    ストレス: boolean
    活力: boolean
    疲労: boolean
    運動不足: boolean
    記憶力: boolean
    集中力: boolean
    体調不良: boolean
    目の疲れ: boolean
    うつっぽさ: boolean
    ダイエット: boolean
    身体の衰え: boolean
  }>({
    睡眠: false,
    ストレス: false,
    活力: false,
    疲労: false,
    運動不足: false,
    記憶力: false,
    集中力: false,
    体調不良: false,
    目の疲れ: false,
    うつっぽさ: false,
    ダイエット: false,
    身体の衰え: false
  })
  const choices = Object.keys(
    [...Object.entries(worried)].reduce((a: any, [b, c]: any) => (c ? (a[b] = c) : null, a), {})
  ) as (
    | '睡眠'
    | 'ストレス'
    | '活力'
    | '疲労'
    | '運動不足'
    | '記憶力'
    | '集中力'
    | '体調不良'
    | '目の疲労'
    | 'うつっぽさ'
    | 'ダイエット'
    | '身体の衰え'
  )[]

  const ansSkip: number[] = flatten(ans.map((x: any) => x.skipQuestionSortIds))
  const skip = mostWorryied.value === '活力' ? ansSkip : ansSkip.concat(sexualQuestionId1, sexualQuestionId2)

  const skipQuestion = questions.filter((x: any) => !skip.some((y: any) => x.questionSortId === y))
  const sectionNumber: number =
    questions.filter((x: any) => !skip.some((y: any) => x.questionSortId === y)).filter((_, self) => self === index)
      .length > 0
      ? questions
          .filter((x: any) => !skip.some((y: any) => x.questionSortId === y))
          .filter((_, self) => self === index)
          .map((x: any) => x.sectionNumber).length > 0
        ? questions
            .filter((x: any) => !skip.some((y: any) => x.questionSortId === y))
            .filter((_, self) => self === index)
            .map((x: any) => x.sectionNumber)[0]
        : 3
      : 3
  const [name, setName] = React.useState('')
  const filterAns = ans
    .filter((x: { questionSortId: number }) => {
      const skipAns = !skip.some((y: any) => x.questionSortId === y)
      return skipAns
    })
    .filter((x: { choiceId: number }) => x.choiceId !== 0 && !isNaN(x.choiceId))
  const targetQuestion = skipQuestion[index]
  const [isChoice, setIsChoice] = React.useState(false)
  const [allergies, setAllergies] = React.useState({
    none: false,
    milk: false,
    gelatin: false,
    chicken: false,
    phosphatidylserine: false
  })

  const selectAllergies = Object.keys(
    [...Object.entries(allergies)].reduce((a: any, [b, c]: any) => (c ? (a[b] = c) : null, a), {})
  )
  const others: Other = {
    name: name,
    worries: choices,
    mostWorrie: 'うつっぽさ',
    allergies: selectAllergies,
    weight: 0,
    height: 0
  }
  const moveResult = () => {
    const ansOBJ: Answers = {
      createDate: new Date(),
      Answer: filterAns
    }
    const result: Results = testProblemChecker(ansOBJ, others)

    db.collection('VER3D20200424')
      .add({
        ans: filterAns,
        others: others,
        result: result,
        timeStamp: now
      })
      .then(function (docRef: any) {})
      .catch(function (error: any) {})
    setLoading(true)
  }
  const windowSize = window.innerWidth
  const [isZenkaku, setZenkaku] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [isEnter, setEnter] = React.useState(false)
  const onNameChange = (event: any) => {
    setZenkaku(event.target.value.match(/^[^\x01-\x7E\xA1-\xDF]+$/) ? true : false)
    setName(event.target.value)
  }
  const onKeyDown = (event: any, isSp: boolean = false) => {
    if (!isSp) {
      if (isEnter && isZenkaku && event.key === 'Enter') {
        onClick()
      } else if (!isEnter && isZenkaku && event.key === 'Enter') {
        setEnter(true)
      } else if (isEnter && isZenkaku && event.key !== 'Enter') {
        setEnter(false)
      }
    }
    if (event.key === 'Enter' && (!isZenkaku || isSp)) {
      onClick()
    }
  }
  return (
    <>
      <Box width="100%" height="100vh" style={{ overflow: 'hidden' }}>
        <Loading isHidden={!isLoading} start={isLoading} others={others} ans={filterAns} history={history} />
        <Box display="flex" justifyContent="center" zIndex={999}>
          <Box
            maxWidth="412px"
            width="65%"
            marginTop={windowSize > 1100 ? '60px' : '10%'}
            mb={targetQuestion.id === figuaId && windowSize >= 1100 ? '1%' : targetQuestion.id === figuaId ? 3 : 0}
          >
            <HeadBar sectionNumber={sectionNumber} />
          </Box>
        </Box>
        {transitions.map(({ item, props, key }) => {
          const question = questions.filter((x: any) => !skip.some((y: any) => x.questionSortId === y))[item]
          const sectionLen = questions.filter((x: any) => x.sectionNumber === question.sectionNumber).length

          return (
            <Box
              key={key}
              width="100%"
              textAlign="center"
              minHeight="60vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
            >
              <FormControl key={key}>
                <Box key={key}>
                  <QuestionCard
                    style={props}
                    question={question}
                    onClick={onClick}
                    questionLen={questionLen}
                    onSubmit={onSubmit}
                    ans={ans}
                    sectionLen={sectionLen}
                    name={name}
                    onNameChange={onNameChange}
                    worried={worried}
                    choices={choices}
                    setWorried={setWorried}
                    isChoice={isChoice}
                    setIsChoice={setIsChoice}
                    buttomBarClick={buttomBarClick}
                    onBottomButtonCounter={onBottomButtonCounter}
                    key={Number(key)}
                    windowSize={windowSize}
                    allergies={allergies}
                    setAllergies={setAllergies}
                    onKeyDown={onKeyDown}
                    moveResult={moveResult}
                    mostWorryied={mostWorryied}
                    onMostWorriedChange={onMostWorriedChange}
                  />
                </Box>
              </FormControl>
            </Box>
          )
        })}
        {((targetQuestion.id === 1 && name?.length >= 1 && windowSize >= 1100) ||
          (targetQuestion.id === worriesId && choices.length > 0 && windowSize >= 1100) ||
          (targetQuestion.id === lastQuestionId && windowSize >= 1100)) && (
          <NextButton onClick={onClick} moveResult={moveResult} isLast={targetQuestion.id === lastQuestionId} />
        )}
        <Box zIndex="-999">
          <BottomBar
            onClick={buttomBarClick}
            leftButtonDisable={index === 0}
            rightButtonDisable={onBottomButtonCounter >= 0}
            questionId={targetQuestion.id}
          />
        </Box>
      </Box>
      {/* お悩みQのSPの「次へ」ボタンQuestionCardでvhをとっているため */}
      {targetQuestion.id === worriesId && windowSize < 1100 && (
        <Box position="fixed" bottom="60px" margin="auto" left={0} right={0}>
          <Box
            margin="auto"
            left={0}
            right={0}
            onClick={choices.length > 1 ? onClick : () => {}}
            width={'177x'}
            height={'40px'}
            borderRadius={'50px'}
            border={choices.length >= 1 ? '2px solid #041E42' : '2px solid transparent'}
            display="flex"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            textAlign="center"
            style={{
              backgroundColor: choices.length >= 1 ? '#041E42' : 'rgba(4, 30, 66, 0.3)',
              fontWeight: 'bold',
              fontSize: '13px',
              lineHeight: '140%',
              letterSpacing: '0.1em',
              width: '50%',
              padding: 0,
              color: '#fff'
            }}
          >
            次へ
          </Box>
        </Box>
      )}
    </>
  )
}
export default withRouter(Diagnosis)
