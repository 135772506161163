import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import autoImgSizeStyle from '../../../common/styleFunctions'
import '../../../CeraPro/stylesheet.css'
import { MapsLocalParking } from 'material-ui/svg-icons'

const HeaderWrapper = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
`

const H1CeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 60px;
line-height: 60px;
/* identical to box height, or 150% */
letter-spacing: 0.1em;
color: #041E42;
`

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 30px;
letter-spacing: 0.1em;
color: #041E42;
`

const H1SpCeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 38px;
/* identical to box height */

letter-spacing: 0.08em;

color: #041E42;
`

const H2SpYuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 26px;
letter-spacing: 0.1em;
color: #041E42;
`

type Props = { name: string; sp: string }

const Top = (props: Props) => {
  const { name, sp } = props
  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          height="100vh"
          position="relative"
          zIndex="-999"
          bgcolor="#F3F3F3"
          style={{
            backgroundImage: `url(./container/result/top.png)`,
            // backgroundSize: "1100px auto",
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            objectFit: 'contain'
          }}
        >
          <Box position="absolute" top={'40%'} left={'10%'}>
            <H2YuGothic style={{ marginTop: '20px' }}>
              2021年10月を持ちまして販売を終了させていただくこととなりました。
              <br />
              長い間ご利用いただきまして、誠にありがとうございました。
            </H2YuGothic>
          </Box>
        </Box>
      </Box>

      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          height={window.screen.height}
          position="relative"
          zIndex="-999"
          bgcolor="#F3F3F3"
          style={{
            backgroundImage: `url(./container/result/SP_top.png)`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 30px',
            objectFit: 'contain'
          }}
        >
          <Box position="absolute" bottom={'6%'} left={'47%'}>
            <img src={'./container/result/scrollArrow.svg'} />
          </Box>
          <Box position="absolute" top={'20%'} left={'10%'}>
            <H2SpYuGothic style={{ marginTop: '16px' }}>
              2021年10月を持ちまして販売を
              <br />
              終了させていただくこととなりました。
              <br />
              長い間ご利用いただきまして、
              <br />
              誠にありがとうございました。
            </H2SpYuGothic>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Top
// const { name, sp } = props
// return (
//   <>
//     <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>

//       <Box
//         width="100%"
//         height="100vh"
//         position="relative"
//         zIndex="-999"
//         bgcolor="#F3F3F3"
//         style={{
//           backgroundImage: `url(./container/result/top.png)`,
//           // backgroundSize: "1100px auto",
//           backgroundSize: '100% auto',
//           backgroundRepeat: 'no-repeat',
//           backgroundPosition: 'bottom',
//           objectFit: 'contain'
//         }}
//       >
//         <Box position="absolute" top={'40%'} left={'10%'}>
//           <H1CeraPro>Dear {name}</H1CeraPro>
//           <H2YuGothic style={{ marginTop: '16px' }}>
//             診断お疲れさまでした。{name}さん専用の
//             <br />
//             サプリメントを選定いたしました。
//             <br />
//             ぜひご確認ください。
//           </H2YuGothic>
//         </Box>
//       </Box>
//     </Box>

//     <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
//       <Box
//         width="100%"
//         height={window.screen.height}
//         position="relative"
//         zIndex="-999"
//         bgcolor="#F3F3F3"
//         style={{
//           backgroundImage: `url(./container/result/SP_top.png)`,
//           backgroundSize: 'auto 100%',
//           backgroundRepeat: 'no-repeat',
//           backgroundPosition: 'right 30px',
//           objectFit: 'contain'
//         }}
//       >
//         <Box position="absolute" bottom={'6%'} left={'47%'}>
//           <img src={'./container/result/scrollArrow.svg'} />
//         </Box>
//         <Box position="absolute" top={'20%'} left={'10%'}>
//           <H1SpCeraPro>Dear {name}</H1SpCeraPro>
//           <H2SpYuGothic style={{ marginTop: '16px' }}>
//             診断お疲れさまでした。{name}さん専用の
//             <br />
//             サプリメントを選定いたしました。
//             <br />
//             ぜひご確認ください。
//           </H2SpYuGothic>
//         </Box>
//       </Box>
//     </Box>
//   </>
// )
// }
