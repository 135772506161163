import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import '../../../CeraPro/stylesheet.css'
import { MapsLocalParking } from 'material-ui/svg-icons'
import FBCard from '../result/FBCard'
import Styled from 'styled-components'
import castspTypeToNumber from '../../../function/castspTypeToNumber'
import { useCookies, Cookies } from 'react-cookie'
import spTypwNumber from '../../../components/container/result/Conversion'

const NormalCVButton = (props: {
  isHiddenPoint: boolean
  isLP: boolean
  spType: 'relax' | 'diet' | 'memory' | 'care' | 'focus'
}) => {
  const isHiddenPoint = props.isHiddenPoint
  const isLP = props.isLP
  const spType = props.spType
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])
  const RootBox = Styled.div`
    width:100%;
    text-align:center;
    position:relative;
    .cv_img{
        width:100%;
    }

    .cta_img{
        width:80%;
    
    }

    .tanpin_img{
      margin-top:16px;
      width:60%;
    
    }

    .CTA_section{
      position:absolute;
      bottom:10%;
      
    }

    .CTA_section_point{
      position:absolute;
      top:29%;
      
    }

    .LP_CTA_section{
      position:absolute;
      bottom:8%;
      
    }

    .LP_CTA_section_point{
      position:absolute;
      top:27%;
      
    }

    @media screen and (min-width:300px) { 
    /*　画面サイズが480pxからはここを読み込む　*/
      .sp{
        display:inline-block;
      }
      .pc{
        display:none;
      }
    }
    @media screen and (min-width:768px) and ( max-width:1024px) {
    /*　画面サイズが768pxから1024pxまではここを読み込む　*/
          .sp{
        display:none;
      }
      .pc{
        display:inline-block;
      }
       .cv_img{
        width:100%;
       }

    .cta_img{
        width:100%;
    
    }

    .tanpin_img{
      margin-top:16px;
      width:80%;
      display:inline-block;
    
    }

    .CTA_section{
      width:25%;
      text-align:center;
      position:absolute;
      bottom:15%;
      left:10%;

    }

    .CTA_section_point{
      width:25%;
      position:absolute;
      top:48%;
      left:9%;
      
    }

    .LP_CTA_section{
      width:25%;
      position:absolute;
      bottom:22%;
      left:10%;
      
    }

    .LP_CTA_section_point{
      width:25%;
      position:absolute;
      top:47%;
      left:9%;
      
    }}

    }
    @media screen and (min-width:1024px) {
    /*　画面サイズが1024pxからはここを読み込む　*/
      .sp{
        display:none;
      }
      .pc{
        display:inline-block;
      }
       .cv_img{
        width:100%;
       }

    .cta_img{
        width:100%;
    
    }

    .tanpin_img{
      margin-top:16px;
      width:80%;
      display:inline-block;
    
    }

    .CTA_section{
      width:25%;
      text-align:center;
      position:absolute;
      bottom:15%;
      left:10%;

    }

    .CTA_section_point{
      width:25%;
      position:absolute;
      top:48%;
      left:9%;
      
    }

    .LP_CTA_section{
      width:25%;
      position:absolute;
      bottom:22%;
      left:10%;
      
    }

    .LP_CTA_section_point{
      width:25%;
      position:absolute;
      top:47%;
      left:9%;
      
    }}
  
`
  if (cookies?.lpCookie?.af === 'hm2x8') {
    const spTypwNumber = (spType: 'relax' | 'diet' | 'memory' | 'care' | 'focus'): number => {
      switch (spType) {
        case 'relax':
          return 18
        case 'diet':
          return 19
        case 'memory':
          return 20
        case 'care':
          return 21
        case 'focus':
          return 22
        default:
          return 21
      }
    }
    if (isHiddenPoint) {
      return (
        <RootBox>
          <img src={'sp_cta_jp-1980.png'} className="cv_img sp" />
          <img src={'pc_cta_lp-1980.png'} className="cv_img pc" />
          <Box position="absolute" className="CTA_section">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + spTypwNumber(spType)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
          </Box>
        </RootBox>
      )
    } else {
      return (
        <RootBox>
          <img src={'sp_lp_point-1980.png'} className="cv_img sp" />
          <img src={'pc_lp_cta_point-1980.png'} className="cv_img pc" />
          <Box position="absolute" className="CTA_section_point">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + spTypwNumber(spType)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
          </Box>
        </RootBox>
      )
    }
  } else if (!isLP) {
    if (isHiddenPoint) {
      return (
        <RootBox>
          <img src={'sp_normal_cv.png'} className="cv_img sp" />
          <img src={'pc_cta-2.png'} className="cv_img pc" />
          <Box position="absolute" className="CTA_section">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP, true)}>
              <img src={'tanpin.png'} className="tanpin_img" />
            </a>
          </Box>
        </RootBox>
      )
    } else {
      return (
        <RootBox>
          <img src={'sp_normal_point.png'} className="cv_img sp" />
          <img src={'pc_cta_point-2.png'} className="cv_img pc" />
          <Box position="absolute" className="CTA_section_point">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP, true)}>
              <img src={'tanpin.png'} className="tanpin_img" />
            </a>
          </Box>
        </RootBox>
      )
    }
  } else {
    if (isHiddenPoint) {
      return (
        <RootBox>
          <img src={'sp_lp_cv.png'} className="cv_img sp" />
          <img src={'pc_cta_lp-2.png'} className="cv_img pc" />
          <Box position="absolute" className="LP_CTA_section">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
          </Box>
        </RootBox>
      )
    } else {
      return (
        <RootBox>
          <img src={'sp_lp_point.png'} className="cv_img sp" />
          <img src={'pc_lp_cta_point-2.png'} className="cv_img pc" />
          <Box position="absolute" className="LP_CTA_section_point">
            <a href={'https://ec.ihack.energy/lp?u=purchase74' + castspTypeToNumber(spType, isLP)}>
              <img src={'cta.png'} className="cta_img" />
            </a>
          </Box>
        </RootBox>
      )
    }
  }
}

export default NormalCVButton
