import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const pcSPM: string = '../supplementDetail/Style/PC_TOP_BG.png'
const spSPM: string = '../supplementDetail/Style/SP_TOP_BG.png' // "../supplementDetail/Style/SP_SPM.png";
const pcTopBgURL: string = '../supplementDetail/Style/PC_PRODUCT_BG.png' // "../supplementDetail/Style/PC_TOP_BG.png";
const SpTopBgURL: string = '../supplementDetail/Style/SP_PRODUCT_BG.png' //"../supplementDetail/Style/SP_TOP_BG.png";
const title = 'STYLE'
const sub_title =
  'Weight loss is not meant to be a sprint. It’s a marathon. There is no lasting glory in rapid weight loss.'
const text =
  '無理なダイエットは、辛く苦しいもの。 ひとりで追い込まず、たまには誰かの力を借りてもいいのではないでしょうか。あなたの頑張りを支えるべくStyleは、ダイエット系の成分を贅沢に配合しています。頑張るあなたのとなりに、Style。'
const containtext =
  'Styleは、エネルギー産生時の補酵素となるコエンザイムQ10をベースとして、運動をサポートするL-オルニチン、カルニチン、α-リポ酸を配合したサプリメントです。ダイエットをする方の健康サポートに、ご利用ください。'

const product_name = 'STYLE'
const product_amount = 'コエンザイムQ10：0.7mg、α-リポ酸：29mg、L-カルニチン：84mg、D-ソルビトール：57mg'
const product_materials =
  '水溶性コエンザイムQ10、α-リポ酸、L-オルニチン塩酸塩、カルニチン酒石酸塩、Dソルビトール、乳糖、デキストリン、ショ糖脂肪酸エステル、セルロース、微粒二酸化ケイ素'
const product_allergy = '乳成分'
const product_agent_type = '../tablet.png'
const productBgSp = '../supplementDetail/Style/SP_SPM.png' // "../supplementDetail/Style/SP_PRODUCT_BG.png";
const productBgPc = '../supplementDetail/Style/PC_SPM.png' // "../supplementDetail/Style/PC_PRODUCT_BG.png";

const evidences: EvidenceProps = [
  {
    title: 'α-リポ酸',
    sub_title: '-  ダイエットをサポート',
    text:
      'α-リポ酸は、エネルギー産生の過程で補酵素として働く成分とされています。ダイエットから、アンチエイジング、疲労回復など、近年様々な効果が期待されている成分です。論文によると「360人の男女を対象とした二重盲検ランダム化比較試験において、1,200mgもしくは1,800mgのα-リポ酸を20週間投与されたグループは、プラセボを投与されたグループと比較して、体重の減少を確認した。平均体重は4週間目から開始し、最終的にはベースラインから大幅に減少した。1,800mg投与群ではプラセボ群と比較して有意差が認められたが、1,200mg投与群では体重減少はしたものの有意差（p<0.05）は認められなかった。」と示されています。',
    thesis: 'Effects of alpha-lipoic Acid on body weight in obese subjects',
    magazine: 'The American Journal of Medicine',
    Impact_Factor: '5.003',
    Impact_Factor_Year: '2014',
    publish: '2011',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/21187189',
    total_quote: '157',
    exists: 'あり'
  },
  {
    title: 'オルニチン',
    sub_title: '-  体型サポート',
    text:
      'オルニチンは、しじみなどに豊富に含まれているアミノ酸の一種です。二日酔いにはしじみのお味噌汁が良いという通説にもある通り、肝機能との関連性が示唆されています。論文によると「運動習慣のない若年男性10人を対象とした試験において、被験者をL-オルニチン塩酸塩摂取群及びプラセボ摂取群に分類した上で、ダンベルカール運動を実施した。その結果、血中の成長ホルモンの上昇幅が、オルニチン摂取群で有意に高かったことが示された。よって、トレーニング時のオルニチン摂取は、成長ホルモン分泌量の増加により筋肥大をサポートする可能性が示唆されている。」と示されています。',
    thesis:
      'The effect of L-ornithine hydrochloride ingestion on human growth hormone secretion after strength training',
    magazine: 'European Journal of Clinical Nutrition',
    Impact_Factor: '3.057',
    Impact_Factor_Year: '2016',
    publish: '2010',
    link: 'https://www.scirp.org/journal/PaperInformation.aspx?paperID=1583',
    total_quote: '14',
    exists: 'あり'
  },
  {
    title: 'コエンザイムQ10',
    sub_title: '-  運動をサポート',
    text:
      'コエンザイムQ10は、細胞内のミトコンドリアに存在しており、生命活動に必要なエネルギーの大部分をつくり出している補酵素です。論文によると「日本人の剣道選手18人を対象とした二重盲検ランダム化比較試験において、1日300mgのCoQ10を２週間投与されたグループは、プラセボを投与されたグループと比較して、筋損傷の程度を示すクレアチンキナーゼの値が有意に低かった。このことから、CoQ10の摂取は、アスリートの筋損傷を軽減し、継続的な運動実施に寄与することが示された。」と示されています。',
    thesis: 'Reducing Exercise-Induced Muscular Injury in Kendo Athletes With Supplementation of Coenzyme Q10',
    magazine: 'British Journal of Nutrition',
    Impact_Factor: '3.302',
    Impact_Factor_Year: '2012',
    publish: '2008',
    link: 'http://www.ncbi.nlm.nih.gov/pubmed/18284711',
    total_quote: '99',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeChill = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader
            title={title}
            pcSPM={pcSPM}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: {
  title: string
  sub_title: string
  text: string
  containtext: string
  pcSPM: string
}) => {
  const { title, text, sub_title, containtext, pcSPM } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src={pcSPM} width={'320px'} />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeChill
