import React from 'react'

const NoneIcon = (props: { color?: string }) => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.558594" y="0.679688" width="50" height="50" fill="none" />
    <path
      d="M7.55432 23.9031V34.3197H4.41797V16.7188H5.2453L15.4877 27.5763L15.9196 28.0341V27.4047V17.042H19.0559V34.6429H18.2022L7.98681 23.7322L7.55432 23.2703V23.9031ZM39.8664 17.042L47.5668 34.6429H44.208L43.2764 32.4776L43.2114 32.3264H43.0468H35.7741H35.6095L35.5444 32.4776L34.6128 34.6429H31.2545L38.9811 17.042H39.8664ZM41.7808 29.8365H42.1482L42.0133 29.4948L39.643 23.4881L39.4104 22.8988L39.1779 23.4881L36.8075 29.4948L36.6726 29.8365H37.0401H41.7808ZM31.0287 16.7328L33.9701 16.7327L25.5468 34.6429H22.3903L31.0287 16.7328Z"
      fill={props.color ? props.color : '#041E42'}
      stroke="white"
      stroke-width="0.5"
    />
  </svg>
)

export default NoneIcon
