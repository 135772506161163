import reacct from 'react'
import { Results } from '../types/Score'
import { useCookies, Cookies } from 'react-cookie'

const castspTypeToNumber = (spType: Results['supplementType'], isLP = false, isSingle = false) => {
  const subcsript = isLP ? 1 : isSingle ? 2 : 0

  switch (spType) {
    case 'relax':
      return [1, 6, 13][subcsript]
    case 'diet':
      return [2, 7, 14][subcsript]
    case 'memory':
      return [3, 8, 15][subcsript]
    case 'care':
      return [4, 9, 16][subcsript]
    case 'focus':
      return [5, 10, 17][subcsript]
    default:
      return [4, 9, 16][subcsript]
  }
}

export default castspTypeToNumber
