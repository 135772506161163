import React from 'react'
import Styled from 'styled-components'
import { Hidden, Box, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const H2PcYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: 40px;
`

const PPcCeraPro = Styled.p`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #041E42;
  margin: 0;
`

const PPcYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: 0.03em;
  color: #041E42;
  margin: 0;
`

const ButtonPcYuGothic = Styled(Button)`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #041e42;
  margin: 0;
`

// @todo 他のボタンに影響が出そうなので一応分けた、問題なければ統一
const PcPurchaseButton = Styled(ButtonPcYuGothic)`
  display: flex;
  place-items: center;
  position: relative;
  .left_icon {
    position: absolute;
    left: 37px;
    width: 40px;
    height: 37px;
  }
  .text {
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #041e42;
  }
  .right_icon {
    position: absolute;
    right: 54px;
    width: 33px;
    height: 8px;
  }
`

const SpPurchaseButton = Styled(ButtonPcYuGothic)`
  width: 75%;
  display: flex;
  place-items: center;
  position: relative;
  .left_icon {
    position: absolute;
    left: 17px;
    width: 19px;
    height: 18px;
  }
  .text {
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.1em;
    color: #041e42;
  }
  .right_icon {
    position: absolute;
    right: 6px;
    width: 24px;
    height: 5px;
  }
`

const H2SpYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: 40px;
`

const PSpCeraPro = Styled.p`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #041E42;
  margin: 0;
`

const PSpYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
  padding: 0;
`

const ButtonSpYuGothic = Styled(Button)`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #041e42;
  margin: 0;
`

const DedicatedToSupplement = (props: {
  drowOpen: (
    name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  ) => void
  name: string
  supplementType: string
}) => {
  const useStyle = makeStyles({
    handPointer: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
  const classes = useStyle()
  const supplements: ('Chill' | 'Memory' | 'Recovery' | 'Style' | 'Zone' | 'Vitality')[] = []
  const baseSupplements: ('Vitamin' | 'Mineral' | 'Omega3')[] = ['Vitamin', 'Mineral', 'Omega3']

  switch (props.supplementType) {
    case 'relax':
      supplements.push('Chill', 'Memory')
      break
    case 'diet':
      supplements.push('Recovery', 'Style')
      break
    case 'memory':
      supplements.push('Zone', 'Vitality')
      break
    case 'care':
      supplements.push('Chill', 'Recovery')
      break
    case 'focus':
      supplements.push('Memory', 'Zone')
      break
  }

  const supplementText = {
    Vitamin: {
      name: 'Vitamin',
      discription:
        '主要なビタミン１１種類をベースとして、ルテイン、BioPerine®といったサポート成分を贅沢に配合した、次世代型マルチビタミンです。日々の健康にお役立てください。'
    },
    Mineral: {
      name: 'Mineral',
      discription:
        '亜鉛やマンガン、銅などの必須ミネラルをベースに、乳酸菌のEC-12、ダイジェザイム、MCTパウダー、朝鮮人参エキス等の成分を配合した次世代型マルチミネラルです。日々の健康にお役立てください。'
    },
    Omega3: {
      name: 'Omega3',
      discription:
        'フィッシュオイルをベースに、オキアミ由来でアスタキサンチンを含むクリルオイルや、ハープシールオイル等のオメガ３脂肪酸を豊富に配合しました。日々の健康にお役立てください。'
    },
    Chill: {
      name: 'Chill',
      discription:
        'リラックスサポートのL-トリプトファンや落ち込みサポートのロディオラ・ロゼアをベースに、４種類の成分を配合したサプリメントです。プレッシャーを感じた時、リラックスしたい時にご利用ください。'
    },
    Memory: {
      name: 'Memory',
      discription:
        '記憶サポートの２大成分であるイチョウ葉エキスとホスファチジルセリンをベースに、レモンバーム（メリッサ）のエキスを加えたサプリメントです。ワーキングメモリの解放が必要な時に、ご利用ください。'
    },
    Recovery: {
      name: 'Recovery',
      discription:
        '元気サポートのイミダゾールジペプチドやコエンザイムQ10をベースに、ビタミンB群やオレアビータ、HMBカルシウムなど成分を配合したサプリメントです。仕事で忙しかった１日をリセットしたい時にご利用ください。'
    },
    Style: {
      name: 'Style',
      discription:
        'エネルギー産生時の補酵素となるコエンザイムQ10をベースとして、運動をサポートするL-オルニチン、カルニチン、α-リポ酸を配合したサプリメントです。ダイエットをする方の健康サポートに、ご利用ください。'
    },
    Zone: {
      name: 'Zone',
      discription:
        '集中サポート成分として知られているカフェインに加えて、カフェインの副作用を抑えることで知られているテアニンを配合したサプリメントです。もうひと踏ん張りが必要な時に、ご利用ください。'
    },
    Vitality: {
      name: 'Vitality',
      discription:
        '滋養強壮系の成分であるマカや朝鮮人参エキスをベースに、雪蓮花エキス、ガラナ等の８種類の有用成分を加えたサプリメントです。毎日の自身と活力のために、ご利用ください。'
    }
  }

  return (
    <>
      <Hidden mdDown>
        <Box mt={14} mx="auto" width="840px">
          <H2PcYuGothic style={{ textAlign: 'center' }}>{props.name}さん専用サプリメント</H2PcYuGothic>

          <Grid container spacing={4} justify="center" style={{ marginBottom: '64px' }}>
            {supplements.map((value) => {
              return (
                <Grid
                  item
                  className={classes.handPointer}
                  style={{ width: '260px' }}
                  onClick={() => props.drowOpen(value)}
                >
                  <Box
                    mb={3}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={2} display="flex" justifyContent="center" alignItems="center">
                    <PPcCeraPro>{supplementText[value].name}</PPcCeraPro>
                    <img src="result_link.png" alt="" width="15px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PPcYuGothic>{supplementText[value].discription.substr(0, 63)}...</PPcYuGothic>
                </Grid>
              )
            })}
          </Grid>

          <Grid container justify="center" spacing={4} style={{ marginBottom: '72px' }}>
            {baseSupplements.map((value) => {
              return (
                <Grid
                  item
                  className={classes.handPointer}
                  style={{ width: '260px' }}
                  onClick={() => props.drowOpen(value)}
                >
                  <Box
                    mb={3}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={2} display="flex" justifyContent="center" alignItems="center">
                    <PPcCeraPro>{supplementText[value].name}</PPcCeraPro>
                    <img src="result_link.png" alt="" width="15px" height="15px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PPcYuGothic>{supplementText[value].discription.substr(0, 63)}...</PPcYuGothic>
                </Grid>
              )
            })}
          </Grid>

          {/* <Box mb={12}>
            <a href="https://ec.ihack.energy/shop/cart" style={{ textDecoration: 'none' }}>
              <PcPurchaseButton fullWidth style={{ background: '#00D4A1', padding: '32px 0' }}>
                <img className="left_icon" src="purchase-vector.svg" alt="vector" />
                <span className="text">ご購入手続きへ進む</span>
                <img className="right_icon" src="arrow-right.svg" alt="arrow-right" />
              </PcPurchaseButton>
            </a>
          </Box> */}
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box mt={6} mx="auto" width="84vw">
          <H2SpYuGothic style={{ textAlign: 'center' }}>{props.name}さん専用サプリメント</H2SpYuGothic>

          <Grid container spacing={4} justify="center" style={{ marginBottom: '80px' }}>
            {supplements.map((value) => {
              return (
                <Grid item style={{ width: '260px', marginBottom: '24px' }} onClick={() => props.drowOpen(value)}>
                  <Box
                    mb={2}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                    <PSpCeraPro>{supplementText[value].name}</PSpCeraPro>
                    <img src="result_link.png" alt="" width="12px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PSpYuGothic>{supplementText[value].discription.substr(0, 52)}...</PSpYuGothic>
                </Grid>
              )
            })}

            {baseSupplements.map((value) => {
              return (
                <Grid item style={{ width: '260px', marginBottom: '16px' }} onClick={() => props.drowOpen(value)}>
                  <Box
                    mb={2}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                    <PSpCeraPro>{supplementText[value].name}</PSpCeraPro>
                    <img src="result_link.png" alt="" width="12px" height="12px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PSpYuGothic>{supplementText[value].discription.substr(0, 52)}...</PSpYuGothic>
                </Grid>
              )
            })}
          </Grid>

          {/* <ButtonPcYuGothic fullWidth style={{ background: '#00D4A1', padding: '32px 0' }}>
            ご購入手続きへ進む
          </ButtonPcYuGothic> */}
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box mt={6} mx="auto" width="84vw">
          <H2SpYuGothic style={{ textAlign: 'center' }}>
            {props.name}さん
            <br />
            専用サプリメント
          </H2SpYuGothic>

          <Grid container spacing={4} justify="center">
            {supplements.map((value) => {
              return (
                <Grid item style={{ width: '260px', marginBottom: '24px' }} onClick={() => props.drowOpen(value)}>
                  <Box
                    mb={2}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                    <PSpCeraPro>{supplementText[value].name}</PSpCeraPro>
                    <img src="result_link.png" alt="" width="12px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PSpYuGothic>{supplementText[value].discription.substr(0, 52)}...</PSpYuGothic>
                </Grid>
              )
            })}

            {baseSupplements.map((value) => {
              return (
                <Grid item style={{ width: '260px', marginBottom: '16px' }} onClick={() => props.drowOpen(value)}>
                  <Box
                    mb={2}
                    mx="auto"
                    width="130px"
                    height="130px"
                    borderRadius="50%"
                    style={{
                      background: `url(common/PC_${value}.png)`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}
                  />
                  <Box mb={1} display="flex" justifyContent="center" alignItems="center">
                    <PSpCeraPro>{supplementText[value].name}</PSpCeraPro>
                    <img src="result_link.png" alt="" width="12px" height="12px" style={{ marginLeft: '4px' }} />
                  </Box>

                  <PSpYuGothic>{supplementText[value].discription.substr(0, 52)}...</PSpYuGothic>
                </Grid>
              )
            })}
          </Grid>
          {/* 修正完了するまでコメントアウト */}
          {/* <Box mt={6} display="flex" justifyContent="center" alignItems="center">
            <a
              href="https://ec.ihack.energy/shop/cart"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                textDecoration: 'none'
              }}
            >
              <SpPurchaseButton style={{ background: '#00D4A1', padding: '8px 0' }}>
                <img className="left_icon" src="purchase-vector.svg" alt="vector" />
                <span className="text">購入手続きへ</span>
                <img className="right_icon" src="arrow-right.svg" alt="arrow-right" />
              </SpPurchaseButton>
            </a>
          </Box> */}
        </Box>
      </Hidden>
    </>
  )
}

export default DedicatedToSupplement
