import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Header from '../components/Header'
import { testProblemChecker } from '../common/problemCheckers'
import ChartComponent from '../components/container/result/chartComponent'
import { withRouter } from 'react-router'
import { Results } from '../types/Score'
import SpCards from '../components/container/result/SpCards'
import FBCards from '../components/container/result/FBCards'
import RecommendSection from '../components/container/result/Recommend'
import TotalFb from '../components/container/result/TotalFb'
import Styled from 'styled-components'
import Footer from '../components/Footer'
import Conversion from '../components/container/result/Conversion'
import Top from '../components/container/result/Top'
import Drawer from '@material-ui/core/Drawer'
import MergeChill from '../components/container/supplementDetails/merge/MergeChill'
import MergeZone from '../components/container/supplementDetails/merge/MergeZone'
import MergeStorage from '../components/container/supplementDetails/merge/MergeStorage'
import MergeVitality from '../components/container/supplementDetails/merge/MergeVitality'
import MergeStyle from '../components/container/supplementDetails/merge/MergeStyle'
import MergeMineral from '../components/container/supplementDetails/merge/MergeMineral'
import MergeOmega3 from '../components/container/supplementDetails/merge/MergeOmega3'
import MergeVitamin from '../components/container/supplementDetails/merge/MergeVitamin'
import MergeRecovery from '../components/container/supplementDetails/merge/MergeRecovery'
import Statement from '../components/container/result/Statement'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { enableScroll } from '../components/container/diagnosis/question/common/scroll'
import CopyToClipboard from 'react-copy-to-clipboard'
import DedicatedToSupplement from '../components/container/result/DedicatedToSupplement'
import { useCookies } from 'react-cookie'
// import ChanelDeveloper from '../components/ChanellDeveloper'
import NormalCVButton from '../components/container/result/NormlCVButton'

import AOS from 'aos'
import 'aos/dist/aos.css'

const H1YuGothic = Styled.h1`
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;

  color: #041E42;
`

const H1CeraPro = Styled.h1`
  margin:0;
  padding:0;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 43px;
  letter-spacing: 0.1em;
`

const H1SpCeraPro = Styled.h1`
  margin:0;
  padding:0;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: #041E42;
`

const H2CeraPro = Styled.h2`
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.08em;
  color: #041E42;
`

const PYuGothic = Styled.p`
  word-wrap: break-all;
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: #041E42;
`

const H2SpCeraPro = Styled.h2`
  margin:0;
  padding:0;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #041E42;
`

const PSpYuGothic = Styled.p`
  word-wrap: break-all;
  margin:0;
  padding:0;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #041E42;
`

const Result = (props: { history: any; location: any }) => {
  // ChanelDeveloper.boot({
  //   pluginKey: '8324e2a5-9a85-4d44-a57a-2118a321f35b' //please fill with your plugin key
  // })
  enableScroll()
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])

  useEffect(() => {
    fetch(
      'https://www.google-analytics.com/collect?v=1&tid=UA-167311277-1&cid=' +
        cookies._hjid +
        '&t=pageview&dp=%2Fresult'
    )
  }, [])

  const [isSendAnswer, setSendAnswer] = useState(false)
  const { location, history } = props
  const ans = localStorage.getItem('answerObjects')
  if (ans === null) {
    // localstorageがnullの場合にはtopページへ
    history.push({
      pathname: '/top'
    })
  }
  const ansOBJ = JSON.parse(localStorage.getItem('answerObjects') || '')
  const others = location?.state?.others ? location.state.others : ansOBJ?.others ? ansOBJ?.others : undefined
  const answer = location?.state?.ans ? location.state.ans : ansOBJ?.ansOBJ ? ansOBJ?.ansOBJ : undefined
  const isSp = window.innerWidth <= 1100
  const size = useState()
  const [isDrowOpen, setDrowOpen] = useState(false)
  /*最後必ずここ修正する */
  const [resultSpTypeHooks, setSpType] = useState<'relax' | 'diet' | 'memory' | 'care' | 'focus'>('relax')

  const [openSpmName, setOpenSpmName] = useState<
    'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  >('Chill')
  const drowOpen = (
    name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  ) => {
    setOpenSpmName(name)
    setDrowOpen(true)
  }

  if (answer && others) {
    const result: Results = testProblemChecker(answer, others)
    const resultSpType = result.supplementType
    const icont = ['brainHealth.png']
    const causesTitle: ('body' | 'mental' | 'brain')[] = ['body', 'mental', 'brain']

    let supplementType = ''
    switch (resultSpType) {
      case 'relax':
        supplementType = 'リラックス&記憶'
        break
      case 'diet':
        supplementType = '疲労回復&ダイエット'
        break
      case 'memory':
        supplementType = '集中&滋養強壮'
        break
      case 'care':
        supplementType = 'リラックス&疲労回復'
        break
      case 'focus':
        supplementType = '集中&記憶'
        break
    }

    const FBs: {
      id: string
      genre: string
      title: string
      text: string
      causes: string[]
    }[] = [
      {
        id: '01',
        genre: '身体の健康',
        title: result.introduction?.[0].problem,
        text: result.introduction?.[0].introduction,
        causes: Object.values(result.cause.body)
      },
      {
        id: '02',
        genre: '精神の健康',
        title: result.introduction?.[1].problem,
        text: result.introduction?.[1].introduction,
        causes: Object.values(result.cause.brain)
      },
      {
        id: '03',
        genre: '脳機能的な健康',
        title: result.introduction?.[2].problem,
        text: result.introduction?.[2].introduction,
        causes: Object.values(result.cause.mental)
      }
    ]
    const selectSPM = (
      spName: 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Chill' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
    ) => {
      switch (spName) {
        case 'Style':
          return <MergeStyle />
        case 'Vitality':
          return <MergeVitality />
        case 'Memory':
          return <MergeStorage />
        case 'Zone':
          return <MergeZone />
        case 'Chill':
          return <MergeChill />
        case 'Mineral':
          return <MergeMineral />
        case 'Omega3':
          return <MergeOmega3 />
        case 'Vitamin':
          return <MergeVitamin />
        case 'Recovery':
          return <MergeRecovery />
      }
    }

    const SectionStr = () => {
      AOS.init()

      return window.innerWidth <= 600 ? (
        <Box
          width="100%"
          textAlign="cener"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="2000"
          data-aos-once="true"
        >
          <H2SpCeraPro style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            {'BODY, MENTAL \nand BRAIN FUNCTIONS'}
          </H2SpCeraPro>
          <PSpYuGothic
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              marginTop: '24px'
            }}
          >
            {'続いて、身体・精神・脳機能ごとの\n改善余地のある点についてです。'}
          </PSpYuGothic>
        </Box>
      ) : (
        <Box
          width="1052px"
          textAlign="cener"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="2000"
          data-aos-once="true"
        >
          <H2CeraPro style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            BODY, MENTAL and BRAIN FUNCTIONS
          </H2CeraPro>
          <PYuGothic
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              marginTop: '24px'
            }}
          >
            続いて、身体・精神・脳機能ごとの改善余地のある点についてです。
          </PYuGothic>
        </Box>
      )
    }

    const RecommendSectionStr = () => {
      return window.innerWidth <= 600 ? (
        <Box width="100%" display="flex" justifyContent="center">
          <Box width="278px">
            <H2SpCeraPro
              style={{
                textAlign: 'center',
                paddingTop: '102px',
                whiteSpace: 'nowrap'
              }}
            >
              {others.name + 'さん'}
              <br />
              専用サプリメント
            </H2SpCeraPro>
          </Box>
        </Box>
      ) : (
        <H2CeraPro style={{ textAlign: 'center', paddingTop: '168px' }}>{others.name}さん専用サプリメント</H2CeraPro>
      )
    }

    // Makuake

    const hash = require('object-hash')
    const randCode = hash(new Date()).substring(0, 8) // use current time as the source of randomness
    const makuakeURL = 'https://www.makuake.com/project/ihack'

    interface MakuakeFontSettings {
      ff: string
      fs: string
      mt: string
      mb: string
    }

    // use current time as the source of randomness

    const MakuakeDirections = Styled.h2<MakuakeFontSettings>`
        font-family: ${(props) => props.ff};
        font-style: normal;
        font-weight: bold;
        font-size: ${(props) => props.fs};
        line-height: 30px;
        letter-spacing: 0.1em;
        color: #041E42;
        margin-top: ${(props) => props.mt};
        margin-bottom: ${(props) => props.mb};
    `

    const MakuakeCodeRelax: React.SFC<MakuakeFontSettings> = (props: {
      ff: string
      fs: string
      mt: string
      mb: string
    }) => {
      return (
        <MakuakeDirections ff={props.ff} fs={props.fs} mt={props.mt} mb={props.mb}>
          r4x{randCode}
        </MakuakeDirections>
      )
    }

    const MakuakeCodeDiet: React.SFC<MakuakeFontSettings> = (props: {
      ff: string
      fs: string
      mt: string
      mb: string
    }) => {
      return (
        <MakuakeDirections ff={props.ff} fs={props.fs} mt={props.mt} mb={props.mb}>
          d9t{randCode}
        </MakuakeDirections>
      )
    }

    const MakuakeCodeMemory: React.SFC<MakuakeFontSettings> = (props: {
      ff: string
      fs: string
      mt: string
      mb: string
    }) => {
      return (
        <MakuakeDirections ff={props.ff} fs={props.fs} mt={props.mt} mb={props.mb}>
          m0y{randCode}
        </MakuakeDirections>
      )
    }

    const MakuakeCodeCare: React.SFC<MakuakeFontSettings> = (props: {
      ff: string
      fs: string
      mt: string
      mb: string
    }) => {
      return (
        <MakuakeDirections ff={props.ff} fs={props.fs} mt={props.mt} mb={props.mb}>
          c2e{randCode}
        </MakuakeDirections>
      )
    }

    const MakuakeCodeFocus: React.SFC<MakuakeFontSettings> = (props: {
      ff: string
      fs: string
      mt: string
      mb: string
    }) => {
      return (
        <MakuakeDirections ff={props.ff} fs={props.fs} mt={props.mt} mb={props.mb}>
          f7s{randCode}
        </MakuakeDirections>
      )
    }

    interface MakuakeBtnCopySettings {
      fs: string
      height: string
    }

    const MakuakeBtnCopy = Styled.button<MakuakeBtnCopySettings>`
        background-color: #041E42;
        border: none;
        font-family: "YuGothic";
        color: white;
        text-align: center;
        font-size: ${(props) => props.fs};
        width: 100%;
        height: ${(props) => props.height};
        cursor: pointer;
        &:hover{
          background-color: #093470;
        }
    `

    interface MakuakeProps {
      resultSpType: string
    }

    const MakuakeCode_PC: React.SFC<MakuakeProps> = (props: { resultSpType: any }) => {
      switch (resultSpType) {
        case 'relax':
          return <MakuakeCodeRelax ff={'Cera Pro'} fs={'36px'} mt={'10px'} mb={'20px'} />
        case 'diet':
          return <MakuakeCodeDiet ff={'Cera Pro'} fs={'36px'} mt={'10px'} mb={'20px'} />
        case 'memory':
          return <MakuakeCodeMemory ff={'Cera Pro'} fs={'36px'} mt={'10px'} mb={'20px'} />
        case 'care':
          return <MakuakeCodeCare ff={'Cera Pro'} fs={'36px'} mt={'10px'} mb={'20px'} />
        case 'focus':
          return <MakuakeCodeFocus ff={'Cera Pro'} fs={'36px'} mt={'10px'} mb={'20px'} />
      }
    }

    interface MakuakePropsSP {
      resultSpType: string
      isTop: boolean
    }

    const MakuakeCode_SP: React.SFC<MakuakePropsSP> = (props: { resultSpType: any; isTop: any }) => {
      switch (resultSpType) {
        case 'relax':
          return (
            <MakuakeCodeRelax
              ff={'Cera Pro'}
              fs={'24px'}
              mt={props.isTop ? '10px' : '5px'}
              mb={props.isTop ? '15px' : '5px'}
            />
          )
        case 'diet':
          return (
            <MakuakeCodeDiet
              ff={'Cera Pro'}
              fs={'24px'}
              mt={props.isTop ? '10px' : '5px'}
              mb={props.isTop ? '15px' : '5px'}
            />
          )
        case 'memory':
          return (
            <MakuakeCodeMemory
              ff={'Cera Pro'}
              fs={'24px'}
              mt={props.isTop ? '10px' : '5px'}
              mb={props.isTop ? '15px' : '5px'}
            />
          )
        case 'care':
          return (
            <MakuakeCodeCare
              ff={'Cera Pro'}
              fs={'24px'}
              mt={props.isTop ? '10px' : '5px'}
              mb={props.isTop ? '15px' : '5px'}
            />
          )
        case 'focus':
          return (
            <MakuakeCodeFocus
              ff={'Cera Pro'}
              fs={'24px'}
              mt={props.isTop ? '10px' : '5px'}
              mb={props.isTop ? '15px' : '5px'}
            />
          )
      }
    }

    const handleAutoCopy = (resultSpType: string): any => {
      switch (resultSpType) {
        case 'relax':
          return 'r4x' + randCode
        case 'diet':
          return 'd9t' + randCode
        case 'memory':
          return 'm0y' + randCode
        case 'care':
          return 'c2e' + randCode
        case 'focus':
          return 'f7s' + randCode
      }
    }

    const MakuakeTopPC: React.SFC<MakuakeProps> = (props) => {
      const [isCopied, setIsCopied] = useState(false)
      return (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: '0'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'24px'} mt={'80px'} mb={'0'}>
              診断お疲れ様です！
            </MakuakeDirections>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: '0'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'10px'} mb={'20px'}>
              {others.name}さん専用サプリメントの処方コードを発行いたしましたので、
              <br />
              Makuake購入画面の備考欄にこちらを入力してください。
            </MakuakeDirections>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '40%',
                boxSizing: 'border-box',
                border: 'solid',
                marginBottom: '15px'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <MakuakeDirections ff={'YuGothic'} fs={'22px'} mt={'30px'} mb={'10px'}>
                  処方コード
                </MakuakeDirections>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <MakuakeCode_PC resultSpType={props.resultSpType} />
              </Box>
            </Box>
          </Box>
          <CopyToClipboard text={handleAutoCopy(props.resultSpType)}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '40%'
                }}
                onClick={() => setIsCopied(true)}
              >
                <MakuakeBtnCopy fs={'18px'} height={'60px'}>
                  処方コードをコピーする
                </MakuakeBtnCopy>
              </Box>
            </Box>
          </CopyToClipboard>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginTop: '10px',
              visibility: isCopied ? 'visible' : 'hidden'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'0'} mb={'0'}>
              処方コードがコピーされました！
            </MakuakeDirections>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
              marginBottom: '60px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '40%'
              }}
            >
              <a href={makuakeURL} target="_blank">
                <img src={'GoBackToMakuake_Top_PC.png'} width="100%" height="auto" />
              </a>
            </Box>
          </Box>
        </>
      )
    }

    const MakuakeBottomPC: React.SFC<MakuakeProps> = (props) => {
      const [isCopied, setIsCopied] = useState(false)
      return (
        <>
          <Box
            mt={20}
            width="100%"
            position="relative"
            style={{
              backgroundImage: `url(./container/result/productImg.png)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left 0px top 0px'
            }}
          >
            <img width="100%" src="./container/result/productImg.png" />
            <Box display="flex" justifyContent="center">
              <Box position="absolute" left={'56%'} top={'20%'} bottom={0} height="400px">
                <Box textAlign="center">
                  <H1CeraPro style={{ whiteSpace: 'nowrap' }}>PERSONALIZED BOX</H1CeraPro>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: '100%',
                      marginBottom: '0'
                    }}
                  >
                    <MakuakeDirections ff={'YuGothic'} fs={'13px'} mt={'10px'} mb={'20px'}>
                      {others.name}さん専用サプリメントの処方コードを発行いたしましたので、
                      <br />
                      Makuake購入画面の備考欄にこちらを入力してください。
                    </MakuakeDirections>
                  </Box>

                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '76%',
                        boxSizing: 'border-box',
                        border: 'solid',
                        marginBottom: '15px',
                        backgroundColor: 'white'
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%'
                        }}
                      >
                        <MakuakeDirections ff={'YuGothic'} fs={'18px'} mt={'30px'} mb={'10px'}>
                          処方コード
                        </MakuakeDirections>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%'
                        }}
                      >
                        <MakuakeCode_PC resultSpType={props.resultSpType} />
                      </Box>
                    </Box>
                  </Box>
                  <CopyToClipboard text={handleAutoCopy(props.resultSpType)}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '76%'
                        }}
                        onClick={() => setIsCopied(true)}
                      >
                        <MakuakeBtnCopy fs={'16px'} height={'60px'}>
                          処方コードをコピーする
                        </MakuakeBtnCopy>
                      </Box>
                    </Box>
                  </CopyToClipboard>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      width: '100%',
                      marginTop: '10px',
                      visibility: isCopied ? 'visible' : 'hidden'
                    }}
                  >
                    <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'0'} mb={'0'}>
                      処方コードがコピーされました！
                    </MakuakeDirections>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      marginTop: '15px',
                      marginBottom: '60px'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '76%'
                      }}
                    >
                      <a href={makuakeURL} target="_blank">
                        <img src={'GoBackToMakuake_Bottom_PC.png'} width="100%" height="auto" />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )
    }

    const MakuakeTopSP: React.SFC<MakuakeProps> = (props) => {
      const [isCopied, setIsCopied] = useState(false)
      return (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: '0'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'18px'} mt={'60px'} mb={'0'}>
              診断お疲れ様です！
            </MakuakeDirections>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: '0'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'10px'} mb={'20px'}>
              {others.name}さん専用サプリメントの
              <br />
              処方コードを発行いたしましたので、
              <br />
              Makuake購入画面の備考欄に
              <br />
              こちらを入力してください。
            </MakuakeDirections>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '85%',
                boxSizing: 'border-box',
                border: 'solid',
                marginBottom: '15px'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <MakuakeDirections ff={'YuGothic'} fs={'18px'} mt={'20px'} mb={'10px'}>
                  処方コード
                </MakuakeDirections>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <MakuakeCode_SP resultSpType={props.resultSpType} isTop={true} />
              </Box>
            </Box>
          </Box>
          <CopyToClipboard text={handleAutoCopy(props.resultSpType)}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '85%'
                }}
                onClick={() => setIsCopied(true)}
              >
                <MakuakeBtnCopy fs={'14px'} height={'45px'}>
                  処方コードをコピーする
                </MakuakeBtnCopy>
              </Box>
            </Box>
          </CopyToClipboard>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              marginTop: '10px',
              visibility: isCopied ? 'visible' : 'hidden'
            }}
          >
            <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'0'} mb={'0'}>
              処方コードがコピーされました！
            </MakuakeDirections>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
              marginBottom: '60px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '85%'
              }}
            >
              <a href={makuakeURL} target="_blank">
                <img src={'GoBackToMakuake_Top_SP.png'} width="100%" height="auto" />
              </a>
            </Box>
          </Box>
        </>
      )
    }

    const MakuakeBottomSP: React.SFC<MakuakeProps> = (props) => {
      const [isCopied, setIsCopied] = useState(false)
      return (
        <>
          <Box bgcolor="#F3F3F3">
            <Box width="90%" pt={6} mx="auto" textAlign="center">
              <H1SpCeraPro>PERSONALIZED BOX</H1SpCeraPro>

              <MakuakeDirections ff={'YuGothic'} fs={'12px'} mt={'8px'} mb={'40px'}>
                {others.name}さん専用サプリメントの処方コードを
                <br />
                発行いたしましたので、Makuake購入画面の
                <br />
                備考欄にこちらを入力してください。
              </MakuakeDirections>

              <Box border="solid" bgcolor="#FFF">
                <MakuakeDirections ff={'YuGothic'} fs={'16px'} mt={'5px'} mb={'0'}>
                  処方コード
                </MakuakeDirections>

                <MakuakeCode_SP resultSpType={props.resultSpType} isTop={false} />
              </Box>

              <CopyToClipboard text={handleAutoCopy(props.resultSpType)}>
                <Box onClick={() => setIsCopied(true)}>
                  <MakuakeBtnCopy fs={'14px'} height={'45px'}>
                    処方コードをコピーする
                  </MakuakeBtnCopy>
                </Box>
              </CopyToClipboard>

              <Box mb={2} visibility={isCopied ? 'visible' : 'hidden'}>
                <MakuakeDirections ff={'YuGothic'} fs={'14px'} mt={'0'} mb={'0'}>
                  処方コードがコピーされました！
                </MakuakeDirections>
              </Box>
            </Box>

            <Box
              width="100%"
              height="50vh"
              style={{
                background: `url(./container/result/sp_reccomend.png)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundPosition: 'bottom 0% right 70%'
              }}
            >
              <Box width="90%" mx="auto" textAlign="center">
                <a href={makuakeURL} target="_blank">
                  <img src={'GoBackToMakuake_Bottom_SP.png'} width="100%" />
                </a>
              </Box>
            </Box>
          </Box>
        </>
      )
    }

    // end of Makuake

    AOS.init()

    return (
      <>
        <Box ml={3} position={'fixed'} zIndex={9999} bgcolor="#F3F3F3">
          {/* <FormControl>
            <InputLabel id="demo-simple-select-label">SpType</InputLabel>
            <Select
              value={resultSpType}
              onChange={(e) => {
                const choice: 'relax' | 'diet' | 'memory' | 'care' | 'focus' = e.target.value as
                  | 'relax'
                  | 'diet'
                  | 'memory'
                  | 'care'
                  | 'focus'
                setSpType((c) => choice)
              }}
            >
              <MenuItem value={'relax'}>relax</MenuItem>
              <MenuItem value={'diet'}>diet</MenuItem>
              <MenuItem value={'memory'}>memory</MenuItem>
              <MenuItem value={'care'}>care</MenuItem>
              <MenuItem value={'focus'}>focus</MenuItem>
            </Select>
          </FormControl> */}
        </Box>

        {/* SP-view */}
        <Hidden lgUp>
          <Box>
            <Header isResult={true} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            <Drawer
              anchor={window.innerWidth <= 1100 ? 'bottom' : 'left'}
              open={isDrowOpen}
              onClose={() => setDrowOpen(false)}
            >
              <Box
                width={window.innerWidth <= 1100 ? '100%' : '960px'}
                height={window.innerWidth <= 1100 ? '90vh' : '100%'}
              >
                <Box
                  width={window.innerWidth <= 1100 ? '100%' : '960px'}
                  position="fixed"
                  zIndex={999}
                  height={window.innerWidth <= 1100 ? '46px' : '64px'}
                >
                  <Box
                    width="100%"
                    height={window.innerWidth <= 1100 ? '46px' : '64px'}
                    bgcolor="#fff"
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      width={window.innerWidth <= 1100 ? '67px' : '96px'}
                      height={window.innerWidth <= 1100 ? '17px' : '25px'}
                      left={0}
                      right={0}
                      top={0}
                      bottom={0}
                      margin="auto"
                      display="flrex"
                      alignItems="center"
                      alignContent="center"
                    >
                      <img src={'logo.svg'} width={window.innerWidth <= 1100 ? '67px' : '96px'} />
                    </Box>
                    <Box
                      position="absolute"
                      width={'20px'}
                      height={'20px'}
                      right={'5%'}
                      top={0}
                      bottom={0}
                      margin="auto"
                      display="flrex"
                      alignItems="center"
                      alignContent="center"
                      padding={0}
                    >
                      <IconButton
                        onClick={() => setDrowOpen(false)}
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: 0,
                          padding: 0
                        }}
                      >
                        <img src={'close.svg'} width={'18px'} style={{ margin: 0, padding: 0 }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                {selectSPM(openSpmName)}
              </Box>
            </Drawer>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <Top name={others.name} sp={supplementType} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <DedicatedToSupplement name={others.name} supplementType={resultSpType} drowOpen={drowOpen} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <NormalCVButton isHiddenPoint={true} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            </Box>

            {/* <MakuakeTopSP resultSpType={resultSpType} /> */}

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <TotalFb
                name={others.name}
                text={result.summary ? result.summary : 'FBはありません！'}
                worries={others.worries}
              />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <ChartComponent result={result} />
            </Box>

            <Box width="100%" display="flex" justifyContent="center" mt={21}>
              <SectionStr />
            </Box>

            <Box
              zIndex={'-999'}
              style={
                window.screen.width > 1100
                  ? {
                      backgroundImage: `url(./container/result/centerBg.svg)`,
                      backgroundSize: '100% auto',
                      backgroundRepeat: 'no-repeat',
                      objectFit: 'cover'
                    }
                  : {
                      backgroundImage: `url(./container/result/tateBg.svg)`,
                      backgroundSize: '77% auto',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center top 350px',
                      objectFit: 'cover'
                    }
              }
            >
              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <FBCards fbs={FBs} />
              </Box>
              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <NormalCVButton isHiddenPoint={false} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
              </Box>

              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <Statement name={others.name} spName={resultSpType} />
              </Box>
            </Box>

            <Box width="100%" display="flex" justifyContent="center">
              <Box
                width="1100px"
                style={{
                  backgroundImage: `url(./container/result/result_bg_green.png)`,
                  backgroundSize: '750px auto',
                  backgroundRepeat: 'no-repeat',
                  objectFit: 'contain',
                  backgroundPosition: 'top 75px right 5%'
                }}
              >
                <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                  <RecommendSectionStr />
                  <SpCards drowOpen={drowOpen} spType={resultSpType} />
                </Box>
              </Box>
            </Box>
            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <RecommendSection name={others.name} spName={resultSpType} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <NormalCVButton isHiddenPoint={false} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            </Box>

            <Footer />
          </Box>
        </Hidden>
        {/* end of SP-view */}

        {/* PC-view */}
        <Hidden mdDown>
          <Box zIndex={9999}>
            <Header isResult={true} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            <Drawer
              anchor={window.innerWidth <= 1100 ? 'bottom' : 'left'}
              open={isDrowOpen}
              onClose={() => setDrowOpen(false)}
            >
              <Box
                width={window.innerWidth <= 1100 ? '100%' : '960px'}
                height={window.innerWidth <= 1100 ? '90vh' : '100%'}
              >
                <Box
                  width={window.innerWidth <= 1100 ? '100%' : '960px'}
                  position="fixed"
                  zIndex={999}
                  height={window.innerWidth <= 1100 ? '46px' : '64px'}
                >
                  <Box
                    width="100%"
                    height={window.innerWidth <= 1100 ? '46px' : '64px'}
                    bgcolor="#fff"
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      width={window.innerWidth <= 1100 ? '67px' : '96px'}
                      height={window.innerWidth <= 1100 ? '17px' : '25px'}
                      left={0}
                      right={0}
                      top={0}
                      bottom={0}
                      margin="auto"
                      display="flrex"
                      alignItems="center"
                      alignContent="center"
                    >
                      <img src={'logo.svg'} width={window.innerWidth <= 1100 ? '67px' : '96px'} />
                    </Box>
                    <Box
                      position="absolute"
                      width={'20px'}
                      height={'20px'}
                      right={'5%'}
                      top={0}
                      bottom={0}
                      margin="auto"
                      display="flrex"
                      alignItems="center"
                      alignContent="center"
                      padding={0}
                    >
                      <IconButton
                        onClick={() => setDrowOpen(false)}
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: 0,
                          padding: 0
                        }}
                      >
                        <img src={'close.svg'} width={'18px'} style={{ margin: 0, padding: 0 }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                {selectSPM(openSpmName)}
              </Box>
            </Drawer>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <Top name={others.name} sp={supplementType} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <DedicatedToSupplement name={others.name} supplementType={resultSpType} drowOpen={drowOpen} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true" mb={4}>
              <NormalCVButton isHiddenPoint={true} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            </Box>

            {/* <MakuakeTopPC resultSpType={resultSpType} /> */}

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <img src={'Result_Arrow.png'} width="20px" height="40px" />
              </Box>
              <TotalFb
                name={others.name}
                text={result.summary ? result.summary : 'FBはありません！'}
                worries={others.worries}
              />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <ChartComponent result={result} />
            </Box>

            <Box width="100%" display="flex" justifyContent="center" mt={21}>
              <SectionStr />
            </Box>

            <Box
              zIndex={'-999'}
              style={{
                backgroundImage: `url(./container/result/centerBg.svg)`,
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                objectFit: 'cover'
              }}
            >
              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <FBCards fbs={FBs} />
              </Box>

              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true" mt={22}>
                <NormalCVButton isHiddenPoint={false} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
              </Box>

              <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <Statement name={others.name} spName={resultSpType} />
              </Box>
            </Box>

            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              bgcolor="#fff"
              mb={10}
              style={{
                backgroundImage: `url(./container/result/spBg.png)`,
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top 40% right 10%'
              }}
            >
              <Box width="1100px" data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
                <RecommendSectionStr />
                <SpCards drowOpen={drowOpen} spType={resultSpType} />
              </Box>
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true" mb={8}>
              <RecommendSection name={others.name} spName={resultSpType} />
            </Box>

            <Box data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000" data-aos-once="true">
              <NormalCVButton isHiddenPoint={false} isLP={cookies?.lpCookie?.lp === 'true'} spType={resultSpType} />
            </Box>

            <Footer />
          </Box>
        </Hidden>
        {/* end of PC-view */}
      </>
    )
  } else {
    console.log({ ans: ansOBJ, others: others })
    {
      history.push({
        pathname: '/top'
      })
    }
    return <p>redirect to Top...</p>
  }
}

export default withRouter(Result)

//withRouter(withWidth(Result));
