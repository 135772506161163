import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'

import { firstQuestionId } from '../../../common/keyQuestion'

import BlackLogo from '../../icon/IHackLogo_Black'
import BlackLogoMini from '../../icon/iHackLogo_Black_Mini'
import LeftButton from '../../icon/LeftButton'
import RightButton from '../../icon/RightButton'

type Props = {
  onClick: (revers: boolean, isBottomBar?: boolean) => void
  leftButtonDisable?: boolean
  rightButtonDisable?: boolean
  leftButtonHidden?: boolean
  rightButtonHidden?: boolean
  isAbsolute?: boolean
  questionId?: number
}

const BottomBar: React.FC<Props> = (props) => {
  const { onClick, leftButtonDisable = false, rightButtonDisable = false, questionId } = props
  const SP = () => {
    return (
      <>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="flex-start"
          style={{}}
          mb="12px"
        >
          <Box
            width="90%"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            alignContent="cneter"
            textAlign="center"
          >
            <Button
              onClick={() => onClick(true, true)}
              disabled={leftButtonDisable}
              style={{ visibility: questionId === firstQuestionId ? 'hidden' : 'visible' }}
            >
              <LeftButton color={leftButtonDisable ? '#F2F4F6' : undefined} />
            </Button>

            <Box>
              <BlackLogo />
            </Box>

            <Button
              onClick={() => onClick(false, true)}
              disabled={rightButtonDisable}
              style={{ visibility: questionId === firstQuestionId ? 'hidden' : 'visible' }}
            >
              <RightButton color={rightButtonDisable ? '#F2F4F6' : undefined} />
            </Button>
          </Box>
        </Box>
      </>
    )
  }
  const PC = () => {
    return (
      <>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="flex-start"
          style={{}}
          mb="12px"
        >
          <Box
            width="90%"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            alignContent="cneter"
            textAlign="center"
          >
            <Button
              onClick={() => onClick(true, true)}
              disabled={leftButtonDisable}
              style={{ visibility: questionId === firstQuestionId ? 'hidden' : 'visible' }}
            >
              <LeftButton color={leftButtonDisable ? '#F2F4F6' : undefined} />
            </Button>

            <Box>
              <BlackLogo />
            </Box>

            <Button
              onClick={() => onClick(false, true)}
              disabled={rightButtonDisable}
              style={{ visibility: questionId === firstQuestionId ? 'hidden' : 'visible' }}
            >
              <RightButton color={rightButtonDisable ? '#F2F4F6' : undefined} />
            </Button>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <SP />
      </Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </>
  )
}

export default BottomBar
