import React from 'react'
import { animated } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'

import { lastQuestionId, worriesId, figuaId } from '../../../common/keyQuestion'

import Text from './util/Text'

import ChoiceOfAnswer from './ChoiceOfAnswers'
import IconCheckBox from './IconCheckBox'
import WorriedCheckBox from './WorriedCheckBox'
import AllergiesCheckBox from './AllergiesCheckBox'
import QuestionText from './question/common/QuestionText'
import NextButton from './question/common/NextButton'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },

  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: '16px',
    color: '#041E42'
  },
  context: {
    marginBottom: 0,
    textAlign: 'center',
    fontSize: 18,
    color: '#041E42'
  },
  icon: {
    textAlign: 'center'
  },
  radioForm: {
    marginBottom: 12,
    textAlign: 'center',
    display: 'inline-block',
    width: '150px'
  },
  radioIcon: {
    background: '#fff',
    width: 52,
    height: 52,
    border: '2px solid #AFAFAF',
    borderRadius: '50%'
  },
  radioCheckedIcon: {
    background: '#041E42',
    width: 42,
    height: 42,
    border: '8px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 0 0 2px #041E42'
  },
  radioIconSP: {
    background: '#fff',
    width: 32,
    height: 32,
    border: '2px solid #AFAFAF',
    borderRadius: '50%'
  },
  radioCheckedIconSP: {
    background: '#041E42',
    width: 24,
    height: 24,
    border: '6px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 0 0 2px #041E42'
  },
  selectForm: {
    margin: 0,
    minWidth: 400,
    maxWidth: 400,
    width: '100%',
    borderRadius: 4,
    border: '2px solid #041E42',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#041E42'
    },
    textAlign: 'center'
  },
  selectFormSP: {
    margin: 0,
    minWidth: 280,
    maxWidth: 400,
    width: '100%',
    borderRadius: 4,
    border: '1px solid #041E42',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#041E42'
    },
    textAlign: 'center'
  }
})

type QuestionCardProps = {
  mostWorryied: any
  onMostWorriedChange: (event: any) => void
  style: any
  question: any
  onClick: () => void
  questionLen: number
  onSubmit: (event: any) => void
  ans: any
  sectionLen: number
  name: string
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  worried: {
    睡眠: boolean
    ストレス: boolean
    活力: boolean
    疲労: boolean
    運動不足: boolean
    記憶力: boolean
    集中力: boolean
    体調不良: boolean
    目の疲れ: boolean
    うつっぽさ: boolean
    ダイエット: boolean
    身体の衰え: boolean
  }
  choices: string[]
  setWorried: (event: any) => void
  isChoice: boolean
  setIsChoice: (event: any) => void
  buttomBarClick: (event: any) => void
  key: number
  onBottomButtonCounter: number
  windowSize: number
  allergies: {
    none: boolean
    milk: boolean
    gelatin: boolean
    chicken: boolean
    phosphatidylserine: boolean
  }
  setAllergies: (event: any) => void
  onKeyDown: (event: any, isSP?: boolean) => void
  moveResult: (email?: string) => void
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  style,
  question,
  onClick,
  onSubmit,
  ans,
  sectionLen,
  name,
  onNameChange,
  worried,
  choices,
  setWorried,
  key,
  windowSize,
  allergies,
  setAllergies,
  onKeyDown,
  moveResult,
  mostWorryied,
  onMostWorriedChange
}) => {
  const classes = useStyles()
  const selectValue =
    ans.length > 0 && ans.some((x: any) => x?.questionSortId === question?.questionSortId)
      ? ans.find((x: any) => x?.questionSortId === question?.questionSortId).choiceId
      : 0
  const sizeMt = (windowSize: number, type: string) => {
    if (windowSize >= 1100) {
      switch (type) {
        case 'RadioButton':
          return '0%'
        case 'PullDown':
          return '10%'
        case 'FiledButton':
          return '4%'
        case 'CheckBoxs':
          return '1%'
        case 'TextFiled':
          return '10%'
        default:
          return '4%'
      }
    } else {
      switch (type) {
        case 'RadioButton':
          return '4%'
        case 'PullDown':
          return '10%'
        case 'FiledButton':
          return '4%'
        case 'CheckBoxs':
          return '0%'
        case 'TextFiled':
          return '10%'
        default:
          return '4%'
      }
    }
  }
  return (
    <Box key={key}>
      <animated.div style={{ ...style }}>
        <Box
          display="flex"
          alignItems={windowSize <= 1100 && question.id === worriesId ? 'flex-start' : 'center'}
          justifyContent="center"
          width="100vw"
          height={
            question.id === worriesId && windowSize <= 1100
              ? '75vh'
              : question.id === lastQuestionId && windowSize <= 1100
              ? '75vh'
              : question.id === figuaId
              ? '69vh'
              : '75vh'
          }
          key={'key'}
          overflow="scroll"
        >
          <Card
            key={'key'}
            className={classes.root}
            variant="outlined"
            style={{
              border: 'none'
            }}
          >
            <CardContent style={{ padding: '0px' }}>
              <Text fw="bold" fs={16} lh={'24px'} ls={'0.1em'} style={{ margin: 0, padding: 0 }}>
                Q{question?.sectionInId}/{sectionLen}
              </Text>
              <Box m={2} textAlign="center">
                <Hidden mdDown>
                  {question.iConName && (
                    <img
                      style={{
                        objectFit: 'contain',
                        height: '50px',
                        width: '50px'
                      }}
                      src={`diagnosisIcon/${question.iConName}`}
                    />
                  )}
                </Hidden>
                <Hidden lgUp>
                  {question.iConName && (
                    <img
                      style={{
                        objectFit: 'contain',
                        height: '40px',
                        width: '40px'
                      }}
                      src={`diagnosisIcon/${question.iConName}`}
                    />
                  )}
                </Hidden>
              </Box>
              <QuestionText questionString={question.questionString} spQuestionString={question.spQuestionString} />
              <Box mt={sizeMt(windowSize, question.type)}>
                {question.id === worriesId ? (
                  <Box maxWidth="700px" className={classes.context} display="flex" justifyContent="center">
                    <>
                      {/* <IconCheckBox
                      question={question}
                      choices={choices}
                      setChoices={setChoices}
                      setIsChoice={setIsChoice}
                      onClick={onClick}
                    /> */}
                      <WorriedCheckBox worried={worried} setWorried={setWorried} />
                    </>
                  </Box>
                ) : question.id === lastQuestionId ? (
                  <Box maxWidth="700px" className={classes.context} display="flex" justifyContent="center">
                    <Box display="inlineblock">
                      <AllergiesCheckBox question={question} allergies={allergies} setAllergies={setAllergies} />
                      <Hidden lgUp>
                        <Box mt={4} pb={4}>
                          <Box
                            // onClick={moveResult}
                            position={'inline-block'}
                            width={'177px'}
                            height={'40px'}
                            borderRadius={'50px'}
                            border="2px solid #041E42"
                            textAlign="center"
                            alignItems="center"
                            justifyContent="center"
                            display="flex"
                            style={{
                              backgroundColor: '#041E42',
                              fontFamily: 'YuGothic',
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '13px',
                              lineHeight: '140%',
                              letterSpacing: '0.1em',
                              margin: 'auto',
                              width: '50%',
                              padding: 0,
                              color: '#fff'
                            }}
                          >
                            診断結果へ
                          </Box>
                        </Box>
                      </Hidden>
                    </Box>
                  </Box>
                ) : (
                  <ChoiceOfAnswer
                    onClick={onClick}
                    question={question}
                    selectValue={selectValue}
                    classes={classes}
                    onSubmit={onSubmit}
                    name={name}
                    onNameChange={onNameChange}
                    onKeyDown={onKeyDown}
                    mostWorryied={mostWorryied}
                    onMostWorriedChange={onMostWorriedChange}
                    choices={choices}
                  />
                )}
                {question.id === 1 && windowSize <= 1100 && (
                  <Box mt={5}>
                    {name.length > 0 ? (
                      <NextButton onClick={onClick} isInlineBlock={true} />
                    ) : (
                      // 名前を入力するとQuestionの位置ずれ対策
                      <Box
                        left="0"
                        right="0"
                        bottom="15%"
                        width={'177x'}
                        height={'40px'}
                        borderRadius={'50px'}
                        border="2px solid transparent"
                        textAlign="center"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        style={{
                          backgroundColor: 'rgba(4, 30, 66, 0.3)',
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          margin: 'auto',
                          width: '50%',
                          padding: 0
                        }}
                      >
                        次へ
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </animated.div>
    </Box>
  )
}

export default QuestionCard
