import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioButtonIcon from '../../common/RadioButtonIcon'

import { nl2br } from '../../../../../../function/index'

const FourRadioButtonIcon = [
  <RadioButtonIcon size="pc" color="defalut" />,
  <RadioButtonIcon size="pc" color="defalut" />,
  <RadioButtonIcon size="pc" color="highlight" />,
  <RadioButtonIcon size="pc" color="highlight" />
]
const FourCheckedRadioButtonIcon = [
  <RadioButtonIcon size="pc" color="defalut" checked={true} />,
  <RadioButtonIcon size="pc" color="defalut" checked={true} />,
  <RadioButtonIcon size="pc" color="highlight" checked={true} />,
  <RadioButtonIcon size="pc" color="highlight" checked={true} />
]
const FourRadioButtonIconSP = [
  <RadioButtonIcon size="sp" color="defalut" />,
  <RadioButtonIcon size="sp" color="defalut" />,
  <RadioButtonIcon size="sp" color="highlight" />,
  <RadioButtonIcon size="sp" color="highlight" />
]

const FourCheckedRadioButtonIconSP = [
  <RadioButtonIcon size="sp" color="defalut" checked={true} />,
  <RadioButtonIcon size="sp" color="defalut" checked={true} />,
  <RadioButtonIcon size="sp" color="highlight" checked={true} />,
  <RadioButtonIcon size="sp" color="highlight" checked={true} />
]

type RadioOptionProps = {
  options: {
    label: string
    value: number
    spLabel: string
  }[]
}

const FourOption: React.FC<RadioOptionProps> = ({ options }) => (
  <>
    {options.map((x: any, i: number) => (
      <>
        <Hidden mdDown>
          <Box display="flex" justifyContent="center">
            <Box ml={4} mr={4} mt={2}>
              <Radio
                key={x}
                value={x?.value}
                icon={FourRadioButtonIcon[i]}
                checkedIcon={FourCheckedRadioButtonIcon[i]}
              />
              <Box mt={3} />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                {nl2br(x?.label)}
              </p>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center">
            <Box ml={'12px'} mr={'12px'} mt={2}>
              <Radio
                key={x}
                value={x?.value}
                icon={FourRadioButtonIconSP[i]}
                checkedIcon={FourCheckedRadioButtonIconSP[i]}
              />
              <Box mt={2} />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                <Box mt={2} />
                {nl2br(x?.spLabel)}
              </p>
            </Box>
          </Box>
        </Hidden>
      </>
    ))}
  </>
)
export default FourOption
