import React from 'react'
import styled, { css } from '../../../../../styledCore'
import CheckIcon from '@material-ui/icons/Check'
import Box from '@material-ui/core/Box'

const RadioButtonIcon: React.FC<Option> = ({ checked, size, color }) => (
  <Box textAlign="center" alignItems="center" justifyContent="center" display="inline-block">
    <ScRadioButtonIcon options={{ checked, size, color }}>
      <CheckIcon
        color="secondary"
        fontSize={size === 'pc' ? 'large' : 'default'}
        style={{ marginTop: size === 'pc' ? '4px' : '4px' }}
      />
    </ScRadioButtonIcon>
  </Box>
)

type Option = {
  checked?: boolean
  size?: 'pc' | 'sp'
  color?: 'defalut' | 'gray' | 'highlight'
}
type Options = {
  options: Option
}

const getBorder = ({ color, size }: Option) => {
  if (size === 'pc') {
    if (color === 'defalut') {
      return `
      border: 2px solid #041E42;
    `
    }
    if (color === 'gray') {
      return `
      border: 2px solid #afafaf;
    `
    }
    if (color === 'highlight') {
      return `
      border: 2px solid #00D4A1;
    `
    }
  }
  if (size === 'sp') {
    if (color === 'defalut') {
      return `
      border: 1px solid #041E42;
    `
    }
    if (color === 'gray') {
      return `
      border: 1px solid #afafaf;
    `
    }
    if (color === 'highlight') {
      return `
      border: 1px solid #00D4A1;
    `
    }
  }
  return `
    border: 2px solid #041E42;
  `
}

const getBgColor = ({ color, checked }: Option) => {
  if (checked) {
    if (color === 'defalut') {
      return `
        background: #041E42;
      `
    }
    if (color === 'gray') {
      return `
        background: #afafaf;
      `
    }
    if (color === 'highlight') {
      return `
        background: #00D4A1;
      `
    }
  }
  return `
    background: #fff;
  `
}
const getSize = ({ size }: Option) => {
  if (size === 'pc') {
    return css`
      width: 40px;
      height: 40px;
    `
  }
  if (size === 'sp') {
    return css`
      width: 30px;
      height: 30px;
    `
  }
  return css`
    width: 30px;
    height: 30px;
  `
}
const ScRadioButtonIcon = styled.div<Options>`
  ${({ options }) => options.color && getBorder({ color: options.color, size: options.size })}
  ${({ options }) => options.color && getBgColor({ color: options.color, checked: options.checked })}
  ${({ options }) => getSize({ size: options.size })}
  ${() => css`
    margin: 0;
    padding: 0;
    border-radius: 50%;
  `}
`

export default RadioButtonIcon
