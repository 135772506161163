import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Styled from "styled-components";
import Header from "../../Header";
import CardSlider from "./CaluselCard";
import autoImgSizeStyle from "../../../common/styleFunctions";
import { Box, Hidden, Container, Button, Link } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

type Props = {
  iConAdress: string;
  title: string;
  sub_title: string;
  text: string;
  containtext: string;
  bgimgURl: string;
  agent_type: string;
  thesis: string;
  magazine: string;
  total_quote: string;
  causes: string[];
  name: string;
  amount: string;
  materials: string;
  link: string;
  Impact_Factor: string;
  publish: string;
  Impact_Factor_Year: string;
  allergy: string;
};

export const EvidenceOpenButton = Styled(Button)`
  position: absolute;
  left: 790px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  background-color: #00D4A1;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size:32px;
  &:hover{
    background-color: #00D4A1;
  }
`;

const H2CerPro = Styled.h2`
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 50px;
display: flex;
align-items: center;
color: #FFFFFF;
  margin: 0;
  padding: 0;
`;

const TopLogo = Styled.h2`
  color: #041E42;
  margin: 0;
  padding: 0;
  font-family: Cera Pro;
  line-height: 52px;
`;

const BlueLink = Styled.a`
  color: #041E42;
  font-family: Cera Pro;
  font-weight: bold;
  font-size: 12px;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-decoration: none;
`;

const ConversionButton = Styled.button`
  background-color: #00D4A1;
  padding: 5px 30px;
  border-radius: 4px;
`;

const Title = Styled.h2`
margin:0 auto 0;
padding:0 0 32px 0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;
/* identical to box height */

letter-spacing: 0.1em;

/* main */

color: #041E42;
`;

export const SecondTitle = Styled.h3`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 32px;
/* identical to box height */
letter-spacing: 0.12em;
color: #041E42;
`;

export const Text = Styled.p`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 32px;
letter-spacing: 0.12em;
color: #041E42;
`;

const textColor: string = "#041E42";

const fontSizexxs: string = "12px";
const fontSizexs: string = "14px";
const fontSizesm: string = "18px";
const fontSizemd: string = "20px";
const fontSizelg: string = "32px";
const fontSizexl: string = "64px";

const letterSpacingsm = "0.06em";
const letterSpacingmd = "0.1em";
const letterSpacinglg = "0.25em";

const lineHeightsm = "30px";
const lineHeightmd = "40px";

export const SplTop = (props: {
  title: string;
  sub_title: string;
  text: string;
  containtext: string;
  bgimgURL: string;
}) => {
  const { title, text, sub_title, containtext, bgimgURL } = props;
  return (
    <Container
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${bgimgURL})`,
        backgroundRepeat: "no-repeat"
      }}
    >
      <Box width="100%" overflow="hideen">
        <Header />
      </Box>
      <Box
        style={{
          width: "85%",
          margin: "82px auto 0"
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: "0",
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: "0.1em"
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: "32px",
                color: `${textColor}`,
                fontSize: `${fontSizesm}`,
                fontWeight: "bold",
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="./spl_detail/chillmedicine.png" />
          </Box>
        </Box>
        <Box width="70%">
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: "bold"
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: "10px",
              marginBottom: "0",
              paddingBottom: "60px",
              color: `${textColor}`,
              fontSize: "16px",
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Container>
  );
};

export const EvidenceList = () => {
  return (
    <>
      <Container
        style={{
          width: "100%",
          backgroundImage: `url(./Rectangle.png)`,
          backgroundRepeat: "repeat-y"
        }}
      >
        <Box
          style={{
            width: "85%",
            margin: "0 auto"
          }}
        >
          <Title
            style={{
              fontSize: `${fontSizelg}`
            }}
          >
            エビデンス一覧
          </Title>
        </Box>
      </Container>
    </>
  );
};

export const ProductDetail = (props: {
  name: string;
  agent_type: string;
  amount: string;
  materials: string;
  allergy: string;
}) => {
  const { name, agent_type, amount, materials, allergy } = props;
  return (
    <>
      <Box width="840px">
        <Title
          style={{
            color: "#fff",
            fontSize: `${fontSizelg}`
          }}
        >
          商品仕様
        </Title>
        <table
          style={{
            height: "500px",
            borderCollapse: "collapse",
            fontSize: `${fontSizexs}`,
            fontWeight: "bold",
            color: "#fff"
          }}
        >
          <tr style={{ height: "110px", borderBottom: "1px solid white" }}>
            <td
              style={{
                width: "180px",
                fontSize: "16px"
              }}
            >
              名称
            </td>
            <td style={{ fontSize: `${fontSizemd}`, fontWeight: "bold" }}>
              <H2CerPro>iHack - {name}</H2CerPro>
            </td>
          </tr>
          <tr style={{ height: "110px", borderBottom: "1px solid white" }}>
            <td
              style={{
                width: "180px",
              }}
            >
              剤形
            </td>
            <td>
              <Box
                width="61px"
                height="56px"
                style={{
                  backgroundImage: `url(${agent_type})`,
                  backgroundRepeat: "no-repeat",
                }}
              ></Box>
            </td>
          </tr>
          <tr style={{ height: "110px", borderBottom: "1px solid white" }}>
            <td
              style={{
                width: "180px",
              }}
            >
              有効成分
            </td>
            <td>{amount}</td>
          </tr>
          <tr style={{ height: "110px", borderBottom: "1px solid white" }}>
            <td
              style={{
                width: "180px",
                fontSize: "16px"
              }}
            >
              原材料名
            </td>
            <td>{materials}</td>
          </tr>
          <tr style={{ height: "110px" }}>
            <td
              style={{
                width: "180px",
                fontSize: "16px"
              }}
            >
              アレルギー
            </td>
            <td>{allergy}</td>
          </tr>
        </table>
        <ul
          style={{
            marginTop: "60px",
            paddingLeft: "16px",
            fontWeight: "bold",
            color: "#fff",
            fontSize: `${fontSizexxs}`
          }}
        >
          <li>
            {name == 'VITAMIN' ? '１日２粒' : '１日１粒'}を目安にお召し上がりください。
        　</li>
          <li>
            原材料をご確認の上、食物アレルギーのある方はお召し上がりにならないでください。
          </li>
          <li>
            使用する前には必ず医師、薬剤師などの専門家に相談しましょう。あなたが妊娠・授乳中の場合、使用を控えるようにしてください。
          </li>
          <li>
            お子様の手の届かないところで保管し、開封後はなるべくお早めにお召し上がりください。
          </li>
        </ul>
      </Box>
    </>
  );
};

export const Carousel = () => {
  return (
    <>
      <Box width="100%" display="flex" justifyContent="center">
        <CardSlider />
      </Box>
    </>
  );
};
