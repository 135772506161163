import React from 'react'
import Hidden from '@material-ui/core/Hidden'

const PhosphatidylserineIcon = (props: { color?: string }) => (
  <>
    <Hidden mdDown>
      <svg width="52" height="54" viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.9144 32.9056L26.1481 29.5766C25.7784 29.363 25.3227 29.363 24.953 29.5766L19.7756 32.5658L14.6524 29.6073V24.3297L20.9718 20.6811C21.5433 20.3511 21.7392 19.6203 21.4092 19.0487C21.079 18.4772 20.3483 18.2813 19.7767 18.6113L13.4573 22.2597L8.88623 19.6206V13.8708C8.88623 13.2108 8.35117 12.6758 7.69119 12.6758C7.0312 12.6758 6.49614 13.2108 6.49614 13.8708V19.6206L1.3275 22.6048C0.957835 22.8182 0.72998 23.2127 0.72998 23.6396V30.2979C0.72998 30.7249 0.957835 31.1193 1.3275 31.3328L7.09366 34.6619C7.2785 34.7686 7.48484 34.822 7.69119 34.822C7.89753 34.822 8.10387 34.7686 8.28871 34.6619L13.458 31.6774L18.5893 34.6405V39.9089L10.5827 44.5315C10.0112 44.8615 9.81534 45.5924 10.1453 46.1639C10.3666 46.5473 10.7683 46.7616 11.1813 46.7616C11.384 46.7616 11.5896 46.71 11.7778 46.6013L19.7844 41.9787L24.953 44.9628C25.1379 45.0696 25.3442 45.123 25.5505 45.123C25.7569 45.123 25.9632 45.0696 26.1481 44.9628L31.9144 41.6337C32.2841 41.4202 32.5119 41.0259 32.5119 40.5988V33.9405C32.5119 33.5135 32.2841 33.1191 31.9144 32.9056ZM12.2623 29.608L7.69119 32.2471L3.12007 29.608V24.3295L7.69119 21.6902L12.2623 24.3295V29.608ZM30.122 39.9089L25.5507 42.548L20.9796 39.9089V34.6305L25.5507 31.9913L30.122 34.6305V39.9089Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M5.81615 45.6133C3.65694 45.6133 1.90039 47.37 1.90039 49.5292C1.90023 51.6884 3.65694 53.445 5.81615 53.445C7.97535 53.445 9.73191 51.6884 9.73191 49.5292C9.73191 47.37 7.97535 45.6133 5.81615 45.6133ZM5.81615 51.0549C4.97484 51.0549 4.29048 50.3705 4.29048 49.5292C4.29032 48.6879 4.97484 48.0034 5.81615 48.0034C6.65746 48.0034 7.34182 48.6879 7.34182 49.5292C7.34182 50.3705 6.65746 51.0549 5.81615 51.0549Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.39222 49.5292V49.5292C2.39208 51.4167 3.92854 52.9531 5.81615 52.9531C7.70372 52.9531 9.24008 51.4168 9.24008 49.5292C9.24008 47.6416 7.7037 46.1051 5.81615 46.1051C3.9286 46.1051 2.39222 47.6416 2.39222 49.5292ZM4.29048 49.5292C4.29032 48.6879 4.97484 48.0034 5.81615 48.0034C6.65746 48.0034 7.34182 48.6879 7.34182 49.5292C7.34182 50.3705 6.65746 51.0549 5.81615 51.0549C4.97484 51.0549 4.29048 50.3705 4.29048 49.5292ZM3.79865 49.5293C3.7987 50.6422 4.70324 51.5467 5.81615 51.5467L3.79865 49.5293ZM3.79865 49.5292C3.79849 48.4162 4.70326 47.5115 5.81615 47.5115C6.92915 47.5115 7.83365 48.4163 7.83365 49.5292C7.83365 50.6421 6.92909 51.5467 5.81615 51.5467M1.90039 49.5292C1.90039 47.37 3.65694 45.6133 5.81615 45.6133C7.97535 45.6133 9.73191 47.37 9.73191 49.5292C9.73191 51.6884 7.97535 53.445 5.81615 53.445C3.65694 53.445 1.90023 51.6884 1.90039 49.5292Z"
          fill="white"
        />
        <path
          d="M7.76781 3.17188C5.6086 3.17188 3.85205 4.92859 3.85205 7.08779C3.85189 9.24699 5.6086 11.0036 7.76781 11.0036C9.92701 11.0036 11.6836 9.24699 11.6836 7.08779C11.6836 4.92859 9.92701 3.17188 7.76781 3.17188ZM7.76781 8.61347C6.9265 8.61347 6.24214 7.9291 6.24214 7.08779C6.24198 6.24648 6.9265 5.56196 7.76781 5.56196C8.60912 5.56196 9.29348 6.24648 9.29348 7.08779C9.29348 7.9291 8.60912 8.61347 7.76781 8.61347Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.34388 7.08779V7.08783C4.34374 8.97534 5.8802 10.5117 7.76781 10.5117C9.65538 10.5117 11.1917 8.97537 11.1917 7.08779C11.1917 5.20019 9.65536 3.6637 7.76781 3.6637C5.88026 3.6637 4.34388 5.20019 4.34388 7.08779ZM6.24214 7.08779C6.24198 6.24648 6.9265 5.56196 7.76781 5.56196C8.60912 5.56196 9.29348 6.24648 9.29348 7.08779C9.29348 7.9291 8.60912 8.61346 7.76781 8.61346C6.9265 8.61346 6.24214 7.9291 6.24214 7.08779ZM5.75031 7.08788C5.75036 8.20078 6.6549 9.10529 7.76781 9.10529L5.75031 7.08788ZM5.75031 7.08779C5.75015 5.9748 6.65492 5.07013 7.76781 5.07013C8.88081 5.07013 9.78531 5.97492 9.78531 7.08779C9.78531 8.20073 8.88075 9.10529 7.76781 9.10529M3.85205 7.08779C3.85205 4.92859 5.6086 3.17188 7.76781 3.17188C9.92701 3.17188 11.6836 4.92859 11.6836 7.08779C11.6836 9.247 9.92701 11.0036 7.76781 11.0036C5.6086 11.0036 3.85189 9.247 3.85205 7.08779Z"
          fill="white"
        />
        <path
          d="M20.8088 20.4811L26.5751 23.8102C26.9447 24.0237 27.4005 24.0237 27.7701 23.8102L32.9475 20.821L38.0708 23.7794V29.057L31.7514 32.7056C31.1798 33.0356 30.984 33.7665 31.314 34.338C31.6441 34.9096 32.3749 35.1054 32.9464 34.7754L39.2658 31.127L43.8369 33.7661V39.5159C43.8369 40.1759 44.372 40.7109 45.032 40.7109C45.6919 40.7109 46.227 40.1759 46.227 39.5159V33.7661L51.3956 30.7819C51.7653 30.5685 51.9932 30.174 51.9932 29.7471V23.0888C51.9932 22.6618 51.7653 22.2674 51.3956 22.0539L45.6295 18.7249C45.4446 18.6181 45.2383 18.5647 45.032 18.5647C44.8256 18.5647 44.6193 18.6181 44.4344 18.7249L39.2652 21.7093L34.1338 18.7462V13.4778L42.1404 8.85523C42.712 8.52524 42.9078 7.79435 42.5778 7.2228C42.3565 6.83943 41.9548 6.62512 41.5418 6.62512C41.3391 6.62512 41.1336 6.67675 40.9454 6.78542L32.9388 11.408L27.7701 8.4239C27.5853 8.31714 27.3789 8.26376 27.1726 8.26376C26.9663 8.26376 26.7599 8.31714 26.5751 8.4239L20.8088 11.753C20.4391 11.9665 20.2112 12.3608 20.2112 12.7879V19.4462C20.2112 19.8732 20.4391 20.2676 20.8088 20.4811ZM40.4608 23.7788L45.032 21.1396L49.6031 23.7788V29.0572L45.032 31.6965L40.4608 29.0572V23.7788ZM22.6012 13.4778L27.1724 10.8387L31.7436 13.4778V18.7562L27.1724 21.3954L22.6012 18.7562V13.4778Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M47.5759 8.27734C49.7351 8.27734 51.4917 6.52063 51.4917 4.36143C51.4919 2.20222 49.7351 0.445667 47.5759 0.445667C45.4167 0.445667 43.6602 2.20222 43.6602 4.36143C43.6602 6.52063 45.4167 8.27734 47.5759 8.27734ZM47.5759 2.83575C48.4173 2.83575 49.1016 3.52012 49.1016 4.36143C49.1018 5.20274 48.4173 5.88726 47.5759 5.88726C46.7346 5.88726 46.0503 5.20274 46.0503 4.36143C46.0503 3.52012 46.7346 2.83575 47.5759 2.83575Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.9999 4.36143V4.36139C51 2.47388 49.4635 0.937496 47.5759 0.937496C45.6884 0.937496 44.152 2.47385 44.152 4.36143C44.152 6.24903 45.6884 7.78552 47.5759 7.78552C49.4635 7.78552 50.9999 6.24903 50.9999 4.36143ZM49.1016 4.36143C49.1018 5.20274 48.4173 5.88726 47.5759 5.88726C46.7346 5.88726 46.0503 5.20274 46.0503 4.36143C46.0503 3.52012 46.7346 2.83575 47.5759 2.83575C48.4173 2.83575 49.1016 3.52012 49.1016 4.36143ZM49.5934 4.36133C49.5934 3.24844 48.6889 2.34393 47.5759 2.34393L49.5934 4.36133ZM49.5934 4.36143C49.5936 5.47442 48.6888 6.37909 47.5759 6.37909C46.4629 6.37909 45.5584 5.4743 45.5584 4.36143C45.5584 3.24849 46.463 2.34393 47.5759 2.34393M51.4917 4.36143C51.4917 6.52063 49.7351 8.27734 47.5759 8.27734C45.4167 8.27734 43.6602 6.52063 43.6602 4.36143C43.6602 2.20222 45.4167 0.445667 47.5759 0.445667C49.7351 0.445667 51.4919 2.20222 51.4917 4.36143Z"
          fill="white"
        />
        <path
          d="M44.9973 50.3008C47.1565 50.3008 48.9131 48.5441 48.9131 46.3849C48.9132 44.2257 47.1565 42.4691 44.9973 42.4691C42.8381 42.4691 41.0816 44.2257 41.0816 46.3849C41.0816 48.5441 42.8381 50.3008 44.9973 50.3008ZM44.9973 44.8592C45.8386 44.8592 46.523 45.5436 46.523 46.3849C46.5232 47.2262 45.8386 47.9107 44.9973 47.9107C44.156 47.9107 43.4717 47.2262 43.4717 46.3849C43.4717 45.5436 44.156 44.8592 44.9973 44.8592Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.4213 46.3849V46.3848C48.4214 44.4973 46.8849 42.9609 44.9973 42.9609C43.1098 42.9609 41.5734 44.4973 41.5734 46.3849C41.5734 48.2725 43.1098 49.809 44.9973 49.809C46.8849 49.809 48.4213 48.2725 48.4213 46.3849ZM46.523 46.3849C46.5232 47.2262 45.8386 47.9107 44.9973 47.9107C44.156 47.9107 43.4717 47.2262 43.4717 46.3849C43.4717 45.5436 44.156 44.8592 44.9973 44.8592C45.8386 44.8592 46.523 45.5436 46.523 46.3849ZM47.0148 46.3848C47.0148 45.2719 46.1102 44.3674 44.9973 44.3674L47.0148 46.3848ZM47.0148 46.3849C47.015 47.4979 46.1102 48.4025 44.9973 48.4025C43.8843 48.4025 42.9798 47.4977 42.9798 46.3849C42.9798 45.2719 43.8844 44.3674 44.9973 44.3674M48.9131 46.3849C48.9131 48.5441 47.1565 50.3008 44.9973 50.3008C42.8381 50.3008 41.0816 48.5441 41.0816 46.3849C41.0816 44.2257 42.8381 42.4691 44.9973 42.4691C47.1565 42.4691 48.9132 44.2257 48.9131 46.3849Z"
          fill="white"
        />
      </svg>
    </Hidden>
    <Hidden lgUp>
      <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.197266" y="0.964844" width="50" height="50" fill="white" />
        <path
          d="M29.519 30.6037L25.0312 28.0128C24.7435 27.8466 24.3888 27.8466 24.1011 28.0128L20.0717 30.3392L16.0844 28.0367V23.9293L21.0026 21.0897C21.4475 20.8329 21.5999 20.2641 21.343 19.8192C21.0861 19.3744 20.5174 19.222 20.0726 19.4788L15.1544 22.3183L11.5968 20.2643V15.7894C11.5968 15.2758 11.1804 14.8594 10.6667 14.8594C10.1531 14.8594 9.73666 15.2758 9.73666 15.7894V20.2643L5.71406 22.5869C5.42636 22.7529 5.24902 23.06 5.24902 23.3922V28.5742C5.24902 28.9065 5.42636 29.2134 5.71406 29.3796L10.2017 31.9705C10.3455 32.0536 10.5061 32.0951 10.6667 32.0951C10.8273 32.0951 10.9879 32.0536 11.1318 31.9705L15.1549 29.6478L19.1485 31.9539V36.0541L12.9171 39.6518C12.4723 39.9086 12.3199 40.4774 12.5767 40.9222C12.749 41.2206 13.0616 41.3874 13.383 41.3874C13.5408 41.3874 13.7007 41.3472 13.8472 41.2626L20.0785 37.665L24.1011 39.9875C24.245 40.0705 24.4056 40.1121 24.5662 40.1121C24.7268 40.1121 24.8873 40.0705 25.0312 39.9875L29.519 37.3965C29.8067 37.2304 29.984 36.9234 29.984 36.5911V31.4091C29.984 31.0768 29.8067 30.7699 29.519 30.6037ZM14.2243 28.0372L10.6667 30.0912L7.10916 28.0372V23.9292L10.6667 21.8751L14.2243 23.9292V28.0372ZM28.124 36.0541L24.5663 38.1081L21.0087 36.0541V31.9461L24.5663 29.8921L28.124 31.9461V36.0541Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M9.20719 40.4922C7.52674 40.4922 6.15967 41.8594 6.15967 43.5398C6.15954 45.2203 7.52674 46.5874 9.20719 46.5874C10.8876 46.5874 12.2547 45.2203 12.2547 43.5398C12.2547 41.8594 10.8876 40.4922 9.20719 40.4922ZM9.20719 44.7272C8.55242 44.7272 8.0198 44.1946 8.0198 43.5398C8.01968 42.8851 8.55242 42.3523 9.20719 42.3523C9.86196 42.3523 10.3946 42.8851 10.3946 43.5398C10.3946 44.1946 9.86196 44.7272 9.20719 44.7272Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.54244 43.5398V43.5399C6.54234 45.0089 7.73812 46.2046 9.20719 46.2046C10.6762 46.2046 11.8719 45.0089 11.8719 43.5398C11.8719 42.0708 10.6762 40.875 9.20719 40.875C7.73816 40.875 6.54244 42.0708 6.54244 43.5398ZM8.0198 43.5398C8.01968 42.8851 8.55242 42.3523 9.20719 42.3523C9.86196 42.3523 10.3946 42.8851 10.3946 43.5398C10.3946 44.1946 9.86196 44.7272 9.20719 44.7272C8.55242 44.7272 8.0198 44.1946 8.0198 43.5398ZM7.63703 43.5399C7.63707 44.406 8.34104 45.11 9.20719 45.11L7.63703 43.5399ZM7.63703 43.5398C7.6369 42.6736 8.34106 41.9695 9.20719 41.9695C10.0734 41.9695 10.7774 42.6737 10.7774 43.5398C10.7774 44.406 10.0734 45.11 9.20719 45.11M6.15967 43.5398C6.15967 41.8594 7.52674 40.4922 9.20719 40.4922C10.8876 40.4922 12.2547 41.8594 12.2547 43.5398C12.2547 45.2203 10.8876 46.5874 9.20719 46.5874C7.52674 46.5874 6.15954 45.2203 6.15967 43.5398Z"
          fill="white"
        />
        <path
          d="M10.7272 7.46094C9.04676 7.46094 7.67969 8.82814 7.67969 10.5086C7.67956 12.189 9.04676 13.5561 10.7272 13.5561C12.4077 13.5561 13.7747 12.189 13.7747 10.5086C13.7747 8.82814 12.4077 7.46094 10.7272 7.46094ZM10.7272 11.696C10.0724 11.696 9.53982 11.1634 9.53982 10.5086C9.5397 9.85381 10.0724 9.32107 10.7272 9.32107C11.382 9.32107 11.9146 9.85381 11.9146 10.5086C11.9146 11.1634 11.382 11.696 10.7272 11.696Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.06246 10.5086V10.5086C8.06236 11.9776 9.25814 13.1733 10.7272 13.1733C12.1963 13.1733 13.392 11.9776 13.392 10.5086C13.392 9.03952 12.1962 7.84371 10.7272 7.84371C9.25818 7.84371 8.06246 9.03952 8.06246 10.5086ZM9.53982 10.5086C9.5397 9.85381 10.0724 9.32107 10.7272 9.32107C11.382 9.32107 11.9146 9.85381 11.9146 10.5086C11.9146 11.1634 11.382 11.696 10.7272 11.696C10.0724 11.696 9.53982 11.1634 9.53982 10.5086ZM9.15705 10.5087C9.15709 11.3748 9.86106 12.0787 10.7272 12.0787L9.15705 10.5087ZM9.15705 10.5086C9.15692 9.64238 9.86108 8.9383 10.7272 8.9383C11.5934 8.9383 12.2974 9.64246 12.2974 10.5086C12.2974 11.3748 11.5934 12.0787 10.7272 12.0787M7.67969 10.5086C7.67969 8.82814 9.04676 7.46094 10.7272 7.46094C12.4077 7.46094 13.7747 8.82814 13.7747 10.5086C13.7747 12.189 12.4077 13.5561 10.7272 13.5561C9.04676 13.5561 7.67956 12.189 7.67969 10.5086Z"
          fill="white"
        />
        <path
          d="M20.8756 20.9315L25.3633 23.5224C25.651 23.6886 26.0057 23.6886 26.2934 23.5224L30.3228 21.196L34.3101 23.4985V27.6059L29.3919 30.4454C28.9471 30.7023 28.7947 31.2711 29.0515 31.7159C29.3084 32.1607 29.8771 32.3131 30.322 32.0563L35.2402 29.2169L38.7977 31.2708V35.7457C38.7977 36.2594 39.2142 36.6758 39.7278 36.6758C40.2414 36.6758 40.6579 36.2594 40.6579 35.7457V31.2708L44.6805 28.9483C44.9682 28.7822 45.1455 28.4752 45.1455 28.143V22.961C45.1455 22.6287 44.9682 22.3217 44.6805 22.1556L40.1928 19.5646C40.049 19.4816 39.8884 19.44 39.7278 19.44C39.5672 19.44 39.4066 19.4816 39.2628 19.5646L35.2397 21.8873L31.2461 19.5813V15.481L37.4774 11.8834C37.9222 11.6266 38.0746 11.0577 37.8178 10.6129C37.6456 10.3146 37.3329 10.1478 37.0115 10.1478C36.8538 10.1478 36.6938 10.1879 36.5473 10.2725L30.316 13.8701L26.2934 11.5477C26.1496 11.4646 25.989 11.4231 25.8284 11.4231C25.6678 11.4231 25.5072 11.4646 25.3633 11.5477L20.8756 14.1386C20.5879 14.3048 20.4105 14.6117 20.4105 14.9441V20.126C20.4105 20.4584 20.5879 20.7653 20.8756 20.9315ZM36.1702 23.498L39.7278 21.444L43.2854 23.498V27.606L39.7278 29.6601L36.1702 27.606V23.498ZM22.2706 15.481L25.8282 13.4271L29.3858 15.481V19.5891L25.8282 21.643L22.2706 19.5891V15.481Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          d="M41.7078 11.4336C43.3883 11.4336 44.7554 10.0664 44.7554 8.38595C44.7555 6.7055 43.3883 5.33842 41.7078 5.33842C40.0274 5.33842 38.6603 6.7055 38.6603 8.38595C38.6603 10.0664 40.0274 11.4336 41.7078 11.4336ZM41.7078 7.19856C42.3626 7.19856 42.8952 7.73118 42.8952 8.38595C42.8954 9.04072 42.3626 9.57346 41.7078 9.57346C41.0531 9.57346 40.5205 9.04072 40.5205 8.38595C40.5205 7.73118 41.0531 7.19856 41.7078 7.19856Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.3726 8.38595V8.38592C44.3727 6.91692 43.1769 5.7212 41.7078 5.7212C40.2388 5.7212 39.0431 6.9169 39.0431 8.38595C39.0431 9.85501 40.2388 11.0508 41.7078 11.0508C43.1769 11.0508 44.3726 9.85501 44.3726 8.38595ZM42.8952 8.38595C42.8954 9.04072 42.3626 9.57346 41.7078 9.57346C41.0531 9.57346 40.5205 9.04072 40.5205 8.38595C40.5205 7.73118 41.0531 7.19856 41.7078 7.19856C42.3626 7.19856 42.8952 7.73118 42.8952 8.38595ZM43.278 8.38588C43.278 7.51974 42.574 6.81578 41.7078 6.81578L43.278 8.38588ZM43.278 8.38595C43.2781 9.25216 42.574 9.95623 41.7078 9.95623C40.8416 9.95623 40.1377 9.25207 40.1377 8.38595C40.1377 7.51978 40.8417 6.81578 41.7078 6.81578M44.7554 8.38595C44.7554 10.0664 43.3883 11.4336 41.7078 11.4336C40.0274 11.4336 38.6603 10.0664 38.6603 8.38595C38.6603 6.7055 40.0274 5.33842 41.7078 5.33842C43.3883 5.33842 44.7555 6.7055 44.7554 8.38595Z"
          fill="white"
        />
        <path
          d="M39.701 44.1406C41.3815 44.1406 42.7485 42.7734 42.7485 41.093C42.7487 39.4125 41.3815 38.0455 39.701 38.0455C38.0206 38.0455 36.6535 39.4125 36.6535 41.093C36.6535 42.7734 38.0206 44.1406 39.701 44.1406ZM39.701 39.9056C40.3558 39.9056 40.8884 40.4382 40.8884 41.093C40.8885 41.7477 40.3558 42.2805 39.701 42.2805C39.0462 42.2805 38.5136 41.7477 38.5136 41.093C38.5136 40.4382 39.0462 39.9056 39.701 39.9056Z"
          fill={props.color ? props.color : '#041E42'}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.3658 41.093V41.093C42.3659 39.624 41.1701 38.4282 39.701 38.4282C38.232 38.4282 37.0363 39.6239 37.0363 41.093C37.0363 42.562 38.232 43.7578 39.701 43.7578C41.17 43.7578 42.3658 42.562 42.3658 41.093ZM40.8884 41.093C40.8885 41.7477 40.3558 42.2805 39.701 42.2805C39.0462 42.2805 38.5136 41.7477 38.5136 41.093C38.5136 40.4382 39.0462 39.9056 39.701 39.9056C40.3558 39.9056 40.8884 40.4382 40.8884 41.093ZM41.2712 41.0929C41.2711 40.2268 40.5672 39.5228 39.701 39.5228L41.2712 41.0929ZM41.2712 41.093C41.2713 41.9592 40.5671 42.6633 39.701 42.6633C38.8348 42.6633 38.1308 41.9591 38.1308 41.093C38.1308 40.2268 38.8348 39.5228 39.701 39.5228M42.7485 41.093C42.7485 42.7734 41.3815 44.1406 39.701 44.1406C38.0206 44.1406 36.6535 42.7734 36.6535 41.093C36.6535 39.4125 38.0206 38.0455 39.701 38.0455C41.3815 38.0455 42.7487 39.4125 42.7485 41.093Z"
          fill="white"
        />
      </svg>
    </Hidden>
  </>
)

export default PhosphatidylserineIcon
