import React, { useState } from 'react'
import { Box, Paper, Button, Grid, Hidden, Divider } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'
import Text from '../components/container/diagnosis/util/Text'
import { animated, useSpring } from 'react-spring'
import { Keyframes, config, animated as renderAnime } from 'react-spring/renderprops'
import delay from 'delay'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router'
import AnimationCard from '../components/container/top/animationCard'
//import ChanelDeveloper from '../components/ChanellDeveloper'

import AOS from 'aos'
import 'aos/dist/aos.css'

const height100 = window.innerHeight

interface H1PCYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1PCYuGothic = Styled.h1<H1PCYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H1PCYuGothic>

interface H2PCYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2PCYuGothic = Styled.h2<H2PCYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '36px')};
  line-height: ${(props) => (props.lh ? props.lh : '54px')};
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H2PCYuGothic>

interface H2PCCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  color?: string
  mb?: string
}

const H2PCCeraPro = Styled.h2<H2PCCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '32px')};
  line-height: ${(props) => (props.lh ? props.lh : '40px')};
  letter-spacing: 0.1em;
  color: ${(props) => (props.color ? props.color : '#041E42')};
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H2PCCeraPro>

const H3PCYuGothic = Styled.h3`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface PPCYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PPCYuGothic = Styled.p<PPCYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<PPCYuGothic>

const PPCCeraPro = Styled.p`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface SpanPCCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const SpanPCCeraPro = Styled.span<SpanPCCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
` as React.FunctionComponent<SpanPCCeraPro>

interface H1SPYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1SPYuGothic = Styled.h1<H1SPYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H1SPYuGothic>

interface H2SPYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2SPYuGothic = Styled.h2<H2SPYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H2SPYuGothic>

interface H2SPCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2SPCeraPro = Styled.h2<H2SPCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '24px')};
  line-height: ${(props) => (props.lh ? props.lh : '30px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<H2SPCeraPro>

const H3SPYuGothic = Styled.h3`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const PSPCeraPro = Styled.p`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

interface PSPYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PSPYuGothic = Styled.p<PSPYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: ${(props) => (props.lh ? props.lh : '22px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
` as React.FunctionComponent<PSPYuGothic>


interface SpanSPCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const SpanSPCeraPro = Styled.span<SpanSPCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: ${(props) => (props.lh ? props.lh : '22px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
` as React.FunctionComponent<SpanSPCeraPro>

const Top = (props: { history: any }) => {

  const flAnimatoinInitVal = localStorage.getItem('flStartAnimation')
  const [flStartAnimation, setFlStartAnimation] = useState(!flAnimatoinInitVal)
  const [isDiagnosed, setDiagnosed] = useState<boolean>(localStorage.getItem('answerObjects') ? true : false)
  localStorage.setItem('flStartAnimation', 'false')
  const { history } = props
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])
  const queryString = require('querystring')
  const parsed = queryString.parse(history.location.search.replace('?', ''))
  const lpParsed = parsed
  if (lpParsed?.lp === 'true') {
    setCookie('lpCookie', parsed, { path: '/' })
  }
  const bodyFade = useSpring({
    // delay: 5800,
    config: { duration: 1000 },
    from: { opacity: 0 },
    to: { opacity: 1 }
  })
  return (
    <>
      {/* <FirstLoadAnimation start={flStartAnimation} setFlState={setFlStartAnimation} /> */}
      <animated.div style={bodyFade}>
        <Box
          width="100%"
        >
          <Header />
          <FirstView />
          <SoldView />
          <SecondView />
          <ThirdView isDiagnosed={isDiagnosed} />
          <FourthView isDiagnosed={isDiagnosed} />
          {/* <FifthView /> */}
          {/* <SixthView /> */}
          <SeventhView />
          <Footer />
        </Box>
      </animated.div>
    </>
  )
}

const FirstLoadAnimation = (props: { start: boolean; setFlState: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const isSP = window.screen.width < 1100
  const fs = isSP ? 14 : 18
  const lh = isSP ? '140%' : '40px'
  const { start, setFlState } = props

  const firstString = useSpring({
    delay: 1000,
    config: { duration: 1500 },
    from: { opacity: 0 },
    to: { opacity: 1 }
  })
  const secondString = useSpring({
    delay: 2500,
    config: { duration: 1500 },
    from: { opacity: 0 },
    to: { opacity: 1 }
  })
  const Parent: any = Keyframes.Spring(async (next: any) => {
    await next({
      to: {
        opacity: 1,
        backgroundColor: '#fff'
      }
    })
    await delay(4000)
    await next({
      config: { duration: 1000 },
      from: {
        opacity: 1,
        backgroundColor: '#fff'
      },
      to: {
        opacity: 0
      }
    })
    await delay(100)
    setFlState((_) => false)
  })

  return (
    <Box display={start ? 'block' : 'none'}>
      <Parent netaive>
        {(ap: any) => (
          <renderAnime.div
            style={{
              width: '100%',
              height: height100,
              position: 'fixed',
              zIndex: 9999,
              overflow: 'hidden',
              ...ap
            }}
          >
            <Box
              width="100%"
              height={height100}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="fixed"
              zIndex={9999}
            >
              <animated.div style={firstString}>
                <Text fw={'bold'} fs={fs} lh={lh} ls={'0.1em'}>
                  挑戦に向かう意志を、
                </Text>
              </animated.div>
              <animated.div style={secondString}>
                <Text fw={'bold'} fs={fs} lh={lh} ls={'0.1em'}>
                  後押しする。
                </Text>
              </animated.div>
            </Box>
          </renderAnime.div>
        )}
      </Parent>
    </Box>
  )
}

const FirstView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          width="100%"
          height="110vh"
          style={{
            background: `url(./top_firstView.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          
          <Box width="88vw" pt={20} mx="auto">
            <H1PCYuGothic mb="16px">世界を変えよう、一心不乱に。</H1PCYuGothic>
            <Box>
              <PPCYuGothic>
                最適な栄養素でその挑戦をサポート。
                <br />
                ビジネスリーダー向けパーソナライズドサプリメント。
                <br />
                飲むパフォーマンスケア。
              </PPCYuGothic>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          width="100%"
          height="100vh"
          style={{
            background: `url(./top_firstview_image_sp.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width="86vw" pt={30} mx="auto">
            <H1SPYuGothic mb="16px">世界を変えよう、一心不乱に。</H1SPYuGothic>
            <Box mt={4}>
              <PSPYuGothic>
                最適な栄養素でその挑戦をサポート。
                <br />
                ビジネスリーダー向けパーソナライズドサプリメント。
                <br />
                飲むパフォーマンスケア。
              </PSPYuGothic>
            </Box>
          </Box>
          <Box textAlign="left" py={5}>
              <PSPYuGothic mb="16px">2021年10月を持ちまして販売を終了させていただくこととなりました。<br />
              長い間ご利用いただきまして、誠にありがとうございました。</PSPYuGothic>
              
            </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          width="100%"
          height="100vh"
          style={{
            background: `url(./top_firstview_image_sp.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width="86vw" pt={14} mx="auto">
            <H1SPYuGothic mb="16px">
              世界を変えよう、
              <br />
              一心不乱に。
            </H1SPYuGothic>
            <Box mt={4}>
              <PSPYuGothic>
                ビジネスリーダー向け、
                <br />
                パーソナライズドサプリメント。
                <br />
                飲むパフォーマンスケア。
              </PSPYuGothic>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const SecondView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          textAlign="center"
        >
          <H2PCYuGothic mb="40px">
            パフォーマンスを引き出す
            <br />
            あなた専用サプリメント
          </H2PCYuGothic>

          <PPCYuGothic>
            健康管理、パフォーマンス向上のためにサプリメントを飲みたいけれど、
            <br />
            種類が多すぎて選べない。そんな課題を解決すべく<SpanPCCeraPro>iHack</SpanPCCeraPro>は開発されました。
            <br />
            <br />
            ビタミンやミネラル等の、日々の食事で不足しがちな栄養素に加え、
            <br />
            ビジネスリーダー特有のお悩みに合わせたサプリメントをご提案します。
            <br />
            <br />
            人によってお悩みや、必要な成分が違うからこそ、
            <br />
            <SpanPCCeraPro>iHack</SpanPCCeraPro>は、ひとりひとりに最適な処方を提案する、
            <br />
            「パーソナライズドサプリメント」にこだわっています。
          </PPCYuGothic>
        </Box>
      </Hidden>
      <Hidden xsDown lgUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          textAlign="center"
        >
          <H2SPYuGothic mb="40px">
            パフォーマンスを引き出す
            <br />
            あなた専用サプリメント
          </H2SPYuGothic>

          <PSPYuGothic>
            健康管理、パフォーマンス向上のためにサプリメントを飲みたいけれど、
            <br />
            種類が多すぎて選べない。そんな課題を解決すべく<SpanSPCeraPro>iHack</SpanSPCeraPro>は開発されました。
            <br />
            <br />
            ビタミンやミネラル等の、日々の食事で不足しがちな栄養素に加え、
            <br />
            ビジネスリーダー特有のお悩みに合わせたサプリメントをご提案します。
            <br />
            <br />
            人によってお悩みや、必要な成分が違うからこそ、
            <br />
            <SpanSPCeraPro>iHack</SpanSPCeraPro>は、ひとりひとりに最適な処方を提案する、
            <br />
            「パーソナライズドサプリメント」にこだわっています。
          </PSPYuGothic>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={13}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="86vw" textAlign="left">
            <H2SPYuGothic mb="40px">
              パフォーマンスを
              <br />
              引き出すあなた専用
              <br />
              サプリメント
            </H2SPYuGothic>

            <PSPYuGothic>
              健康管理、パフォーマンス向上のためにサプリメントを飲みたいけれど、
              種類が多すぎて選べない。そんな課題を解決すべく<SpanSPCeraPro>iHack</SpanSPCeraPro>は開発されました。
              <br />
              <br />
              ビタミンやミネラル等の、日々の食事で不足しがちな栄養素に加え、
              ビジネスリーダー特有のお悩みに合わせたサプリメントをご提案します。
              <br />
              <br />
              人によってお悩みや、必要な成分が違うからこそ、 <SpanSPCeraPro>iHack</SpanSPCeraPro>
              は、ひとりひとりに最適な処方を提案する、 「パーソナライズドサプリメント」にこだわっています。
            </PSPYuGothic>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const ThirdView = (props: { isDiagnosed: boolean }) => {
  const { isDiagnosed } = props
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true" mb={15}>
          <Box mb={4} textAlign="center">
            <H2PCCeraPro>Our 3 Values</H2PCCeraPro>
            <PPCYuGothic fs="12px" lh="24px">
              3つのバリュー
            </PPCYuGothic>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
            <Grid container spacing={4} style={{ width: '936px' }}>
              <Grid item lg={4} style={{ width: '157px' }}>
                <Box mb={2}>
                  <img src="top_doctor_icon.svg" />
                </Box>

                <H3PCYuGothic style={{ marginBottom: '24px' }}>
                  医師・専門家との
                  <br />
                  共同開発
                </H3PCYuGothic>

                <PPCYuGothic fs="12px" lh="24px" ls="0.05em">
                  医師と共同開発した診断ロジックを採用。
                  また、専門家が処方を選定し、専用のオリジナルサプリを1粒1粒製造しています。
                </PPCYuGothic>
              </Grid>
              <Grid item lg={4} style={{ width: '157px' }}>
                <Box mb={2}>
                  <img src="top_paper_icon.svg" />
                </Box>

                <H3PCYuGothic style={{ marginBottom: '24px' }}>
                  医学論文に
                  <br />
                  基づいた処方
                </H3PCYuGothic>

                <PPCYuGothic fs="12px" lh="24px" ls="0.05em">
                  <SpanPCCeraPro fs="12px" lh="24px" ls="0.05em">
                    iHack
                  </SpanPCCeraPro>
                  は、医学論文に基づいて処方を選定。また、各成分の詳細情報や論文を開示し、情報の透明化にこだわっています。
                </PPCYuGothic>
              </Grid>
              <Grid item lg={4} style={{ width: '157px' }}>
                <Box mb={2}>
                  <img src="top_supplement_icon.svg" />
                </Box>

                <H3PCYuGothic style={{ marginBottom: '24px' }}>
                  1日分を小分けにした
                  <br />
                  オールインワン仕様
                </H3PCYuGothic>

                <PPCYuGothic fs="12px" lh="24px" ls="0.05em">
                  毎日簡単に継続してもらえるよう、
                  <SpanPCCeraPro fs="12px" lh="24px" ls="0.05em">
                    iHack
                  </SpanPCCeraPro>
                  はご提案した5種類のサプリメントを1日分の小包装にまとめてお届けしています。
                </PPCYuGothic>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          mb={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="280px">
            <Box mb={4} textAlign="center">
              <H2SPCeraPro>Our 3 Values</H2SPCeraPro>
              <PSPYuGothic lh="24px">3つのバリュー</PSPYuGothic>
            </Box>

            <Box mb={6} display="flex" justifyContent="center" alignItems="center" textAlign="center">
              <Grid container>
                <Grid item style={{ marginBottom: '88px' }}>
                  <Box mb={2}>
                    <img src="top_doctor_icon.svg" />
                  </Box>

                  <H3SPYuGothic style={{ marginBottom: '24px' }}>
                    医師・専門家との
                    <br />
                    共同開発
                  </H3SPYuGothic>

                  <PSPYuGothic lh="24px">
                    医師と共同開発した診断ロジックを採用。
                    また、専門家が処方を選定し、専用のオリジナルサプリを1粒1粒製造しています。
                  </PSPYuGothic>
                </Grid>
                <Grid item style={{ marginBottom: '88px' }}>
                  <Box mb={2}>
                    <img src="top_paper_icon.svg" />
                  </Box>

                  <H3SPYuGothic style={{ marginBottom: '24px' }}>
                    医学論文に
                    <br />
                    基づいた処方
                  </H3SPYuGothic>

                  <PSPYuGothic lh="24px">
                    <SpanSPCeraPro lh="24px">iHack</SpanSPCeraPro>は、医学論文に基づいて処方を選定。
                    また、各成分の詳細情報や論文を開示し、情報の透明化にこだわっています。
                  </PSPYuGothic>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <img src="top_supplement_icon.svg" />
                  </Box>

                  <H3SPYuGothic style={{ marginBottom: '24px' }}>
                    1日分を小分けにした
                    <br />
                    オールインワン仕様
                  </H3SPYuGothic>

                  <PSPYuGothic lh="24px">
                    毎日簡単に継続してもらえるよう、<SpanSPCeraPro lh="24px">iHack</SpanSPCeraPro>
                    はご提案した5種類のサプリメントを1日分の小包装にまとめてお届けしています。
                  </PSPYuGothic>
                </Grid>
              </Grid>
            </Box>
            <Button
              href="ready"
              fullWidth
              size="large"
              style={{
                padding: '14px 0',
                backgroundColor: '#00D4A1'
              }}
            >
              <PSPYuGothic>{isDiagnosed ? '再診断する' : 'まずは無料診断する'}</PSPYuGothic>
              <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
            </Button>
            {isDiagnosed ? (
              <Box width="100%" display="flex" justifyContent="center">
                <a href="result" style={{ textDecoration: 'none' }}>
                  <PPCYuGothic fs="12px">過去の診断結果はこちら</PPCYuGothic>
                </a>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const FourthView = (props: { isDiagnosed: boolean }) => {
  const { isDiagnosed } = props
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true">
          <Box width="100%" display="flex" justifyContent="space-around" bgcolor="#F3F3F3">
            <Box width="440px" display="flex" alignItems="center">
              <Box width="354px" height="250px" style={{ margin: 'auto' }}>
                <H2PCCeraPro>Plan For You</H2PCCeraPro>
                <PPCYuGothic fs="12px" lh="24px" mb="24px">
                  自分だけの専用サプリメント
                </PPCYuGothic>

                <PPCYuGothic fs="14px" lh="30px">
                  診断結果を元に、医師とISNFサプリメントアドバイザーの知見を照らし合わせ、
                  <SpanPCCeraPro fs="14px" lh="30px">
                    iHack
                  </SpanPCCeraPro>
                  はひとりひとりに専用の処方を提案します。 どんなサプリメントが処方されるのか、ぜひ一度お試しください。
                </PPCYuGothic>
              </Box>
            </Box>
            <Box width="660px" bgcolor="#F3F3F3" textAlign="center">
              <img width="660px" height="700px" style={{ objectFit: 'contain', margin: 'auto' }} src="PlanforYou.jpg" />
            </Box>
          </Box>
          <Box width="100%" mb={25}>
            <Box position="relative">
              <img width="100%" height="auto" src="/container/result/productImg.png" />
              <Box width="360px" ml="auto" position="absolute" top={'35%'} left={'65%'}>
                <Box mb={5}>
                  <Box mb={3}>
                    <H2PCCeraPro>PERSONALIZED BOX</H2PCCeraPro>
                    <PPCYuGothic fs="12px" lh="24px">
                      1ヶ月毎の定期コース
                    </PPCYuGothic>
                  </Box>

                  <PPCYuGothic fs="14px" lh="25px">
                    1日分の専用サプリメントが、
                    <br />
                    小分けでまとまっているから手間いらず。
                    <br />
                    1ヶ月分（30個）をまとめて毎月お届けします。
                  </PPCYuGothic>
                </Box>

                <Button
                  href="ready"
                  style={{
                    width: '251px',
                    height: '45px',
                    backgroundColor: '#00D4A1'
                  }}
                >
                  <PPCYuGothic fs="14px">{isDiagnosed ? '再診断する' : 'まずは無料診断する'}</PPCYuGothic>

                  <img src={'arrow_new.png'} style={{ width: '25px', height: '8px', paddingLeft: '1vw' }} />
                </Button>
                {isDiagnosed ? (
                  <Box width="251px" display="flex" justifyContent="center">
                    <a href="result" style={{ textDecoration: 'none' }}>
                      <PPCYuGothic fs="12px">過去の診断結果はこちら</PPCYuGothic>
                    </a>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box data-aos-duration="2000" data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
          <Box bgcolor="#F3F3F3">
            <Box pt={12} display="flex" justifyContent="center" alignItems="center">
              <Box width="84vw">
                <Box mb={4} textAlign="center">
                  <H2SPCeraPro>Plan For You</H2SPCeraPro>
                  <PSPYuGothic lh="24px">自分だけの専用サプリメント</PSPYuGothic>
                </Box>
                <PSPYuGothic lh="24px">
                  診断結果を元に、医師とISNFサプリメントアドバイザーの知見を照らし合わせ、
                  <SpanSPCeraPro lh="24px">iHack</SpanSPCeraPro>はひとりひとりに専用の処方を提案します。
                  どんなサプリメントが処方されるのか、ぜひ一度お試しください。
                </PSPYuGothic>
              </Box>
            </Box>

            <img src="top_supplement_list_sp.jpg" width="100%" />
          </Box>

          <Box bgcolor="#F3F3F3" mb={13} pt={10}>
            <Box width="84vw" mx="auto">
              <Box mb={6} textAlign="left">
                <Box mb={4} textAlign="center">
                  <H2SPCeraPro>PERSONALIZED BOX</H2SPCeraPro>
                  <PSPYuGothic lh="24px">1ヶ月毎の定期コース</PSPYuGothic>
                </Box>

                <PSPYuGothic lh="24px">
                  1日分の専用サプリメントが、 小分けでまとまっているから手間いらず。
                  1ヶ月分（30個）をまとめて毎月お届けします。
                </PSPYuGothic>
              </Box>

              <Button
                href="ready"
                size="large"
                fullWidth
                style={{
                  padding: '14px 0',
                  backgroundColor: '#00D4A1'
                }}
              >
                <PSPYuGothic>{isDiagnosed ? '再診断する' : 'まずは無料診断する'}</PSPYuGothic>
                <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
              </Button>
              {isDiagnosed ? (
                <Box width="100%" display="flex" justifyContent="center">
                  <a href="result" style={{ textDecoration: 'none' }}>
                    <PPCYuGothic fs="12px">過去の診断結果はこちら</PPCYuGothic>
                  </a>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <img src={'top_5_sp.png'} style={{ width: '100%' }} />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const FifthView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          width="800px"
          mb={25}
          mx="auto"
        >
          <H2PCYuGothic fs="56px" lh="84px" mb="24px">
            まだ見ぬ、
            <br />
            ポジティブへの挑戦
          </H2PCYuGothic>

          <PPCYuGothic lh="30px" mb="56px">
            健康需要の高まりにともない、世の中にはたくさんのサプリメントが出回るようになりました。
            <br />
            しかし、中には過剰にコンプレックスを煽る誰かを傷つける言葉や、
            <br />
            誤解を招くような情報を見かけることも。
            <br />
            <br />
            もっと前向きに誰かを応援できないか、もっと情報の非対称性を埋めることはできないか。
            <br />
            <br />
            もしそのようなプロダクトが、まだ世の中にないのなら、自分たちの手で創ればいい。
            <br />
            <br />
            ただサプリメントを提供するだけでなく、日常の小さな勇気やエール、
            <br />
            そういったポジティブを、一緒に届けられるようなプロダクトを。
            <br />
            <br />
            そんな願いから生まれたのが、<SpanPCCeraPro lh="30px">iHack</SpanPCCeraPro>です。
          </PPCYuGothic>

          <Button
            href="story"
            style={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '34px',
              letterSpacing: '0.1em',
              color: '#041E42'
            }}
          >
            READ OUR STORY
            <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '1vw' }} />
          </Button>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          width="86vw"
          mb={13}
          mx="auto"
        >
          <H2SPYuGothic mb="16px">
            まだ見ぬ、
            <br />
            ポジティブへの挑戦。
          </H2SPYuGothic>
          <PSPYuGothic mb="40px">
            健康需要の高まりに伴い、世の中にはたくさんのサプリメントが出回るようになりました。
            <br />
            <br />
            しかし、中には過剰にコンプレックスを煽る誰かを傷つける言葉や、誤解を招くような情報を見かけることも。
            <br />
            <br />
            もっと前向きに誰かを応援できないか、もっと情報の非対称性を埋めることはできないか。
            <br />
            <br />
            もしそのようなプロダクトが、まだ世の中にないのなら、自分たちの手で創ればいい。
            <br />
            <br />
            ただサプリメントを提供するだけでなく、
            <br />
            日常の小さな勇気やエール、そういったポジティブを、一緒に届けられるプロダクトを。
            <br />
            <br />
            そんな願いから生まれたのが、<SpanSPCeraPro>iHack</SpanSPCeraPro>です。
            <br />
          </PSPYuGothic>

          <Button
            href="story"
            style={{
              fontFamily: 'Cera Pro',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '18px',
              lineHeight: '149.69%',
              letterSpacing: '0.1em',
              color: '#041E42'
            }}
          >
            READ OUR STORY
            <img src={'arrow_new.png'} style={{ width: '24px', height: '8px', paddingLeft: '2vw' }} />
          </Button>
        </Box>
      </Hidden>
    </>
  )
}
const SixthView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          position="relative"
          width="100%"
          height="750px"
          style={{
            background: `url(./three_circles_top.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            height="429px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AnimationCard />
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="400"
          data-aos-once="true"
          position="relative"
          width="100%"
          height="350px"
          style={{
            background: `url(./top_three_circles_sp.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            height="250px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AnimationCard />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const SeventhView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true" mb={14}>
          <Box width="710px" mx="auto" textAlign="center">
            <H2PCCeraPro mb="80px">NEWS</H2PCCeraPro>

            <Box textAlign="left">
              <Grid container spacing={6}>
              <Grid item lg={3}>
                  <PPCCeraPro>2021.10.29</PPCCeraPro>
                </Grid>

                <Grid item lg={9}>
                  <PPCYuGothic fs="12px" lh="26px">
                  2021年10月を持ちまして販売を終了させていただくこととなりました
                  </PPCYuGothic>
                </Grid>

              

                <Grid item lg={3}>
                  <PPCCeraPro>2020.09.18</PPCCeraPro>
                </Grid>

                <Grid item lg={9}>
                  <PPCYuGothic fs="12px" lh="26px">
                  D2C支援プログラム「Original Lab by FABRIC TOKYO」に採択されました
                  </PPCYuGothic>
                </Grid>

                
                <Button
                  href="news"
                  style={{
                    width: '170px',
                    height: '45px',
                    margin: '40px 0px 0px 16px'
                  }}
                >
                  <PPCYuGothic fs="16px" lh="26px">
                  >>ニュース一覧へ
                  </PPCYuGothic>
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true" mb={10} mx="auto">
          <Box width="280px"  mx="auto" textAlign="center">
            <H2SPCeraPro mb="48px">NEWS</H2SPCeraPro>

            <Box textAlign="left" py={1}>
              <PSPYuGothic mb="4px">2021.10.29</PSPYuGothic>
              <PSPYuGothic mb="16px">2021年10月を持ちまして販売を終了させていただくこととなりました。</PSPYuGothic>
              <Divider />
            </Box>
                
            <Box textAlign="left" py={1}>
              <PSPYuGothic mb="4px">2020.09.18</PSPYuGothic>
              <PSPYuGothic mb="16px">D2C支援プログラム「Original Lab by FABRIC TOKYO」に採択されました</PSPYuGothic>
              <Divider />
            </Box>
                
            <Box textAlign="left" py={1}>
              <PSPYuGothic mb="4px">2020.08.31</PSPYuGothic>
              <PSPYuGothic mb="16px">Forbes JAPAN Rising Star Communityに採択されました</PSPYuGothic>
                <Divider />
            </Box>
            <Button
                  href="news"
                  style={{
                    width: '140px',
                    height: '28px',
                    float: 'right'
                  }}
                >
                  <PPCYuGothic fs="12px" lh="10px" >
                  >>ニュース一覧へ
                  </PPCYuGothic>
                </Button>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
const SoldView = () => {
  AOS.init()
  return (
    <>
      <Hidden mdDown>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true" mb={14}>
          <Box width="710px" mx="auto" textAlign="center" >
            <H2PCCeraPro mb="180px"></H2PCCeraPro>

            <Box textAlign="center">
              <Grid container spacing={6}>
                  <PPCYuGothic fs="20px" lh="30px" >
                  2021年10月を持ちまして販売を終了させていただくこととなりました。<br />長い間ご利用いただきまして、誠にありがとうございました。
                  </PPCYuGothic>
                
              </Grid>
            </Box>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-once="true" mb={10} mx="auto">
          <Box width="280px"  mx="auto" textAlign="center">
            <H2SPCeraPro mb="48px"></H2SPCeraPro>

            <Box textAlign="left" py={5}>
              <PSPYuGothic mb="16px">2021年10月を持ちまして販売を終了させていただくこととなりました。<br />
              長い間ご利用いただきまして、誠にありがとうございました。</PSPYuGothic>
              
            </Box>
              
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
export default withRouter(Top)