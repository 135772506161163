export const firebaseConfig = {
  apiKey: "AIzaSyAe0dI-ftMXLE0J2wOHYTCKvfZhc8EfI6Y",
  authDomain: "ihack-energy.firebaseapp.com",
  databaseURL: "https://ihack-energy.firebaseio.com",
  projectId: "ihack-energy",
  storageBucket: "ihack-energy.appspot.com",
  messagingSenderId: "356633644857",
  appId: "1:356633644857:web:843232e11425ec9823a2e8",
  measurementId: "G-QY20S0XM03"
};
