import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles'
import { ThemeProvider as StyledThemeProvider } from './styledCore/index'
import { Box, Dialog } from '@material-ui/core'
import palette from './style/variables'
import muiTheme from './style/muiTheme'
import BaseStyle from './style/base'
import Top from './pages/top'
import Supplements from './pages/supplements'
import Result from './pages/result'
import SupplementDetail from './pages/SupplementDetail'
import Diagnosis from './pages/diagnosis'
import Diagnosiss from './pages/diagnosiss'
import Story from './pages/Story'
import Low from './pages/Low'
import Privacy from './pages/Privacy'
import Company from './pages/Company'
import FAQ from './pages/FAQ'
import SupplementList from './pages/SupplementList'
import News from './pages/news'
import ResultCheck from './pages/RescltCheck'
import Ready from './pages/Ready'
import './CeraPro/stylesheet.css'
import './YuGothic/stylesheet.css'

import TagManager from 'react-gtm-module'

export default function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-K4W957R'
  }
  const [needRedirect, setRedirect] = useState(false)
  useEffect(() => {
    // Update the document title using the browser API
    console.log(window.location.hostname)
    //|| window.location.hostname === 'localhost'
    if (window.location.hostname === 'supplement.ihack.co.jp' || window.location.hostname === 'localhost') {
    } else {
      window.location.assign('https://supplement.ihack.co.jp/')
      setRedirect((c) => true)
    }
  }, [])

  TagManager.initialize(tagManagerArgs)

  return (
    <>
      <Router>
        {needRedirect ? (
          <Dialog open={needRedirect}>
            <Box width="90%" my={5} m="auto">
              <h1 style={{ color: '#041E42' }}>お知らせ</h1>
              <p style={{ textAlign: 'left', lineHeight: '180%', color: '#041E42' }}>
                iHackは2021年11月1日より、"ihack.energy"から"supplement.ihack.co.jp"にドメインを変更致しました。自動的にリダイレクトされない場合は、
                <a href="https://supplement.ihack.co.jp/">こちら</a>から遷移して下さい。
              </p>
            </Box>
          </Dialog>
        ) : (
          <></>
        )}
        <MaterialThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={palette()}>
            <BaseStyle />
            <Switch>
              <Route path="/news">
                <News />
              </Route>
              <Route path="/supplementList">
                <SupplementList />
              </Route>
              <Route path="/faq">
                <FAQ />
              </Route>
              <Route path="/company">
                <Company />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/low">
                <Low />
              </Route>
              <Route path="/story">
                <Story />
              </Route>
              {/* <Route path="/supplements">
                <Supplements />
              </Route> */}
              <Route path="/result">
                <Result />
              </Route>
              <Route path="/supplementDetail">
                <SupplementDetail />
              </Route>
              <Route path="/ready">
                <Ready />
              </Route>
              <Route path="/diagnosis_old">
                <Diagnosis />
              </Route>
              <Route path="/diagnosis">
                <Diagnosiss />
              </Route>
              <Route path="/resultCheck">
                <ResultCheck />
              </Route>
              <Route path="/top">
                <Top />
              </Route>
              <Route path="/">
                <Top />
              </Route>
            </Switch>
          </StyledThemeProvider>
        </MaterialThemeProvider>
      </Router>
    </>
  )
}
