import React from 'react'

const ConcentrationIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2278 11.8672H14.1355C13.3534 11.8672 12.7195 12.5275 12.7195 13.3422V24.8475C12.7195 25.6622 13.3534 26.3226 14.1355 26.3226C14.9176 26.3226 15.5516 25.6623 15.5516 24.8475V14.8172H25.2278C26.0099 14.8172 26.6438 14.1569 26.6438 13.3422C26.6438 12.5275 26.0099 11.8672 25.2278 11.8672Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M25.1805 46.433H15.5515V36.4028C15.5515 35.5881 14.9176 34.9277 14.1355 34.9277C13.3534 34.9278 12.7195 35.5881 12.7195 36.4028V47.9081C12.7195 48.7227 13.3534 49.3831 14.1355 49.3831H25.1806C25.9627 49.3831 26.5966 48.7228 26.5966 47.9081C26.5966 47.0933 25.9627 46.433 25.1805 46.433Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M47.3178 34.9277C46.5357 34.9277 45.9017 35.588 45.9017 36.4028V46.4821H36.2727C35.4907 46.4821 34.8567 47.1424 34.8567 47.9572C34.8567 48.7719 35.4906 49.4322 36.2727 49.4322H47.3178C48.0999 49.4322 48.7338 48.7724 48.7338 47.9572V36.4027C48.7338 35.588 48.0999 34.9277 47.3178 34.9277Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M47.365 11.8672H36.2727C35.4907 11.8672 34.8567 12.5275 34.8567 13.3422C34.8567 14.1569 35.4906 14.8173 36.2727 14.8173H45.9489V24.8967C45.9489 25.7113 46.5828 26.3717 47.365 26.3717C48.1476 26.3717 48.781 25.7114 48.781 24.8967V13.3422C48.781 12.5275 48.1471 11.8672 47.365 11.8672Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default ConcentrationIcon
