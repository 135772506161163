import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Paper, Grid } from '@material-ui/core'
import autoImgSizeStyle from '../../../common/styleFunctions'
import RadarChart from 'react-svg-radar-chart'
import 'react-svg-radar-chart/build/css/index.css'
import './CustomChart.css'
import BlackLogo from '../../icon/IHackLogo_Black'

import { Answers, Results } from '../../../types/Score'

interface H2PcYuGothic {
  mb?: string
}

const H2PcYuGothic = Styled.h2<H2PcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  padding: 0;
`
interface H3PcYuGothic {
  mb?: string
}

const H3PcYuGothic = Styled.h3<H3PcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 81px;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  padding: 0;
`

const PYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  color: #041E42;
  margin: 0;
  padding: 0;
`

interface H2SpYuGothic {
  mb?: string
}

const H2SpYuGothic = Styled.h2<H2SpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  padding: 0;
`

interface H3PcYuGothic {
  mb?: string
}

const H3SpYuGothic = Styled.h3<H3PcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 81px;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  padding: 0;
`
const PSpYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  color: #041E42;
  margin: 0;
  padding: 0;
`

type Props = {
  iConAdress: string
  title: string
  text: string
  causes: string[]
}

const captions = {
  nutrition: '栄養状態',
  power: '体力',
  lifestyle: '生活習慣',
  stress: 'ストレスフリー',
  sleep: '睡眠の質',
  brain: '脳の体力'
}

const noSmoothing = (points: any) => {
  let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4)
  for (let i = 1; i < points.length; i++) {
    d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4)
  }
  return d + 'z'
}
const defaultOptions = {
  size: 200,
  axes: true, // show axes?
  scales: 5, // show scale circles?
  captions: true, // show captions?
  captionMargin: 15,
  dots: false, // show dots?
  zoomDistance: 1.3, // where on the axes are the captions?
  setViewBox: (options: any) =>
    `-${options.captionMargin} 0 ${options.size + options.captionMargin * 2} ${options.size}`, // custom viewBox ?
  smoothing: noSmoothing, // shape smoothing function
  axisProps: () => ({ className: 'axis' }),
  scaleProps: () => ({ className: 'scale', fill: '#none' }),
  shapeProps: () => ({ className: 'shape' }),
  captionProps: () => ({
    className: 'caption',
    textAnchor: 'middle',
    fontSize: 11,
    fontFamily: 'sans-serif',
    color: '#041E42'
  }),
  dotProps: () => ({
    className: 'dot',
    mouseEnter: (dot: any) => {},
    mouseLeave: (dot: any) => {}
  })
}

const spDefaultOptions = {
  // size: 200,
  axes: true, // show axes?
  scales: 5, // show scale circles?
  captions: true, // show captions?
  captionMargin: 10,
  dots: false, // show dots?
  zoomDistance: 1.4, // where on the axes are the captions?
  setViewBox: (options: any) =>
    `-${options.captionMargin} -${options.captionMargin} ${options.size + options.captionMargin * 1.6} ${
      options.size + options.captionMargin * 1.6
    }`, // custom viewBox ?
  smoothing: noSmoothing, // shape smoothing function
  axisProps: () => ({ className: 'axis' }),
  scaleProps: () => ({ className: 'scale', fill: 'none' }),
  shapeProps: () => ({ className: 'shape' }),
  captionProps: () => ({
    textAnchor: 'middle',
    fontFamily: 'YuGothic',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.08em',
    color: '#041E42'
  }),
  dotProps: () => ({
    className: 'dot',
    mouseEnter: (dot: any) => {},
    mouseLeave: (dot: any) => {}
  })
}

const ChartComponent = (props: { result: Results }) => {
  const data = [
    {
      data: {
        nutrition: 0.79,
        power: 0.62,
        lifestyle: 0.62,
        stress: 0.79,
        sleep: 0.79,
        brain: 0.59
      },
      meta: {
        color: '#041E42'
      }
    },
    {
      data: {
        nutrition: props.result.analyzeAnswer.health.nutrition,
        power: props.result.analyzeAnswer.health.power,
        lifestyle: props.result.analyzeAnswer.health.lifestyle,
        stress: props.result.analyzeAnswer.health.stress,
        sleep: props.result.analyzeAnswer.health.sleep,
        brain: props.result.analyzeAnswer.health.brain
      },
      meta: {
        color: '#00d4a1'
      }
    }
  ]

  const nutrition = props.result.analyzeAnswer.health.nutrition
  const power = props.result.analyzeAnswer.health.power
  const lifestyle = props.result.analyzeAnswer.health.lifestyle
  const stress = props.result.analyzeAnswer.health.stress
  const sleep = props.result.analyzeAnswer.health.sleep
  const brain = props.result.analyzeAnswer.health.brain

  const sumScore = nutrition + power + lifestyle + stress + sleep + brain
  const average = 3.88
  const standartDeviation = 0.76
  const standardization = (sumScore - average) / standartDeviation
  const conditionRating = 50 + 10 * standardization
  const floorConditionRationg = Math.floor(conditionRating * 10) / 10

  let ratingImage = 'b'
  switch (true) {
    case floorConditionRationg >= 70:
      ratingImage = 's'
      break
    case floorConditionRationg >= 60:
      ratingImage = 'a'
      break
    case floorConditionRationg >= 50:
      ratingImage = 'b'
      break
    case floorConditionRationg >= 40:
      ratingImage = 'c'
      break
    default:
      ratingImage = 'd'
      break
  }

  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box mt={7}>
          <Paper elevation={1} style={{ width: '840px', margin: '0 auto', padding: '16px' }}>
            <Grid container alignItems="center">
              <Grid item lg={6} style={{ textAlign: 'center' }}>
                <H2PcYuGothic mb="8px">コンディション偏差値</H2PcYuGothic>
                <H3PcYuGothic mb="8px">{floorConditionRationg}</H3PcYuGothic>
                <img
                  src={`result_rating_${ratingImage}.png`}
                  alt="ランク"
                  width="120px"
                  style={{ marginBottom: '8px' }}
                />
                {/* <PYuGothic>相対評価: 上位２％以内</PYuGothic> */}
              </Grid>

              <Grid item lg={6} style={{ textAlign: 'center' }}>
                <RadarChart captions={captions} data={data} size={400} captionMargin={30} options={defaultOptions} />
              </Grid>
            </Grid>

            <Grid container alignItems="flex-end">
              <Grid item lg={7}>
                <BlackLogo />
              </Grid>

              <Grid item lg={2}>
                <Box display="flex">
                  <hr color="#00D4A1" style={{ margin: 'auto 8px auto 0', width: '20px' }} />
                  <PYuGothic style={{ color: '#00D4A1' }}>あなたの結果</PYuGothic>
                </Box>

                <Box display="flex">
                  <hr color="#041E42" style={{ margin: 'auto 8px auto 0', width: '20px' }} />
                  <PYuGothic style={{ color: '#041E42' }}>ユーザー平均</PYuGothic>
                </Box>
              </Grid>

              <Grid item lg={3} style={{ textAlign: 'right' }}>
                <PYuGothic>※六角形が大きいほど、良い状態です</PYuGothic>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>

      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box mt={4}>
          <Paper
            elevation={1}
            style={{
              width: '85vw',
              margin: '0 auto',
              padding: '32px 8px 16px'
            }}
          >
            <Grid container direction="column" justify="center" style={{ marginBottom: '16px' }}>
              <Grid item style={{ marginBottom: '16px', textAlign: 'center' }}>
                <H2SpYuGothic mb="8px">コンディション偏差値</H2SpYuGothic>
                <H3SpYuGothic mb="8px">{floorConditionRationg}</H3SpYuGothic>
                <img
                  src={`result_rating_${ratingImage}.png`}
                  alt="ランク"
                  width="120px"
                  style={{ marginBottom: '8px' }}
                />
                {/* <PSpYuGothic>相対評価: 上位２％以内</PSpYuGothic> */}
              </Grid>

              <Grid item style={{ textAlign: 'center' }}>
                <RadarChart captions={captions} data={data} size={270} captionMargin={0} options={spDefaultOptions} />
              </Grid>
            </Grid>

            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item>
                <Box display="flex">
                  <hr color="#00D4A1" style={{ width: '20px', marginRight: '8px' }} />
                  <PSpYuGothic style={{ color: '#00D4A1' }}>あなたの結果</PSpYuGothic>
                </Box>

                <Box display="flex">
                  <hr color="#041E42" style={{ width: '20px', marginRight: '8px' }} />
                  <PSpYuGothic style={{ color: '#041E42' }}>ユーザー平均</PSpYuGothic>
                </Box>
              </Grid>

              <Grid item>
                <PSpYuGothic>※六角形が大きいほど、良い状態です</PSpYuGothic>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default ChartComponent
