import React from 'react'

const StressIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.6334 16.3444C47.4754 15.1272 46.1629 13.748 43.6204 13.748C41.0775 13.748 39.765 15.1272 38.6073 16.3444C37.5904 17.4129 36.7872 18.2568 35.0397 18.2568C33.2925 18.2568 32.4893 17.4129 31.4727 16.3444C30.315 15.1272 29.0025 13.748 26.4596 13.748C23.9171 13.748 22.6046 15.1272 21.4469 16.3444C20.4303 17.4129 19.627 18.2568 17.8799 18.2568C16.1327 18.2568 15.3295 17.4129 14.3129 16.3444C13.475 15.4637 12.5562 14.4982 11.1491 14.0317C10.1642 13.7052 9.2998 14.5892 9.2998 15.6267C9.2998 16.6643 10.1953 17.4348 11.1128 17.9193C11.7724 18.2675 12.2846 18.8058 12.8668 19.4178C14.0248 20.635 15.337 22.0141 17.8799 22.0141C20.4224 22.0141 21.7349 20.635 22.8926 19.4178C23.9092 18.3493 24.7124 17.5054 26.4596 17.5054C28.2071 17.5054 29.01 18.3493 30.0269 19.4178C31.1846 20.635 32.4971 22.0141 35.0397 22.0141C37.5825 22.0141 38.895 20.635 40.053 19.4178C41.0697 18.3493 41.8729 17.5054 43.6204 17.5054C45.3679 17.5054 46.1708 18.3493 47.1877 19.4178C48.0254 20.2986 48.9442 21.2641 50.3515 21.7306C51.3363 22.057 52.2008 21.173 52.2008 20.1355C52.2008 19.0979 51.3053 18.3273 50.3878 17.843C49.728 17.4947 49.2157 16.9564 48.6334 16.3444Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M48.6333 29.1042C47.4753 27.887 46.1628 26.5078 43.6203 26.5078C41.0774 26.5078 39.7649 27.887 38.6072 29.1042C37.5903 30.1727 36.787 31.0166 35.0395 31.0166C33.2924 31.0166 32.4892 30.1727 31.4725 29.1042C30.3148 27.887 29.0023 26.5078 26.4595 26.5078C23.9169 26.5078 22.6044 27.887 21.4468 29.1042C20.4301 30.1727 19.6269 31.0166 17.8797 31.0166C16.1326 31.0166 15.3294 30.1727 14.3127 29.1042C13.4748 28.2234 12.5561 27.2579 11.1489 26.7914C10.1641 26.4649 9.29968 27.3489 9.29968 28.3865C9.29968 29.424 10.1952 30.1946 11.1127 30.679C11.7723 31.0273 12.2844 31.5656 12.8667 32.1775C14.0247 33.3948 15.3369 34.7739 17.8797 34.7739C20.4223 34.7739 21.7348 33.3948 22.8925 32.1775C23.9091 31.1091 24.7123 30.2651 26.4595 30.2651C28.207 30.2651 29.0099 31.1091 30.0268 32.1775C31.1845 33.3948 32.497 34.7739 35.0395 34.7739C37.5824 34.7739 38.8949 33.3948 40.0529 32.1775C41.0695 31.1091 41.8728 30.2651 43.6203 30.2651C45.3678 30.2651 46.1706 31.1091 47.1876 32.1775C48.0253 33.0583 48.9441 34.0239 50.3514 34.4904C51.3362 34.8168 52.2006 33.9328 52.2006 32.8953C52.2006 31.8577 51.3052 31.0871 50.3876 30.6027C49.7279 30.2545 49.2156 29.7162 48.6333 29.1042Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M48.6334 41.862C47.4754 40.6448 46.1629 39.2656 43.6204 39.2656C41.0775 39.2656 39.765 40.6448 38.6073 41.862C37.5904 42.9305 36.7872 43.7744 35.0397 43.7744C33.2925 43.7744 32.4893 42.9305 31.4727 41.862C30.315 40.6448 29.0025 39.2656 26.4596 39.2656C23.9171 39.2656 22.6046 40.6448 21.4469 41.862C20.4303 42.9305 19.627 43.7744 17.8799 43.7744C16.1327 43.7744 15.3295 42.9305 14.3129 41.862C13.475 40.9813 12.5562 40.0157 11.1491 39.5492C10.1642 39.2227 9.2998 40.1067 9.2998 41.1443C9.2998 42.1818 10.1953 42.9524 11.1128 43.4368C11.7724 43.7851 12.2846 44.3234 12.8668 44.9354C14.0248 46.1526 15.337 47.5317 17.8799 47.5317C20.4224 47.5317 21.7349 46.1526 22.8926 44.9354C23.9092 43.8669 24.7124 43.0229 26.4596 43.0229C28.2071 43.0229 29.01 43.8669 30.0269 44.9354C31.1846 46.1526 32.4971 47.5317 35.0397 47.5317C37.5825 47.5317 38.895 46.1526 40.053 44.9354C41.0697 43.8669 41.8729 43.0229 43.6204 43.0229C45.3679 43.0229 46.1708 43.8669 47.1877 44.9354C48.0254 45.8161 48.9442 46.7817 50.3515 47.2482C51.3363 47.5746 52.2008 46.6906 52.2008 45.6531C52.2008 44.6155 51.3053 43.8449 50.3878 43.3606C49.728 43.0123 49.2157 42.474 48.6334 41.862Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default StressIcon
