import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Answer, MostWories, Question } from '../../../../types/Score'
import ChoiceOfAnswer from '../ChoiceOfAnswers'
import { worriesId, mostWorriesId } from '../../../../common/keyQuestion'

type Prop = {
  question: Question
  useStyles: any
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
  worries: MostWories[]
  mostWorries: MostWories
  setMostWorries: React.Dispatch<React.SetStateAction<MostWories>>
  //onSubmit: (event: any) => void
}

const PullDownSelect = (props: Prop) => {
  const { question, useStyles, answer, setAnswer, onMoveAnimation, worries, setMostWorries, mostWorries } = props
  const classes = useStyles()
  const [selectValue, setSelctValue] = useState<Number>(mostWorries ? worries.findIndex((c) => c === mostWorries) : 0)
  const onChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelctValue(Number(e.target.value) - 1)
    if (mostWorries !== '未選択') {
      answer.splice(
        answer.findIndex((c) => c.questionSortId === question.questionSortId),
        1
      )
    }
    const tempAnswer: Answer = {
      id: question.id,
      questionSortId: question.questionSortId,
      choiceId: Number(e.target.value) - 1
    }
    setAnswer((_) => [...answer, tempAnswer])
    onMoveAnimation(false)
    const tempMostValue = worries?.[Number(e.target.value)]
    setMostWorries(tempMostValue ? tempMostValue : '未選択')
  }
  return (
    <Box>
      <FormControl variant="outlined" className={classes.context}>
        <InputLabel id={String(question.questionSortId)} />
        <Hidden mdDown>
          <Select
            labelId={String(question.questionSortId)}
            id={String(question.questionSortId)}
            value={selectValue ? selectValue : 0}
            onChange={(e) => onChange(e)}
            name={String(question.questionSortId)}
            className={classes.selectForm}
          >
            {worries.map((x, i) => (
              <MenuItem key={i} value={i}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <Hidden lgUp>
          <Select
            labelId={String(question.questionSortId)}
            id={String(question.questionSortId)}
            value={selectValue ? selectValue : 0}
            onChange={(e) => onChange(e)}
            name={String(question.questionSortId)}
            className={classes.selectFormSP}
          >
            {worries.map((x, i) => (
              <MenuItem key={i} value={i}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
      </FormControl>
    </Box>
  )
}

export default PullDownSelect
