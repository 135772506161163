import React from 'react'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'
import { animated } from 'react-spring'
import TitleCard from '../diagnosis/TitleCard'
import { Question } from '../../../types/Score'
import AnsCard from '../diagnosis/AnsCard'
import { Answer, MostWories, WoriesObj, Allergie, Quetsions } from '../../../types/Score/index'
import { worriesId, allergieId } from '../../../common/keyQuestion'

type Props = {
  styleProps: any
  item: number
  height: string
  sectionLength: number
  idForDisplay: number
  question: Question
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (isReverse: boolean) => void
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  setWorries: React.Dispatch<React.SetStateAction<MostWories[]>>
  worries: MostWories[]
  mostWories: MostWories
  setMostWories: React.Dispatch<React.SetStateAction<MostWories>>
  allergie: Allergie[]
  setAllergie: React.Dispatch<React.SetStateAction<Allergie[]>>
  moveResult: (emai?: string) => void
  skipIds: number[]
  dispQuestionId: number
  setDispQuestionId: React.Dispatch<React.SetStateAction<number>>
  deleteSkipQuestions: Quetsions
  sectionId: number
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
}

const DiagnosisCard = (props: Props) => {
  const {
    styleProps,
    item,
    height,
    question,
    sectionLength,
    idForDisplay,
    answer,
    onMoveAnimation,
    name,
    setAnswer,
    setName,
    setWorries,
    worries,
    mostWories,
    setMostWories,
    allergie,
    setAllergie,
    moveResult,
    skipIds,
    dispQuestionId,
    setDispQuestionId,
    deleteSkipQuestions,
    sectionId,
    email,
    setEmail
  } = props

  //todo リファクタ案件　flex-start とflex:center の使い分け条件
  //お悩み選択だけスクロールが発生するものは"flex-start""
  const alignItemOption =
    worriesId === question.questionSortId || allergieId === question.questionSortId ? 'flesx-start' : 'center'

  const SP = () => {
    return (
      <animated.div style={{ ...styleProps, width: '100%' }}>
        <Box width="100%" height={height} bgcolor={'#fff'} display="flex" justifyContent="center">
          <Box
            width="300px"
            height={height}
            display="flex"
            justifyContent="center"
            alignItems={alignItemOption}
            overflow="scroll"
          >
            <Box>
              <Box width="300x" mt={alignItemOption === 'flesx-start' ? '100px' : '0px'}>
                <TitleCard
                  question={question}
                  sectionLen={sectionLength}
                  idForDisplay={idForDisplay}
                  skipQuestionIds={skipIds}
                  dispQuestionId={dispQuestionId}
                  setDispQuestionId={setDispQuestionId}
                  deleteSkipQuestions={deleteSkipQuestions}
                  sectionId={sectionId}
                />
              </Box>
              <Box width="300px" mt={7}>
                <AnsCard
                  question={question}
                  setAnswer={setAnswer}
                  setName={setName}
                  answer={answer}
                  onMoveAnimation={onMoveAnimation}
                  name={name}
                  worries={worries}
                  setWories={setWorries}
                  setMostWories={setMostWories}
                  mostWories={mostWories}
                  allergie={allergie}
                  setAllergie={setAllergie}
                  moveResult={moveResult}
                  email={email}
                  setEmail={setEmail}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </animated.div>
    )
  }
  const PC = () => {
    return (
      <animated.div style={{ ...styleProps, width: '100%' }}>
        <Box width="100%" height={height} bgcolor={'#fff'} display="flex" justifyContent="center" alignItems="center">
          <Box width="600px" height={height} display="flex" justifyContent="center" alignItems="center">
            <Box>
              <Box width="600px">
                <TitleCard
                  question={question}
                  sectionLen={sectionLength}
                  idForDisplay={idForDisplay}
                  skipQuestionIds={skipIds}
                  dispQuestionId={dispQuestionId}
                  setDispQuestionId={setDispQuestionId}
                  deleteSkipQuestions={deleteSkipQuestions}
                  sectionId={sectionId}
                />
              </Box>
              <Box width="600px" mt={5}>
                <AnsCard
                  question={question}
                  setAnswer={setAnswer}
                  setName={setName}
                  answer={answer}
                  onMoveAnimation={onMoveAnimation}
                  name={name}
                  worries={worries}
                  setWories={setWorries}
                  setMostWories={setMostWories}
                  mostWories={mostWories}
                  allergie={allergie}
                  setAllergie={setAllergie}
                  moveResult={moveResult}
                  email={email}
                  setEmail={setEmail}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </animated.div>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <Box>
          <SP />
        </Box>
      </Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </>
  )
}

export default DiagnosisCard
