import * as styledComponents from 'styled-components'
import { ITheme } from './Variables'
const {
    default: styled,
    css,
    keyframes,
    ThemeProvider,
    createGlobalStyle
  } = styledComponents as styledComponents.ThemedStyledComponentsModule<ITheme>

export default styled
export { css, keyframes, ThemeProvider, createGlobalStyle }