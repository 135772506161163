import React, { useState, useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { Question } from '../../../../types/Score'
import TextField from '@material-ui/core/TextField'
import { Input, FormControl } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Answer, MostWories, WoriesObj } from '../../../../types/Score/index'
import MoveButton from '../../diagnosis/MoveButton'

type Props = {
  question: Question
  answer: Answer[]
  onMoveAnimation: (isReverse: boolean) => void
  diagnosisName: string
  setDiagnosName: React.Dispatch<React.SetStateAction<string>>
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        border: '2px solid #041E42',
        '& input+ fieldset': {
          border: '0px solid #fff'
        },
        '& input:invalid + fieldset': {
          border: '0px solid #fff'
        },
        '& input:valid:focus + fieldset': {
          border: '0px solid #fff'
        },
        '& input:valid:hover+ fieldset': {
          border: '0px solid #fff'
        }
      }
    }
  })
)

const TextFiled = (props: Props) => {
  const { question, answer, onMoveAnimation, diagnosisName, setDiagnosName, setAnswer } = props
  const [name, setName] = useState(diagnosisName)
  const onNameChange = (event: any) => {
    setName(event.target.value)
  }
  const nameValidationOK = name.length >= 2 && name.match(/^(\w| |'|,|&)+$/) && name.length <= 10
  const classes = useStyles()
  const setNameFunction = () => {
    setDiagnosName(name)
  }

  /*Atention! 
    ===TextField===
    本体内のreturnで使用=>●　
    本体内で定義したコンポーネントでの使用=>︎✖
    ===*/
  return (
    <>
      <Hidden lgUp>
        <Box>
          <FormControl>
            <TextField
              className={classes.root}
              value={name}
              onChange={(e) => {
                onNameChange(e)
              }}
              margin={'none'}
              onKeyDown={(e) => {}}
              placeholder={'ローマ字で入力して下さい'}
              helperText={
                name.length > 10
                  ? '10文字まで'
                  : name.length === 0
                  ? ''
                  : !name.match(/^(\w| |'|,|&)+$/)
                  ? 'ローマ字で入力して下さい'
                  : ''
              }
              variant="outlined"
              style={{
                width: '270px'
              }}
            />

            <Box mt={0}>
              <MoveButton
                disable={!nameValidationOK}
                answers={answer}
                setAnswer={setAnswer}
                onMoveAnimation={onMoveAnimation}
                question={question}
                anyFunction={setNameFunction}
              />
            </Box>
          </FormControl>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box height="54px">
          {/* <form className={classes.root} style={{ outlineColor: '#fff' }} action={''}> */}
          <TextField
            className={classes.root}
            value={name}
            onChange={(e) => {
              onNameChange(e)
            }}
            margin={'none'}
            onKeyDown={(_) => {}}
            placeholder={'ローマ字で入力して下さい'}
            helperText={
              name.length > 10
                ? '10文字まで'
                : name.length === 0
                ? ''
                : !name.match(/^(\w| |'|,|&)+$/)
                ? 'ローマ字で入力して下さい'
                : ''
            }
            variant="outlined"
            style={{
              width: '350px'
            }}
          />
          {/* </form> */}
          <Box mt={6} overflow={'visible'} zIndex="999">
            <MoveButton
              disable={!nameValidationOK}
              answers={answer}
              setAnswer={setAnswer}
              onMoveAnimation={onMoveAnimation}
              question={question}
              anyFunction={setNameFunction}
            />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default TextFiled
