import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplements/Recovery/SP_TOP_BG.png'
const pcTopBgURL: string = '../supplements/Recovery/PC_PRODUCT_BG.png'
const SpTopBgURL: string = '../supplements/Recovery/SP_PRODUCT_BG.png'
const title = 'RECOVERY'
const sub_title = 'May the Force be with you'
const text =
  '頑張れば頑張るほど、消耗していくあなたの体。加えて、忙しい日々のなかで、体のケアをするのは難しいことです。Recoveryは、抗疲労や疲労回復に関する成分を贅沢に配合しました。あなたの頑張りを応援するサプリメント、Recovery。'
const containtext =
  'Recoveryは、元気サポートのイミダゾールジペプチドやコエンザイムQ10をベースに、ビタミンB群やオレアビータ、HMBカルシウムなど成分を配合したサプリメントです。仕事で忙しかった１日をリセットしたい時にご利用ください。'

const product_name = 'RECOVERY'
const product_amount =
  'イミダゾールジペプチド：12mg、コエンザイムQ10：24mg、HMB：16mg、オレアノール酸：0.3mg、オレウロペイン：0.3mg、チアミン：0.5mg、リボフラビン：0.8mg、ピリドキシン：0.8mg、シアノコバラミン：2.5μg、ナイアシン：6.1mg、パントテン酸：3.4mg'
const product_materials =
  'AC-50（鶏胸抽出物）、コエンザイムQ10、HMBCa、オリーブ葉抽出物（オレアビータ®）、ビタミンB1、ビタミンB2、ビタミンB6、ビタミンB12（0.1％粉末）、ナイアシン、パントテン酸Ca、加工デンプン、ステアリン酸Ca、微粒二酸化ケイ素、HPMC、カラメル色素、水'
const product_allergy = '鶏肉'
const product_agent_type = '../hard.png'
const productBgSp = '../supplements/Recovery/SP_SPM.png'
const productBgPc = '../supplements/Recovery/PC_SPM.png'

const evidences: EvidenceProps = [
  {
    title: 'HMB',
    sub_title: '-  筋損傷の回復をサポート',
    text:
      'HMBとは、必須アミノ酸の一種であるロイシンから体内で合成される物質です。経口摂取することで筋肉の合成促進と分解抑制の効果が期待されており、近年注目を集めています。筋肉をつけたい方から、若々しい身体をキープしたい人まで広く支持されています。論文によると「18-29歳の健康な男性20人を対象とした臨床試験において、3gのHMBカルシウムを3回に分けて（昼食の30分前、運動の30分前、夕食の30分前に1gずつ）摂取したグループは、プラセボを投与されたグループと比較して筋損傷の回復を大幅に軽減するとともに、筋損傷が発生した際に上昇する血中クレアチンキナーゼの数値を減少させた。」と示されています。',
    thesis:
      'β-Hydroxy-β-methylbutyrate free acid reduces markers of exercise-induced muscle damage and improves recovery in resistance-trained men',
    magazine: 'British Journal of Nutrition',
    Impact_Factor: '3.302',
    Impact_Factor_Year: '2012',
    publish: '2013',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/23286834',
    total_quote: '81',
    exists: 'あり'
  },
  {
    title: 'コエンザイムQ10',
    sub_title: '-  疲労回復をサポート',
    text:
      'コエンザイムQ10は、体内のあらゆる細胞に存在する、生命活動に不可欠な補酵素です。20代をピークに体内での生成量は減少するとされていますが、経口摂取でも補給することが可能です。論文によると「17人の健康な被験者を2群に分けて、コエンザイムQ10（100または300 mg）またはプラセボを8日間投与しながら、両群は1日2時間のエアロバイクでの運動を行った。その結果、コエンザイムQ10を投与された群はそうでない群と比較して運動後の身体的疲労を有意に改善した。」と示されています。',
    thesis: 'Antifatigue effects of coenzyme Q10 during physical fatigue',
    magazine: 'Nutrition',
    Impact_Factor: '3.42',
    Impact_Factor_Year: '2016',
    publish: '2008',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/18272335',
    total_quote: '202',
    exists: 'あり'
  },
  {
    title: 'イミダゾールジペプチド',
    sub_title: '-  疲労回復をサポート',
    text:
      'イミダゾールジペプチドは、疲労の元である、活性酸素を抑える作用があるとされています。長い距離を飛ぶ渡り鳥や水中を休まず泳ぎ続ける回遊魚のスタミナ源ともされており、近年注目されている成分です。論文によると「6週齢のマウス20匹を2群に分け、対照群には注射用水、試験群にはアンセリン塩酸塩の水溶液を経口投与した。 その１時間後に運動機能をテストした結果、遊泳時間や懸垂時間の延長が確認された他、試験群では筋疲労の指標となる乳酸値の増加が抑制されていた（p<0.05）ことから、アンセリンには運動に伴う筋肉疲労の軽減効果があると考えられる。」と示されています。',
    thesis: '魚肉ペプチドの抗疲労効果',
    magazine: '「魚肉ペプチドの抗疲労効果」FOOD Style 21',
    Impact_Factor: '-',
    Impact_Factor_Year: '-',
    publish: '2001',
    link: 'https://www.yskf.jp/research/anserine02.html',
    total_quote: '-',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeRecovery = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="../supplements/Recovery/PC_TOP_BG.png" width="320px" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeRecovery
