import React, { useState, useEffect } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBox from '@material-ui/core/Checkbox'

import AgingIcon from '../../icon/Leaf'
import ConcentrationIcon from '../../icon/ConcentrationIcon'
import DepressionIcon from '../../icon/DepressionIcon'
import DietIcon from '../../icon/DietIcon'
import EyeIcon from '../../icon/EyeIcon'
import FatigueIcon from '../../icon/FatigueIcon'
import MemoryIcon from '../../icon/MemoryIcon'
import MotionIcon from '../../icon/MotionIcon'
import PhysicalConditionIcon from '../../icon/PhysicalConditionIcon'
import SleepIcon from '../../icon/SleepIcon'
import StressIcon from '../../icon/StressIcon'
import VitalityIcon from '../../icon/Fire'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import SpCard from '../result/SupplementCard'

type IconCheckBoxProps = {
  question: any
  choices: string[]
  setChoices: (event: any) => void
  setIsChoice: (event: any) => void
}

type Icons = JSX.Element[]

const ingredient: (
  | 'Vitamin'
  | 'Mineral'
  | 'Omega3'
  | 'Recovery'
  | 'Chill'
  | 'Zone'
  | 'Memory'
  | 'Vitality'
  | 'Style'
)[] = ['Vitamin', 'Mineral', 'Omega3', 'Recovery', 'Chill']

const pcSpSets: {
  id: string
  sps: ('Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Chill' | 'Zone' | 'Memory' | 'Vitality' | 'Style')[]
}[] = [
  { id: 'relax', sps: ['Vitamin', 'Mineral', 'Omega3', 'Chill', 'Memory'] },
  { id: 'diet', sps: ['Vitamin', 'Mineral', 'Omega3', 'Recovery', 'Style'] },
  { id: 'memory', sps: ['Vitamin', 'Mineral', 'Omega3', 'Zone', 'Vitality'] },
  { id: 'care', sps: ['Vitamin', 'Mineral', 'Omega3', 'Chill', 'Recovery'] },
  { id: 'focus', sps: ['Vitamin', 'Mineral', 'Omega3', 'Zone', 'Memory'] }
]

const spSpSets: {
  id: string
  sps: ('Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Chill' | 'Zone' | 'Memory' | 'Vitality' | 'Style')[]
}[] = [
  { id: 'relax', sps: ['Omega3', 'Mineral', 'Vitamin', 'Chill', 'Memory'] },
  { id: 'diet', sps: ['Omega3', 'Mineral', 'Vitamin', 'Recovery', 'Style'] },
  { id: 'memory', sps: ['Omega3', 'Mineral', 'Vitamin', 'Zone', 'Vitality'] },
  { id: 'care', sps: ['Omega3', 'Mineral', 'Vitamin', 'Chill', 'Recovery'] },
  { id: 'focus', sps: ['Omega3', 'Mineral', 'Vitamin', 'Zone', 'Memory'] }
]

const SpCards = (props: {
  drowOpen: (
    name: 'Chill' | 'Vitamin' | 'Mineral' | 'Omega3' | 'Recovery' | 'Zone' | 'Memory' | 'Vitality' | 'Style'
  ) => void
  spType: string
}) => {
  const { drowOpen, spType } = props
  const pcSpSet = pcSpSets.filter((c) => c.id == spType)[0]
  const spSpSet = spSpSets.filter((c) => c.id == spType)[0]
  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box width="sp100%" display="flex" justifyContent="center" mt={5}>
          <Box width="900px" height="833px">
            <Grid
              container
              spacing={3}
              alignContent="space-between"
              justify="center"
              direction="row"
              wrap="wrap-reverse"
            >
              {pcSpSet.sps.map((c, i) => {
                return (
                  <Grid item>
                    <SpCard ingredient={c} DetailDrowOpen={drowOpen} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box width="100vw" mt={5} overflow={'scroll'} style={{ msOverflowStyle: 'none' }}>
          <Box display="flex">
            {spSpSet.sps
              .slice()
              .reverse()
              .map((c, i) => {
                return (
                  <Box mt={5} mb={5} px={2} ml={2}>
                    <SpCard ingredient={c} DetailDrowOpen={drowOpen} />
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SpCards
