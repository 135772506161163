import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Styled from 'styled-components'
import Text from '../diagnosis/util/Text'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useSpring, animated } from 'react-spring'
import { Keyframes, config, animated as renderAnime } from 'react-spring/renderprops'
import delay from 'delay'

type Props = {
  isHidden: boolean
  start: boolean
  isSP: boolean
  sectionNumber: number
  setStartAnimation: React.Dispatch<React.SetStateAction<boolean>>
}
const moveResultWait = 30

const SectionAnimation = (props: Props) => {
  const { isHidden, start, setStartAnimation, isSP, sectionNumber } = props
  const focusSecction: number = sectionNumber
  const smallCiceleSize: number = isSP ? 17 : 20
  const lageCiceleSize: number = isSP ? 35 : 40
  const addUnit = (num: number): string => {
    return String(num) + 'px'
  }
  const Parent: any = Keyframes.Spring(async (next: any) => {
    await delay(100)
    await next({
      to: {
        opacity: 0,
        backgroundColor: '#fff'
      }
    })
    await delay(300)
    await next({
      to: {
        opacity: 1,
        backgroundColor: '#fff'
      }
    })
    await delay(5500)
    await next({
      to: {
        opacity: 0,
        backgroundColor: '#fff'
      }
    })
    await delay(100)
    setStartAnimation((_) => false)
  })

  const animationProps = useSpring({
    delay: 2000,
    reset: true,
    to: start ? { width: isSP ? '55px' : '100px', height: '100px' } : { width: '300px', height: '100px' },
    from: { width: '300px', height: '100px' }
  })
  const textShowProps = useSpring({
    delay: 2000,
    reset: true,
    to: start ? { opacity: 0 } : { opacity: 1 },
    from: { opacity: 1 }
  })

  const focusTextShowProps = useSpring({
    delay: 2000,
    reset: true,
    to: { opacity: 1 },
    from: { opacity: 1 }
  })

  const FocusCircle: any = Keyframes.Spring(async (next: any) => {
    await next({
      to: {
        width: addUnit(smallCiceleSize),
        height: addUnit(smallCiceleSize),
        borderRadius: '50%',
        border: '1px solid #041E42'
      }
    })
    await delay(2500)
    await next({
      to: {
        width: addUnit(lageCiceleSize),
        height: addUnit(lageCiceleSize),
        borderRadius: '50%',
        border: '3px solid #041E42'
      }
    })
    await delay(1000)
    await next({
      to: {
        width: addUnit(smallCiceleSize),
        height: addUnit(smallCiceleSize),
        borderRadius: '50%',
        backgroundColor: '#041E42',
        border: '3px solid #041E42'
      }
    })
  })

  const Circle: any = Keyframes.Spring(async (next: any) => {
    await next({
      to: {
        width: addUnit(smallCiceleSize),
        height: addUnit(smallCiceleSize),
        borderRadius: '50%',
        border: '1px solid #041E42'
      }
    })
  })

  return (
    <>
      <Hidden mdDown>
        <Box width="100%" height="100vh" position="absolute" zIndex={1000} display={isHidden ? 'none' : 'flex'}>
          <Parent native>
            {(bodyProps: any) => {
              return (
                <renderAnime.div
                  style={{
                    background: 'yellow',
                    width: '100%',
                    height: '100vh',
                    ...bodyProps
                  }}
                >
                  <animated.div style={{ backgroundColor: 'skyblue', ...bodyProps }}>
                    <Box
                      width="100%"
                      height="100vh"
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                      // bgcolor="white"
                      position="fixed"
                      top={0}
                      left={0}
                      zIndex={1001}
                    >
                      <animated.div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100px',
                          height: '100px',
                          ...animationProps
                        }}
                      >
                        <Box
                          width="100px"
                          height="100px"
                          display="flex"
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Box height="100px">
                            <Box
                              height="60px"
                              display="flex"
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                            >
                              {focusSecction === 1 ? (
                                <FocusCircle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </FocusCircle>
                              ) : (
                                <Circle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </Circle>
                              )}
                            </Box>
                            <Box height="20px">
                              <animated.div style={focusSecction === 1 ? focusTextShowProps : textShowProps}>
                                <Text
                                  fw={'bold'}
                                  fs={14}
                                  lh={'140%'}
                                  ls={'0.1em'}
                                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                >
                                  基本情報
                                </Text>
                              </animated.div>
                            </Box>
                          </Box>
                        </Box>
                      </animated.div>
                      <animated.div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100px',
                          height: '100px',
                          ...animationProps
                        }}
                      >
                        <Box
                          width="100px"
                          height="100px"
                          display="flex"
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Box height="100px">
                            <Box
                              height="60px"
                              display="flex"
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                            >
                              {focusSecction === 2 ? (
                                <FocusCircle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </FocusCircle>
                              ) : (
                                <Circle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </Circle>
                              )}
                            </Box>
                            <Box height="20px">
                              <animated.div style={focusSecction === 2 ? focusTextShowProps : textShowProps}>
                                <Text
                                  fw={'bold'}
                                  fs={14}
                                  lh={'140%'}
                                  ls={'0.1em'}
                                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                >
                                  お悩み
                                </Text>
                              </animated.div>
                            </Box>
                          </Box>
                        </Box>
                      </animated.div>
                      <animated.div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100px',
                          height: '100px',
                          ...animationProps
                        }}
                      >
                        <Box
                          width="100px"
                          height="100px"
                          display="flex"
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Box height="100px">
                            <Box
                              height="60px"
                              display="flex"
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                            >
                              {focusSecction === 3 ? (
                                <FocusCircle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </FocusCircle>
                              ) : (
                                <Circle native>
                                  {(props: any) => {
                                    return (
                                      <renderAnime.div
                                        style={{
                                          ...props,
                                          margin: 'auto'
                                        }}
                                      />
                                    )
                                  }}
                                </Circle>
                              )}
                            </Box>
                            <Box height="20px">
                              <animated.div style={focusSecction === 3 ? focusTextShowProps : textShowProps}>
                                <Text
                                  fw={'bold'}
                                  fs={14}
                                  lh={'140%'}
                                  ls={'0.1em'}
                                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                >
                                  生活習慣
                                </Text>
                              </animated.div>
                            </Box>
                          </Box>
                        </Box>
                      </animated.div>
                    </Box>
                  </animated.div>
                </renderAnime.div>
              )
            }}
          </Parent>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box width="100%" height="100vh" position="absolute" zIndex={1000} display={isHidden ? 'none' : 'flex'}>
          <Parent native>
            {(bodyProps: any) => {
              return (
                <renderAnime.div
                  style={{
                    background: 'yellow',
                    width: '100%',
                    height: '100vh',
                    ...bodyProps
                  }}
                >
                  <Box
                    width="100%"
                    height="100vh"
                    display={isHidden ? 'none' : 'flex'}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    //bgcolor="white"
                    position="fixed"
                    top={0}
                    left={0}
                    zIndex={1000}
                  >
                    <animated.div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100px',
                        height: '100px',
                        ...animationProps
                      }}
                    >
                      <Box
                        width="100px"
                        height="100px"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                      >
                        <Box height="100px">
                          <Box
                            height="60px"
                            display="flex"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                          >
                            {focusSecction === 1 ? (
                              <FocusCircle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </FocusCircle>
                            ) : (
                              <Circle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </Circle>
                            )}
                          </Box>
                          <Box height="20px">
                            <animated.div style={focusSecction === 1 ? focusTextShowProps : textShowProps}>
                              <Text
                                fw={'bold'}
                                fs={12}
                                lh={'140%'}
                                ls={'0.1em'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                              >
                                基本情報
                              </Text>
                            </animated.div>
                          </Box>
                        </Box>
                      </Box>
                    </animated.div>
                    <animated.div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100px',
                        height: '100px',
                        ...animationProps
                      }}
                    >
                      <Box
                        width="100px"
                        height="100px"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                      >
                        <Box height="100px">
                          <Box
                            height="60px"
                            display="flex"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                          >
                            {focusSecction === 2 ? (
                              <FocusCircle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </FocusCircle>
                            ) : (
                              <Circle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </Circle>
                            )}
                          </Box>
                          <Box height="20px">
                            <animated.div style={focusSecction === 2 ? focusTextShowProps : textShowProps}>
                              <Text
                                fw={'bold'}
                                fs={12}
                                lh={'140%'}
                                ls={'0.1em'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                              >
                                お悩み
                              </Text>
                            </animated.div>
                          </Box>
                        </Box>
                      </Box>
                    </animated.div>
                    <animated.div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100px',
                        height: '100px',
                        ...animationProps
                      }}
                    >
                      <Box
                        width="100px"
                        height="100px"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                      >
                        <Box height="100px">
                          <Box
                            height="60px"
                            display="flex"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                          >
                            {focusSecction === 3 ? (
                              <FocusCircle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </FocusCircle>
                            ) : (
                              <Circle native>
                                {(props: any) => {
                                  return (
                                    <renderAnime.div
                                      style={{
                                        ...props,
                                        margin: 'auto'
                                      }}
                                    />
                                  )
                                }}
                              </Circle>
                            )}
                          </Box>
                          <Box height="20px">
                            <animated.div style={focusSecction === 3 ? focusTextShowProps : textShowProps}>
                              <Text
                                fw={'bold'}
                                fs={12}
                                lh={'140%'}
                                ls={'0.1em'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                              >
                                生活習慣
                              </Text>
                            </animated.div>
                          </Box>
                        </Box>
                      </Box>
                    </animated.div>
                  </Box>
                </renderAnime.div>
              )
            }}
          </Parent>
        </Box>
      </Hidden>
    </>
  )
}

export default SectionAnimation
