import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { animated } from 'react-spring'
import { Question, Quetsions } from '../../../types/Score'
import { nl2br } from '../../../function'
import { questions } from '../../../common/question'

// import { lastQuestionId, worriesId, figuaId } from '../../../common/keyQuestion'

// import Text from './util/Text'

// import ChoiceOfAnswer from './ChoiceOfAnswers'
// import IconCheckBox from './IconCheckBox'
// import WorriedCheckBox from './WorriedCheckBox'
// import AllergiesCheckBox from './AllergiesCheckBox'
// import QuestionText from './question/common/QuestionText'
// import NextButton from './question/common/NextButton'
import Text from './util/Text'
import deleteSkipQuestion from './util/diagnosisUtil'

type Props = {
  deleteSkipQuestions: Quetsions
  question: Question
  sectionLen: number
  idForDisplay: number
  skipQuestionIds: number[]
  dispQuestionId: number
  sectionId: number
  setDispQuestionId: React.Dispatch<React.SetStateAction<number>>
}

const TitleCard = (props: Props) => {
  const {
    question,
    sectionLen,
    idForDisplay,
    skipQuestionIds,
    dispQuestionId,
    setDispQuestionId,
    sectionId,
    deleteSkipQuestions
  } = props
  const sectionQuestion = deleteSkipQuestions.filter((c) => c.sectionNumber === sectionId)
  const dispId = sectionQuestion.findIndex((c) => c.questionSortId === question.questionSortId) + 1
  const SP = () => {
    return (
      <>
        <Box width="100%" height="120px" justifyContent="center" alignItems="space-between">
          <Box>
            <Text fw="bold" fs={16} lh={'24px'} ls={'0.1em'}>
              Q{dispId}/{sectionLen}
            </Text>
            <img width="32px" height="32px" style={{ marginTop: '16px' }} src={'diagnosisIcon/' + question.iConName} />
            <Text fw="bold" fs={16} lh={'24px'} ls={'0.1em'} style={{ wordBreak: 'break-all', marginTop: '16px' }}>
              {nl2br(question.spQuestionString)}
            </Text>
          </Box>
        </Box>
      </>
    )
  }
  const PC = () => {
    return (
      <>
        <Box width="100%" height="120px" display="flex" justifyContent="center" alignItems="space-between">
          <Box>
            <Text fw="bold" fs={16} lh={'24px'} ls={'0.1em'}>
              Q{dispId}/{sectionLen}
            </Text>
            <img width="40px" height="40px" style={{ marginTop: '16px' }} src={'diagnosisIcon/' + question.iConName} />
            <Text fw="bold" fs={16} lh={'24px'} ls={'0.1em'} style={{ wordBreak: 'break-all', marginTop: '16px' }}>
              {nl2br(question.questionString)}
            </Text>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <SP />
      </Hidden>
      <Hidden mdDown>
        <PC />
      </Hidden>
    </>
  )
}

const getSeqtionIds = (seqtionId: number, skipIds: number[]): number => {
  const ids: number[] = []
  const q: Quetsions = questions
  const skipQ: Question[] = q.filter((c) => c.sectionNumber === seqtionId)
  skipQ.map((c) => {
    if (skipIds.includes(c.questionSortId)) {
      ids.push(c.questionSortId)
    }
  })
  return ids.length
}

export default TitleCard
