import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import MilkIcon from '../../icon/MilkIcon'
import NoneIcon from '../../icon/NoneIcon'
import GelatinIcon from '../../icon/GelatinIcon'
import ChickenIcon from '../../icon/ChickenIcon'
import PhosphatidylserineIcon from '../../icon/PhosphatidylserineIcon'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'

type AllergiesCheckBoxProps = {
  question: any
  allergies: {
    none: boolean
    milk: boolean
    gelatin: boolean
    chicken: boolean
    phosphatidylserine: boolean
  }
  setAllergies: (event: any) => void
}

type Icons = JSX.Element[]

const icons: Icons = [<NoneIcon />, <MilkIcon />, <GelatinIcon />, <ChickenIcon />, <PhosphatidylserineIcon />]
const iconsSp: Icons = [<NoneIcon />, <MilkIcon sp />, <GelatinIcon />, <ChickenIcon />, <PhosphatidylserineIcon />]

const checkedIcons: Icons = [
  <NoneIcon color={'#00D4A1'} />,
  <MilkIcon color={'#00D4A1'} />,
  <GelatinIcon color={'#00D4A1'} />,
  <ChickenIcon color={'#00D4A1'} />,
  <PhosphatidylserineIcon color={'#00D4A1'} />
]

const checkedIconsSp: Icons = [
  <NoneIcon color={'#00D4A1'} />,
  <MilkIcon color={'#00D4A1'} sp />,
  <GelatinIcon color={'#00D4A1'} />,
  <ChickenIcon color={'#00D4A1'} />,
  <PhosphatidylserineIcon color={'#00D4A1'} />
]

const arrayChunk = ([...array], size = 1) => {
  return array.reduce((acc, _, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]), [])
}

const AllergiesCheckBox = (props: AllergiesCheckBoxProps) => {
  const { allergies, setAllergies } = props
  const onAllergiesChange = (event: any) => {
    setAllergies({ ...allergies, [event.target.name]: event.target.checked })
  }
  const titles = ['なし', '乳糖', 'ゼラチン', '鶏肉', 'ホスファチジルセリン']
  const sepaleteTitle = arrayChunk(titles, 3)
  const sepalateIcon = arrayChunk(icons, 3)
  const sepalateCheckedIcon = arrayChunk(checkedIcons, 3)
  const sepalateIconSp = arrayChunk(iconsSp, 3)
  const sepalateCheckedIconSp = arrayChunk(checkedIconsSp, 3)
  const sepaleteCheckBoxName = arrayChunk(Object.keys(allergies), 3)
  const sepaleteCheckBoxValue = arrayChunk(Object.values(allergies), 3)
  // 一旦、mapしないでも個別にいじれるコンポーネントをとっておく
  // const { wheat, milk, egg, soba, shrimp, crab, peanuts } = allergies;
  // const FormRow = (props: { sp?: boolean }) => {
  //   const { sp } = props;
  //   return (
  //     <>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={wheat}
  //           onChange={onAllergiesChange}
  //           name="wheat"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>小麦</CheckBoxText>
  //       </CheckBoxBlock>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={milk}
  //           onChange={onAllergiesChange}
  //           name="milk"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>乳</CheckBoxText>
  //       </CheckBoxBlock>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={egg}
  //           onChange={onAllergiesChange}
  //           name="egg"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>卵</CheckBoxText>
  //       </CheckBoxBlock>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={soba}
  //           onChange={onAllergiesChange}
  //           name="soba"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>そば</CheckBoxText>
  //       </CheckBoxBlock>
  //     </>
  //   );
  // };
  // const FormRow2 = (props: { sp?: boolean }) => {
  //   const { sp } = props;
  //   return (
  //     <>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={shrimp}
  //           onChange={onAllergiesChange}
  //           name="shrimp"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>えび</CheckBoxText>
  //       </CheckBoxBlock>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={crab}
  //           onChange={onAllergiesChange}
  //           name="crab"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>かに</CheckBoxText>
  //       </CheckBoxBlock>
  //       <CheckBoxBlock>
  //         <Checkbox
  //           checked={peanuts}
  //           onChange={onAllergiesChange}
  //           name="peanuts"
  //           icon={<MilkIcon sp={sp} />}
  //           checkedIcon={<MilkIcon checked sp={sp} />}
  //         />
  //         <br />
  //         <CheckBoxText>落花生</CheckBoxText>
  //       </CheckBoxBlock>
  //     </>
  //   );
  // };
  return (
    <Box marginBottom={0}>
      <Hidden mdDown>
        <Box mt={'4%'}>
          <BlockBox>
            {[1, 2].map((_, i) => (
              <FlexBox>
                <FormRows
                  names={sepaleteCheckBoxName[i]}
                  labels={sepaleteTitle[i]}
                  values={sepaleteCheckBoxValue[i]}
                  icons={sepalateIcon[i]}
                  checkedIcons={sepalateCheckedIcon[i]}
                  handleChange={onAllergiesChange}
                />
              </FlexBox>
            ))}
          </BlockBox>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <BlockBox>
          {[1, 2].map((_, i) => (
            <FlexBox>
              <FormRows
                names={sepaleteCheckBoxName[i]}
                labels={sepaleteTitle[i]}
                values={sepaleteCheckBoxValue[i]}
                icons={sepalateIconSp[i]}
                checkedIcons={sepalateCheckedIconSp[i]}
                handleChange={onAllergiesChange}
                sp
              />
            </FlexBox>
          ))}
        </BlockBox>
      </Hidden>
    </Box>
  )
}
type FormRowProps = {
  names: string[]
  values: boolean[]
  labels: string[]
  icons: JSX.Element[]
  checkedIcons: JSX.Element[]
  sp?: boolean
  handleChange: (event: any) => void
}
const FormRows: React.FC<FormRowProps> = ({ names, values, labels, icons, checkedIcons, sp, handleChange }) => {
  return (
    <>
      {names.map((name: string, i: number) => (
        <CheckBoxBlock sp={sp ? true : false}>
          <Checkbox
            checked={values[i]}
            onChange={handleChange}
            name={name}
            icon={icons[i]}
            checkedIcon={checkedIcons[i]}
          />
          <br />
          <CheckBoxText sp={sp ? true : false}>{labels[i]}</CheckBoxText>
        </CheckBoxBlock>
      ))}
    </>
  )
}
const BlockBox = (props: { children: React.ReactNode }) => (
  <Box textAlign="center" alignItems="center" justifyContent="center" display={'inline-block'}>
    {props.children}
  </Box>
)

const FlexBox = (props: { children: React.ReactNode }) => (
  <Box textAlign="center" alignItems="center" justifyContent="center" display={'flex'}>
    {props.children}
  </Box>
)
const CheckBoxBlock = (props: { children: React.ReactNode; sp: boolean }) => (
  <Box display="inline-block" alignItems="center" ml={props.sp ? 1 : 3} mr={props.sp ? 1 : 3}>
    {props.children}
  </Box>
)

const CheckBoxText = (props: { children: React.ReactChild; sp: boolean }) => (
  <Box
    component="p"
    style={{
      fontWeight: 'bold',
      fontSize: !props.sp ? '14px' : '10px',
      lineHeight: '140%',
      margin: '0'
    }}
  >
    {props.children}
  </Box>
)

export default AllergiesCheckBox
