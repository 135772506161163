import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = 'supplementDetail/Zone/SP_TOP_BG.png' // "../supplementDetail/Style/SP_SPM.png";
const pcTopBgURL: string = '../supplementDetail/Zone/PC_PRODUCT_BG.png' // "../supplementDetail/Style/PC_TOP_BG.png";
const SpTopBgURL: string = '../supplementDetail/Zone/SP_PRODUCT_BG.png' //"../supplementDetail/Style/SP_TOP_BG.png";
const title = 'ZONE'
const sub_title = 'If you can dream it, you can do it. Genius is one % inspiration and ninety-nine % perspiration.'
const text =
  '集中を持続させることが難しいと言われている現代。 Zoneは、カフェインをはじめ、集中に関わる成分を贅沢に配合しています。日々高いパフォーマンスを求めるビジネスアスリートのために。 あなたの力を最大化させる、Zone。 '
const containtext =
  'Zoneは、集中サポート成分として知られているカフェインに加えて、カフェインの副作用を抑えることで知られているテアニンを配合したサプリメントです。 もうひと踏ん張りが必要な時に、ご利用ください。'
const product_name = 'ZONE'
const product_amount = 'バコサイド：3.0mg、L-テアニン：99mg、カフェイン：50mg'
const product_materials =
  'バコパモニエラエキス末、L-フェニルアラニン、L-テアニン、カフェイン（抽出物）、加工デンプン、セルロース、ステアリン酸Ca、微粒二酸化ケイ素　HPMC、着色料（酸化チタン）、水'
const product_allergy = 'なし'
const product_agent_type = '../hard.png'
const productBgSp = '../supplementDetail/Zone/SP_SPM.png' // "../supplementDetail/Style/SP_PRODUCT_BG.png";
const productBgPc = '../supplementDetail/Zone/PC_SPM.png' // "../supplementDetail/Style/PC_PRODUCT_BG.png";
const evidences: EvidenceProps = [
  {
    title: 'カフェイン＆テアニン',
    sub_title: '-  集中をサポート',
    text:
      'ZONEに含まれているカフェインはコーヒーなどに多く含まれている成分で、覚醒作用があると広く知られています。一方、テアニンには脳内でα波を増加させることでリラックス作用があると言われています。論文によると「54人を対象としたプラセボ比較試験において、50mgのカフェインと100mgのテアニンを摂取したグループは、プラセボを投与されたグループと比較して、マルチタスクの処理速度と正確性の両方を改善した他、集中力を維持する効果が認められた。これは、50mgのカフェインを単体で摂取した時よりも高いスコアだった。」と示されています。',
    thesis: 'The combined effects of L-theanine and caffeine on cognitive performance and mood',
    magazine: 'Nutritional Neuroscience',
    Impact_Factor: '3.765',
    Impact_Factor_Year: '2016',
    publish: '2008',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/18681988',
    total_quote: '162',
    exists: 'あり'
  },
  {
    title: 'バコパモニエラ',
    sub_title: '-  集中サポート',
    text:
      'バコパは、ヨーロッパ、北アフリカ、アジア、南北アメリカに分布するオオバコ科に分類される植物の一種です。インドの伝統医学であるアーユルヴェーダにおいて古くから活用されており、脳機能全般や抗不安との関連性が示されています。論文によると「48人の非認知症患者を対象とした二重盲検ランダム化比較試験において、300mg/dayのバコパエキスを12週間摂取したグループは、プラセボを投与されたグループと比較して、集中力及びワーキングメモリが顕著に改善した。」と示されています。',
    thesis:
      'Effects of a standardized Bacopa monnieri extract on cognitive performance, anxiety, and depression in the elderly: a randomized, double-blind, placebo-controlled trial',
    magazine: 'Journal of Alternative and Complementary Medicine',
    Impact_Factor: '1.395',
    Impact_Factor_Year: '2015',
    publish: '2008',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/18611150',
    total_quote: '239',
    exists: 'あり'
  },
  {
    title: 'バコパモニエラ',
    sub_title: '-  不安をサポート',
    text:
      '論文によると「46人の健康な男女を対象とした二重盲検ランダム化比較試験において、300mg/dayのバコパエキスを最大24週間摂取したグループは、プラセボを投与されたグループと比較して、不安を改善した。」と示されています。',
    thesis: 'Summary of Bacopa monnieri',
    magazine: '-',
    Impact_Factor: '-',
    Impact_Factor_Year: '-',
    publish: '-',
    link: 'https://examine.com/supplements/bacopa-monnieri/',
    total_quote: '-',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeChill = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img width="320px" height="320px" src="supplementDetail/Zone/PC_TOP_BG.png" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeChill
