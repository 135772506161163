import React from 'react'

const MemoryIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.75 55.4685C24.2433 55.4685 18.1261 52.8868 13.5251 48.1992C8.92425 43.5113 6.39038 37.2787 6.39038 30.6493C6.39038 24.0198 8.92425 17.7872 13.5251 13.0994C18.1261 8.41176 24.2433 5.83008 30.75 5.83008C37.2566 5.83008 43.3738 8.41176 47.9749 13.0994C52.5757 17.7872 55.1095 24.0198 55.1095 30.6493C55.1095 37.2787 52.5757 43.5113 47.9749 48.1992C43.3738 52.8868 37.2566 55.4685 30.75 55.4685ZM30.75 8.73858C25.0058 8.73858 19.6055 11.0177 15.5437 15.1561C11.4819 19.2945 9.24502 24.7967 9.24502 30.6493C9.24502 36.5019 11.4819 42.0041 15.5437 46.1425C19.6055 50.2809 25.0058 52.56 30.75 52.56C36.4942 52.56 41.8945 50.2809 45.9562 46.1425C50.018 42.0041 52.2549 36.5019 52.2549 30.6493C52.2549 24.7967 50.018 19.2945 45.9562 15.1561C41.8945 11.0177 36.4942 8.73858 30.75 8.73858Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M29.3226 13.0777C29.3226 12.2894 29.9617 11.6504 30.75 11.6504C31.5382 11.6504 32.1773 12.2894 32.1773 13.0777V14.1042C32.1773 14.8925 31.5382 15.5315 30.75 15.5315C29.9617 15.5315 29.3226 14.8925 29.3226 14.1042V13.0777Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M41.2367 19.9617C40.6793 19.3939 40.6793 18.4733 41.2367 17.9054L41.9115 17.2178C42.4689 16.65 43.3725 16.65 43.9298 17.2178C44.4871 17.7856 44.4871 18.7063 43.9298 19.2741L43.2549 19.9617C42.6976 20.5296 41.794 20.5296 41.2367 19.9617Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M45.5875 30.6496C45.5875 29.8464 46.2386 29.1953 47.0418 29.1953H47.9425C48.7457 29.1953 49.3968 29.8464 49.3968 30.6496C49.3968 31.4527 48.7457 32.1038 47.9425 32.1038H47.0418C46.2386 32.1038 45.5875 31.4527 45.5875 30.6496Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M41.2343 43.3953C40.677 42.8275 40.677 41.9069 41.2343 41.339C41.7917 40.7712 42.6952 40.7712 43.2526 41.339L43.9275 42.0267C44.4848 42.5945 44.4848 43.5151 43.9275 44.083C43.3701 44.6508 42.4665 44.6508 41.9092 44.083L41.2343 43.3953Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M29.3226 47.1949C29.3226 46.4066 29.9617 45.7676 30.75 45.7676C31.5382 45.7676 32.1773 46.4066 32.1773 47.1949V48.2214C32.1773 49.0096 31.5382 49.6487 30.75 49.6487C29.9617 49.6487 29.3226 49.0096 29.3226 48.2214V47.1949Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M17.5597 44.0809C17.0023 43.5131 17.0023 42.5924 17.5597 42.0246L18.2345 41.337C18.7919 40.7691 19.6955 40.7691 20.2528 41.337C20.8101 41.9048 20.8101 42.8254 20.2528 43.3933L19.5779 44.0809C19.0206 44.6487 18.117 44.6487 17.5597 44.0809Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M12.103 30.6496C12.103 29.8464 12.7541 29.1953 13.5573 29.1953H14.458C15.2612 29.1953 15.9123 29.8464 15.9123 30.6496C15.9123 31.4527 15.2612 32.1038 14.458 32.1038H13.5573C12.7541 32.1038 12.103 31.4527 12.103 30.6496Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M17.5621 19.2723C17.0048 18.7045 17.0048 17.7838 17.5621 17.216C18.1194 16.6482 19.023 16.6482 19.5803 17.216L20.2552 17.9036C20.8125 18.4715 20.8125 19.3921 20.2552 19.9599C19.6979 20.5278 18.7943 20.5278 18.237 19.9599L17.5621 19.2723Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M40.8261 30.649C40.8261 31.4522 40.175 32.1033 39.3718 32.1033H31.3226C30.2181 32.1033 29.3226 31.2079 29.3226 30.1033V21.8101C29.3226 21.0218 29.9617 20.3828 30.75 20.3828C31.5382 20.3828 32.1773 21.0218 32.1773 21.8101V27.1948C32.1773 28.2994 33.0727 29.1948 34.1773 29.1948H39.3718C40.175 29.1948 40.8261 29.8459 40.8261 30.649Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default MemoryIcon
