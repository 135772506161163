import React from 'react'

const EyeIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.9059 29.7908C51.523 29.267 42.4007 16.9668 30.7498 16.9668C19.0989 16.9668 9.97618 29.267 9.59373 29.7903C9.23113 30.2872 9.23113 30.9611 9.59373 31.4579C9.97618 31.9817 19.0989 44.2819 30.7498 44.2819C42.4007 44.2819 51.523 31.9816 51.9059 31.4583C52.269 30.9616 52.269 30.2872 51.9059 29.7908ZM30.7498 41.4562C22.1677 41.4562 14.7347 33.2923 12.5343 30.6234C14.7318 27.9521 22.1493 19.7925 30.7498 19.7925C39.3315 19.7925 46.764 27.955 48.9653 30.6253C46.7678 33.2965 39.3503 41.4562 30.7498 41.4562Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M30.7497 22.1465C26.0755 22.1465 22.2726 25.9494 22.2726 30.6236C22.2726 35.2978 26.0755 39.1008 30.7497 39.1008C35.4239 39.1008 39.2269 35.2978 39.2269 30.6236C39.2269 25.9494 35.4239 22.1465 30.7497 22.1465ZM30.7497 36.275C27.6334 36.275 25.0984 33.7399 25.0984 30.6236C25.0984 27.5074 27.6335 24.9723 30.7497 24.9723C33.866 24.9723 36.4011 27.5074 36.4011 30.6236C36.4011 33.7399 33.866 36.275 30.7497 36.275Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default EyeIcon
