import React from 'react'
import { Box, Grid, Hidden, Paper } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'
// import ChanelDeveloper from '../components/ChanellDeveloper'

interface TitlePCComp {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
}

const TitlePC = Styled.h1<TitlePCComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'Cera Pro')};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '24px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
` as React.FunctionComponent<TitlePCComp>

interface SubTitlePCComp {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
}

const SubTitlePC = Styled.h2<SubTitlePCComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'YuGothic')};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '32px')};
  line-height: ${(props) => (props.lh ? props.lh : '48px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
` as React.FunctionComponent<SubTitlePCComp>

interface TextPCComp {
  ff?: string
  fw?: string
  fs?: string
  lh?: string
  ls?: string
  m0?: boolean
}

const TextPC = Styled.p<TextPCComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'YuGothic')};
  font-style: normal;
  font-weight: ${(props) => (props.fw ? props.fw : 'bold')};
  font-size: ${(props) => (props.fs ? props.fs : '14px')};
  line-height: ${(props) => (props.lh ? props.lh : '30px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin: ${(props) => (props.m0 ? '0' : 'auto 0')};
` as React.FunctionComponent<TextPCComp>

interface TitleSPComp {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
}

const TitleSP = Styled.h1<TitleSPComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'Cera Pro')};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '24px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
` as React.FunctionComponent<TitleSPComp>

interface SubTitleSPComp {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
}

const SubTitleSP = Styled.h2<SubTitleSPComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'YuGothic')};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '22px')};
  line-height: ${(props) => (props.lh ? props.lh : '33px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.08em')};
  color: #041E42;
` as React.FunctionComponent<SubTitleSPComp>

interface TextSPComp {
  ff?: string
  fw?: string
  fs?: string
  lh?: string
  ls?: string
  m0?: boolean
}

const TextSP = Styled.p<TextSPComp>`
  margin: 0;
  padding: 0;
  font-family: ${(props) => (props.ff ? props.ff : 'YuGothic')};
  font-style: normal;
  font-weight: ${(props) => (props.fw ? props.fw : 'bold')};
  font-size: ${(props) => (props.fs ? props.fs : '12px')};
  line-height: ${(props) => (props.lh ? props.lh : '20px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin: ${(props) => (props.m0 ? '0' : 'auto 0')};
` as React.FunctionComponent<TextPCComp>

const FirstView = () => {
  return (
    <>
      <Hidden mdDown>
        <Box
          position="relative"
          width="100%"
          height="100vh"
          mb={10}
          style={{
            backgroundImage: `url(./story_top-pc.jpg)`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <Box position="absolute" left="10vw" top="20%" textAlign="left">
            <TitlePC>OUR STORY</TitlePC>
            <br />
            <SubTitlePC>
              世界は、諦めなかった
              <br />
              誰かの夢でできている。
            </SubTitlePC>
            <TextPC>
              <br />
              むかし、アメリカの東海岸に、人類を空に飛ばすことを夢見た兄弟がいた。
              <br />
              またとある大学生は、インターネット上で世界中の人たちが繋がることを願った。
              <br />
              彼らがいなかったら、人は空を飛んでいなかったかもしれないし、
              <br />
              SNSもここまで普及していなかったかもしれない。
              <br />
              <br />
              私たちが生きる今があるのは、その未来を夢見た過去の人たちがいたからだ。
              <br />
              そして今日も、未来を創ろうと挑戦に向かう人たちが、世界中にたくさんいる。
              <br />
              <br />
              私たちiHackの役割は、そんなひとりひとりの挑戦をそばで支えること。
              <br />
              あなたの挑戦が、未来を紡ぐそのときまで。
            </TextPC>
          </Box>
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box
          position="relative"
          width="100%"
          height="100vh"
          mb={10}
          style={{
            backgroundImage: `url(./story_top-sp.jpg)`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <Box position="absolute" left="10vw" top="12%" textAlign="left">
            <TitleSP>OUR STORY</TitleSP>
            <br />
            <SubTitleSP>
              世界は、諦めなかった
              <br />
              誰かの夢でできている。
            </SubTitleSP>
            <TextSP>
              <br />
              むかし、アメリカの東海岸に、人類を空に飛ばすことを夢見た兄弟がいた。
              <br />
              またとある大学生は、インターネット上で世界中の人たちが繋がることを願った。
              <br />
              彼らがいなかったら、人は空を飛んでいなかったかもしれないし、
              <br />
              SNSもここまで普及していなかったかもしれない。
              <br />
              <br />
              私たちが生きる今があるのは、その未来を夢見た過去の人たちがいたからだ。
              <br />
              そして今日も、未来を創ろうと挑戦に向かう人たちが、世界中にたくさんいる。
              <br />
              <br />
              私たちiHackの役割は、そんなひとりひとりの挑戦をそばで支えること。
              <br />
              あなたの挑戦が、未来を紡ぐそのときまで。
            </TextSP>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box mb={11}>
          <img src={'story_top-sp.jpg'} style={{ width: '100%' }} />
          <Box mx={3} mt={4}>
            <TitleSP>OUR STORY</TitleSP>
            <br />
            <SubTitleSP>
              世界は、諦めなかった
              <br />
              誰かの夢でできている。
            </SubTitleSP>
            <TextSP>
              <br />
              むかし、アメリカの東海岸に、人類を空に飛ばすことを夢見た兄弟がいた。
              <br />
              <br />
              またとある大学生は、インターネット上で世界中の人たちが繋がることを願った。
              <br />
              <br />
              彼らがいなかったら、人は空を飛んでいなかったかもしれないし、 SNSもここまで普及していなかったかもしれない。
              <br />
              <br />
              私たちが生きる今があるのは、その未来を夢見た過去の人たちがいたからだ。
              そして今日も、未来を創ろうと挑戦に向かう人たちが、世界中にたくさんいる。
              <br />
              <br />
              私たちiHackの役割は、そんなひとりひとりの挑戦をそばで支えること。
              <br />
              <br />
              あなたの挑戦が、未来を紡ぐそのときまで。
            </TextSP>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const SecondView = () => {
  return (
    <>
      <Hidden mdDown>
        <Box mb={12}>
          <Paper style={{ margin: 'auto', width: '1010px', height: '689px' }}>
            <Box pt={11} pb={9} px={5}>
              <Box mb={11} textAlign="center">
                <SubTitlePC>「iHack」という名前に込めたふたつの想い</SubTitlePC>
              </Box>
              <Grid container justify="center">
                <Grid item>
                  <Box pr={2} borderRight="0.5px solid #F2F4F6">
                    <TextPC fw="500" lh="28px" ls="0.05em">
                      このプロダクトが、社会においてどんな役割を果たし、どんな価値
                      <br />
                      を届けていくのか、その想いをしっかりと名前で表現したい。そん
                      <br />
                      なことを考え、iHackという名前にふたつの想いを込めました。
                      <br />
                      <br />
                      ひとつ目は、サプリメントを通じて人々の身体を、ライフスタイル
                      <br />
                      を、そして挑戦をHackしていく、という私たち事業者自身のミッ
                      <br />
                      ション。
                      <br />
                      <br />
                      ふたつ目は、あなたは何をHackするのか、というユーザーへの問
                      <br />
                      いかけ。本来英文法的には、「Hack」は他動詞であるため、
                      <br />
                      主語（I）、そして動詞（Hack）の後には目的語が続きます。
                    </TextPC>
                  </Box>
                </Grid>
                <Grid item>
                  <Box pl={2}>
                    <TextPC fw="500" lh="28px" ls="0.05em">
                      私たちは、サプリメントを飲むという行為そのものは手段であり、
                      <br />
                      目的ではないと考えています。人それぞれ必ず、サプリメントを飲
                      <br />
                      む先に、目的があるはずです。
                      <br />
                      <br />
                      iHackの役割は、その目的に向かう人たちを肯定し、彼らの苦悩を
                      <br />
                      そばで支えること。私たちは、全ての挑戦 (ALL CHALLENGES)
                      <br />
                      には価値があると強く信じ、それを全力でフォローしていきたい。
                      <br />
                      <br />
                      まだ見ぬ世界をともにカタチにしましょう、一心不乱に。
                      <br />
                      iHack ALL CHALLENGES.
                    </TextPC>
                  </Box>
                </Grid>
              </Grid>
              <Box textAlign="right">
                <img src={'story_iHack_CEO_sign.jpg'} style={{ width: '30%' }} />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box textAlign="center">
          <img src={'story_product_image.png'} style={{ width: '50%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box mb={11} mx={2}>
          <Paper>
            <Box pt={6} pb={6} mx={5}>
              <Box mb={6} textAlign="center">
                <SubTitleSP fs="16px" lh="24px" ls="0.1em">
                  「iHack」という名前に込めたふたつの想い
                </SubTitleSP>
              </Box>
              <Grid container justify="center">
                <Grid item>
                  <Box mb={2}>
                    <TextSP fw="500" ls="0.05em">
                      このプロダクトが、社会においてどんな役割を果たし、どんな価値を届けていくのか、その想いをしっかりと名前で表現したい。そんなことを考え、iHackという名前にふたつの想いを込めました。
                      <br />
                      <br />
                      ひとつ目は、サプリメントを通じて人々の身体を、ライフスタイルを、そして挑戦をHackしていく、という私たち事業者自身のミッション。
                      <br />
                      <br />
                      ふたつ目は、あなたは何をHackするのか、というユーザーへの問いかけ。本来英文法的には、「Hack」は他動詞であるため、主語（I）、そして動詞（Hack）の後には目的語が続きます。
                    </TextSP>
                  </Box>
                </Grid>
                <hr color="#F2F4F6" style={{ width: '40%' }} />
                <Grid item>
                  <Box mb={4} mt={2}>
                    <TextSP fw="500" ls="0.05em">
                      私たちは、サプリメントを飲むという行為そのものは手段であり、目的ではないと考えています。人それぞれ必ず、サプリメントを飲む先に、目的があるはずです。
                      <br />
                      <br />
                      iHackの役割は、その目的に向かう人たちを肯定し、彼らの苦悩をそばで支えること。私たちは、全ての挑戦(ALL
                      CHALLENGES) には価値があると強く信じ、それを全力でフォローしていきたい。
                      <br />
                      <br />
                      まだ見ぬ世界をともにカタチにしましょう、一心不乱に。
                      <br />
                      <br />
                      iHack ALL CHALLENGES.
                    </TextSP>
                  </Box>
                </Grid>
              </Grid>
              <Box textAlign="right">
                <img src={'story_iHack_CEO_sign.jpg'} style={{ width: '20%' }} />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box textAlign="center">
          <img src={'story_product_image.png'} style={{ width: '100%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box mb={11} mx={2}>
          <Paper>
            <Box pt={6} pb={8} mx={2}>
              <Box mb={3} textAlign="center">
                <SubTitleSP fs="16px" lh="24px" ls="0.1em">
                  「iHack」という名前に込めた
                  <br />
                  ふたつの想い
                </SubTitleSP>
              </Box>
              <Grid container justify="center">
                <Grid item>
                  <Box mb={2}>
                    <TextSP fw="500" ls="0.05em">
                      このプロダクトが、社会においてどんな役割を果たし、どんな価値を届けていくのか、その想いをしっかりと名前で表現したい。そんなことを考え、iHackという名前にふたつの想いを込めました。
                      <br />
                      <br />
                      ひとつ目は、サプリメントを通じて人々の身体を、ライフスタイルを、そして挑戦をHackしていく、という私たち事業者自身のミッション。
                      <br />
                      <br />
                      ふたつ目は、あなたは何をHackするのか、というユーザーへの問いかけ。本来英文法的には、「Hack」は他動詞であるため、主語（I）、そして動詞（Hack）の後には目的語が続きます。
                    </TextSP>
                  </Box>
                </Grid>
                <hr color="#F2F4F6" style={{ width: '40%' }} />
                <Grid item>
                  <Box mb={4} mt={2}>
                    <TextSP fw="500" ls="0.05em">
                      私たちは、サプリメントを飲むという行為そのものは手段であり、目的ではないと考えています。人それぞれ必ず、サプリメントを飲む先に、目的があるはずです。
                      <br />
                      <br />
                      iHackの役割は、その目的に向かう人たちを肯定し、彼らの苦悩をそばで支えること。私たちは、全ての挑戦(ALL
                      CHALLENGES) には価値があると強く信じ、それを全力でフォローしていきたい。
                      <br />
                      <br />
                      まだ見ぬ世界をともにカタチにしましょう、一心不乱に。
                      <br />
                      <br />
                      iHack ALL CHALLENGES.
                    </TextSP>
                  </Box>
                </Grid>
              </Grid>
              <Box textAlign="right">
                <img src={'story_iHack_CEO_sign.jpg'} style={{ width: '60%' }} />
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box textAlign="center">
          <img src={'story_product_image.png'} style={{ width: '100%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>
    </>
  )
}

const ThirdView = () => {
  return (
    <>
      <Hidden mdDown>
        <Box textAlign="center">
          <Box mb={5}>
            <SubTitlePC>まだ見ぬ、ポジティブへの挑戦</SubTitlePC>
          </Box>
          <TextPC>
            健康需要の高まりにともない、世の中にはたくさんのサプリメントが出回るようになりました。
            <br />
            しかし、中には過剰にコンプレックスを煽る誰かを傷つける言葉や、
            <br />
            誤解を招くような情報を見かけることも。
            <br />
            <br />
            もっと前向きに誰かを応援できないか、もっと情報の非対称性を埋めることはできないか。
            <br />
            <br />
            もしそのようなプロダクトが、まだ世の中にないのなら、自分たちの手で創ればいい。
            <br />
            <br />
            ただサプリメントを提供するだけでなく、日常の小さな勇気やエール、
            <br />
            そういったポジティブを、一緒に届けられるようなプロダクトを。
            <br />
            <br />
            そんな願いから生まれたのが、iHackです。
          </TextPC>
          <img src={'story_product_image.png'} style={{ width: '50%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>

      <Hidden xsDown lgUp>
        <Box textAlign="center">
          <Box mb={5}>
            <SubTitleSP>まだ見ぬ、ポジティブへの挑戦</SubTitleSP>
          </Box>
          <TextSP>
            健康需要の高まりにともない、世の中にはたくさんのサプリメントが出回るようになりました。
            <br />
            しかし、中には過剰にコンプレックスを煽る誰かを傷つける言葉や、
            <br />
            誤解を招くような情報を見かけることも。
            <br />
            <br />
            もっと前向きに誰かを応援できないか、もっと情報の非対称性を埋めることはできないか。
            <br />
            <br />
            もしそのようなプロダクトが、まだ世の中にないのなら、自分たちの手で創ればいい。
            <br />
            <br />
            ただサプリメントを提供するだけでなく、日常の小さな勇気やエール、
            <br />
            そういったポジティブを、一緒に届けられるようなプロダクトを。
            <br />
            <br />
            そんな願いから生まれたのが、iHackです。
          </TextSP>
          <img src={'story_product_image.png'} style={{ width: '100%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box mx={2}>
          <Box mb={5} textAlign="center">
            <SubTitleSP>
              まだ見ぬ、
              <br />
              ポジティブへの挑戦
            </SubTitleSP>
          </Box>
          <TextSP>
            健康需要の高まりにともない、世の中にはたくさんのサプリメントが出回るようになりました。
            <br />
            <br />
            しかし、中には過剰にコンプレックスを煽る誰かを傷つける言葉や、誤解を招くような情報を見かけることも。
            <br />
            <br />
            もっと前向きに誰かを応援できないか、もっと情報の非対称性を埋めることはできないか。
            <br />
            <br />
            もしそのようなプロダクトが、まだ世の中にないのなら、自分たちの手で創ればいい。
            <br />
            <br />
            ただサプリメントを提供するだけでなく、日常の小さな勇気やエール、そういったポジティブを、一緒に届けられるようなプロダクトを。
            <br />
            <br />
            そんな願いから生まれたのが、iHackです。
          </TextSP>
          <img src={'story_product_image.png'} style={{ width: '100%', margin: 0, padding: 0 }} />
        </Box>
      </Hidden>
    </>
  )
}

const Story = () => {
  // ChanelDeveloper.boot({
  //   pluginKey: '8324e2a5-9a85-4d44-a57a-2118a321f35b' //please fill with your plugin key
  // })

  return (
    <>
      <Header />
      <Box bgcolor="#FAFAFA" mb={'-6px'}>
        <FirstView />
        <SecondView />
        {/* <ThirdView /> */}
      </Box>
      <Footer />
    </>
  )
}

export default Story
