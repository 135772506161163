import React from 'react'

const DepressionIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.4852 19.0182C43.6054 19.0182 42.749 19.1423 41.925 19.388C39.9934 14.2013 35.0237 10.6895 29.3809 10.6895C26.1429 10.6895 23.0177 11.8608 20.581 13.9877C18.4601 15.839 16.9829 18.3015 16.3465 21.0128C16.2383 21.0085 16.13 21.0056 16.0216 21.0056C11.627 21.0056 8.05188 24.5807 8.05188 28.9752C8.05188 33.3697 11.627 36.9449 16.0216 36.9449H44.4851C49.4275 36.9449 53.4484 32.924 53.4484 27.9816C53.4485 23.0392 49.4275 19.0182 44.4852 19.0182ZM44.4852 34.285H16.0216C13.0937 34.285 10.7118 31.9031 10.7118 28.9752C10.7118 26.0473 13.0937 23.6654 16.0216 23.6654C16.3987 23.6654 16.7743 23.7049 17.1386 23.7827C17.8572 23.9367 18.5637 23.4786 18.7172 22.7603C18.7222 22.7371 18.7248 22.7141 18.7285 22.6908C18.7433 22.6371 18.7562 22.5825 18.7644 22.5258C19.1298 20.0003 20.3961 17.6797 22.3303 15.9915C24.2824 14.2877 26.7865 13.3492 29.3811 13.3492C34.2548 13.3492 38.5001 16.6181 39.7475 21.3118C39.7652 21.4607 39.807 21.609 39.8781 21.7508C40.2075 22.4073 41.0067 22.6726 41.6633 22.3432C42.543 21.9018 43.4925 21.678 44.4854 21.678C47.9611 21.678 50.7888 24.5057 50.7888 27.9814C50.7885 31.4573 47.9608 34.285 44.4852 34.285Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M20.1525 39.2363C19.418 39.2363 18.8225 39.8318 18.8225 40.5663V45.7712C18.8225 46.5057 19.418 47.1012 20.1525 47.1012C20.887 47.1012 21.4825 46.5057 21.4825 45.7712V40.5663C21.4825 39.8318 20.887 39.2363 20.1525 39.2363Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M34.2825 39.2363C33.548 39.2363 32.9525 39.8318 32.9525 40.5663V43.9979C32.9525 44.7324 33.548 45.3279 34.2825 45.3279C35.017 45.3279 35.6125 44.7324 35.6125 43.9979V40.5663C35.6124 39.8318 35.017 39.2363 34.2825 39.2363Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M41.3478 40.3633C40.6133 40.3633 40.0178 40.9588 40.0178 41.6933V46.8982C40.0178 47.6327 40.6133 48.2282 41.3478 48.2282C42.0823 48.2282 42.6778 47.6327 42.6778 46.8982V41.6933C42.6778 40.9588 42.0823 40.3633 41.3478 40.3633Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M27.2177 42.2832C26.4832 42.2832 25.8877 42.8787 25.8877 43.6132V49.2609C25.8877 49.9954 26.4832 50.5909 27.2177 50.5909C27.9522 50.5909 28.5477 49.9954 28.5477 49.2609V43.6132C28.5477 42.8787 27.9522 42.2832 27.2177 42.2832Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)
export default DepressionIcon
