import { Answer, Results, Other, Quetsions, WoriesObj, MostWories, Question } from '../../../../types/Score/index'
import { questions } from '../../../../common/question'
import { sexualQuestionId1, sexualQuestionId2, mostWorriesId } from '../../../../common/keyQuestion'

export const deleteSkipQuestion = (skipQuestions: number[]): Quetsions => {
  return questions.filter((c) => !skipQuestions.includes(c.questionSortId))
}

export const createSkipquestions = (answer: Answer[], mostWories: MostWories, wories: MostWories[]): number[] => {
  const skips: number[] = []
  const pickupExistSkipOption = questions.filter((i) => i.skipOptions)
  answer.map((c) => {
    pickupExistSkipOption.filter((i) => {
      if (i.questionSortId === c.questionSortId) {
        i.skipOptions?.map((t) => {
          if (t.choiceIds === c.choiceId) {
            skips.push(...t.skipQuestionSortIds)
          }
        })
      }
    })
  })
  if (mostWories !== '活力') {
    skips.push(sexualQuestionId1, sexualQuestionId2)
  }
  if (wories.length === 1) {
    skips.push(mostWorriesId)
  }
  return skips
}

export const getSectionLength = (sectionNumber: number, deletedSkipQuestion: Question[]): number => {
  const length = deletedSkipQuestion.filter((i) => i.sectionNumber === sectionNumber)?.length
  return length ? length : 0
}

//todo:リファクタ対象
export const OldGetIdForDisplay = (
  deletedSkipQuestion: Question[],
  answer: Answer[],
  sectionNumber: number,
  adJustId: number
): number => {
  const answerdId = []
  const sectionQuestion = deletedSkipQuestion.filter((i) => i.sectionNumber === sectionNumber)
  sectionQuestion.map((c) =>
    answer.map((i) => {
      if (i.questionSortId === c.questionSortId) {
        answerdId.push(i.questionSortId)
      }
    })
  )
  return answerdId.length + adJustId + 1
}

export const getIdForDisplay = (
  deletedSkipQuestion: Question[],
  answer: Answer[],
  sectionNumber: number,
  adJustId: number
): number => {
  const sectionAnswer = []
  const sectionQuestion = deletedSkipQuestion.filter((i) => i.sectionNumber === sectionNumber)
  sectionQuestion.map((i) => sectionAnswer.push(answer.filter((c) => i.questionSortId === c.questionSortId)))

  return sectionAnswer.length
}
export default deleteSkipQuestion
