import React from 'react'

const FatigueIcon = (props: { color?: string }) => (
  <>
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.7511 52.1387C23.1812 52.1387 17.0256 45.9832 17.0256 38.4136C17.0256 31.4897 28.0905 12.0774 29.3496 9.883C29.9287 8.89056 31.567 8.89056 32.1498 9.883C33.4122 12.0774 44.4745 31.4897 44.4745 38.4136C44.4759 45.9832 38.3221 52.1387 30.7511 52.1387ZM30.7511 13.9831C26.9005 20.9436 20.2517 33.9005 20.2517 38.4136C20.2517 44.1995 24.961 48.9102 30.7511 48.9102C36.5401 48.9102 41.2493 44.2012 41.2493 38.4136C41.249 33.8971 34.6011 20.9436 30.7511 13.9831Z"
        fill={props.color ? props.color : '#041E42'}
      />
      <path
        d="M30.751 45.6804C29.8573 45.6804 29.1357 44.9582 29.1357 44.0637C29.1357 43.1709 29.8587 42.4501 30.751 42.4501C32.976 42.4501 34.7854 40.6477 34.7854 38.4278C34.7854 37.5349 35.5075 36.8125 36.4004 36.8125C37.2966 36.8125 38.0187 37.5349 38.0187 38.4278C38.0187 42.428 34.758 45.6804 30.751 45.6804Z"
        fill={props.color ? props.color : '#041E42'}
      />
    </svg>
  </>
)

export default FatigueIcon
