import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '56px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplementDetail/Vitality/SP_TOP_BG.png' // "../supplementDetail/Style/SP_SPM.png";
const pcTopBgURL: string = '../supplementDetail/Vitality/PC_PRODUCT_BG.png' // "../supplementDetail/Style/PC_TOP_BG.png";
const SpTopBgURL: string = '../supplementDetail/Vitality/SP_PRODUCT_BG.png' //"../supplementDetail/Style/SP_TOP_BG.png";
const title = 'VITALITY'
const sub_title = 'Confidence is contagious. So is lack of confidence.'
const text =
  'どんなに強い人でも、老いには敵わない。　ひとりでも多くの人が、何歳になっても自信に満ちた自分でいて欲しい。　そんな想いを込めてVitalityには、滋養強壮に関わる成分を贅沢に配合しました。いつまでも若々しい自分でいるために、Vitality。'
const containtext =
  'Vitalityは、滋養強壮系の成分であるマカや朝鮮人参エキスをベースに、雪蓮花エキス、ガラナ等の８種類の有用成分を加えたサプリメントです。毎日の自信と活力のために、ご利用ください。'
const product_name = 'VITALITY'
const product_amount = 'ベンジルグルコシノレート0.4mg、総サポニン0.4mg'
const product_materials =
  'マカエキス末、黒マカエキス末、マムシ粉末、マカ粉末、雪蓮花エキス末、すっぽん粉末、高麗人参エキス末、ガラナ末、還元麦芽糖水飴、セルロース、HPC、ステアリン酸Ca、微粒二酸化ケイ素'
const product_allergy = 'なし'
const product_agent_type = '../tablet.png'
const productBgSp = '../supplementDetail/Vitality/SP_SPM.png' // "../supplementDetail/Style/SP_PRODUCT_BG.png";
const productBgPc = '../supplementDetail/Vitality/PC_SPM.png' // "../supplementDetail/Style/PC_PRODUCT_BG.png";

const evidences: EvidenceProps = [
  {
    title: 'マカ',
    sub_title: '-  滋養強壮をサポート',
    text:
      'マカは、南米ペルーに植生するアブラナ科の多年生植物です。昔からペルーの人々には欠かせない滋養強壮食として大切にされてきました。栄養価も高く『アンデスの女王』という別名もあるほどです。論文によると「57人の男性（21-56歳）を対象とした二重盲検ランダム化比較試験において、1.5gもしくは3gのマカエキスを３ヶ月間投与されたグループは、プラセボを投与されたグループと比較して、性的欲求の上昇が確認された。テストステロン値などに影響は見られなかったため、マカの性的欲求に対する独立的な効果を示している。」と示されています。',
    thesis:
      'Effect of Lepidium meyenii (MACA) on sexual desire and its absent relationship with serum testosterone levels in adult healthy men',
    magazine: 'Andrologia',
    Impact_Factor: '1.458',
    Impact_Factor_Year: '2016',
    publish: '2002',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/12472620',
    total_quote: '271',
    exists: 'あり'
  },
  {
    title: '朝鮮人参',
    sub_title: '-  滋養強壮をサポート',
    text:
      '朝鮮人参は、中国や韓国を原産地とする根菜の一種です。古くから薬草として用いられており、現代でも高いものなら実際に1本数十万円という高値で取引される貴重品です。論文によると「更年期の女性32人（45-64歳）を対象とした二重盲検ランダム化比較試験において、3gの朝鮮人参エキスを2-4週間摂取したグループは、プラセボを投与されたグループと比較して、女性性機能質問紙（FSFI）ベースで有意（P<0.006）に性的欲求の向上が確認された。」と示されています。',
    thesis:
      'Effects of Korean red ginseng on sexual arousal in menopausal women: placebo-controlled, double-blind crossover clinical study',
    magazine: 'The Journal of Sexual Medicine',
    Impact_Factor: '3.151',
    Impact_Factor_Year: '2014',
    publish: '2010',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/20141583',
    total_quote: '73',
    exists: 'あり'
  },
  {
    title: 'マカ',
    sub_title: '-  滋養強壮をサポート',
    text:
      '論文によると「軽度の勃起不全白人男性（21-56歳）を対象とした二重盲検ランダム化比較試験において、1.5gもしくは3gのマカエキスを３ヶ月間投与されたグループは、プラセボを投与されたグループと比較して、性的欲求の上昇が確認された。テストステロン値などに影響は見られなかったため、マカの性的欲求に対する独立的な効果を示している。」と示されています。',
    thesis:
      'Subjective effects of Lepidium meyenii (Maca) extract on well-being and sexual performances in patients with mild erectile dysfunction: a randomised, double-blind clinical trial',
    magazine: 'Andrologia',
    Impact_Factor: '1.458',
    Impact_Factor_Year: '2016',
    publish: '2009',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/19260845',
    total_quote: '145',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeChill = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img width="320px" height="320px" src="supplementDetail/Vitality/PC_TOP_BG.png" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeChill
