import React, { useState } from 'react'
import Styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import autoImgSizeStyle from '../../../common/styleFunctions'
import '../../../CeraPro/stylesheet.css'
import { MapsLocalParking } from 'material-ui/svg-icons'

const HeaderWrapper = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
`

const H1CeraPro = Styled.h1`
margin:0;
padding:0;
  font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 60px;
letter-spacing: 0.1em;
`

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.1em;
`

const H3YuGothic = Styled.h3`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;
letter-spacing: 0.1em;
color: #041E42;
text-decoration-line: underline;
text-decoration-color:rgba(0, 212, 161, 0.38);
text-decoration-thickness: 3px;
`

const H4YuGothic = Styled.h4`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
/* identical to box height */
color: #041E42;
letter-spacing: 0.1em;
`

const PYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 25px;
letter-spacing: 0.05em;
color: #041E42;
`

type Props = {
  id: string
  genre: string
  title: string
  text: string
  causes: string[]
}

const ResultCard = (props: Props) => {
  const [isOpen, setOpen] = useState(false)
  const { id, genre, title, text, causes } = props

  return (
    <>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="312px"
          height={isOpen ? '400px' : '405px'}
          textAlign="center"
          border="3px solid #041E42 "
          position="relative"
          bgcolor="white"
        >
          <H1CeraPro style={{ marginTop: '40px' }}>{id}</H1CeraPro>
          <H2YuGothic style={{ marginTop: '16px' }}>{genre}</H2YuGothic>
          <H3YuGothic style={{ marginTop: '16px' }}>{title}</H3YuGothic>
          <PYuGothic
            style={{
              marginTop: '24px',
              marginLeft: '20px',
              marginRight: '20px',
              textAlign: 'justify'
            }}
          >
            {text}
          </PYuGothic>

          {/* <Box position="absolute" left={0} right={0} top={'344px'}>
            <Button
              onClick={() => setOpen(!isOpen)}
              style={{
                borderRadius: '30px',
                width: '60px',
                height: '60px'
              }}
            >
              {isOpen ? (
                <img src="./common/UpArrow.svg" width={'40px'} />
              ) : (
                <img src="./common/DownArrow.svg" width={'40px'} />
              )}
            </Button>
          </Box> */}
          {/* <Box
            position="absolute"
            width="100%"
            top={'424px'}
            textAlign="center"
            visibility={isOpen ? 'visible' : 'hidden'}
          >
            <H4YuGothic>【原因として考えられること】</H4YuGothic>
            {causes.map((c) => {
              return (
                <PYuGothic
                  style={{
                    textAlign: 'left',
                    marginLeft: '20px',
                    marginTop: '8px'
                  }}
                >
                  ・{c}
                </PYuGothic>
              )
            })}
          </Box> */}
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <Box
          width="279px"
          height={isOpen ? '350px' : '405px'}
          textAlign="center"
          border="3px solid #041E42 "
          position="relative"
          bgcolor="white"
        >
          <H1CeraPro style={{ marginTop: '28px' }}>{id}</H1CeraPro>
          <H2YuGothic style={{ marginTop: '4px' }}>{genre}</H2YuGothic>
          <H3YuGothic style={{ marginTop: '28px' }}>{title}</H3YuGothic>
          <PYuGothic
            style={{
              marginTop: '24px',
              marginLeft: '18px',
              marginRight: '18px',
              textAlign: 'justify'
            }}
          >
            {text}
          </PYuGothic>

          {/* <Box position="absolute" left={0} right={0} top={'344px'}>
            <Button
              onClick={() => setOpen(!isOpen)}
              style={{
                borderRadius: '30px',
                width: '60px',
                height: '60px'
              }}
            >
              {isOpen ? (
                <img src="./common/UpArrow.svg" width={'40px'} />
              ) : (
                <img src="./common/DownArrow.svg" width={'40px'} />
              )}
            </Button>
          </Box> */}
          {/* <Box
            position="absolute"
            width="100%"
            top={'424px'}
            textAlign="center"
            visibility={isOpen ? 'visible' : 'hidden'}
          >
            <H4YuGothic>【原因として考えられること】</H4YuGothic>
            {causes.map((c) => {
              return (
                <PYuGothic
                  style={{
                    textAlign: 'left',
                    marginLeft: '20px',
                    marginTop: '8px'
                  }}
                >
                  ・{c}
                </PYuGothic>
              )
            })}
          </Box> */}
        </Box>
      </Box>
    </>
  )
}

export default ResultCard
