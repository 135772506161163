import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Styled from "styled-components";
import Header from "../components/Header";
import CardSlider from "../components/container/supplementDetails/slider";
import autoImgSizeStyle from "../common/styleFunctions";
import { Box, Hidden, Container, Button, Link } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { IhackBanner } from "./supplements";
import { IhackFooter } from "./supplements";
import MergeChill from "../components/container/supplementDetails/merge/MergeChill";
import MergeZone from "../components/container/supplementDetails/merge/MergeZone";
import MergeStorage from "../components/container/supplementDetails/merge/MergeStorage";
import MergeVitality from "../components/container/supplementDetails/merge/MergeVitality";
import MergeStyle from "../components/container/supplementDetails/merge/MergeStyle";
import MergeMineral from "../components/container/supplementDetails/merge/MergeMineral";
import MergeOmega3 from "../components/container/supplementDetails/merge/MergeOmega3";
import MergeVitamin from "../components/container/supplementDetails/merge/MergeVitamin";
import MergeRecovery from "../components/container/supplementDetails/merge/MergeRecovery";
import {
  CarouselSP,
  IhackBannerSP,
  IhackFooterSP
} from "../components/container/supplementDetails/sp/spCommon";

const HeaderWrapper = Styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
`;

export const EvidenceOpenButton = Styled(Button)`
  position: absolute;
  left: 790px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  background-color: #00D4A1;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size:32px;
  &:hover{
    background-color: #00D4A1;
  }
`;

const TopLogo = Styled.h1`
  color: #041E42;
  margin: 0;
  padding: 0;
  font-family: Cera Pro;
  line-height: 52px;
`;

const BlueLink = Styled.a`
  color: #041E42;
  font-family: Cera Pro;
  font-weight: bold;
  font-size: 12px;
  line-height: 52px;
  letter-spacing: 0.08em;
  text-decoration: none;
`;

const ConversionButton = Styled.button`
  background-color: #00D4A1;
  padding: 5px 30px;
  border-radius: 4px;
`;

const Title = Styled.h2`
margin:0 auto 0;
padding:0 0 32px 0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 36px;
/* identical to box height */

letter-spacing: 0.1em;

/* main */

color: #041E42;
`;

export const SecondTitle = Styled.h3`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 32px;
/* identical to box height */
letter-spacing: 0.12em;
color: #041E42;
`;

export const Text = Styled.p`
word-wrap: break-all;
margin-top:0;
padding-top:0;
font-family: YuGothic;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 32px;
letter-spacing: 0.12em;
color: #041E42;
`;

export const IHackdetails = Styled.details`
  position:relative;
  width:64%;
  padding:80px 120px:
  border-radius:8px;
  box-shadow:0px 12px 16px rgba(0,0,0,0.05);
  background-color:#FFF;
  list-style:none;
  margin:0 auto;
  transition:.5s;
}
`;

export const IHacksummary = Styled.summary`
  position:relative;
  display: block;
  font-size:24px;
  outline:none;
  list-style:none;
  vertical-align:middle;
  color: #041E42;
  cursor:pointer;
  &::-webkit-details-marker {
  display: none;
}
`;

const textColor: string = "#041E42";

const fontSizexxs: string = "12px";
const fontSizexs: string = "14px";
const fontSizesm: string = "18px";
const fontSizemd: string = "20px";
const fontSizelg: string = "32px";
const fontSizexl: string = "64px";

const letterSpacingsm = "0.06em";
const letterSpacingmd = "0.1em";
const letterSpacinglg = "0.25em";

const lineHeightsm = "30px";
const lineHeightmd = "40px";

const SupplementDetail = () => {
  return (
    <>
      <Hidden mdUp>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            fontFamily: "Cera Pro"
          }}
        >
          {/* <Header /> */}
          <MergeStyle />
          <MergeVitality />
          <MergeStorage />
          <MergeZone />
          <MergeChill />
          <MergeMineral />
          <MergeOmega3 />
          <MergeVitamin />
          <MergeRecovery />

          {/* <IhackBannerSP />
          <IhackFooterSP /> */}
        </Box>
      </Hidden>
      <Hidden smDown>
        <MergeStyle />
        <MergeVitality />
        <MergeStorage />
        <MergeZone />
        <MergeChill />
        <MergeMineral />
        <MergeOmega3 />
        <MergeVitamin />
        <MergeRecovery />
        <Box width="100%" display="flex" justifyContent="center">
          <CardSlider />
        </Box>
        <IhackBanner />
        <IhackFooter />
      </Hidden>
    </>
  );
};

export default SupplementDetail;
