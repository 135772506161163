import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Answer, MostWories, WoriesObj, Question } from '../../../../types/Score'
import ChoiceOfAnswer from '../ChoiceOfAnswers'
import Radio from '@material-ui/core/Radio'
import RadioButtonIcon from '../../../../components/container/diagnosis/question/common/RadioButtonIcon'
import RadioBottons from '../../../../components/container/diagnosis/question/type/radio/FiveOption'
import { nl2br } from '../../../../function'
import RadioGroup from '@material-ui/core/RadioGroup'
import MoveButton from '../../diagnosis/MoveButton'

type Props = {
  question: Question
  useStyles: any
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
  moveResult: (emai?: string) => void
}

const RadioButtonRoot = (props: Props) => {
  const { question, useStyles, answer, setAnswer, onMoveAnimation, moveResult } = props
  const tempOptions = question.options.length <= 3 ? question.options : question.options.slice().reverse()
  const tempQuestion: Question = { ...question, options: tempOptions }
  const answerId = answer.filter((c) => c.questionSortId === tempQuestion.questionSortId)?.[0]?.choiceId
  const [selectValue, setSelectValue] = useState(answerId ? answerId : 0)

  const onChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectValue(Number(e.target.value))
    if (answerId) {
      answer.splice(
        answer.findIndex((c) => c.questionSortId === tempQuestion.questionSortId),
        1
      )
    }
    const tempAnswer: Answer = {
      id: question.id,
      questionSortId: tempQuestion.questionSortId,
      choiceId: Number(e.target.value)
    }
    setAnswer((_) => [...answer, tempAnswer])
  }
  return (
    <>
      <Hidden lgUp>
        <Box width="100%" mt={-2}>
          <RadioGroup
            style={{ width: '100%' }}
            name={String(tempQuestion.questionSortId)}
            onChange={onChange}
            value={selectValue ? selectValue : 0}
          >
            <Box width="100%" display="flex" justifyContent="center" alignContent="center" textAlign="center">
              <RadioBottons options={tempQuestion.options} />
            </Box>
          </RadioGroup>
          <MoveButton
            disable={selectValue === 0}
            answers={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            question={question}
            anyFunction={() => {}}
            moveResult={moveResult}
            isFinish={true}
            isFixed={false}
          />
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box width="500px">
          <RadioGroup
            style={{ width: '100%' }}
            name={String(tempQuestion.questionSortId)}
            onChange={onChange}
            value={selectValue ? selectValue : 0}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignContent="center"
              textAlign="center"
              bgcolor="#fff"
            >
              <RadioBottons options={tempQuestion.options} />
              <MoveButton
                disable={selectValue === 0}
                answers={answer}
                setAnswer={setAnswer}
                onMoveAnimation={onMoveAnimation}
                question={question}
                anyFunction={() => {}}
                moveResult={moveResult}
                isFinish={true}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Hidden>
    </>
  )
}

export default RadioButtonRoot
