import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Answer, MostWories, WoriesObj, Question } from '../../../../types/Score'
import ChoiceOfAnswer from '../ChoiceOfAnswers'

type Prop = {
  question: Question
  useStyles: any
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
  //onSubmit: (event: any) => void
}

const PullDownSelect = (props: Prop) => {
  const { question, useStyles, answer, setAnswer, onMoveAnimation } = props
  const answerId = answer.filter((c) => c.questionSortId === question.questionSortId)?.[0]?.choiceId
  const classes = useStyles()
  const [selectValue, setSelctValue] = useState<Number>(answerId ? question.options[answerId]?.value - 1 : 0)
  const onChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelctValue(Number(e.target.value))
    if (answerId) {
      answer.splice(
        answer.findIndex((c) => c.questionSortId === question.questionSortId),
        1
      )
    }
    const tempAnswer: Answer = {
      id: question.id,
      questionSortId: question.questionSortId,
      choiceId: Number(e.target.value)
    }
    setAnswer((_) => [...answer, tempAnswer])
    onMoveAnimation(false)
  }
  return (
    <Box>
      <FormControl variant="outlined" className={classes.context}>
        <InputLabel id={String(question.questionSortId)} />
        <Hidden mdDown>
          <Select
            labelId={String(question.questionSortId)}
            id={String(question.questionSortId)}
            value={selectValue ? selectValue : 0}
            onChange={(e) => onChange(e)}
            name={String(question.questionSortId)}
            className={classes.selectForm}
          >
            {question.options.map((x: any) => (
              <MenuItem key={x} value={x?.value}>
                {x?.label}
              </MenuItem>
            ))}
          </Select>
        </Hidden>
        <Hidden lgUp>
          {/*２行のものはmarginの値を変更 */}
          <Box mt={question.questionString.length >= 20 ? -2 : -4}>
            <Select
              labelId={String(question.questionSortId)}
              id={String(question.questionSortId)}
              value={selectValue ? selectValue : 0}
              onChange={(e) => onChange(e)}
              name={String(question.questionSortId)}
              className={classes.selectFormSP}
            >
              {question.options.map((x) => (
                <MenuItem key={x?.value} value={x?.value}>
                  {x?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Hidden>
      </FormControl>
    </Box>
  )
}

export default PullDownSelect
