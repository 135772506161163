import React, { useState } from 'react'
import { Box, Hidden, Container, Button, Link, makeStyles, IconButton } from '@material-ui/core'
import Styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

const BlueLink = Styled.a`
  color: #041E42;
  font-family: Cera Pro;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  text-decoration: none;
`

const EvidenceOpenButtonSP = Styled(Button)`
  position: absolute;
  top:31px;
  right: 10px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  background-color: #00D4A1;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size:32px;
  &:hover{
    background-color: #00D4A1;
  }
`

export const EvidenceOpenButton = Styled(Button)`
  position: absolute;
  right: 0px;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  background-color: #00D4A1;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size:32px;
  &:hover{
    background-color: #00D4A1;
  }
`

export const IHackdetails = Styled(Box)`
  position:relative;
  width:100%;
  padding:0px 120px:
  border-radius:8px;
  box-shadow:0px 12px 16px rgba(0,0,0,0.05);
  background-color:#FFF;
  list-style:none;
  margin:0 auto;
  transition:.5s;
}
`

export const IHacksummary = Styled.summary`
  position:relative;
  display: block;
  font-size:24px;
  outline:none;
  list-style:none;
  vertical-align:middle;
  color: #041E42;
  cursor:pointer;
  &::-webkit-details-marker {
  display: none;
  }
`

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: 'YuGothic';
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 50px;
color: #041E42;
`
const H3YuGothic = Styled.h3`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 32px;
color: #041E42;
`

const H3SpYuGothic = Styled.h3`
margin:0;
padding:0;
font-family: Cera Pro,YuGothic;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 28px;
display: flex;
align-items: center;
color: #041E42;
`

const PSpYuGothic = Styled.p`
margin:0;
padding:0;
font-family: YuGothic;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
color: #041E42;
`

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightxs = '24px'
const lineHeightsm = '24px'
const lineHeightmd = '40px'

const useStyles = makeStyles({
  baseBox: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 2px 5px 2px rgba(0, 212, 161, .5)'
    }
  },
  openBox: {
    boxShadow: '0 2px 5px 2px rgba(0, 212, 161, .5)'
  }
})

export const Evidence = (props: {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  total_quote: string
  link: string
  publish: string
  Impact_Factor: string
  Impact_Factor_Year: string
  exists: string
  id?: number
}) => {
  const {
    title,
    sub_title,
    text,
    thesis,
    magazine,
    total_quote,
    link,
    publish,
    Impact_Factor,
    Impact_Factor_Year,
    exists,
    id = 2
  } = props
  const [isOpen, setOpen] = useState(id === 0)
  const classes = useStyles()
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          style={{
            backgroundImage: `url(./Rectangle.png)`,
            backgroundRepeat: 'repeat-y'
          }}
        >
          <Box width="100%" pb={4}>
            <Box
              className={classes.baseBox}
              width={'90%'}
              display={isOpen ? 'block' : 'flex'}
              alignContent="center"
              alignItems="center"
              height={isOpen ? 'auto' : '100px'}
              py={'30px'}
              px={'5%'}
              bgcolor="#fff"
              boxShadow={4}
              borderRadius={8}
            >
              <Box
                display="flex"
                alignContent="center"
                alignItems="center"
                bgcolor="#fff"
                justifyContent="space-between"
                width="100%"
                height="100px"
              >
                <Box>
                  <H2YuGothic>{title}</H2YuGothic>
                  <H3YuGothic style={{ textAlign: 'left', flexGrow: 2 }}>&nbsp;{sub_title}</H3YuGothic>
                </Box>
                <Box width="40px" height="40px" textAlign="center">
                  <IconButton onClick={() => setOpen(!isOpen)} style={{ margin: 0, padding: 0 }}>
                    {isOpen ? (
                      <RemoveCircle style={{ fontSize: 40, color: '#00D4A1' }} />
                    ) : (
                      <AddCircle style={{ fontSize: 40, color: '#00D4A1' }} />
                    )}
                  </IconButton>
                </Box>
              </Box>

              <Box display={isOpen ? 'block' : 'none'}>
                <p
                  style={{
                    marginTop: '32px',
                    width: '80%',
                    color: `${textColor}`,
                    fontSize: `${fontSizexs}`,
                    lineHeight: `${lineHeightmd}`,
                    letterSpacing: `${letterSpacingsm}`,
                    fontFamily: 'YuGothic'
                  }}
                >
                  {text}
                </p>
              </Box>

              <Box
                width="inherit"
                px={4}
                pt={3}
                pb={2}
                bgcolor="#F2F4F6"
                fontFamily="Cera Pro"
                display={isOpen ? 'block' : 'none'}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    color: `${textColor}`,
                    fontSize: `${fontSizexxs}`,
                    lineHeight: `${lineHeightsm}`,
                    letterSpacing: `${letterSpacingsm}`,
                    fontFamily: 'YuGothic'
                  }}
                >
                  論文：{thesis} ({publish})
                </p>
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    color: `${textColor}`,
                    fontSize: `${fontSizexxs}`,
                    lineHeight: `${lineHeightsm}`,
                    letterSpacing: `${letterSpacingsm}`,
                    fontFamily: 'YuGothic'
                  }}
                >
                  掲載誌：{magazine}（<span>Impact Factor：{Impact_Factor}</span>/ {Impact_Factor_Year}年 ）
                </p>
                <Box
                  style={{
                    fontSize: `${fontSizexs}`,
                    textAlign: 'right'
                  }}
                >
                  <BlueLink href={`${link}`} target={`blank`}>
                    論文を見る（外部サイトに遷移します）　
                    <img src="arrow.png" alt="" />
                  </BlueLink>
                </Box>
              </Box>
              <Box display={isOpen ? 'block' : 'none'}>
                <h3
                  style={{
                    fontSize: '20px',
                    margin: '42px 0',
                    color: `${textColor}`,
                    fontFamily: 'YuGothic'
                  }}
                >
                  臨床試験データ
                </h3>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: 'inherit'
                  }}
                >
                  <Box style={{ textAlign: 'center' }}>
                    <Box>
                      <img src="../accordion/img1.png" alt="" style={{ width: '50px' }} />
                    </Box>
                    <p
                      style={{
                        fontSize: `${fontSizexxs}`,
                        color: `${textColor}`,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      論文タイプ
                    </p>
                    <p
                      style={{
                        fontSize: `${fontSizexs}`,
                        color: `${textColor}`,
                        lineHeight: '8px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      レビュー論文
                    </p>
                    <p
                      style={{
                        fontSize: `${fontSizexxs}`,
                        color: `${textColor}`,
                        fontWeight: 'bold',
                        lineHeight: '0',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      (総引用数&nbsp;&nbsp;:&nbsp;&nbsp;{total_quote}回)
                    </p>
                  </Box>

                  <Box style={{ textAlign: 'center' }}>
                    <Box>
                      <img src="../accordion/img2.png" alt="" style={{ width: '50px' }} />
                    </Box>
                    <p
                      style={{
                        fontSize: `${fontSizexxs}`,
                        color: `${textColor}`,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      二重盲検ランダム化比較試験
                    </p>
                    <p
                      style={{
                        fontSize: `${fontSizexs}`,
                        color: `${textColor}`,
                        lineHeight: '8px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      {exists}
                    </p>
                  </Box>

                  <Box style={{ textAlign: 'center' }}>
                    <Box>
                      <img src="../accordion/img4.png" alt="" style={{ width: '50px' }} />
                    </Box>
                    <p
                      style={{
                        fontSize: `${fontSizexxs}`,
                        color: `${textColor}`,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      Impact Factor
                    </p>
                    <p
                      style={{
                        fontSize: `${fontSizexs}`,
                        color: `${textColor}`,
                        lineHeight: '8px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      {Impact_Factor}
                    </p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        <>
          <Container
            style={{
              width: '100%',
              backgroundImage: `url(../Rectangle.png)`,
              backgroundRepeat: 'repeat-y'
            }}
          >
            <Box pb={3} display="flex" justifyContent="center">
              <Box
                width="95%"
                borderRadius="8px"
                boxShadow="0px 12px 16px rgba(0, 0, 0, 0.05)"
                bgcolor="#fff"
                height={isOpen ? 'auto' : '122px'}
                className={isOpen ? classes.openBox : ''}
              >
                <Box
                  width="90%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  height="122px"
                  onClick={() => setOpen(!isOpen)}
                  px={2}
                >
                  <Box display="flex" alignItems="center">
                    <Box>
                      <H3SpYuGothic>{title}</H3SpYuGothic>
                      <H3SpYuGothic style={{ fontWeight: 'normal', fontSize: '16px', marginTop: '8px' }}>
                        &nbsp;{sub_title}
                      </H3SpYuGothic>
                    </Box>
                  </Box>
                  <Box width="40px" height="40px" textAlign="center">
                    <IconButton onClick={() => setOpen(!isOpen)} style={{ margin: 0, padding: 0 }}>
                      {isOpen ? (
                        <RemoveCircle style={{ fontSize: 40, color: '#00D4A1' }} />
                      ) : (
                        <AddCircle style={{ fontSize: 40, color: '#00D4A1' }} />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                <Box display={isOpen ? 'block' : 'none'}>
                  <Box mt={3} mx={3}>
                    <PSpYuGothic style={{ whiteSpace: 'pre-wrap' }}>{text}</PSpYuGothic>
                  </Box>
                  <Box
                    mt={2}
                    mx={2}
                    pt={2}
                    pb={3}
                    px={2}
                    width="inherit"
                    style={{
                      fontFamily: 'Cera Pro',
                      backgroundColor: '#F2F4F6'
                    }}
                  >
                    <p
                      style={{
                        color: `${textColor}`,
                        fontSize: `${fontSizexxs}`,
                        lineHeight: `${lineHeightxs}`,
                        letterSpacing: `${letterSpacingsm}`,
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      論文：{thesis} ({publish})
                    </p>
                    <p
                      style={{
                        color: `${textColor}`,
                        fontSize: `${fontSizexxs}`,
                        lineHeight: `${lineHeightxs}`,
                        letterSpacing: `${letterSpacingsm}`,
                        fontFamily: 'Cera Pro'
                      }}
                    >
                      掲載誌：{magazine}（<span>Impact Factor：{Impact_Factor}</span>/ {Impact_Factor_Year}年 ）
                    </p>
                    <Box
                      style={{
                        fontSize: `${fontSizexs}`,
                        textAlign: 'right'
                      }}
                    >
                      <BlueLink href={`${link}`} target={`blank`}>
                        論文を見る（外部サイトに遷移します）
                        <img src="../arrow.png" alt="" />
                      </BlueLink>
                    </Box>
                  </Box>
                  <Box mt={4} mb={4}>
                    <h3
                      style={{
                        fontSize: '24px',
                        color: `${textColor}`,
                        textAlign: 'center',
                        fontFamily: 'YuGothic'
                      }}
                    >
                      臨床試験データ
                    </h3>
                    <Box
                      mt={4}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: 'inherit'
                      }}
                    >
                      <Box style={{ textAlign: 'center' }}>
                        <Box>
                          <img src="../accordion/img1.png" alt="" style={{ width: '50px' }} />
                        </Box>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexxs}`,
                            color: `${textColor}`,
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          論文タイプ
                        </p>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexs}`,
                            color: `${textColor}`,
                            lineHeight: '8px',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          レビュー論文
                        </p>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexxs}`,
                            color: `${textColor}`,
                            fontWeight: 'bold',
                            lineHeight: '0',
                            textAlign: 'center'
                          }}
                        >
                          (総引用数&nbsp;&nbsp;:&nbsp;&nbsp;{total_quote}回)
                        </p>
                      </Box>

                      <Box style={{ textAlign: 'center' }}>
                        <Box>
                          <img src="../accordion/img2.png" alt="" style={{ width: '50px' }} />
                        </Box>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexxs}`,
                            color: `${textColor}`,
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          二重盲検ランダム化
                          <br />
                          比較試験
                        </p>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexs}`,
                            color: `${textColor}`,
                            lineHeight: '8px',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          {exists}
                        </p>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: 'inherit',
                        marginTop: '20px'
                      }}
                    >
                      <Box style={{ textAlign: 'center' }}>
                        <Box>
                          <img src="../accordion/img4.png" alt="" style={{ width: '50px' }} />
                        </Box>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexxs}`,
                            color: `${textColor}`,
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          Impact Factor
                        </p>
                        <p
                          style={{
                            fontFamily: 'Cera Pro',
                            fontSize: `${fontSizexs}`,
                            color: `${textColor}`,
                            lineHeight: '8px',
                            fontWeight: 'bold',
                            textAlign: 'center'
                          }}
                        >
                          {Impact_Factor}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </>
      </Box>
    </Box>
  )
}

export default Evidence
