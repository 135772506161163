import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Styled from 'styled-components'
import { Box, Hidden, Container, Button, Link } from '@material-ui/core'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Text } from '../chill'
import { SecondTitle } from '../chill'
import Evidence from '../Evidence'
import Header from '../../../Header'
import { ProductDetail } from '../chill'
import { url } from 'inspector'
import { SplTopSP, EvidenceListSP, ProductDetailSP } from '../sp/chill'

const H2YuGothic = Styled.h2`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
text-align: center;
letter-spacing: 0.1em;
color: #041E42;
text-align:left;
`

export type EvidenceProps = {
  title: string
  sub_title: string
  text: string
  thesis: string
  magazine: string
  Impact_Factor: string
  Impact_Factor_Year: string
  publish: string
  link: string
  total_quote: string
  exists: string
}[]

const textColor: string = '#041E42'

const fontSizexxs: string = '12px'
const fontSizexs: string = '14px'
const fontSizes: string = '16px'
const fontSizesm: string = '18px'
const fontSizemd: string = '20px'
const fontSizelg: string = '32px'
const fontSizexl: string = '64px'

const letterSpacingsm = '0.06em'
const letterSpacingmd = '0.1em'
const letterSpacinglg = '0.25em'

const lineHeightsm = '30px'
const lineHeightmd = '40px'

const spSPM: string = '../supplements/Chill/SP_TOP_BG.png'
const pcTopBgURL: string = '../supplements/Chill/PC_PRODUCT_BG.png'
const SpTopBgURL: string = '../supplements/Chill/SP_PRODUCT_BG.png'
const title = 'CHILL'
const sub_title = 'Change is as good as a rest.'
const text =
  '全力で挑戦するためには、全力で休むことが大切です。忙しい日々の中で、十分に休む時間を確保するのは難しいこと。Chillには、リラックスやストレス、睡眠に関わる４種類の成分を 贅沢に配合しています。頑張るあなたの休息をサポートするサプリ、Chill。 '
const containtext =
  'Chillは、リラックスサポートのL-トリプトファンや落ち込みサポートのロディオラ・ロゼアをベースに、4種類の成分を配合したサプリメントです。 プレッシャーを感じた時、リラックスしたい時にご利用ください。'

const product_name = 'CHILL'
const product_amount =
  'GABA：28mg、サリドロシド：0.2μg、ロサビン：0.5mg、トータルロサビン：1.0mg、トリプトファン：200mg、マグネシウム：60mg'
const product_materials =
  '大麦乳酸発酵液GABA、ロゼア乾燥エキス末、L-トリプトファン、酸化マグネシウム、還元麦芽糖水飴、麦芽糖、セルロース、ステアリン酸Ca、微粒二酸化ケイ素、HPC、シェラック'
const product_allergy = 'なし'
const product_agent_type = '../soft.png'
const productBgSp = '../supplements/Chill/SP_SPM.png'
const productBgPc = '../supplements/Chill/PC_SPM.png'
const evidences: EvidenceProps = [
  {
    title: 'ロディオラ・ロゼア',
    sub_title: '-   落ち込み軽減',
    text:
      'CHILLに含まれているロディオラ・ロゼアは、ヨーロッパやアジアの寒冷で高緯度の地域に生息している植物です。とりわけ、この根には抵抗力があります。論文によると「うつ病の診断を受けた89人を対象とした臨床試験において、340mg/dayのロディオラ・ロゼアを42日間摂取したグループは、プラセボを投与されたグループと比較して鬱の諸症状を大幅に軽減した。」と示されています。',
    thesis: 'Clinical trial of Rhodiola rosea L. extract SHR-5 in the treatment of mild to moderate depression',
    magazine: 'Nordic Journal of Psychiatry',
    Impact_Factor: '1.08',
    Impact_Factor_Year: '2016',
    publish: '2007',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/17990195',
    total_quote: '230',
    exists: 'あり'
  },
  {
    title: 'GABA',
    sub_title: '-  ストレス軽減',
    text:
      'GABAは神経伝達物質の一種です。GABAを摂取することで脳内が副交感神経優位となり、リラックス時に放出されるα波が増加することが確認されています。そのことから、ストレスを和らげたり、脳の興奮を鎮めたりする効果が期待されています。論文によると「2018年に不眠症の患者40人を対象とした小規模臨床試験において、 入眠の1時間前に300mgのGABAを経口摂取したグループは、プラセボ群と比較して入眠までの時間を短縮したことが示された。継続投与４週間で睡眠の質が改善したことも併せて報告されている。」と示されています。',
    thesis:
      'Safety and Efficacy of Gamma-Aminobutyric Acid from Fermented Rice Germ in Patients with Insomnia Symptoms: A Randomized, Double-Blind Trial',
    magazine: 'journal of clinical neurology',
    Impact_Factor: '1.736',
    Impact_Factor_Year: '2017',
    publish: '2018',
    link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6031986/',
    total_quote: '9',
    exists: 'あり'
  },
  {
    title: 'L-トリプトファン',
    sub_title: '-  睡眠をサポート',
    text:
      'トリプトファンは、体内でセロトニンに変換される必須アミノ酸です。セロトニンは別名しあわせホルモンと呼ばれており、減少することで感情や精神、睡眠等に悪影響を及ぼすことが確認されています。論文によると「入眠に問題（覚醒状態から眠りに入るまでの所要時間が30分以上）を持つ男性15名を対象とした臨床試験において、250mg〜1,000mgのトリプトファンを経口摂取した結果、全ての投与量で入眠時間が改善された。この結果から、トリプトファンには入眠サポート機能があると考えられる。」と示されています。',
    thesis: 'Sleep Induced by L-tryptophan. Effect of Dosages Within the Normal Dietary Intake',
    magazine: 'The Journal of Nervous and Mental Disease',
    Impact_Factor: '1.682',
    Impact_Factor_Year: '2011',
    publish: '1979',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/469515',
    total_quote: '139',
    exists: 'あり'
  }
]

const maxWidth = '1000px'
const minWidth = '700px'

const MergeChill = () => {
  return (
    <Box>
      <Box display={window.innerWidth > 1100 ? 'inline' : 'none'}>
        <Box
          width="100%"
          bgcolor="#F2F4F6"
          style={{
            backgroundImage: `url(${pcTopBgURL})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover'
          }}
        >
          <SplTopWithoutHeader title={title} sub_title={sub_title} text={text} containtext={containtext} />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" bgcolor="#F2F4F6">
          <Box width="100%" maxWidth={maxWidth} minWidth={minWidth} mt={13} mx={7}>
            <H2YuGothic style={{ marginBottom: '32px' }}>エビデンス一覧</H2YuGothic>
            <PrintEvidences evidences={evidences} />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          style={{
            backgroundImage: `url(${productBgPc})`,
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            objectFit: 'cover'
          }}
        >
          <Box maxWidth={maxWidth} minWidth={minWidth} mt={13} mb={13}>
            <ProductDetail
              name={product_name}
              amount={product_amount}
              materials={product_materials}
              allergy={product_allergy}
              agent_type={product_agent_type}
            />
          </Box>
        </Box>
      </Box>
      <Box display={window.innerWidth <= 1100 ? 'inline' : 'none'}>
        {/* スマホ&タブレット View start*/}
        <Box width="100%" style={{ fontFamily: 'Cera Pro' }}>
          <SplTopSP
            spSPM={spSPM}
            bgimgURL={SpTopBgURL}
            title={title}
            sub_title={sub_title}
            text={text}
            containtext={containtext}
          />
          <EvidenceListSP />
          <PrintEvidences evidences={evidences} />
          <ProductDetailSP
            name={product_name}
            amount={product_amount}
            materials={product_materials}
            allergy={product_allergy}
            agent_type={product_agent_type}
            bgimg={productBgSp}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SplTopWithoutHeader = (props: { title: string; sub_title: string; text: string; containtext: string }) => {
  const { title, text, sub_title, containtext } = props
  return (
    <Box display="flex" justifyContent="center">
      <Box mt={0} pt={'15%'} maxWidth={maxWidth} minWidth={minWidth} mx={7}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box width="55%">
            <h2
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizexl}`,
                color: `${textColor}`,
                letterSpacing: '0.1em'
              }}
            >
              {title}
            </h2>
            <Text
              style={{
                margin: 0,
                padding: 0,
                fontSize: `${fontSizesm}`
              }}
            >
              {sub_title}
            </Text>
            <p
              style={{
                marginTop: '32px',
                padding: 0,
                marginBottom: 0,
                color: `${textColor}`,
                fontSize: `${fontSizes}`,
                fontWeight: 'bold',
                lineHeight: `${lineHeightmd}`,
                letterSpacing: `${letterSpacinglg}`,
                fontFamily: 'YuGothic'
              }}
            >
              {text}
            </p>
          </Box>
          <Box>
            <img src="../supplements/Chill/PC_TOP_BG.png" width="320px" />
          </Box>
        </Box>
        <Box width="70%" mt={8}>
          <SecondTitle
            style={{
              fontSize: `${fontSizemd}`,
              fontWeight: 'bold'
            }}
          >
            どんな成分か？
          </SecondTitle>
          <p
            style={{
              marginTop: '10px',
              marginBottom: '0',
              color: `${textColor}`,
              fontSize: '16px',
              lineHeight: `${lineHeightmd}`,
              letterSpacing: `${letterSpacingsm}`,
              fontFamily: 'YuGothic'
            }}
          >
            {containtext}
          </p>
        </Box>
      </Box>
    </Box>
  )
}

const PrintEvidences = (props: { evidences: EvidenceProps }) => {
  const { evidences } = props
  return (
    <>
      {evidences.map((c, i) => {
        return (
          <Box key={i}>
            <Evidence
              title={c.title}
              sub_title={c.sub_title}
              text={c.text}
              thesis={c.thesis}
              magazine={c.magazine}
              Impact_Factor={c.Impact_Factor}
              Impact_Factor_Year={c.Impact_Factor_Year}
              publish={c.publish}
              link={c.link}
              total_quote={c.total_quote}
              exists={c.exists}
              id={i}
            />
          </Box>
        )
      })}
    </>
  )
}

export default MergeChill
