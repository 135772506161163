import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Answer, MostWories, WoriesObj, Question } from '../../../../types/Score'
import ChoiceOfAnswer from '../ChoiceOfAnswers'
import Radio from '@material-ui/core/Radio'
import RadioButtonIcon from '../../../../components/container/diagnosis/question/common/RadioButtonIcon'
import RadioBottons from '../../../../components/container/diagnosis/question/type/radio/FiveOption'
import { nl2br } from '../../../../function'
import RadioGroup from '@material-ui/core/RadioGroup'
import Text from '../util/Text'

type Props = {
  question: Question
  useStyles: any
  answer: Answer[]
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
}

const FieldButton = (props: Props) => {
  const { question, useStyles, answer, setAnswer, onMoveAnimation } = props
  const answerId = answer.filter((c) => c.questionSortId === question.questionSortId)?.[0]?.choiceId
  const [selectValue, setSelectValue] = useState(answerId ? answerId : 0)
  const classes = useStyles()

  const onChange = (choiceId: number) => {
    setSelectValue(choiceId)
    if (answerId) {
      answer.splice(
        answer.findIndex((c) => c.questionSortId === question.questionSortId),
        1
      )
    }
    const tempAnswer: Answer = {
      id: question.id,
      questionSortId: question.questionSortId,
      choiceId: choiceId
    }
    setAnswer((_) => [...answer, tempAnswer])
    onMoveAnimation(false)
  }
  return (
    <Box className={classes.context}>
      {question.options.map((x, index) => {
        return (
          <>
            <Hidden lgUp>
              <Box width="216px" height="41px" mt={index === 0 ? -3 : 3} margin="auto">
                <Button
                  fullWidth
                  onClick={() => onChange(x.value)}
                  variant="outlined"
                  style={{
                    height: '52px',
                    border: selectValue === x.value ? '1px solid #041E42' : '1px solid #AFAFAF'
                  }}
                >
                  <Text
                    ff="YuGothic"
                    fw="bold"
                    fs={16}
                    lh={'29px'}
                    ls="0.1em"
                    style={{ margin: 0, padding: 0, color: selectValue === x.value ? '#041E42' : '#AFAFAF' }}
                  >
                    {x.label}
                  </Text>
                </Button>
              </Box>
            </Hidden>
            <Hidden mdDown>
              <Box width="400px" height="54px" mt={index === 0 ? 0 : 2}>
                <Button
                  fullWidth
                  onClick={() => onChange(x.value)}
                  variant="outlined"
                  style={{
                    height: '54px',
                    border: selectValue === x.value ? '2px solid #041E42' : '2px solid #AFAFAF'
                  }}
                >
                  <Text
                    ff="YuGothic"
                    fw="bold"
                    fs={16}
                    lh={'29px'}
                    ls="0.1em"
                    style={{ margin: 0, padding: 0, color: selectValue === x.value ? '#041E42' : '#AFAFAF' }}
                  >
                    {x.label}
                  </Text>
                </Button>
              </Box>
            </Hidden>
          </>
        )
      })}
      {/* 
      <Button
        name={String(question.questionSortId)}
        onChange={() => onChange(3)}
        value={selectValue ? selectValue : 0}
        style={{ display: 'flex' }}
      >
        {question.options.map((x) => (
          <>
            <Box marginLeft={4} marginRight={4} display="inline-block">
              <Radio
                value={x?.value}
                style={{}}
                icon={
                  <>
                    <Hidden mdDown>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        width={400}
                        height={54}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text ff="YuGothic" fw="bold" fs={16} lh={'29px'} ls="0.1em" style={{ margin: 0, padding: 0 }}>
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                    <Hidden lgUp>
                      <Box
                        borderRadius={'4px'}
                        border="1px solid #041E42"
                        width={230}
                        height={52}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text ff="YuGothic" fw="bold" fs={14} lh={'29px'} ls="0.1em" style={{ margin: 0, padding: 0 }}>
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                  </>
                }
                checkedIcon={
                  <>
                    <Hidden mdDown>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        bgcolor="#041E42"
                        width={400}
                        height={54}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text
                          ff="YuGothic"
                          fw="bold"
                          fs={16}
                          lh={'29px'}
                          ls="0.1em"
                          color="white"
                          style={{ margin: 0, padding: 0 }}
                        >
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                    <Hidden lgUp>
                      <Box
                        borderRadius={'4px'}
                        border="2px solid #041E42"
                        bgcolor="#041E42"
                        width={230}
                        height={52}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        textAlign="center"
                      >
                        <Text
                          ff="YuGothic"
                          fw="bold"
                          fs={14}
                          lh={'29px'}
                          ls="0.1em"
                          color="white"
                          style={{ margin: 0, padding: 0 }}
                        >
                          {x?.label}
                        </Text>
                      </Box>
                    </Hidden>
                  </>
                }
              />
            </Box>
          </>
        ))}
      </Button>
    */}
    </Box>
  )
}

export default FieldButton
