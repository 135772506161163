import React from 'react'
import Box from '@material-ui/core/Box'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBox from '@material-ui/core/Checkbox'
import { Question } from '../../../types/Score'

import RadioButtonRoot from './question/type/RadioButtonRoot'
import FieldButtonRoot from './question/type/FiledButtonRoot'
import PullDownSelect from './question/type/PullDownSelect'
import NameTextField from './question/type/NameTextField'
import WorriedSelect from './question/type/select/WorriedSelect'

type Classes = Record<
  | 'root'
  | 'icon'
  | 'title'
  | 'context'
  | 'radioForm'
  | 'radioIcon'
  | 'radioCheckedIcon'
  | 'selectForm'
  | 'selectFormSP'
  | 'radioIconSP'
  | 'radioCheckedIconSP',
  string
>

type Props = {
  question: Question
  selectValue: number
  onSubmit: (event: any) => void
  classes: Classes
  name: string
  onNameChange: (event: any) => void
  onClick: () => void
  onKeyDown: (event: any, isSp: boolean) => void
  mostWorryied: any
  onMostWorriedChange: (event: any) => void
  choices: string[]
}
const ChoiceOfAnswer = (props: Props) => {
  const {
    question,
    selectValue,
    classes,
    onSubmit,
    name,
    onNameChange,
    onClick,
    onKeyDown,
    mostWorryied,
    onMostWorriedChange,
    choices
  } = props
  switch (question.type) {
    case 'RadioButton':
      return (
        <>
          <RadioButtonRoot
            classes={classes}
            questionSortId={String(question.questionSortId)}
            onSubmit={onSubmit}
            selectValue={selectValue}
            options={question.options}
          />
        </>
      )
    case 'PullDown':
      return (
        <>
          <PullDownSelect
            classes={classes}
            questionSortId={String(question.questionSortId)}
            options={question.options}
            onSubmit={onSubmit}
            selectValue={selectValue}
          />
        </>
      )
    case 'CheckBoxs':
      return (
        <Box className={classes.context}>
          <FormLabel component="legend" />
          <FormGroup>
            {question?.options.map((x: any) => (
              <FormControlLabel key={x} control={<CheckBox />} label={x?.label} />
            ))}
          </FormGroup>
        </Box>
      )
    case 'FiledButton':
      return (
        <>
          <FieldButtonRoot
            classes={classes}
            questionSortId={String(question.questionSortId)}
            onSubmit={onSubmit}
            selectValue={selectValue}
            options={question.options}
          />
        </>
      )
    case 'TextFiled':
      return (
        <>
          <NameTextField
            questionSortId={String(question.questionSortId)}
            onKeyDown={onKeyDown}
            onNameChange={onNameChange}
            name={name}
          />
        </>
      )
    case 'WorriedSelect':
      return (
        <>
          <WorriedSelect
            classes={classes}
            questionSortId={String(question.questionSortId)}
            mostWorryied={mostWorryied}
            onMostWorriedChange={onMostWorriedChange}
            choices={choices}
          />
        </>
      )
    default:
      return <></>
  }
}

export default ChoiceOfAnswer
