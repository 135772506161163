import React, { useEffect } from 'react'
import { Box, Link, Hidden, Grid } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'

const H1PcYuGothic = Styled.h1`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const H2PcYuGothic = Styled.h2`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 54px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const PPcYuGothic = Styled.p`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 54px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin-top: 0;
    margin-bottom: 24px;
    `

const SpanPcCeraPro = Styled.span`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: '16px';
    line-height: '35px';
    letter-spacing: '0.1em';
    color: #041E42;
    margin: 0;`

const H1SpYuGothic = Styled.h1`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 45px;
    letter-spacing: 0.08em;
    color: #041E42;
    margin: 0;`

const H2SpYuGothic = Styled.h2`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 60px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin: 0;`

const PSpYuGothic = Styled.p`
    font-family: YuGothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #041E42;
    margin-top: 0;
    margin-bottom: 24px;
    `

const SpanSpCeraPro = Styled.span`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: bold;
    font-size: '14px';
    line-height: '18px';
    letter-spacing: '0.1em';
    color: #041E42;
    margin: 0;`

const Low = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  )
}

const Main = () => {
  return (
    <>
      <Hidden xsDown>
        <Box width="65vw" mx="auto" mb={10} pt={20}>
          <H1PcYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>【特定商取引に基づく表記】</H1PcYuGothic>

          <Box textAlign="left">
            <Grid container spacing={6}>
              <Grid item lg={4}>
                <H2PcYuGothic>販売事業者</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  株式会社<SpanPcCeraPro>iHack</SpanPcCeraPro>（<SpanPcCeraPro>iHack</SpanPcCeraPro>, Inc.）
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>代表者</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>富田真人（とみた　まさと）</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>ホームページ</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <Link href="https://supplement.ihack.co.jp/">
                  <PPcYuGothic>https://supplement.ihack.co.jp/</PPcYuGothic>
                </Link>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>所在地</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  〒160-0003
                  <br />
                  東京都新宿区四谷本塩町2-8 A YOTSUYA 4N/3
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>連絡先</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  TEL:080-1318-4455
                  <br />
                  MAIL:support@ihack.energy
                  <br />
                  受付時間:10:00-18:00（土日祝を除く）
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>販売価格帯</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>商品ページに税別金額を記載</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>製造について</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  iHackは以下にて製造を行っております
                  <br />
                  iHack パーソナライズサプリメント
                  <br />
                  【販売者】 株式会社iHack
                  <br />
                  〒160-0003 東京都新宿区四谷本塩町2-8
                  <br />
                  【製造所】 株式会社AFC-HD アムスライフサイエンス
                  <br />
                  ▼本社工場(AH1) 〒422-8027 静岡県静岡市駿河区豊田三丁目 6番 36号
                  <br />
                  ▼第二工場(AH2) 〒422-8027 静岡県静岡市駿河区豊田二丁目 4番 3号
                  <br />
                  ▼国吉田工場(AH3) 〒422-8004 静岡県静岡市駿河区国吉田二丁目 6番 7号
                  <br />
                  ▼千葉工場(AH4) 〒297-0143 千葉県長生郡長南町美原台 1番 21号
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>配送業者</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>ヤマト運輸</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>送料</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>全国一律、500円（税込）の送料がかかります。</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>お届け時期</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  ご注文から2〜5日でのお届けとなります。
                  <br />
                  ※お届け場所により異なる場合があります。
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>お支払い方法</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  クレジットカード決済
                  <br />
                  AmazonPay
                  <br />
                  ※ご利用のクレジットカードの締め日によって決済日が異なります。ご利用カードをご確認の上、ご注文ください。
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>返品・返金</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  原則、注文手続完了後のお客様のご都合による注文のキャンセル、返品、交換のご希望はお受けすることはできません。
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H2PcYuGothic>解約</H2PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  次回配送予定日の10日前までに解約申請を行っていただければ、いつでも定期コースの解約を承ることが可能です。
                </PPcYuGothic>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box width="84vw" mx="auto" mb={10} pt={20}>
          <H1SpYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>【特定商取引に基づく表記】</H1SpYuGothic>

          <H2SpYuGothic>販売事業者</H2SpYuGothic>
          <PSpYuGothic>
            株式会社<SpanSpCeraPro>iHack</SpanSpCeraPro>
          </PSpYuGothic>

          <H2SpYuGothic>代表者</H2SpYuGothic>
          <PSpYuGothic>富田真人</PSpYuGothic>

          <H2SpYuGothic>ホームページ</H2SpYuGothic>
          <Link href="https://supplement.ihack.co.jp/">
            <PSpYuGothic>https://supplement.ihack.co.jp/</PSpYuGothic>
          </Link>

          <H2SpYuGothic>メールアドレス</H2SpYuGothic>
          <PSpYuGothic>support@ihack.energy</PSpYuGothic>

          <H2SpYuGothic>所在地</H2SpYuGothic>
          <PSpYuGothic>
            〒160-0003
            <br />
            東京都新宿区四谷本塩町2-8 A YOTSUYA 4N/3
          </PSpYuGothic>

          <H2SpYuGothic>連絡先</H2SpYuGothic>
          <PSpYuGothic>
            TEL:080-1318-4455
            <br />
            MAIL:support@ihack.energy
            <br />
            受付時間:10:00-18:00（土日祝を除く）
          </PSpYuGothic>

          <H2SpYuGothic>販売価格帯</H2SpYuGothic>
          <PSpYuGothic>商品ページに税別金額を記載</PSpYuGothic>

          <H2SpYuGothic>製造について</H2SpYuGothic>
          <PSpYuGothic>
            iHackは以下にて製造しております。
            <br />
            iHack パーソナライズサプリメント
            <br />
            【販売者】 株式会社iHack
            <br />
            〒160-0003 東京都新宿区四谷本塩町2-8
            <br />
            【製造所】 株式会社AFC-HD アムスライフサイエンス
            <br />
            ▼本社工場(AH1) 〒422-8027 静岡県静岡市駿河区豊田三丁目 6番 36号
            <br />
            ▼第二工場(AH2) 〒422-8027 静岡県静岡市駿河区豊田二丁目 4番 3号
            <br />
            ▼国吉田工場(AH3) 〒422-8004 静岡県静岡市駿河区国吉田二丁目 6番 7号
            <br />
            ▼千葉工場(AH4) 〒297-0143 千葉県長生郡長南町美原台 1番 21号
          </PSpYuGothic>

          <H2SpYuGothic>配送業者</H2SpYuGothic>
          <PSpYuGothic>ヤマト運輸</PSpYuGothic>

          <H2SpYuGothic>送料</H2SpYuGothic>
          <PSpYuGothic>全国一律、500円（税込）の送料がかかります。</PSpYuGothic>

          <H2SpYuGothic>お届け時期</H2SpYuGothic>
          <PSpYuGothic>
            ご注文から2〜5日でのお届けとなります。
            <br />
            ※お届け場所により異なる場合があります。
          </PSpYuGothic>

          <H2SpYuGothic>お支払い方法</H2SpYuGothic>
          <PSpYuGothic>
            クレジットカード決済
            <br />
            AmazonPay
            <br />
            ※ご利用のクレジットカードの締め日によって決済日が異なります。ご利用カードをご確認の上、ご注文ください。
          </PSpYuGothic>
          <PSpYuGothic></PSpYuGothic>

          <H2SpYuGothic>返品・返金</H2SpYuGothic>
          <PSpYuGothic>
            原則、注文手続完了後のお客様のご都合による注文のキャンセル、返品、交換のご希望はお受けすることはできません。
          </PSpYuGothic>

          <H2SpYuGothic>解約</H2SpYuGothic>
          <PSpYuGothic>
            次回配送予定日の10日前までに解約申請を行っていただければ、いつでも定期コースの解約を承ることが可能です。
          </PSpYuGothic>
        </Box>
      </Hidden>
    </>
  )
}

export default Low
