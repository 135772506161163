import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Lottie from 'react-lottie'
import animationData from './LottieData/data_v2.json'
import Styled from 'styled-components'
import { Answers, Results, Other } from '../../../types/Score/index'
import { withRouter } from 'react-router'
import thema from '../../../style/muiTheme'

type Props = {
  isHidden: boolean
  start: boolean
  others: Other
  ans: Answers
  history: any
}
const moveResultWait = 30
const LoadingView = (props: Props) => {
  const { isHidden, start, ans, others, history } = props
  const defaultOptions = {
    loop: true,
    autoplay: start,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const [per, setPer] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setPer((c) => (start ? (c < 100 + moveResultWait ? c + 1 : c) : 0))
    }, 60)
  })

  const H2CeraPro = Styled.h1`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 100%;
text-align: center;
letter-spacing: 0.15em;
color: #041E42;`

  const H3SpCeraPro = Styled.h3`
margin:0;
padding:0;
font-family: Cera Pro;
font-style: normal;
font-weight: 900;
font-size: 18px;
line-height: 100%;
text-align: center;
letter-spacing: 0.15em;
color: #041E42;
`
  /* xs: 0,
      sm: 600,
      md: 960,
      lg: 1100,
      xl: 1920*/
  if (per >= 100 + moveResultWait) {
    history.push({
      pathname: '/result',
      state: { others: others, ans: ans }
    })
  }
  return (
    <>
      <Hidden mdDown>
        <Box
          width="100%"
          height="100vh"
          display={isHidden ? 'none' : 'flex'}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          bgcolor="white"
          position="absolute"
          top={0}
          left={0}
          zIndex={1000}
        >
          <Box width="900px" textAlign="center">
            <Lottie options={defaultOptions} height={500} width={900} />
          </Box>
          <Box position="absolute" top="30%">
            <H2CeraPro>{per < 33 ? 'CHECKING RESULTS...' : per < 66 ? 'MIXING IT UP...' : 'ALMOST DONE! '}</H2CeraPro>
            <H3SpCeraPro style={{ marginTop: '8px' }}>{per <= 100 ? per : 100}%</H3SpCeraPro>
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box
          width="100%"
          height="100vh"
          display={isHidden ? 'none' : 'flex'}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          bgcolor="white"
          position="fixed"
          top={0}
          left={0}
          zIndex={1000}
        >
          <Box width="300px" textAlign="center">
            <Lottie options={defaultOptions} height={100} width={300} />
          </Box>
          <Box position="absolute" top="30%">
            <H2CeraPro>{per < 33 ? 'CHECKING RESULTS...' : per < 66 ? 'MIXING IT UP...' : 'ALMOST DONE! '}</H2CeraPro>
            <H3SpCeraPro style={{ marginTop: '8px' }}>{per <= 100 ? per : 100}%</H3SpCeraPro>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default LoadingView
