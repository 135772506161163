import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioButtonIcon from '../../common/RadioButtonIcon'

import { nl2br } from '../../../../../../function/index'

const ThreeRadioButtonIcon = [
  <RadioButtonIcon size="pc" color="defalut" />,
  <RadioButtonIcon size="pc" color="defalut" />,
  <RadioButtonIcon size="pc" color="defalut" />
]
const ThreeCheckedRadioButtonIcon = [
  <RadioButtonIcon size="pc" color="defalut" checked={true} />,
  <RadioButtonIcon size="pc" color="defalut" checked={true} />,
  <RadioButtonIcon size="pc" color="defalut" checked={true} />
]
const ThreeRadioButtonIconSP = [
  <RadioButtonIcon size="sp" color="defalut" />,
  <RadioButtonIcon size="sp" color="defalut" />,
  <RadioButtonIcon size="sp" color="defalut" />
]

const ThreeCheckedRadioButtonIconSP = [
  <RadioButtonIcon size="sp" color="defalut" checked={true} />,
  <RadioButtonIcon size="sp" color="defalut" checked={true} />,
  <RadioButtonIcon size="sp" color="defalut" checked={true} />
]

type RadioOptionProps = {
  options: {
    label: string
    value: number
    spLabel: string
  }[]
}

const ThreeOption: React.FC<RadioOptionProps> = ({ options }) => (
  <>
    {options.map((x: any, i: number) => (
      <>
        <Hidden mdDown>
          <Box display="flex" justifyContent="center">
            <Box pl={5} pr={5} mt={2}>
              <Radio
                key={x}
                value={x?.value}
                icon={ThreeRadioButtonIcon[i]}
                checkedIcon={ThreeCheckedRadioButtonIcon[i]}
              />
              <Box mt={3} />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                {nl2br(x?.label)}
              </p>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center">
            <Box pl={2} pr={2} mt={2}>
              <Radio
                key={x}
                value={x?.value}
                icon={ThreeRadioButtonIconSP[i]}
                checkedIcon={ThreeCheckedRadioButtonIconSP[i]}
              />
              <Box mt={2} />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '13px',
                  lineHeight: '140%',
                  letterSpacing: '0.1em',
                  margin: 0,
                  padding: 0
                }}
              >
                {nl2br(x?.spLabel)}
              </p>
            </Box>
          </Box>
        </Hidden>
      </>
    ))}
  </>
)
export default ThreeOption
