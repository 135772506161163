import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { Answer, MostWories, WoriesObj, Question } from '../../../../types/Score'
import AgingIcon from '../../../../components/icon/AgingIcon'
import ConcentrationIcon from '../../../../components/icon/ConcentrationIcon'
import DepressionIcon from '../../../../components/icon/DepressionIcon'
import DietIcon from '../../../../components/icon/DietIcon'
import EyeIcon from '../../../../components/icon/EyeIcon'
import FatigueIcon from '../../../../components/icon/FatigueIcon'
import MemoryIcon from '../../../../components/icon/MemoryIcon'
import MotionIcon from '../../../../components/icon/MotionIcon'
import PhysicalConditionIcon from '../../../../components/icon/PhysicalConditionIcon'
import SleepIcon from '../../../../components/icon/SleepIcon'
import StressIcon from '../../../../components/icon/StressIcon'
import VitalityIcon from '../../../../components/icon/Fire'
import MoveButton from '../../diagnosis/MoveButton'
import { makeStyles } from '@material-ui/core/styles'

import Text from '../util/Text'

type IconCheckBoxProps = {
  question: any
  choices: string[]
  setChoices: (event: any) => void
  setIsChoice: (event: any) => void
  onClick: () => void
}
type Icons = { icon: JSX.Element; name: MostWories }[]

const icons: Icons = [
  { icon: <SleepIcon />, name: '睡眠' },
  { icon: <StressIcon />, name: 'ストレス' },
  { icon: <VitalityIcon />, name: '活力' },
  { icon: <FatigueIcon />, name: '疲労' },
  { icon: <MotionIcon />, name: '運動不足' },
  { icon: <MemoryIcon />, name: '記憶力' },
  { icon: <ConcentrationIcon />, name: '集中力' },
  { icon: <PhysicalConditionIcon />, name: '体調不良' },
  { icon: <EyeIcon />, name: '目の疲労' },
  { icon: <DepressionIcon />, name: 'うつっぽさ' },
  { icon: <DietIcon />, name: 'ダイエット' },
  { icon: <AgingIcon />, name: '身体の衰え' }
]

const hilightIcons: Icons = [
  { icon: <SleepIcon color={'#00D4A1'} />, name: '睡眠' },
  { icon: <StressIcon color={'#00D4A1'} />, name: 'ストレス' },
  { icon: <VitalityIcon color={'#00D4A1'} />, name: '活力' },
  { icon: <FatigueIcon color={'#00D4A1'} />, name: '疲労' },
  { icon: <MotionIcon color={'#00D4A1'} />, name: '運動不足' },
  { icon: <MemoryIcon color={'#00D4A1'} />, name: '記憶力' },
  { icon: <ConcentrationIcon color={'#00D4A1'} />, name: '集中力' },
  { icon: <PhysicalConditionIcon color={'#00D4A1'} />, name: '体調不良' },
  { icon: <EyeIcon color={'#00D4A1'} />, name: '目の疲労' },
  { icon: <DepressionIcon color={'#00D4A1'} />, name: 'うつっぽさ' },
  { icon: <DietIcon color={'#00D4A1'} />, name: 'ダイエット' },
  { icon: <AgingIcon color={'#00D4A1'} />, name: '身体の衰え' }
]

type Props = {
  question: Question
  useStyles: any
  answer: Answer[]
  worries: MostWories[]
  setWories: React.Dispatch<React.SetStateAction<MostWories[]>>
  setAnswer: React.Dispatch<React.SetStateAction<Answer[]>>
  onMoveAnimation: (revers: boolean) => void
}

const CheckBox = (props: Props) => {
  const useStyle = makeStyles({
    baseBox: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
  const classes = useStyle()
  const { question, useStyles, answer, setAnswer, onMoveAnimation, setWories, worries } = props
  const [selectValues, setSelectValues] = useState<MostWories[]>(worries)

  const onChange = (worieTitle: MostWories) => {
    if (selectValues.filter((c) => c === worieTitle).length > 0) {
      setSelectValues((_) => selectValues.filter((c) => c !== worieTitle))
    } else {
      setSelectValues((_) => [...selectValues, worieTitle])
    }
  }
  const nextQuestion = () => {
    setWories((_) => [...selectValues])
  }
  return (
    <>
      <Hidden lgUp>
        <Box
          width="300px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          m={'auto'}
          mt={-4}
        >
          {icons.map((c, index) => (
            <Box width="100px" height="78px" mt={4} onClick={() => onChange(c.name)}>
              <Box width="100px" height="50px" m={'auto'}>
                {selectValues.filter((i) => i === c.name).length > 0 ? hilightIcons[index].icon : c.icon}
              </Box>
              <Box
                mt={1}
                width="100px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                style={{ whiteSpace: 'nowrap' }}
              >
                <Text
                  fw={'bold'}
                  fs={12}
                  lh={'140%'}
                  ls={'0.1em'}
                  style={{ color: selectValues.filter((i) => i === c.name).length > 0 ? '#00D4A1' : '#041E42' }}
                >
                  {c.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
        <Box width="100%" height="100px">
          <MoveButton
            disable={selectValues.length <= 0}
            answers={answer}
            setAnswer={setAnswer}
            onMoveAnimation={onMoveAnimation}
            question={question}
            anyFunction={nextQuestion}
            isFixed={true}
          />
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box
          width="800px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          m={'auto'}
          mt={-4}
        >
          {icons.map((c, index) => (
            <Box width="130px" height="86px" mt={7} onClick={() => onChange(c.name)} className={classes.baseBox}>
              <Box width="130px" height="50px" m={'auto'}>
                {selectValues.filter((i) => i === c.name).length > 0 ? hilightIcons[index].icon : c.icon}
              </Box>
              <Box
                mt={4}
                width="130px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                style={{ whiteSpace: 'nowrap' }}
              >
                <Text
                  fw={'bold'}
                  fs={14}
                  lh={'140%'}
                  ls={'0.1em'}
                  style={{ color: selectValues.filter((i) => i === c.name).length > 0 ? '#00D4A1' : '#041E42' }}
                >
                  {c.name}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
        <MoveButton
          disable={selectValues.length <= 0}
          answers={answer}
          setAnswer={setAnswer}
          onMoveAnimation={onMoveAnimation}
          question={question}
          anyFunction={nextQuestion}
        />
      </Hidden>
    </>
  )
}

export default CheckBox
