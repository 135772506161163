import React, { useState } from 'react'
import { Box, List, ListItem, Collapse, Hidden, Divider } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'
//import ChanelDeveloper from '../components/ChanellDeveloper'

interface H1PcYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1PcYuGothic = Styled.h1<H1PcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`
interface LinePcYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const LinePcYuGothic = Styled.h2<LinePcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 20px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`
interface H2PcYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2PcYuGothic = Styled.h2<H2PcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface PPcYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PPcYuGothic = Styled.p<PPcYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  line-height: ${(props) => (props.lh ? props.lh : '35px')};
  letter-spacing: ${(props) => (props.ls ? props.ls : '0.1em')};
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface H1SpYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H1SpYuGothic = Styled.h1<H1SpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface H2SpYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const H2SpYuGothic = Styled.h2<H2SpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`

interface PSpYuGothic {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const PSpYuGothic = Styled.p<PSpYuGothic>`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: ${(props) => (props.lh ? props.lh : '22px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
  max-width : 100% ;
  word-break: break-all;
`

const FAQ = () => {
  // ChanelDeveloper.boot({
  //   pluginKey: '8324e2a5-9a85-4d44-a57a-2118a321f35b' //please fill with your plugin key
  // })

  const [open1, setOpen1] = useState(false)
  const handleClick1 = () => {
    setOpen1(!open1)
  }

  const [open2, setOpen2] = useState(false)
  const handleClick2 = () => {
    setOpen2(!open2)
  }

  const [open3, setOpen3] = useState(false)
  const handleClick3 = () => {
    setOpen3(!open3)
  }

  const [open4, setOpen4] = useState(false)
  const handleClick4 = () => {
    setOpen4(!open4)
  }

  const [open5, setOpen5] = useState(false)
  const handleClick5 = () => {
    setOpen5(!open5)
  }

  const [open6, setOpen6] = useState(false)
  const handleClick6 = () => {
    setOpen6(!open6)
  }

  const [open7, setOpen7] = useState(false)
  const handleClick7 = () => {
    setOpen7(!open7)
  }

  const [open8, setOpen8] = useState(false)
  const handleClick8 = () => {
    setOpen8(!open8)
  }

  const [open9, setOpen9] = useState(false)
  const handleClick9 = () => {
    setOpen9(!open9)
  }

  const [open10, setOpen10] = useState(false)
  const handleClick10 = () => {
    setOpen10(!open10)
  }

  const [open11, setOpen11] = useState(false)
  const handleClick11 = () => {
    setOpen11(!open11)
  }

  const [open12, setOpen12] = useState(false)
  const handleClick12 = () => {
    setOpen12(!open12)
  }

  const [open13, setOpen13] = useState(false)
  const handleClick13 = () => {
    setOpen13(!open13)
  }

  const [open14, setOpen14] = useState(false)
  const handleClick14 = () => {
    setOpen14(!open14)
  }

  const [open15, setOpen15] = useState(false)
  const handleClick15 = () => {
    setOpen15(!open15)
  }

  const [open16, setOpen16] = useState(false)
  const handleClick16 = () => {
    setOpen16(!open16)
  }

  const [open17, setOpen17] = useState(false)
  const handleClick17 = () => {
    setOpen17(!open17)
  }

  const [open18, setOpen18] = useState(false)
  const handleClick18 = () => {
    setOpen18(!open18)
  }

  const [open19, setOpen19] = useState(false)
  const handleClick19 = () => {
    setOpen19(!open19)
  }

  const [open20, setOpen20] = useState(false)
  const handleClick20 = () => {
    setOpen20(!open20)
  }

  const [open21, setOpen21] = useState(false)
  const handleClick21 = () => {
    setOpen21(!open21)
  }

  const [open22, setOpen22] = useState(false)
  const handleClick22 = () => {
    setOpen22(!open22)
  }

  const [open23, setOpen23] = useState(false)
  const handleClick23 = () => {
    setOpen23(!open23)
  }

  const [open24, setOpen24] = useState(false)
  const handleClick24 = () => {
    setOpen24(!open24)
  }
  const [open25, setOpen25] = useState(false)
  const handleClick25 = () => {
    setOpen25(!open25)
  }

  const lineQuestion = [
    {
      question: 'LINE Botの使い方が分からない',
      answer:
        'このBotでは、各種登録情報の確認・変更に加え、毎日の天気予報やサプリメント・健康管理に関する情報を受け取ることができます。操作方法は、画面最下部のメニューバーよりご確認ください。',
      handleClick: handleClick1,
      open: open1
    },
    {
      question: 'LINE Botが使いにくい',
      answer:
        'ご指摘ありがとうございます。製品・サービスに関するフィードバックは、画面最下部のメニューバーよりいただけますと幸いです。',
      handleClick: handleClick2,
      open: open2
    }
  ]

  const supplementQuestion = [
    {
      question: 'いつ飲めばいいのか',
      answer: '毎日お好きなタイミングでお飲みください。飲み忘れ防止のため、食事後など決めておくことがお勧めです。',
      handleClick: handleClick3,
      open: open3
    },
    {
      question: '副作用などはないのか',
      answer:
        'サプリメントの配合成分は、医師やサプリメントアドバイザーと共同開発しております。万が一ご心配の場合はかかりつけの医師にご相談ください',
      handleClick: handleClick4,
      open: open4
    },
    {
      question: '他の薬、サプリメントと併用して大丈夫か',
      answer:
        'サプリメントは健康食品ですので、併用いただいても問題ありません。ご心配な場合は、かかりつけの医師にご相談ください。',
      handleClick: handleClick5,
      open: open5
    },
    {
      question: '賞味期限はいつまでか',
      answer: '個包装、商品箱、外箱にそれぞれ賞味期限を記載しておりますので、ご確認いただけますと幸いです。',
      handleClick: handleClick6,
      open: open6
    },
    {
      question: 'アレルギーはあるか',
      answer:
        'サプリメント一覧に、対象となる成分を記載しておりますので、ご使用前に必ずご確認ください。（*https://supplement.ihack.co.jp/supplementList*）',
      handleClick: handleClick7,
      open: open7
    },
    {
      question: '処方内容を変更することは可能か',
      answer:
        '次回配送予定日の10日前までであれば可能です。こちらのお問い合わせフォームよりご連絡ください。（*https://forms.gle/onFz578E1wEdcpgE8*）',
      handleClick: handleClick8,
      open: open8
    },
    {
      question: 'どのように保存したらいいか',
      answer: '直射日光や高温多湿を避け、室内の涼しい場所での保管を推奨しています。',
      handleClick: handleClick9,
      open: open9
    },
    {
      question: '身体に合わない場合はどうするか',
      answer:
        '商品に使用している原材料にアレルギーのある方はお避けいただいております。また、体質に合わない方は、お申し込みいただいている定期購入の中止等、ご対応をお願いいたします。',
      handleClick: handleClick10,
      open: open10
    }
  ]

  const subscriptionQuestion = [
    {
      question: '解約について',
      answer: '解約をご希望の場合は、以下のリンクより手続きをお願いいたします(*https://forms.gle/o7Gy9DSTiJMfFn626*)。',
      handleClick: handleClick11,
      open: open11
    },
    {
      question: '次回配送日時の変更について',
      answer: 'こちらの画面より、次回配送日時の変更が可能です。(*https://ec.ihack.energy/shop/customer/subs_orders*)',
      handleClick: handleClick12,
      open: open12
    },
    {
      question: '定期便の再開について',
      answer: '定期便の再開は、サポート窓口までご連絡ください。',
      handleClick: handleClick13,
      open: open13
    },
    {
      question: '次回の発送予定・配送状況はどこで確認できるか',
      answer: 'マイページより次回のお届け日を確認することができます。（*https://ec.ihack.energy/shop/customer*）',
      handleClick: handleClick14,
      open: open14
    },
    {
      question: '配送のスキップについて',
      answer:
        'スキップはできませんが、こちらの画面より、次回配送日時の変更が可能です。(*https://ec.ihack.energy/shop/customer/subs_orders*)',
      handleClick: handleClick15,
      open: open15
    }
  ]

  const paymentQuestion = [
    {
      question: '注文内容を確認したい',
      answer: '現在の注文内容は、以下のリンクよりご確認ください(*https://ec.ihack.energy/shop/customer/orders*)。',
      handleClick: handleClick16,
      open: open16
    },
    {
      question: '送料について',
      answer: '配達送料は、初回のみ無料、2回目以降500円頂戴しております。',
      handleClick: handleClick17,
      open: open17
    },
    {
      question: 'お支払い方法について',
      answer:
        '毎月のお支払いには、各種クレジットカード、またはAmazon Payがご利用頂けます。お支払い方法は、以下のリンクより変更頂けます(*https://ec.ihack.energy/shop/customer/cards*)。',
      handleClick: handleClick18,
      open: open18
    },
    {
      question: '友達招待について',
      answer:
        'ご紹介いただいたご友人がiHackを購入した場合、次回の決済が1000円引きになるポイント（1000ポイント）をプレゼントしています。招待コードはこちらより発行いただけます。(*https://ec.ihack.energy/shop/customer/points*）',
      handleClick: handleClick19,
      open: open19
    },
    {
      question: '領収書の発行について',
      answer: '大変申し訳ございませんが、領収書の発行は承っておりません。',
      handleClick: handleClick20,
      open: open20
    }
  ]

  const deliveryQuestion = [
    {
      question: '配送先住所を変更したい',
      answer: '配送先住所の変更は、以下のリンクよりご確認ください(*https://ec.ihack.energy/shop/customer/edit*)。',
      handleClick: handleClick21,
      open: open21
    },
    {
      question: '配送状況を確認したい',
      answer: '現在の配送状況は、以下のリンクよりご確認ください(*https://ec.ihack.energy/shop/customer/edit*)。',
      handleClick: handleClick22,
      open: open22
    }
  ]

  return (
    <>
      <Header />
      <Hidden mdDown>
        <Box width="1080px" mx="auto" mb={10} pt={20} textAlign="center">
          <H1PcYuGothic mb="64px">Q&A</H1PcYuGothic>

          <H2PcYuGothic mb="24px">サプリメントについて</H2PcYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {supplementQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2PcYuGothic>Q: {value.question}</H2PcYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PPcYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PPcYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>

          <H2PcYuGothic mb="24px">定期購入について</H2PcYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {subscriptionQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2PcYuGothic>Q: {value.question}</H2PcYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PPcYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PPcYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}

            <ListItem button onClick={handleClick24} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: 14日間全額返金補償について</H2SpYuGothic>
              {open24 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open24} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PPcYuGothic>
                    {' '}
                    A: 返金をご希望の場合、こちらの
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3Vu-TaM4E5k5vEiuHn4z_USQXdX4NSCwFfeI_G1M3K2VuJg/viewform">
                      返金申し込みフォーム
                    </a>
                    から返金をご申し込みください。
                    <br />
                    返金保証制度のご利用は、以下の条件のお客様が対象となります。
                    <br />
                    (1)初回購入であること（返金保証の対象は初回1箱分です）
                    <br />
                    (2)商品到着後14日以内の連絡で、14袋以下の使用であること
                    <br />
                    (3)返金申し込み後10日以内に指定住所まで商品の返送をいただくこと(返送にかかる送料はお客様負担となります)
                    <br />
                    (4)同一住所への配送が過去にないこと
                    <br />
                    <br />
                    ▼ご返送いただく物
                    <br />
                    1. サプリメント(16日分以上)
                    <br />
                    2. サプリカード
                    <br />
                    3. ブランドブック
                    <br />
                    4. 白い箱梱包箱
                    <br />
                    ※ご注意点※
                    <br />
                    ・弊社よりお伝えする返品先のご住所以外へお送りいただいた場合、上記の返金保証が対応でき兼ねますので、お気をつけください。
                    <br />
                    ・弊社倉庫への返品は破棄されてしまいますので、お気をつけください。
                    <br />
                    ・ご購入時の送料は返金対象外となります。
                    <br />
                    ・ご返送時の送料や返金の際の振込手数料は、お客様負担とさせていただきます。
                  </PPcYuGothic>
                </ListItem>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={handleClick25} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: キャンペーン価格購入での初回キャンセルについて</H2SpYuGothic>
              {open25 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open25} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PPcYuGothic>
                    A:
                    サプリメントは効果を実感いただくためにも、最低でも3ヶ月以上のご利用を推奨しておりますが、最低購入回数のお約束や縛りなどはありません。
                    <br />
                    特に初回のお届けは、始めていただきやすいよう、高い割引率にてご提供させていただいております。
                    <br />
                    ただし、初回限定のキャンペーン価格でのご購入後、2回目をお受け取りいただけずに定期購入を解約する場合のみ、別途キャンペーン分の差額5,000円（税込み）を請求させていただきます。
                  </PPcYuGothic>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
          </List>

          <H2PcYuGothic mb="24px">支払いについて</H2PcYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {paymentQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2PcYuGothic>Q: {value.question}</H2PcYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PPcYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PPcYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>

          <H2PcYuGothic mb="24px">配送について</H2PcYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {deliveryQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2PcYuGothic>Q: {value.question}</H2PcYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PPcYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PPcYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}

            <ListItem button onClick={handleClick23} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: 注文してどのくらいで届きますか</H2SpYuGothic>
              {open23 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open23} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PPcYuGothic>
                    A: ご注文からおよそ1週間ほどでお届けしています。
                    <br />
                    ※地域によってお届け日数に違いがございますことをご了承ください。
                    <br />
                    ※天候や交通状況により遅れが発生する場合がございます。土日祝、年末年始を除き営業しております。
                  </PPcYuGothic>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
          </List>

          <H2PcYuGothic mb="24px">LINE BOTについて</H2PcYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {lineQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2PcYuGothic>Q: {value.question}</H2PcYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PPcYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PPcYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box width="84vw" mx="auto" mb={10} pt={20} textAlign="center">
          <H1SpYuGothic mb="64px">Q&A</H1SpYuGothic>

          <H2SpYuGothic mb="24px">サプリメントについて</H2SpYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {supplementQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2SpYuGothic>Q: {value.question}</H2SpYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PSpYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PSpYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>

          <H2SpYuGothic mb="24px">定期購入について</H2SpYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {subscriptionQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2SpYuGothic>Q: {value.question}</H2SpYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PSpYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PSpYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
            <ListItem button onClick={handleClick24} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: 14日間の全額返金補償について</H2SpYuGothic>
              {open24 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open24} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PSpYuGothic>
                    {' '}
                    A:返金をご希望の場合、こちらの
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3Vu-TaM4E5k5vEiuHn4z_USQXdX4NSCwFfeI_G1M3K2VuJg/viewform">
                      返金申し込みフォーム
                    </a>
                    から返金をご申し込みください。
                    <br />
                    返金保証制度のご利用は、以下の条件のお客様が対象となります。
                    <br />
                    (1)初回購入であること（返金保証の対象は初回1箱分です）
                    <br />
                    (2)商品到着後14日以内の連絡で、14袋以下の使用であること
                    <br />
                    (3)返金申し込み後10日以内に指定住所まで商品の返送をいただくこと(返送にかかる送料はお客様負担となります)
                    <br />
                    (4)同一住所への配送が過去にないこと
                    <br />
                    ※ご注意点※
                    <br />
                    ・弊社よりお伝えする返品先のご住所以外へお送りいただいた場合、上記の返金保証が対応でき兼ねますので、お気をつけください。
                    <br />
                    ・弊社倉庫への返品は破棄されてしまいますので、お気をつけください。
                    <br />
                    ・ご購入時の送料は返金対象外となります。
                    <br />
                    ・ご返送時の送料や返金の際の振込手数料は、お客様負担とさせていただきます。
                  </PSpYuGothic>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
            <ListItem button onClick={handleClick25} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: キャンペーン価格購入での初回キャンセルについて</H2SpYuGothic>
              {open25 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open25} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PSpYuGothic>
                    A:
                    サプリメントは効果を実感いただくためにも、最低でも3ヶ月以上のご利用を推奨しておりますが、最低購入回数のお約束や縛りなどはありません。
                    <br />
                    特に初回のお届けは、始めていただきやすいよう、高い割引率にてご提供させていただいております。
                    <br />
                    ただし、初回限定のキャンペーン価格でのご購入後、2回目をお受け取りいただけずに定期購入を解約する場合のみ、別途キャンペーン分の差額5,000円（税込み）を請求させていただきます
                  </PSpYuGothic>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
          </List>

          <H2SpYuGothic mb="24px">支払いについて</H2SpYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {paymentQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2SpYuGothic>Q: {value.question}</H2SpYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ marginBottom: '24px 0' }}>
                      <ListItem button>
                        <PSpYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PSpYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>

          <H2SpYuGothic mb="24px">配送について</H2SpYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {deliveryQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2SpYuGothic>Q: {value.question}</H2SpYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PSpYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PSpYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}

            <ListItem button onClick={handleClick23} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <H2SpYuGothic>Q: 注文してどのくらいで届きますか</H2SpYuGothic>
              {open23 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open23} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ margin: '24px 0' }}>
                <ListItem button>
                  <PSpYuGothic>
                    A: ご注文からおよそ1週間ほどでお届けしています。
                    <br />
                    ※地域によってお届け日数に違いがございますことをご了承ください。
                    <br />
                    ※天候や交通状況により遅れが発生する場合がございます。土日祝、年末年始を除き営業しております。
                  </PSpYuGothic>
                </ListItem>
              </List>
            </Collapse>
          </List>

          <H2SpYuGothic mb="24px">LINE BOTについて</H2SpYuGothic>
          <List style={{ marginBottom: '24px' }}>
            {lineQuestion.map((value: any) => {
              return (
                <>
                  <ListItem
                    button
                    onClick={value.handleClick}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <H2SpYuGothic>Q: {value.question}</H2SpYuGothic>
                    {value.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Divider />
                  <Collapse in={value.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ margin: '24px 0' }}>
                      <ListItem button>
                        <PSpYuGothic>
                          A:
                          {value.answer.split('*').map((link: any, i: number) => {
                            return i % 2 === 1 ? <a href={link}>{link}</a> : link
                          })}
                        </PSpYuGothic>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )
            })}
          </List>
        </Box>
      </Hidden>
      <Footer />
    </>
  )
}

export default FAQ
