export const firstQuestionId = 1
// 最後のQuestionID
export const lastQuestionId = 42
// お悩みのQuestionID
export const worriesId = 10
// 一番のお悩み
export const mostWorriesId = 11
// 理想の体型のQuestionID
export const figuaId = 8
// 性交渉に関する質問
export const sexualQuestionId1 = 29
export const sexualQuestionId2 = 30

//アレルギーに関する質問
export const allergieId = 43
