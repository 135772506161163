import React from 'react'
import { Box, Hidden, Grid, Link } from '@material-ui/core'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Styled from 'styled-components'

const H1PcYuGothic = Styled.h1`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const H2PcYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin: 0;
`

const H3PcYuGothic = Styled.h3`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const PPcYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
`

interface SpanPcCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const SpanPcCeraPro = Styled.span<SpanPcCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  line-height: ${(props) => (props.fs ? props.fs : '35px')};
  letter-spacing: '0.1em';
  color: #041E42;
  margin: 0;
`

const H1SpYuGothic = Styled.h1`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const H2SpYuGothic = Styled.h2`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 45px;
  letter-spacing: 0.08em;
  color: #041E42;
  margin: 0;
`

const H3SpYuGothic = Styled.h3`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: 8px;
`

const PSpYuGothic = Styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #041E42;
  margin-top: 0;
  margin-bottom: 24px;
`

interface SpanSpCeraPro {
  ff?: string
  fs?: string
  lh?: string
  ls?: string
  mb?: string
}

const SpanSpCeraPro = Styled.span<SpanSpCeraPro>`
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.fs ? props.fs : '14px')};
  line-height: ${(props) => (props.fs ? props.fs : '18px')};
  letter-spacing: 0.1em;
  color: #041E42;
  margin: 0;
`

const Company = () => {
  return (
    <>
      <Header />
      <FirstView />
      <Main />
      <Footer />
    </>
  )
}

const FirstView = () => {
  return (
    <>
      <Hidden mdDown>
        <Box
          position="relative"
          mb={15}
          width="100%"
          height="100vh"
          style={{
            background: `url(./container/result/top.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box position="absolute" top="42%" left="7%">
            <H1PcYuGothic>
              株式会社
              <SpanPcCeraPro fs="24px" lh="36px">
                iHack
              </SpanPcCeraPro>
            </H1PcYuGothic>
            <H2PcYuGothic>挑戦に向かう意志を、後押しする。</H2PcYuGothic>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box
          mb={15}
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          bgcolor="#f3f3f3"
          style={{
            backgroundImage: `url(./container/result/SP_top.png)`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 30px',
            objectFit: 'contain'
          }}
        >
          <Box width="88vw" mx="auto">
            <H1SpYuGothic>
              株式会社
              <SpanSpCeraPro fs="24px" lh="36px">
                iHack
              </SpanSpCeraPro>
            </H1SpYuGothic>
            <H2SpYuGothic>
              挑戦に向かう意志を、
              <br />
              後押しする。
            </H2SpYuGothic>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

const Main = () => {
  return (
    <>
      <Hidden mdDown>
        <Box width="60vw" mx="auto" mb={10}>
          <H2PcYuGothic style={{ textAlign: 'center', marginBottom: '64px' }}>Company</H2PcYuGothic>

          <Box textAlign="left">
            <Grid container spacing={6}>
              <Grid item lg={4}>
                <H3PcYuGothic>会社名</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  株式会社<SpanPcCeraPro>iHack</SpanPcCeraPro>（<SpanPcCeraPro>iHack</SpanPcCeraPro>, Inc.）
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>代表</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>富田真人（とみた　まさと）</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>設立</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>2019年9月30日</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>所在地</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  〒160-0003
                  <br />
                  東京都新宿区四谷本塩町2-8 A YOTSUYA 4N/3
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>資本金</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>2,000万円</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>事業内容</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>
                  <SpanPcCeraPro>iHack</SpanPcCeraPro>の開発及び運営
                </PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>取引銀行</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <PPcYuGothic>三井住友銀行　渋谷支店</PPcYuGothic>
              </Grid>

              <Grid item lg={4}>
                <H3PcYuGothic>お問い合わせ</H3PcYuGothic>
              </Grid>
              <Grid item lg={8}>
                <H3PcYuGothic>
                  <Link
                    href="https://ihack-energy.channel.io/"
                    style={{ color: '#041E42', borderBottom: 'solid 1px #041E42' }}
                  >
                    お問い合わせフォームはこちら
                  </Link>
                </H3PcYuGothic>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Box width="84vw" mx="auto" mb={10}>
          <H2SpYuGothic style={{ textAlign: 'center', marginBottom: '40px' }}>Company</H2SpYuGothic>

          <Box textAlign="left">
            <H3SpYuGothic>会社名</H3SpYuGothic>
            <PSpYuGothic>
              株式会社<SpanSpCeraPro>iHack</SpanSpCeraPro>（<SpanSpCeraPro>iHack</SpanSpCeraPro>, Inc.）
            </PSpYuGothic>

            <H3SpYuGothic>代表</H3SpYuGothic>
            <PSpYuGothic>富田真人（とみた　まさと）</PSpYuGothic>

            <H3SpYuGothic>設立</H3SpYuGothic>
            <PSpYuGothic>2019年9月30日</PSpYuGothic>

            <H3SpYuGothic>所在地</H3SpYuGothic>
            <PSpYuGothic>
              〒160-0003
              <br />
              東京都新宿区四谷本塩町2-8 A YOTSUYA 4N/3
            </PSpYuGothic>

            <H3SpYuGothic>資本金</H3SpYuGothic>
            <PSpYuGothic>2,000万円</PSpYuGothic>

            <H3SpYuGothic>事業内容</H3SpYuGothic>
            <PSpYuGothic>
              <SpanSpCeraPro>iHack</SpanSpCeraPro>の開発及び運営
            </PSpYuGothic>

            <H3SpYuGothic>取引銀行</H3SpYuGothic>
            <PSpYuGothic>三井住友銀行　渋谷支店</PSpYuGothic>

            <H3SpYuGothic>
              <Link
                href="https://ec.ihack.energy/shop/contact/draft"
                style={{ color: '#041E42', borderBottom: 'solid 1px #041E42' }}
              >
                お問い合わせはこちら
              </Link>
            </H3SpYuGothic>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default Company
