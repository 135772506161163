import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SupplementCards1 } from "../../../pages/supplements";
import { SupplementCards2 } from "../../../pages/supplements";
import { SupplementCards3 } from "../../../pages/supplements";
import { Box, Button } from "@material-ui/core";
import Supplement from "../../container/result/SupplementCard";

const names: (
  | "Vitamin"
  | "Mineral"
  | "Omega3"
  | "Recovery"
  | "Chill"
  | "Zone"
  | "Memory"
  | "Vitality"
  | "Style"
)[] = [
  "Vitamin",
  "Mineral",
  "Omega3",
  "Recovery",
  "Chill",
  "Zone",
  "Memory",
  "Vitality",
  "Style"
];

const SampleNextArrow = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return <Button onClick={onClick}></Button>;
};

const SamplePrevArrow = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return <Button onClick={onClick}></Button>;
};

const CardSlider = () => {
  return (
    <Box>
      <Box maxWidth="900px" minWidth="600px">
        <Box>
          <Slider
            dots={false}
            infinite={true}
            speed={500}
            slidesToScroll={3}
            slidesToShow={3}
            arrows={true}
            centerMode={false}
            nextArrow={<SampleNextArrow onClick={() => {}} />}
            prevArrow={<SamplePrevArrow onClick={() => {}} />}
            responsive={[
              {
                breakpoint: 1100,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerPadding: "100px",
                  swipeToSlide: true,
                  infinite: false,
                  arrows: false,
                  initialSlide: 0
                }
              }
            ]}
          >
            {names.map(c => {
              return (
                <Box height="450px">
                  <Box mt={4} ml={4}>
                    <Supplement
                      ingredient={c}
                      DetailDrowOpen={(name: string) => {}}
                    />
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};
export default CardSlider;
