// 色々使えそうな関数を格納
import React from 'react'

// \nが入った文字列に<br />を入れ込む
const nlRegex = /(\r\n|\r|\n)/g
export const nl2br = (str: string) =>
  str.split(nlRegex).map((line, i) =>
    line.match(nlRegex) ? (
      <>
        <br key={i} />
      </>
    ) : (
      line
    )
  )

export const flatten = (xs: any) =>
  xs.reduce((acc: any, e: any) => (Array.isArray(e) ? [...acc, ...flatten(e)] : [...acc, e]), [])

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const useInput = (initalValue: string) => {
  const [value, set] = React.useState(initalValue)
  return { value, onChange: (e: any) => set(e) }
}
